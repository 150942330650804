import {useDispatch} from 'react-redux'
import {showLoginModal} from '@/redux/modules/layout/actions'
import Result from '@/components/shared/Result'
import resultStyles from '@/styles/molecules/Result/styles.module.css'

function ResultLogin() {
  const dispatch = useDispatch()
  const handleResultButtonClick = () => {
    dispatch(showLoginModal({show: true}))
  }

  return (
    <div className={resultStyles.ecResult__wrapper}>
      <Result
        icon="/img/icons/people.svg"
        title="Entrar na minha conta"
        text="Para exibir esse conteúdo precisamos saber quem é você."
        buttons={[
          {
            active: true,
            label: 'Entrar',
            onClick: handleResultButtonClick
          }
        ]}
      />
    </div>
  )
}

export default ResultLogin
