import Button from '@emcasa/ui-dom/components/Button'

export default function YesNoButtonGroup({onChange, value}) {
  return (
    <Button.Group initialValue={value} onChange={onChange}>
      <Button type="button" fluid mr={5} value={true}>
        Sim
      </Button>
      <Button type="button" fluid value={false}>
        Não
      </Button>
    </Button.Group>
  )
}
