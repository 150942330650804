import {LOG_LEVEL, UI_VERSION} from '@/config'
import GET_USER_PROFILE from '@/graphql/queries/userProfile'
import reporter from '@/config/logger'
import * as sentry from './sentry'
import * as amplitude from './amplitude'
import * as GTM from './gtm'
import * as Pavlov from './pavlov'
import {createLogger} from './levels'
import {
  getActiveExperiments,
  getActiveExperimentsList
} from '@/lib/hooks/useExperiment'
import {getAppVersion} from '@/lib/experiments'

let currentUser = null
let currentFcmToken = null

const generateEventID = () =>
  self.crypto.getRandomValues(new Uint32Array(1))[0].toString()

/**
 * Enhance the logger to return the first argument on log functions
 */
const logger = createLogger(
  (level = 'log') => (ret, ...message) => {
    reporter[level](ret, ...message)
    return ret
  },
  reporter
)

/**
 * Logs exceptions and submits to sentry
 */
logger.capture = function capture(error) {
  if (reporter.capture) reporter.capture(error)
  sentry.capture(error)
  return error
}

/**
 * Logs user interactions on the front-end and submits to all trackers
 */
logger.action = function action(event, props) {
  const eventProps = Object.assign(props || {}, {
    eventID: generateEventID(),
    uiVersion: getAppVersion() || UI_VERSION,
    ...getActiveExperiments(),
    experiments: getActiveExperimentsList(),
    path: window && window.location ? window.location.pathname : null,
    windowScrollY: window && window.scrollY ? window.scrollY : null
  })
  if (reporter.action) reporter.action(event, eventProps)
  sentry.log(event, {category: 'action', level: 'info', ...props})

  if (currentUser && currentUser.role === 'admin') return

  amplitude.logEvent(event, eventProps)
  GTM.logEvent(event, eventProps)
  Pavlov.logEvent(event, eventProps, currentUser)

  if (typeof global.hj === 'function') {
    global.hj('tagRecording', [event])
    global.hj('trigger', event)
  }
}

/**
 *
 * Identify user and submits on Amplitude, Sentry and GTM
 */
logger.identify = function identify(user, fcmToken = null) {
  if (fcmToken !== null) {
    currentFcmToken = fcmToken
  }
  if (user !== null) {
    currentUser = user
  }
  const userProps = Object.assign(user || currentUser || {}, {
    ...getActiveExperiments(),
    uiVersion: getAppVersion() || UI_VERSION,
    fcmToken: currentFcmToken,
    experiments: getActiveExperimentsList()
  })
  sentry.identify(userProps)
  amplitude.identify(userProps)
  GTM.identify(userProps)
}

/**
 * Init all avaialble loggers and subscribe to user events
 */
logger.init = function init(ctx) {
  const {apolloClient} = ctx
  sentry.init()
  apolloClient
    .watchQuery({
      query: GET_USER_PROFILE,
      fetchPolicy: 'cache-first',
      errorPolicy: 'ignore'
    })
    .subscribe(({data}) => {
      if (data && data.userProfile) {
        logger.identify(data.userProfile)
      }
    })
  logger.identify()
}

logger.setLevel(LOG_LEVEL)

// Logger is available globally for debugging
global.logger = logger

export default logger
