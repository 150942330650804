// Seller create
export const SELLER_ONBOARDING_PAGE = 'seller-onboarding-page'
export const SELLER_ONBOARDING_STEP_FORWARD = 'seller-onboarding-step-forward'
export const SELLER_ONBOARDING_STEP_LISTING = 'seller-onboarding-step-listing'
export const SELLER_ONBOARDING_STEP_LISTING_TYPES =
  'seller-onboarding-step-listing-types'
export const SELLER_ONBOARDING_STEP_LISTING_ADDRESS_FOCUS =
  'seller-onboarding-step-listing-address-focus'
export const SELLER_ONBOARDING_STEP_LISTING_ADDRESS_DATA =
  'seller-onboarding-step-listing-address-data'
export const SELLER_ONBOARDING_STEP_LISTING_COMPLEMENT =
  'seller-onboarding-step-listing-complement'
export const SELLER_ONBOARDING_STEP_LISTING_STREETNUMBER =
  'seller-onboarding-step-listing-streetNumber'
export const SELLER_ONBOARDING_STEP_LISTING_PRICE =
  'seller-onboarding-step-listing-price'
export const SELLER_ONBOARDING_SUCCESS = 'seller-onboarding-success'

export const SELLER_ONBOARDING_ONSUBMITFORM = 'seller-onboarding-onsubmitform'
export const SELLER_ONBOARDING_STEP_BUTTON_BACK =
  'seller-onboarding-step-button-back'
export const SELLER_ONBOARDING_STEP_BUTTON_NEXT =
  'seller-onboarding-step-button-next'
export const SELLER_ONBOARDING_STEP_CONTACT = 'seller-onboarding-step-contact'
export const SELLER_ONBOARDING_STEP_CONTACT_NAME =
  'seller-onboarding-step-contact-name'
export const SELLER_ONBOARDING_STEP_CONTACT_PHONE =
  'seller-onboarding-step-contact-phone'
export const SELLER_ONBOARDING_STEP_CONTACT_EMAIL =
  'seller-onboarding-step-contact-email'
export const SELLER_ONBOARDING_STEP_SEND = 'seller-onboarding-step-send'
export const SELLER_ONBOARDING_STEP_SEND_SUCCESS =
  'seller-onboarding-step-send-success'
export const SELLER_ONBOARDING_STEP_SEND_ADDRESSISNOTCOVERED =
  'seller-onboarding-step-send-addressIsNotCovered'
export const SELLER_ONBOARDING_STEP_SEND_ERROR =
  'seller-onboarding-step-send-error'
export const SELLER_ONBOARDING_STEP_SUCCESS = 'seller-onboarding-step-success'
export const SELLER_ONBOARDING_STEP_ERROR = 'seller-onboarding-step-error'
export const SELLER_ONBOARDING_STEP_LISTING_STREETNUMBEROTFOUNDED =
  'seller-onboarding-step-listing-streetnumberotfounded'
