import styled from 'styled-components'
import {themeGet} from '@styled-system/theme-get'
import {elevation} from '@emcasa/ui/lib/styles'

export const Marker = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: white;
  margin-top: 3px;
  ${elevation};
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -5px;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: ${themeGet('colors.pink500')};
  }
`

Marker.defaultProps = {elevation: 3}

export const InputWrapper = styled.div`
  flex: 1;
  padding-left: ${themeGet('space.2')}px;
`

export const Input = styled.input`
  width: 100%;
`

export const trackProps = {
  default: {bg: 'grey100', height: 8, style: {marginTop: 0}},
  active: {bg: 'pink500', height: 8, style: {marginTop: 0}}
}
