import PropTypes from 'prop-types'
import Button from '@/components/shared/Button'
import ButtonLink from '@/components/shared/Button/Link'
import classNames from 'classnames'
import styles from './styles.module.css'

const Banner = ({
  noPadding,
  onlyMobileLayout,
  large,
  pinkBg,
  greyBg,
  noBox,
  pinkBox,
  greyBox,
  darkBox,
  icon,
  iconFull,
  image,
  title,
  text,
  button,
  smallPadding
}) => {
  const bannerClasses = classNames(styles.ecBanner, {
    [styles.ecBanner_noPadding]: noPadding,
    [styles.ecBanner_desktop]: !onlyMobileLayout,
    [styles.ecBanner_pink]: pinkBg,
    [styles.ecBanner_grey]: greyBg
  })
  const containerClasses = classNames(styles.ecBanner__container, {
    [styles.ecBanner__container_pink]: pinkBox,
    [styles.ecBanner__container_grey]: greyBox,
    [styles.ecBanner__container_dark]: darkBox,
    [styles.ecBanner__container_noBox]: noBox,
    [styles.ecBanner__container_smallPadding]: smallPadding,
    [styles.ecBanner__container_large]: large
  })
  const iconWrapperClasses = classNames(styles.ecBanner__iconWrapper, {
    [styles.ecBanner__iconWrapper_grey]:
      (noBox && greyBg) || (!noBox && greyBox),
    [styles.ecBanner__iconWrapper_full]: iconFull
  })
  const iconClasses = classNames(styles.ecBanner__icon, {
    [styles.ecBanner__icon_full]: iconFull
  })
  const imageClasses = classNames(styles.ecBanner__image)
  const textWrapperClasses = classNames(styles.ecBanner__textWrapper, {
    [styles.ecBanner__textWrapper_dark]: darkBox,
    [styles.ecBanner__textWrapper_noMarginBottom]: !button
  })

  return (
    <div className={bannerClasses}>
      <div className={containerClasses}>
        {icon && (
          <div className={iconWrapperClasses}>
            <img className={iconClasses} src={icon} width="24" alt="Ícone" />
          </div>
        )}
        {image && (
          <img
            className={imageClasses}
            src={image.src}
            width={image.width}
            height={image.height}
            style={
              process.browser
                ? {
                    '--maxwidth': `${image.width}px` || '280px'
                  }
                : {}
            }
          />
        )}
        <div className={textWrapperClasses}>
          {title && <h2 className={styles.ecBanner__title}>{title}</h2>}
          {text && <p className={styles.ecBanner__text}>{text}</p>}
        </div>
        {button &&
          (button.to || button.href ? (
            <ButtonLink
              active
              to={button.to}
              href={button.href}
              className={styles.ecBanner__button}
              onClick={button.onClick}
              {...button.props}
            >
              {button.label}
            </ButtonLink>
          ) : (
            <Button
              active
              className={styles.ecBanner__button}
              onClick={button.onClick}
              {...button.props}
            >
              {button.label}
            </Button>
          ))}
      </div>
    </div>
  )
}

Banner.propTypes = {
  noPadding: PropTypes.bool,
  onlyMobileLayout: PropTypes.bool,
  large: PropTypes.bool,
  pinkBg: PropTypes.bool,
  greyBg: PropTypes.bool,
  pinkBox: PropTypes.bool,
  greyBox: PropTypes.bool,
  darkBox: PropTypes.bool,
  noBox: PropTypes.bool,
  icon: PropTypes.string,
  iconFull: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    href: PropTypes.string,
    props: PropTypes.object,
    onClick: PropTypes.func
  }),
  smallPadding: PropTypes.bool
}

export default Banner
