const loadScript = ({id, url, callback, idsToIgnore, defer = false}) => {
  const scriptElement = document.querySelectorAll(
    idsToIgnore
      ? `#${id}, ${idsToIgnore.map((id) => `#${id}`).join(', ')}`
      : `#${id}`
  )

  if (scriptElement.length === 0) {
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.src = url
    script.id = id
    script.defer = defer

    document.getElementsByTagName('head')[0].appendChild(script)
  }

  if (callback) callback()
}

export default loadScript
