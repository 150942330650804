export const NODE_ENV = process.env.NODE_ENV

export const LOG_LEVEL =
  (process.browser
    ? process.env.LOG_LEVEL_BROWSER
    : process.env.LOG_LEVEL_NODE) ||
  process.env.LOG_LEVEL ||
  (NODE_ENV === 'production' && (process.browser ? 'none' : 'info')) ||
  'debug'

export const SSR = process.env.SSR !== 'false'

export const API_URL = process.env.API_URL || 'http://localhost:4000'

export const APOLLO_ENGINE_URL =
  process.env.APOLLO_ENGINE_URL || `${API_URL}/graphql_api`

export const UBA_URL = process.env.UBA_URL

export const GRAPHQL_SCHEMA_VERSION = process.env.GRAPHQL_SCHEMA_VERSION || ''

export const SERVICE_WORKER = process.env.SERVICE_WORKER
  ? process.env.SERVICE_WORKER == 'true'
  : NODE_ENV === 'production'

export const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY

export const GTM_ID = process.env.GTM_ID

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY

export const CLOUDINARY_IMAGE_URL = `${process.env.CLOUDINARY_IMAGE_URL}/o_70,fl_relative,g_south_west,w_0.2,x_20,y_20,l_overlay`

export const PAVLOV_URL = process.env.PAVLOV_URL

export const FIREBASE_CONFIG = process.env.FIREBASE_CONFIG
  ? JSON.parse(process.env.FIREBASE_CONFIG)
  : null

export const FIREBASE_FCM_VAPID = process.env.FIREBASE_FCM_VAPID

export const OPENREPLAY_KEY = process.env.OPENREPLAY_KEY

export const MAX_FETCH_ITEMS = 400

export const UI_VERSION = 'web-3.3.0'

export const NEARBY_SETTINGS = {
  searchInputRadius: 1500, // in meters
  radius: 5 * 1000, // in meters
  url: 'perto-de-voce'
}

export const USER_GEO_DATA_LOCALSTORAGE_KEY = 'userGeoData'
export const CLOUDFRONT_VIEWER_CITY_HEADER = 'cloudfront-viewer-city'
export const CLOUDFRONT_VIEWER_STATE_HEADER = 'cloudfront-viewer-country-region'
export const CLOUDFRONT_VIEWER_COUNTRY_HEADER = 'cloudfront-viewer-country'
export const CLOUDFRONT_VIEWER_X_FORWARDED_FOR_HEADER = 'x-forwarded-for'

/*
 * Routes that should be handled/rendered by this application
 * when X-EmCasa-Routes-From-Origin is defined
 */
export const ROUTES_FROM_ORIGIN_HEADER = 'x-emcasa-routes-from-origin'
export const ROUTES_FROM_ORIGIN = [
  /nocache=sw/,
  /nextView=true/,
  /meu-perfil/,
  /blog/,
  /quem-somos/,
  /corretor-parceiro\/?$/,
  /especialistas-de-vendas/,
  /perfil\/minhas-indicacoes/,
  /\/imoveis(?!.*\/proposta$)/, // all listing paths except proposals
  /^\/$/ // home path
]
