import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {setBackButton} from '@/redux/modules/layout/actions'
import logger from '@/lib/logger'
import Button from '@/components/shared/Button'
import ButtonLink from '@/components/shared/Button/Link'

const BackButton = ({getBackButton, history}) => {
  const getBackButtonLabel = getBackButton && getBackButton.label

  const getBackButtonProps = () => {
    return {
      small: true,
      anchor: true,
      arialabel: getBackButtonLabel,
      icon: getBackButton.icon,
      flipIcon: getBackButton.flipIcon,
      title: getBackButtonLabel
    }
  }

  function backButtonLoggerAction() {
    logger.action('header-back-button', {label: getBackButton.label})
  }
  function handleBackButton() {
    backButtonLoggerAction()
    history.goBack()
  }

  return getBackButton && getBackButton.to ? (
    <ButtonLink
      {...getBackButtonProps()}
      to={getBackButton.to}
      onClick={backButtonLoggerAction}
    >
      {getBackButtonLabel}
    </ButtonLink>
  ) : getBackButton && getBackButton.label ? (
    <Button {...getBackButtonProps()} onClick={handleBackButton}>
      {getBackButtonLabel}
    </Button>
  ) : null
}

export default compose(connect(null, {setBackButton}))(BackButton)
