import Shell from '@/components/layout/templates/Default'
import styles from '@/styles/templates/Listings/styles.module.css'

export default function ListingsShell({children, ...props}) {
  return (
    <Shell withBottomBar {...props}>
      <div className={styles.ecTemplateListings}>{children}</div>
    </Shell>
  )
}
