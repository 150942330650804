import styled from 'styled-components'
import {layout, space, zIndex} from 'styled-system'

export const Container = styled.div`
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  ${layout};
  ${space};
  ${zIndex};
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => ({
    objectFit: props.objectFit,
    objectPosition: props.objectPosition
  })}
  ${layout};
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
`

export const Loader = styled.img`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
`

Image.defaultProps = {
  objectFit: 'cover',
  objectPosition: 'center'
}
