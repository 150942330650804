import theme from '@/config/theme'
import Row from '@emcasa/ui-dom/components/Row'
import Col from '@emcasa/ui-dom/components/Col'
import styled, {css, keyframes} from 'styled-components'

const BLINK_ANIM_TIME = 0.4
const BAR_HEIGHT_MAX = 12
const BAR_HEIGHT_MIN = 8

export const LISTING_CARD_IMAGE_HEIGHT = 180

export const Wrapper = styled.article`
  position: relative;
  width: 100%;
  > button {
    z-index: 2;
    svg {
      transition: transform 0.45s cubic-bezier(0.4, 0.2, 0, 1);
    }
    &:hover {
      svg {
        transform: scale(1.12);
      }
    }
  }
`

export const CardStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  border: 1px solid ${theme.colors.grey300};
  border-radius: ${theme.space[2]}px;
  background-color: ${theme.colors.white};
  transform: translate3d(0, 0, 0); // to fix border radius on iOS
`

const animationName = keyframes`
  0% { opacity: 0.65; }
  100% { opacity: 1; }
`

const Container = styled.span`
  ${CardStyle};
`

const Image = styled(Col)`
  width: 100%;
  height: ${LISTING_CARD_IMAGE_HEIGHT}px;
  background-color: ${({backgroundColor}) =>
    backgroundColor ? backgroundColor : null};
`

const Bar = styled(Col)`
  border-radius: 4px;
  background-color: ${({backgroundColor}) =>
    backgroundColor ? backgroundColor : null};
`

const BlinkContainer = styled(Row)`
  animation: ${animationName} ${BLINK_ANIM_TIME}s linear 0s infinite alternate;
`

export default function Loading() {
  return (
    <Wrapper as="div">
      <Container>
        <BlinkContainer flexDirection="column">
          <Image backgroundColor={theme.colors.grey300} as="span" />
          <Row as="span" flexDirection="column" pt={3} pb={2} px={2}>
            <Row as="span" justifyContent="space-between" mb={3}>
              <Bar
                backgroundColor={theme.colors.grey300}
                as="span"
                height={`${BAR_HEIGHT_MAX}px`}
                width="55%"
              />
              <Bar
                backgroundColor={theme.colors.grey300}
                as="span"
                height={`${BAR_HEIGHT_MAX}px`}
                width="30%"
              />
            </Row>
            <Bar
              backgroundColor={theme.colors.grey300}
              as="span"
              height={`${BAR_HEIGHT_MIN}px`}
              width="65%"
              mb={1}
            />
            <Bar
              backgroundColor={theme.colors.grey300}
              as="span"
              height={`${BAR_HEIGHT_MIN}px`}
              width="55%"
              mb={1}
            />
          </Row>
        </BlinkContainer>
      </Container>
    </Wrapper>
  )
}
