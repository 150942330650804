import Shell from '@/components/layout/templates/Default'
import Result from '@/components/shared/Result'
import styles from '@/styles/molecules/Result/styles.module.css'

export default function ErrorPage({code}) {
  const illustration =
    code === 404
      ? '/img/illustrations/error-404.svg'
      : '/img/illustrations/error-500.svg'
  const title =
    code === 404 ? 'Página não encontrada' : 'Ocorreu um erro no site!'
  const text =
    code === 404
      ? 'Não se preocupe, você pode voltar para a página anterior ou ir para a nossa Página Inicial. Pode também entrar em contato conosco!'
      : 'Ocorreu um erro no site e sua página não pôde ser exibida. Nossa equipe já está trabalhando nisso. Dentro de alguns instantes estaremos de volta.'
  return (
    <Shell>
      <div className={styles.ecResult__wrapper}>
        <Result
          large
          titleBig
          textBig
          illustration={illustration}
          title={title}
          text={text}
          buttons={[
            {
              active: true,
              label: 'Buscar imóveis',
              to: '/imoveis'
            }
          ]}
        />
      </div>
    </Shell>
  )
}
