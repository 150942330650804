import {
  SchemaWebSite,
  SchemaRealEstateAgent,
  SchemaOrganization
} from '@/lib/seo'

const Seo = () => (
  <>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(SchemaRealEstateAgent)
      }}
    />
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(SchemaOrganization)
      }}
    />
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(SchemaWebSite)
      }}
    />
  </>
)

export default Seo
