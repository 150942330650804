import * as events from './events'

const GTM_EVENTS = {
  [events.PAGE_OPEN]: {
    action: 'Page Open',
    event: 'page_open',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_OPEN]: {
    action: 'Open Listing',
    event: 'listing_detail_open',
    getProps: (props) => {
      return Object.assign(props, {
        listing_pagetype: 'offerdetail'
      })
    }
  },
  [events.LISTING_DETAIL_MATTERPORT_OPEN]: {
    action: 'Open Matterport',
    event: 'listing_detail_matterport_open',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_OPEN_VISIT_FORM]: {
    action: 'Open Visit Form',
    event: 'listing_detail_open_visit_form',
    getProps: (props) => {
      return Object.assign(props, {
        listing_pagetype: 'conversionintent'
      })
    }
  },
  [events.LISTING_DETAIL_SCHEDULE_VISIT]: {
    action: 'Schedule Visit',
    event: 'listing_detail_schedule_visit',
    getProps: (props) => {
      return Object.assign(props, {
        listing_pagetype: 'conversion'
      })
    }
  },
  [events.LISTING_DETAIL_CONTACT_BUTTON]: {
    action: 'Listing Detail Contact Button',
    event: 'listing_detail_contact_button',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SINGLECTA_SHOWMODAL]: {
    action: 'Listing Detail Singlecta ShowModal',
    event: 'listing_detail_singlecta_showmodal',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_MORE_LISTINGS_BUTTON]: {
    action: 'More Listings Button',
    event: 'listing_detail_more_listings_button',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_VIEW_FEATURED_LISTING]: {
    action: 'View Featured Listing',
    event: 'listing_detail_view_featured_listing',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_FAVORITE_LISTING]: {
    action: 'Listing Detail Favorite Listing',
    event: 'listing_detail_favorite_listing',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_FAVORITE_RELATED_LISTING]: {
    action: 'Listing Detail Favorite Related Listing',
    event: 'listing_detail_favorite_related_listing',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_OPEN_INTEREST]: {
    action: 'Listing Detail Open Interest',
    event: 'listing_detail_open_interest',
    getProps: (props) => props
  },
  [events.YOU_SETUP_INTRO]: {
    action: 'You Setup Intro',
    event: 'you_setup_intro',
    getProps: (props) => props
  },
  [events.YOU_SETUP_LOCATION]: {
    action: 'You Setup Location',
    event: 'you_setup_location',
    getProps: (props) => props
  },
  [events.YOU_SETUP_TYPE]: {
    action: 'You Setup Type',
    event: 'you_setup_type',
    getProps: (props) => props
  },
  [events.YOU_SETUP_PRICE]: {
    action: 'You Setup Price',
    event: 'you_setup_price',
    getProps: (props) => props
  },
  [events.YOU_SETUP_CONDO]: {
    action: 'You Setup Condo',
    event: 'you_setup_condo',
    getProps: (props) => props
  },
  [events.YOU_SETUP_DETAILS]: {
    action: 'You Setup Details',
    event: 'you_setup_details',
    getProps: (props) => props
  },
  [events.YOU_SETUP_PREFERENCES]: {
    action: 'You Setup Preferences',
    event: 'you_setup_preferences',
    getProps: (props) => props
  },
  [events.YOU_SETUP_PROFILE]: {
    action: 'You Setup Profile',
    event: 'you_setup_profile',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SEND]: {
    action: 'You Setup Send',
    event: 'you_setup_send',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SEND_SUCCESS]: {
    action: 'You Setup Send Success',
    event: 'you_setup_send_success',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SEND_ERROR]: {
    action: 'You Setup Send Error',
    event: 'you_setup_send_error',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SUCCESS]: {
    action: 'You Setup Success',
    event: 'you_setup_success',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SUCCESS_BUTTON]: {
    action: 'You Setup Success Button',
    event: 'you_setup_success_button',
    getProps: (props) => props
  },
  [events.YOU_SETUP_ERROR]: {
    action: 'You Setup Error',
    event: 'you_setup_error',
    getProps: (props) => props
  },
  [events.YOU_SETUP_ERROR_BUTTON]: {
    action: 'You Setup Error Button',
    event: 'you_setup_error_button',
    getProps: (props) => props
  },
  [events.YOU_SETUP_ERROR_MAILTO]: {
    action: 'You Setup Error Mailto',
    event: 'you_setup_error_mailto',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SIGNIN]: {
    action: 'You Setup Signin',
    event: 'you_setup_signin',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SIGNIN_SUCCESS]: {
    action: 'You Setup Signin_Success',
    event: 'you_setup_signin_success',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SIGNIN_ERROR]: {
    action: 'You Setup Signin Error',
    event: 'you_setup_signin_error',
    getProps: (props) => props
  },
  [events.YOU_SETUP_COMPLETED]: {
    action: 'You Setup Completed',
    event: 'you_setup_completed',
    getProps: (props) => props
  },
  [events.YOU_SETUP_COMPLETED_BUTTON_WHATSAPP]: {
    action: 'You Setup Completed Button Whatsapp',
    event: 'you_setup_completed_button_whatsapp',
    getProps: (props) => props
  },
  [events.YOU_SETUP_COMPLETED_BUTTON_LISTINGS]: {
    action: 'You Setup Completed Button Listings',
    event: 'you_setup_completed_button_listings',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SPECIALIST_SEND]: {
    action: 'YOU_SETUP_SPECIALIST_SEND',
    event: 'you_setup_specialist_send',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SPECIALIST_SEND_SUCCESS]: {
    action: 'YOU_SETUP_SPECIALIST_SEND_SUCCESS',
    event: 'you_setup_specialist_send_success',
    getProps: (props) => props
  },
  [events.YOU_SETUP_SPECIALIST_SEND_ERROR]: {
    action: 'YOU_SETUP_SPECIALIST_SEND_ERROR',
    event: 'you_setup_specialist_send_error',
    getProps: (props) => props
  },
  [events.YOU_CTA_LINK]: {
    action: 'You CTA Link',
    event: 'you_cta_link',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_GETAVAILABLEAPPOINTMENTTIME]: {
    action: 'Listing Detail Schedulemodal Getavailableappointmenttime',
    event: 'listing_detail_schedulemodal_getavailableappointmenttime',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_GETAVAILABLEAPPOINTMENTTIME_SUCCESS]: {
    action: 'Listing Detail Schedulemodal Getavailableappointmenttime Success',
    event: 'listing_detail_schedulemodal_getavailableappointmenttime_success',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_GETAVAILABLEAPPOINTMENTTIME_ERROR]: {
    action: 'Listing Detail Schedulemodal Getavailableappointmenttime Error',
    event: 'listing_detail_schedulemodal_getavailableappointmenttime_error',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_GETUSERINFO]: {
    action: 'Listing Detail Schedulemodal Getuserinfo',
    event: 'listing_detail_schedulemodal_getuserinfo',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_GETUSERINFO_SUCCESS]: {
    action: 'Listing Detail Schedulemodal Getuserinfo Success',
    event: 'listing_detail_schedulemodal_getuserinfo_success',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_GETUSERINFO_ERROR]: {
    action: 'Listing Detail Schedulemodal Getuserinfo Error',
    event: 'listing_detail_schedulemodal_getuserinfo_error',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_DATE]: {
    action: 'Listing Detail Schedulemodal Step Date',
    event: 'listing_detail_schedulemodal_step_date',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_DATE_ONDAYSELECTED]: {
    action: 'Step Date On Day Selected',
    event: 'listing_detail_schedulemodal_step_date_ondayselected',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_PROFILE]: {
    action: 'Listing Detail Schedulemodal Step Profile',
    event: 'listing_detail_schedulemodal_step_profile',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SEND]: {
    action: 'Listing Detail Schedulemodal Step Send',
    event: 'listing_detail_schedulemodal_step_send',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SEND_SUCCESS]: {
    action: 'Listing Detail Schedulemodal Step Send Success',
    event: 'listing_detail_schedulemodal_step_send_success',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SEND_ERROR]: {
    action: 'Listing Detail Schedulemodal Step Send Error',
    event: 'listing_detail_schedulemodal_step_send_error',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS]: {
    action: 'Listing Detail Schedulemodal Step Success',
    event: 'listing_detail_schedulemodal_step_success',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_SEARCH]: {
    action: 'Listing Detail Schedulemodal Step Success Button Search',
    event: 'listing_detail_schedulemodal_step_success_button_search',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_LIKE]: {
    action: 'Listing Detail Schedulemodal Step Success Button Like',
    event: 'listing_detail_schedulemodal_step_success_button_like',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_LIKE_SUCCESS]: {
    action: 'Listing Detail Schedulemodal Step Success Button Like Success',
    event: 'listing_detail_schedulemodal_step_success_button_like_success',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_LIKE_ERROR]: {
    action: 'Listing Detail Schedulemodal Step Success Button Like Error',
    event: 'listing_detail_schedulemodal_step_success_button_like_error',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_ERROR]: {
    action: 'Listing Detail Schedulemodal Step Error',
    event: 'listing_detail_schedulemodal_step_error',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_ERROR_BUTTON_TRYGAIN]: {
    action: 'Listing Detail Schedulemodal Step Error Button Trygain',
    event: 'listing_detail_schedulemodal_step_error_button_trygain',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_SCHEDULEMODAL_STEP_ERROR_BUTTON_MAILTO]: {
    action: 'Listing Detail Schedulemodal Step Error Button Mailto',
    event: 'listing_detail_schedulemodal_step_error_button_mailto',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_TAXES]: {
    action: 'Listing Detail Taxes',
    event: 'listing_detail_taxes',
    getProps: (props) => props
  },
  [events.LISTING_CARD_CLICK]: {
    action: 'Listing Card Click',
    event: 'listing_card_click',
    getProps: (props) => props
  },
  [events.LISTING_CARD_PHOTOS]: {
    action: 'Listing Card Photos',
    event: 'listing_card_photos',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_MAP_OPEN]: {
    action: 'Listing Detail Map Open',
    event: 'listing_detail_map_open',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_MATTERPORT_OPEN]: {
    action: 'Listing Detail Matterport Open',
    event: 'listing_detail_matterport_open',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_PHOTOS]: {
    action: 'Listing Detail Photos',
    event: 'listing_detail_photos',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_PHOTOS_FULLSCREEN_OPEN]: {
    action: 'Listing Detail Photos Fullscreen Open',
    event: 'listing_detail_photos_fullscreen_open',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_STREETVIEW_OPEN]: {
    action: 'Listing Detail Streetview Open',
    event: 'listing_detail_streetview_open',
    getProps: (props) => props
  },
  [events.LISTING_DETAIL_TAXES]: {
    action: 'Listing Detail Taxes',
    event: 'listing_detail_taxes',
    getProps: (props) => props
  },
  [events.LISTING_SEARCH_OPEN]: {
    action: 'Listing Search Open',
    event: 'listing_search_open',
    getProps: (props) => props
  },
  [events.LISTING_SEARCH_RESULTS]: {
    action: 'Listing Search Results',
    event: 'listing_search_results',
    getProps: (props) => {
      const content_ids = []
      const neighborhood = []
      const city = []
      const region = []

      props.listings.map((content) => {
        content_ids.push(content.id)
        if (neighborhood.indexOf(content.address.neighborhood) === -1) {
          neighborhood.push(content.address.neighborhood)
        }
        if (city.indexOf(content.address.city) === -1) {
          city.push(content.address.city)
        }

        if (region.indexOf(content.address.state) === -1) {
          region.push(content.address.state)
        }
      })

      return Object.assign(props, {
        content_ids: content_ids,
        listing_pagetype: 'searchresults',
        neighborhood: neighborhood.toString(),
        city: city.toString(),
        region: region.toString()
      })
    }
  },
  [events.LISTING_SEARCH_FILTER_OPEN]: {
    action: 'Listing Search Filter Open',
    event: 'listing_search_filter_open',
    getProps: (props) => props
  },
  [events.LISTING_SEARCH_FILTER_CLOSE]: {
    action: 'Listing Search Filter Close',
    event: 'listing_search_filter_close',
    getProps: (props) => props
  },
  [events.LISTING_SEARCH_FILTER_CLEAR]: {
    action: 'Listing Search Filter Clear',
    event: 'listing_search_filter_clear',
    getProps: (props) => props
  },
  [events.LISTING_SEARCH_FILTER_APPLY]: {
    action: 'Listing Search Filter Apply',
    event: 'listing_search_filter_apply',
    getProps: (props) => props
  },
  [events.BUYER_LANDING_PAGE]: {
    action: 'Buyer Landing Page',
    event: 'buyer_landing_page',
    getProps: (props) => {
      return Object.assign(props || {}, {
        listing_pagetype: 'home'
      })
    }
  },
  [events.BUYER_LANDING_SELECT_CHANGE]: {
    action: 'Buyer Landing Select Change',
    event: 'buyer_landing_select_change',
    getProps: (props) => props
  },
  [events.BUYER_LANDING_SEARCH_CTA]: {
    action: 'Buyer Landing Search CTA',
    event: 'buyer_landing_search_cta',
    getProps: (props) => props
  },
  [events.SELLER_CTA_LINK]: {
    action: 'Seller CTA Link',
    event: 'seller_cta_link',
    getProps: (props) => props
  },
  [events.BUYER_LANDING_SEARCH_CTA_LINK]: {
    action: 'Buyer Landing Search CTA Link',
    event: 'buyer_landing_search_cta_link',
    getProps: (props) => props
  },
  [events.BUYER_LANDING_YOU_CHANGE]: {
    action: 'Buyer Landing You Change',
    event: 'buyer_landing_you_change',
    getProps: (props) => props
  },
  [events.BUYER_LANDING_YOU_CTA]: {
    action: 'Buyer Landing You CTA',
    event: 'buyer_landing_you_cta',
    getProps: (props) => props
  },
  [events.BUYER_LANDING_FEED_LIST_BUTTON]: {
    action: 'Buyer Landing Feed List Button',
    event: 'buyer_landing_feed_list_button',
    getProps: (props) => props
  },
  [events.SELLER_LANDING_PAGE]: {
    action: 'Seller Landing Page',
    event: 'seller_landing_page',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_SUCCESS]: {
    action: 'Seller Onboarding Success',
    event: 'seller_onboarding_success',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_STEP_LISTING_PRICE]: {
    action: 'Seller Onboarding pricing',
    event: 'seller_onboarding_pricing',
    getProps: (props) => props
  },
  [events.SELLER_LANDING_HEADER_ADDRESS_ONFOCUS]: {
    action: 'Seller Landing Header Address Onfocus',
    event: 'seller_landing_header_address_onfocus',
    getProps: (props) => props
  },
  [events.SELLER_LANDING_HEADER_ADDRESS_DATA]: {
    action: 'Seller Landing Header Address Data',
    event: 'seller_landing_header_address_data',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_CTA_LINK]: {
    action: 'Seller Onboarding CTA Link',
    event: 'seller_onboarding_cta_link',
    getProps: (props) => props
  },
  [events.SELLER_LANDING_EVALUATION_CTA_LINK]: {
    action: 'Seller Landing Evaluation CTA Link',
    event: 'seller_landing_evaluation_cta_link',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_PAGE]: {
    action: 'Seller Onboarding Page',
    event: 'seller_onboarding_page',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_STEP_LISTING_TYPES]: {
    action: 'Seller Onboarding Step Listing Types',
    event: 'seller_onboarding_step_listing_types',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_STEP_LISTING_ADDRESS_FOCUS]: {
    action: 'Seller Onboarding Step Listing Address Focus',
    event: 'seller_onboarding_step_listing_address_focus',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_STEP_LISTING_ADDRESS_DATA]: {
    action: 'Seller Onboarding Step Listing Address Data',
    event: 'seller_onboarding_step_listing_address_data',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_STEP_SEND]: {
    action: 'Seller Onboarding Step Send',
    event: 'seller_onboarding_step_send',
    getProps: (props) => props
  },
  [events.SELLER_ONBOARDING_SUCCESS]: {
    action: 'Seller Onboarding Success',
    event: 'seller_onboarding_success',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_LANDING_PAGE]: {
    action: 'Partnerbroker Landing Page',
    event: 'partnerbroker_landing_page',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_LANDING_SIGNUP_NOT_LOGGED]: {
    action: 'Partnerbroker Landing Signup Not Logged',
    event: 'partnerbroker_landing_signup_not_logged',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_LANDING_REGISTER_NOT_LOGGED]: {
    action: 'Partnerbroker Landing Register Not Logged',
    event: 'partnerbroker_landing_register_not_logged',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_LANDING_SIGNUP]: {
    action: 'Partnerbroker Landing Signup',
    event: 'partnerbroker_landing_signup',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_LANDING_REGISTER]: {
    action: 'Partnerbroker Landing Register',
    event: 'partnerbroker_landing_register',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_PROFILE]: {
    action: 'Partnerbroker Signup Profile',
    event: 'partnerbroker_signup_profile',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_PROFILE_NEXT]: {
    action: 'Partnerbroker Signup Profile Next',
    event: 'partnerbroker_signup_profile_next',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_NEIGHBORHOODS]: {
    action: 'Partnerbroker Signup Neighborhoods',
    event: 'partnerbroker_signup_neighborhoods',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_NEIGHBORHOODS_NEXT]: {
    action: 'Partnerbroker Signup Neighborhoods Next',
    event: 'partnerbroker_signup_neighborhoods_next',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_NEIGHBORHOODS_BACK]: {
    action: 'Partnerbroker Signup Neighborhoods Back',
    event: 'partnerbroker_signup_neighborhoods_back',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_SEND]: {
    action: 'Partnerbroker Signup Send',
    event: 'partnerbroker_signup_send',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_SEND_DONE]: {
    action: 'Partnerbroker Signup Send Done',
    event: 'partnerbroker_signup_send_done',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_SEND_ERROR]: {
    action: 'Partnerbroker Signup Send Error',
    event: 'partnerbroker_signup_send_error',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_SUCCESS]: {
    action: 'Partnerbroker Signup Success',
    event: 'partnerbroker_signup_success',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_SUCCESS_BUTTON]: {
    action: 'Partnerbroker Signup Success Button',
    event: 'partnerbroker_signup_success_button',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_ERROR]: {
    action: 'Partnerbroker Signup Error',
    event: 'partnerbroker_signup_error',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_SIGNUP_ERROR_BUTTON]: {
    action: 'Partnerbroker Signup Error Button',
    event: 'partnerbroker_signup_error_button',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_ADDRESS]: {
    action: 'Partnerbroker Register Address',
    event: 'partnerbroker_register_address',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_ADDRESS_NEXT]: {
    action: 'Partnerbroker Register Address Next',
    event: 'partnerbroker_register_address_next',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_PRICING]: {
    action: 'Partnerbroker Register Pricing',
    event: 'partnerbroker_register_pricing',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_PRICING_NEXT]: {
    action: 'Partnerbroker Register Pricing Next',
    event: 'partnerbroker_register_pricing_next',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_PRICING_BACK]: {
    action: 'Partnerbroker Register Pricing Back',
    event: 'partnerbroker_register_pricing_back',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_OWNER]: {
    action: 'Partnerbroker Register Owner',
    event: 'partnerbroker_register_owner',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_OWNER_NEXT]: {
    action: 'Partnerbroker Register Owner Next',
    event: 'partnerbroker_register_owner_next',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_OWNER_BACK]: {
    action: 'Partnerbroker Register Owner Back',
    event: 'partnerbroker_register_owner_back',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_SUCCESS]: {
    action: 'Partnerbroker Register Success',
    event: 'partnerbroker_register_success',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_SUCCESS_BUTTON]: {
    action: 'Partnerbroker Register Success Button',
    event: 'partnerbroker_register_success_button',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_ERROR]: {
    action: 'Partnerbroker Register Error',
    event: 'partnerbroker_register_error',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_ERROR_BUTTON]: {
    action: 'Partnerbroker Register Error Button',
    event: 'partnerbroker_register_error_button',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_DUPLICATED]: {
    action: 'Partnerbroker Register Duplicated',
    event: 'partnerbroker_register_duplicated',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_DUPLICATED_BUTTON]: {
    action: 'Partnerbroker Register Duplicated Button',
    event: 'partnerbroker_register_duplicated_button',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_NOCOVERED]: {
    action: 'Partnerbroker Register NoCovered',
    event: 'partnerbroker_register_nocovered',
    getProps: (props) => props
  },
  [events.PARTNERBROKER_REGISTER_NOCOVERED_BUTTON]: {
    action: 'Partnerbroker Register NoCovered Button',
    event: 'partnerbroker_register_nocovered_button',
    getProps: (props) => props
  },
  [events.REFERRALS_SIGNUP_SUCCESS]: {
    action: 'Referrals Signup Success',
    event: 'referrals_signup_success',
    getProps: (props) => props
  },
  [events.REFERRALS_REGISTER_SUCCESS]: {
    action: 'Referrals Register Success',
    event: 'referrals_register_success',
    getProps: (props) => props
  },
  [events.LISTING_OFFER_SUBMIT_SUCCESS]: {
    action: 'Listing Offer Submit Success',
    event: 'listing_offer_submit_success',
    getProps: (props) => props
  }
}

function _getDataLayer() {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer
}

export function identify(user) {
  _getDataLayer().push({
    event: 'identify',
    ...user
  })
}

export function logEvent(event, properties) {
  let gtmEvent = GTM_EVENTS[event]
  if (gtmEvent) {
    const {getProps, ...dataLayerEvent} = gtmEvent
    _getDataLayer().push(
      getProps
        ? Object.assign(dataLayerEvent, getProps(properties))
        : dataLayerEvent
    )
  }
}
