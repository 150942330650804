import PropTypes from 'prop-types'
import styles from '@/styles/atoms/LoadBar/styles.module.css'

const LoadBar = ({percentage}) => (
  <span
    style={{'--translate-x': `-${100 - percentage}%`}}
    className={styles.ecLoadBar}
  />
)

LoadBar.defaultProps = {
  percentage: 0
}

LoadBar.propTypes = {
  percentage: PropTypes.number
}

export default LoadBar
