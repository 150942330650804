import gql from 'graphql-tag'
import PartialListing from '../fragments/PartialListing'

export default gql`
  query listing($id: ID!) {
    listing(id: $id) {
      ...PartialListing
    }
  }
  ${PartialListing}
`
