import {InMemoryCache, defaultDataIdFromObject} from 'apollo-cache-inmemory'
import initialState from '../resolvers/initialState'

const dataIdFromObject = (data) =>
  data.uuid ? data.uuid : defaultDataIdFromObject(data)

export default function createCache(state) {
  const cache = new InMemoryCache({
    addTypename: true,
    dataIdFromObject
  })
  if (state) cache.restore(state)
  if (!process.browser) {
    cache.writeData({data: initialState})
  }
  return cache
}
