import {themeGet} from '@styled-system/theme-get'
import styled, {css} from 'styled-components'
import {space, layout, flex, flexbox, border} from 'styled-system'

export const snap = css`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  scroll-snap-coordinate: 0% 0%;
`

export const snapContainer = css`
  scroll-snap-type: both mandatory;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-destination: 0% 0%;
  -webkit-overflow-scrolling: touch;
`

export const hiddenScrollbar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Button = styled.button`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 0 ${themeGet('space.4')}px;
  ${(props) => (props.position == 'left' ? {left: 0} : {right: 0})};
  svg {
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.6));
  }
  ${(props) => props.css}
`

export const Container = styled.div`
  z-index: 0;
  position: relative;
  width: fit-content;
  ${layout};
  ${space};
  ${(props) =>
    props.controlAlwaysVisible ? `${Button} { pointer-events: all; }` : ''}
  @media screen and (min-width: 768px) {
    ${Button} {
      pointer-events: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      ${Button} {
        pointer-events: all;
      }
    }
  }
`

export const Carousel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: overlay;
  ${(props) => props.snapping && snapContainer};
  ${(props) => !props.scrollbar && hiddenScrollbar};
  ${flexbox};
  ${layout};
  ${space};
  ${border};
`

Carousel.defaultProps = {snapping: true}

export const Slide = styled.div`
  position: relative;
  box-sizing: border-box;
  line-height: ${(props) => (props.ignoreLineHeight ? 'auto' : 0)};
  display: flex;
  flex-direction: row;
  ${(props) => props.snap && snap}
  ${flex};
  ${flexbox};
  ${layout};
  ${space};
  ${border};
`
