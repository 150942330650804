import {useState, useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import GoogleMaps from 'google-map-react'
import {useHistory} from 'react-router-dom'
import useSupercluster from 'use-supercluster'
import {GOOGLE_MAPS_API_KEY} from '@/config'
import MapMarker from './MapMarker'
import MapCard from './MapCard'
import MapUserMarker from './MapUserMarker'
import MapClusterMarker from './MapClusterMarker'
import LocationControl from './LocationControl'
import styles from '@/styles/templates/Listings/styles.module.css'
import logger from '@/lib/logger'
import Loader from '@/components/listing/components/Gallery/components/Loader'
import {getUserCenter} from '@/lib/hooks/useGeolocation'

export const DEFAULT_CENTER = {
  'rio-de-janeiro': {lat: -22.971785, lng: -43.189251},
  'santo-andre': {lat: -23.6573395, lng: -46.5322504},
  'sao-bernardo-do-campo': {lat: -23.7080345, lng: -46.5506747},
  'sao-caetano-do-sul': {lat: -23.6248835, lng: -46.5824175},
  'sao-jose-dos-campos': {lat: -23.2086891, lng: -45.9009177},
  'sao-paulo': {lat: -23.550121, lng: -46.634159}
}

// NOTE (jpd): fallback location when city isn't available
export const BRAZIL_CENTER = {lat: -10.3333333, lng: -53.2}

const DEFAULT_ZOOM = 15
const USER_ZOOM = 17

const DEFAULT_OPTIONS = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  clickableIcons: false
}

const getMapBounds = (map, maps, points) => {
  const bounds = new maps.LatLngBounds()

  points.forEach((place) => {
    bounds.extend(
      new maps.LatLng(
        place.geometry.coordinates[1],
        place.geometry.coordinates[0]
      )
    )
  })
  return bounds
}

const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds)
    })
  })
}

const addLocationControl = (map, maps, userLocation) => {
  const controls = map.controls[maps.ControlPosition.RIGHT_BOTTOM]
  if (controls.length === 0) {
    const controlButtonDiv = document.createElement('div')
    ReactDOM.render(
      <LocationControl
        onClick={() => {
          map.panTo(userLocation)
        }}
      />,
      controlButtonDiv
    )
    controls.push(controlButtonDiv)
  }
}

const updateBounds = (map, maps, points, userLocation, nearbyFilter) => {
  if (!maps) {
    return null
  }
  const bounds = getMapBounds(map, maps, points)
  if (nearbyFilter && userLocation) {
    map.panTo(userLocation)
  } else {
    map.fitBounds(bounds)
    bindResizeListener(map, maps, bounds)
  }
  if (userLocation) {
    addLocationControl(map, maps, userLocation)
  }
}

const getPositionFromHash = (hash) => {
  if (hash[0] === '#') hash = hash.slice(1)
  if (hash[0] !== '@') return null
  const [lat, lng, zoom] = hash.slice(1).split(',')
  if (isNaN(lat) || isNaN(lng) || isNaN(zoom)) return null
  return {
    center: {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    },
    zoom: parseInt(zoom)
  }
}

const getHashFromPosition = ({center: {lat, lng}, zoom}) =>
  `@${lat},${lng},${zoom}`

function ListingsMap({
  listings,
  loading,
  geolocation,
  filters: {citiesSlug = ['sao-paulo'], location: nearbyFilter}
}) {
  if (!process.browser) {
    return null
  }
  const history = useHistory()
  const mapRef = useRef()
  const mapsRef = useRef()
  const [bounds, setBounds] = useState(null)
  const [initialPosition, setInitialPosition] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const defaultCenter = DEFAULT_CENTER[citiesSlug[0]] || BRAZIL_CENTER
  const userLocation = geolocation ? getUserCenter(geolocation) : null
  const [zoom, setZoom] = useState(userLocation ? USER_ZOOM : DEFAULT_ZOOM)
  const [center, setCenter] = useState(defaultCenter)
  const [selectedListing, setSelectedListing] = useState()
  const points = listings.map(({id, price, address: {lat, lng}}) => ({
    type: 'Feature',
    properties: {id, price, cluster: false},
    geometry: {
      type: 'Point',
      coordinates: [lng, lat]
    }
  }))

  const {clusters, supercluster} = useSupercluster({
    points,
    bounds,
    zoom,
    options: {radius: 100, maxZoom: 20, minZoom: 6, minPoints: 4}
  })
  useEffect(() => {
    if (!loading) {
      const hashPosition = getPositionFromHash(history.location.hash)
      if (hashPosition && !initialLoading) {
        setZoom(hashPosition.zoom)
        setCenter(hashPosition.center)
        setInitialPosition(true)
      } else {
        updateBounds(
          mapRef.current,
          mapsRef.current,
          points,
          userLocation,
          nearbyFilter
        )
      }
      setInitialLoading(true)
    }
  }, [listings, userLocation])

  return (
    <div
      className={classNames(styles.ecTemplateListings__map, {
        [styles.ecTemplateListings__map__loading]: loading
      })}
    >
      <GoogleMaps
        bootstrapURLKeys={{key: GOOGLE_MAPS_API_KEY}}
        defaultCenter={DEFAULT_CENTER}
        center={center}
        zoom={zoom}
        draggable={!loading}
        defaultZoom={DEFAULT_ZOOM}
        options={DEFAULT_OPTIONS}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => {
          mapRef.current = map
          mapsRef.current = maps
          if (userLocation) {
            addLocationControl(map, maps, userLocation)
          }
          if (!loading && listings && !initialPosition) {
            updateBounds(map, maps, points, userLocation, nearbyFilter)
          }
        }}
        onDragEnd={() => logger.action('listing-search-map-pan')}
        onZoomAnimationEnd={() => logger.action('listing-search-map-zoom')}
        onClick={() => setSelectedListing(null)}
        onChange={({zoom, center, bounds}) => {
          if (!loading) {
            const hash = getHashFromPosition({zoom, center})
            if (hash) {
              history.replace({...history.location, hash})
            }
            setZoom(zoom)
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ])
          }
        }}
      >
        {userLocation && <MapUserMarker {...userLocation} />}
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties

          if (isCluster) {
            return (
              <MapClusterMarker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                pointCount={pointCount}
                totalPoints={points.length}
                onClick={() => {
                  const point = {lat: latitude, lng: longitude}
                  const expansionZoom = Math.max(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    15
                  )
                  mapRef.current.setZoom(expansionZoom)
                  mapRef.current.panTo(point)
                  setSelectedListing(null)
                  logger.action('listing-search-map-cluster', {...point})
                }}
              />
            )
          }

          const isSelected = selectedListing === cluster.properties.id
          return (
            <MapMarker
              key={`listing-${cluster.properties.id}`}
              lat={latitude}
              lng={longitude}
              price={cluster.properties.price}
              selected={isSelected}
              onClick={() => {
                const listingId = cluster.properties.id
                setSelectedListing(listingId)
                const latOffset = (70 * (bounds[3] - bounds[1])) / 246
                setCenter({lat: latitude + latOffset, lng: longitude})
                logger.action('listing-search-map-pin', {listingId})
              }}
            >
              {isSelected && <MapCard listingId={cluster.properties.id} />}
            </MapMarker>
          )
        })}
      </GoogleMaps>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  )
}

export default ListingsMap
