import {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'
import * as qs from 'query-string'
const QS_OPTIONS = {arrayFormat: 'comma'}

export default function usePagination(dataKey, pageSize, mergeResults) {
  const history = useHistory()
  const query = qs.parse(
    (history.location.pathname + history.location.search || '?').slice(1),
    QS_OPTIONS
  )
  const [value, setValue] = useState({
    from: query.pg ? Math.max(0, Number(query.pg) - 1) * pageSize : 0,
    size: pageSize
  })

  const fetchMore = useCallback((data) => {
    if (!_canFetchMore(data, dataKey, pageSize)) return
    const vars = _nextPageVariables(data, dataKey, pageSize)
    const {location} = history
    const query = qs.parse(
      (history.location.search || '?').slice(1),
      QS_OPTIONS
    )
    query.pg = (
      Number(vars.pagination.from / vars.pagination.size) + 1
    ).toString()
    const search = qs.stringify(query, QS_OPTIONS)
    history.replace({...location, search})
    return data.fetchMore({
      variables: vars,
      updateQuery(prev, {fetchMoreResult}) {
        if (!fetchMoreResult) return prev
        return mergeResults(dataKey, prev, fetchMoreResult)
      }
    })
  })

  const canFetchMore = useCallback((data) => {
    if (!data) {
      return false
    }
    return _canFetchMore(data, dataKey, pageSize)
  })

  const clear = useCallback(() => {
    setValue({from: 0, size: pageSize})
  })

  return [value, fetchMore, canFetchMore, clear]
}

const _nextPageVariables = (data, dataKey, pageSize) => ({
  filters: data.variables.filters,
  pagination: {from: data[dataKey].from + pageSize, size: pageSize}
})

const _canFetchMore = (data, dataKey, pageSize) =>
  !data.loading &&
  data[dataKey] &&
  data[dataKey].totalCount &&
  data[dataKey].totalCount - data[dataKey].from > 0 &&
  data[dataKey].totalCount > pageSize
