import {Component} from 'react'
import PropTypes from 'prop-types'
import Text from '@emcasa/ui-dom/components/Text'
import Row from '@emcasa/ui-dom/components/Row'
import Col from '@emcasa/ui-dom/components/Col'
import Loader, {
  SIZE
} from '@/components/listing/components/Gallery/components/Loader'

export const TIME_PER_SENTENCE = 3000

class LoadingText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSentence: 0
    }
    this.swapSentence = this.swapSentence.bind(this)
    this.sentenceTimer = null
  }

  componentDidMount() {
    this.sentenceTimer = setInterval(this.swapSentence, TIME_PER_SENTENCE)
  }

  componentWillUnmount() {
    clearInterval(this.sentenceTimer)
  }

  swapSentence() {
    const {sentences} = this.props
    let nextSentence = ++this.state.currentSentence
    if (nextSentence >= sentences.length) {
      nextSentence = 0
    }
    this.setState({currentSentence: nextSentence})
  }

  render() {
    const {sentences} = this.props
    return (
      <Row flexDirection="column" flex="1">
        <Col mb={4} style={{height: SIZE, position: 'relative'}}>
          <Loader />
        </Col>
        {sentences.map((sentence, index) => {
          if (this.state.currentSentence === index) {
            return (
              <Row justifyContent="center" key={index}>
                <Text textAlign="center">{sentence}</Text>
              </Row>
            )
          }
        })}
      </Row>
    )
  }
}

LoadingText.propTypes = {
  sentences: PropTypes.array.isRequired
}

export default LoadingText
