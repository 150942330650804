import compose from 'recompose/compose'
import {useState, useEffect} from 'react'
import {graphql} from 'react-apollo'
import styled from 'styled-components'
import * as format from '@/lib/format'
import Text from '@emcasa/ui-dom/components/Text'
import Col from '@emcasa/ui-dom/components/Col'
import Row from '@emcasa/ui-dom/components/Row'
import SkeletonBox from '@/components/layout/SkeletonBox'
import OFFER_LISTING from '@/graphql/queries/offerListing'

const Title = (props) => <Text fontSize={12} {...props} />
const Content = ({children, loading}) => {
  if (loading) {
    return <SkeletonBox style={{width: '80%', height: 18}} />
  }
  return (
    <Text fontWeight="bold" fontSize="small">
      {children || '--'}
    </Text>
  )
}

const Summary = styled.div`
  padding: 5px 15px 5px 15px;
  border-top: 1px solid #e0e6ed;
  border-bottom: 1px solid #e0e6ed;
  line-height: 100%;
`
const SummaryHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  justify-content: space-between;
`

const ArrowButton = styled.button`
  width: 30px;
  height: 40px;
  img {
    width: 16px;
    height: 16px;
    transform: rotate(${({visible}) => (visible ? '180deg' : '0deg')});
  }
`
const SummaryInfo = styled.div`
  margin-top: 20px;
`

const InfoRow = styled(Row)`
  margin-bottom: 20px;
`

function OfferListingSummary({
  loading,
  visible: _visible = true,
  listing: {id, area, price, address = {}, propertyTax, maintenanceFee, isAdmin}
}) {
  const [visible, setVisible] = useState(_visible)
  useEffect(() => {
    if (typeof _visible === 'boolean') setVisible(_visible)
  }, [_visible])

  return (
    <Summary>
      <SummaryHeader>
        {loading && <SkeletonBox style={{width: '100%', height: 16}} />}
        {!loading && (
          <Text fontSize={16} fontWeight="bold">
            Imóvel de interesse #{id}
          </Text>
        )}
        <ArrowButton
          visible={visible}
          aria-label={visible ? 'Esconder informações' : 'Exibir informações'}
          onClick={() => setVisible(!visible)}
        >
          <img alt="Esconder / Exibir informações" src="/img/icons/arrow.svg" />
        </ArrowButton>
      </SummaryHeader>
      {visible && (
        <SummaryInfo>
          <InfoRow>
            <Col>
              <Title>Endereço</Title>
              <Content loading={loading}>
                {address.street}
                {isAdmin && address.streetNumber
                  ? `, ${address.streetNumber}`
                  : ''}{' '}
                - {address.neighborhood}, {address.city}
              </Content>
            </Col>
          </InfoRow>
          <InfoRow>
            <Col width={6 / 12}>
              <Title>Valor</Title>
              <Content loading={loading}>{format.price(price)}</Content>
            </Col>
            <Col width={6 / 12}>
              <Title>Área</Title>
              <Content loading={loading}>{area}m²</Content>
            </Col>
          </InfoRow>
          <InfoRow>
            <Col width={4 / 12}>
              <Title>Preço/m²</Title>
              <Content loading={loading}>
                {format.price(Math.floor(price / area))}
              </Content>
            </Col>
            <Col width={4 / 12}>
              <Title>IPTU (ano)</Title>
              <Content loading={loading}>{format.price(propertyTax)}</Content>
            </Col>
            <Col width={4 / 12}>
              <Title>Condomínio</Title>
              <Content loading={loading}>
                {format.price(maintenanceFee)}
              </Content>
            </Col>
          </InfoRow>
        </SummaryInfo>
      )}
    </Summary>
  )
}

export default compose(
  graphql(OFFER_LISTING, {
    options: ({listingId}) => ({
      fetchPolicy: 'cache-first',
      variables: {
        id: listingId
      }
    }),
    props: ({data}) => ({
      loading: data.loading,
      listing: data.listing || {}
    })
  })
)(OfferListingSummary)
