import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

const TagButton = ({children, onClose, onClick}) => {
  const wrapperClasses = classNames(styles.ecTagButton__iosFix, {
    [styles.ecTagButton__iosFix_oneColumn]: !onClose
  })
  return (
    <div className={styles.ecTagButton}>
      <div className={wrapperClasses}>
        <button
          type="button"
          className={styles.ecTagButton__label}
          onClick={onClick}
        >
          {children}
        </button>
        {onClose && (
          <button
            type="button"
            className={styles.ecTagButton__button}
            onClick={onClose}
          >
            <img
              className={styles.ecTagButton__button__icon}
              src="/img/icons/close-gray.svg"
              alt="Ícone"
            />
          </button>
        )}
      </div>
    </div>
  )
}

TagButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default TagButton
