import {CLOUDINARY_IMAGE_URL} from '@/config'

export const imageUrl = ({filename}) =>
  `${CLOUDINARY_IMAGE_URL}/f_auto/v1513818385/${filename}`

export const thumbnailUrl = (width = 600, height = 400) => ({filename}) =>
  `${CLOUDINARY_IMAGE_URL}/f_auto,c_fit,h_${height},w_${width}/v1513818385/${filename}`

export const placeholderUrl = (width = 240, height = 160) => ({filename}) =>
  `${CLOUDINARY_IMAGE_URL}/f_auto,c_fit,h_${height},w_${width},e_blur:1000,q_1/v1513818385/${filename}`
