import curry from 'lodash/curry'
import omit from 'lodash/fp/omit'
import pick from 'lodash/fp/pick'
import zipWith from 'lodash/zipWith'
import concat from 'lodash/concat'
import $mergeProps from 'merge-props'

export const pullProps = curry((key, props) => [
  pick(key)(props),
  omit(key)(props)
])

const pullPropsList = (key, propsList) =>
  propsList.reduce(
    (ret, props) => zipWith(ret, pullProps(key, props), concat),
    [[], []]
  )

export const mergeRefs = (refs) => (element) =>
  refs.forEach((ref) => {
    if (typeof ref === 'function') ref(element)
    else if (ref) ref.current = element
  })

export function mergeProps(...propsList) {
  const [refs, props] = pullPropsList('ref', propsList)
  const validRefs = refs.map((props) => props.ref).filter(Boolean)
  return {
    ...$mergeProps(...props),
    ref: validRefs.length ? mergeRefs(validRefs) : undefined
  }
}
