import useExperiment from '@/lib/hooks/useExperiment'
import Cookie from 'js-cookie'

const experiments = {
  growth01: 'listingDetailSearchCTA',
  growth02: 'listingVisitFormExitQEV',
  growth03: 'listingSingleCTA',
  growth04: 'buttonColor',
  growth05: 'listingVisitLabel',
  product01: 'specialistSegmentation',
  portalExperiment01: 'relatedListingsScrollX',
  portalExperiment02: 'listingBar',
  portalExperiment03: 'hideListingTopVisitBar',
  portalExperiment04: 'hideListingUnavailableTags',
  portalExperiment05: 'defaultFilter',
  portalExperiment06: 'listingScheduleVisitCTA',
  portalExperiment07: 'financingCTA',
  portalExperiment08: 'searchField',
  acquisitionExperiment01: 'removeTopVisitBar'
}

export const isOnAcquisitionExperiment01Segmentation = () =>
  useExperiment(experiments.acquisitionExperiment01)

export const getAppVersion = () => {
  const uiVersionMobile = Cookie.get('uiVersionWebview')

  return uiVersionMobile
}

export default experiments
