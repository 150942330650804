import {memo, useState, useEffect, useCallback, useMemo} from 'react'
import {graphql} from 'react-apollo'
import compose from 'recompose/compose'
import GET_TAGS from '@/graphql/queries/tags'
import {TagCategories} from '@/config/enum/filters'
import Button from '@/components/shared/Button'
import {getGroupsMatched} from '@/components/form/TagInput/groups'
import styles from '@/styles/organisms/Filters/styles.module.css'

const mapTagToOption = ({category, name, nameSlug}) => ({
  name,
  group: TagCategories.get(category || 'undefined'),
  value: nameSlug
})

const withTags = graphql(GET_TAGS, {
  props: ({data}) => ({
    options: (data.tags || []).map(mapTagToOption)
  }),
  options: {
    fetchPolicy: 'cache-and-network'
  }
})

const tagTitle = new Map([
  ['Imóvel', 'Detalhes do imóvel'],
  ['Infraestrutura', 'Infraestrutura'],
  ['Portaria', 'Portaria'],
  ['Vista', 'Vista']
])

function Tags({initialValue, options, ...props}) {
  const [value, setValue] = useState(initialValue || [])
  const onChange = useCallback(
    (e) => {
      const targetValue = e.target.value
      const newValue = value.includes(targetValue)
        ? value.filter((tag) => tag !== targetValue)
        : value.concat(targetValue)

      setValue(newValue)
    },
    [props.onChange]
  )

  useEffect(() => {
    if (props.onChange) props.onChange(value)
  }, [value])

  useEffect(() => {
    if (props.value && props.value !== value) setValue(props.value)
  }, [props.value])

  const groups = useMemo(() => getGroupsMatched('', options), [options])
  return Array.from(groups).map((tag) =>
    tag[0] && tag[0] !== 'undefined' && tag[0] !== 'Vista' ? (
      <div key={tag[0]} className={styles.ecFilters__fieldset}>
        <p className={styles.ecFilters__fieldset__legend}>
          {tagTitle.get(tag[0])}
        </p>
        <div className={styles.ecFilters__filter__buttons_wrapButtons}>
          {tag[1] && tag[1].length > 0
            ? tag[1].map((item) => (
                <Button
                  key={item.value}
                  value={item.value}
                  selected={value.includes(item.value)}
                  small
                  onClick={onChange}
                >
                  {item.name}
                </Button>
              ))
            : null}
        </div>
      </div>
    ) : null
  )
}

export default compose(memo, withTags)(Tags)
