import {useState, useEffect} from 'react'
import {useLazyQuery} from '@apollo/react-hooks'
import SEARCH_LISTINGS from '@/graphql/queries/searchListings'
import logger from '@/lib/logger'
import Result from '@/components/shared/Result'
import Loader from '@/components/shared/Loader'
import ListingsGrid from '@/components/shared/ListingsGrid'
import styles from '@/styles/templates/Listings/styles.module.css'
import resultStyles from '@/styles/molecules/Result/styles.module.css'

const loggerAction = {
  openFilterbutton: 'listing-search-not-found-openfilter-button',
  specialistButton: 'listing-search-not-found-specialist-button',
  gridItem: 'listing-search-not-found-listing',
  gridButton: 'listing-search-not-found-listings-button',
  card: 'listing-search-not-found-card-click'
}
const LISTS_TITLE = 'Separamos alguns imóveis parecidos com o que você buscou.'
const MAX_LISTS = 6
const MAX_TAGS_LISTS = 4
const FILTERS_LIST = new Map([
  [
    'minGarageSpots',
    {
      title: 'Com 1 vaga ou mais',
      filters: {
        minGarageSpots: 1
      },
      to: {
        search: '?gs=1'
      }
    }
  ],
  [
    'minRooms',
    {
      title: 'Com 1 quarto ou mais',
      filters: {
        minRooms: 1
      },
      to: {
        search: '?q=1'
      }
    }
  ],
  [
    'types',
    {
      title: 'Apartamentos',
      filters: {
        types: ['Apartamento']
      },
      to: {
        pathname: (value) => `${value}/apartamento`
      }
    }
  ]
])

const EmptySearch = ({userProfile, searchedParams, openFilters, filters}) => {
  const [fetchLists, setFetchLists] = useState([])
  const [lists, setList] = useState([])
  const [excludeUuid, setExcludeUuid] = useState([])
  const [getListings, {loading, error, data}] = useLazyQuery(SEARCH_LISTINGS)

  const pathname = `/imoveis/${searchedParams?.stateSlug ||
    'sp'}/${searchedParams?.citySlug || ''}`

  function fetchList(listFilters) {
    getListings({
      ssr: false,
      variables: {
        filters: {
          citiesSlug: filters.citiesSlug,
          excludeUuid,
          ...listFilters
        },
        pagination: {from: 0, size: 4},
        isMap: false
      }
    })
  }

  function onFetchComplete({listings, totalCount}) {
    const index = fetchLists.findIndex((item) => {
      return item.list === undefined
    })
    const newFetchLists = fetchLists

    if (index !== -1) {
      const listItem = {
        list: listings,
        totalCount,
        ...fetchLists[index]
      }

      if (!listItem.title) {
        listItem.title = `Com ${
          listings[0]?.tags?.find(
            (item) => item.nameSlug === listItem.filters?.tagsSlug[0]
          )?.name
        }`
      }

      newFetchLists[index] = listItem

      const uuid = listings.map((listing) => listing.uuid)
      setExcludeUuid(excludeUuid.concat(uuid))

      setFetchLists(newFetchLists)
      if (fetchLists[index + 1]?.filters) {
        fetchList(fetchLists[index + 1]?.filters)
      } else {
        onLoadAllLists(newFetchLists)
      }
    } else {
      onLoadAllLists(newFetchLists)
    }
  }

  function onLoadAllLists(lists = []) {
    const haveSomeList = lists.some((item) => item?.list?.length)
    if (haveSomeList) {
      setList(lists)
    }
  }

  function restart() {
    setFetchLists([])
    setList([])
    setExcludeUuid([])
    const listListings =
      filters?.tagsSlug?.map((item, index) => {
        if (index < MAX_TAGS_LISTS) {
          return {
            filters: {
              tagsSlug: [item]
            },
            to: {
              pathname,
              search: `?ts=${item}`,
              state: {scroll: 0}
            }
          }
        }
      }) || []
    if (filters) {
      for (const [key] of FILTERS_LIST) {
        if (filters[key] && listListings.length < MAX_LISTS) {
          if (
            !(typeof filters[key] === 'object' && filters[key][0] === undefined)
          ) {
            const item = FILTERS_LIST.get(key)
            listListings.push({
              ...item,
              to: {
                ...item.to,
                pathname: item.to.pathname
                  ? item.to.pathname(pathname)
                  : pathname
              }
            })
          }
        }
      }
    }

    if (listListings.length) {
      setFetchLists(listListings)
      fetchList(listListings[0].filters)
    }
  }

  const getLogProps = () => {
    return {
      userProfile,
      searchedParams,
      filters
    }
  }

  const getResultProps = () => {
    return {
      large: true,
      icon: '/img/icons/house-search.svg',
      title: 'Não encontramos nenhum imóvel como você quer',
      text:
        'Fale com um dos nossos especialistas, que ele encontrará esse imóvel em outros lugares para você.',
      buttons: [
        {
          label: 'Falar com especialista',
          to: '/falar-com-especialista',
          dark: true,
          icon: '/img/icons/people-pink.svg',
          onClick: () => {
            logger.action(loggerAction.specialistButton, {
              ...getLogProps(),
              label: 'Falar com especialista'
            })
          }
        },
        {
          label: 'Filtrar novamente',
          onClick: () => {
            logger.action(loggerAction.openFilterbutton, {
              ...getLogProps(),
              label: 'Filtrar novamente'
            })
            openFilters()
          }
        }
      ]
    }
  }

  useEffect(() => {
    if (error) {
      logger.capture(error)
    }
  }, [error])

  useEffect(() => {
    if (data?.searchListings?.listings) {
      onFetchComplete(data.searchListings)
    }
  }, [data])

  useEffect(restart, [filters])

  return (
    <>
      <div className={resultStyles.ecResult__wrapper}>
        <Result {...getResultProps()} />
      </div>
      {!loading && lists?.length ? (
        <>
          <h2 className={styles.ecTemplateListings__notFound__title}>
            {LISTS_TITLE}
          </h2>
          <div className={styles.ecTemplateListings__notFound__list}>
            {lists.map((item, index) => {
              return (
                Boolean(item?.list?.length > 0) && (
                  <div
                    key={index}
                    className={styles.ecTemplateListings__notFound__item}
                  >
                    <h2
                      className={
                        styles.ecTemplateListings__notFound__item__title
                      }
                    >
                      {item.title}
                    </h2>
                    <ListingsGrid
                      scroll
                      listings={item.list}
                      loading={loading}
                      cardActions={{
                        cardClickAction: loggerAction.card
                      }}
                      button={{
                        label: `Mostrar mais ${item?.totalCount} imóveis`,
                        to: item.to,
                        onClick: () =>
                          logger.action(loggerAction.gridButton, {
                            ...getLogProps()
                          })
                      }}
                    />
                  </div>
                )
              )
            })}
          </div>
        </>
      ) : loading && fetchLists?.length ? (
        <Loader />
      ) : null}
    </>
  )
}

export default EmptySearch
