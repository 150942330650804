import usePagination from '@/lib/hooks/usePagination'

export default (valueProp, dataKey, pageSize, mergeResults) => (Component) =>
  function withPagination({...props}) {
    const [value, fetchMore, canFetchMore, clear] = usePagination(
      dataKey,
      pageSize,
      mergeResults
    )
    props[valueProp] = {
      variables: value,
      fetchMore,
      canFetchMore,
      clear
    }
    return <Component {...props} />
  }
