import {fork, all, call, getContext, takeEvery} from 'redux-saga/effects'
import createAuthSaga from '@emcasa/login/lib/sagas/authSaga'
import * as JWT from '@/lib/jwt'
import {postWebviewMessage} from '@/lib/webview'
import * as action from './actions'

export function* loginSuccess(jwt) {
  if (jwt) {
    const client = yield getContext('apolloClient')
    yield call(JWT.persist, jwt)
    yield call([client, client.resetStore])
    yield call(postWebviewMessage, 'app-webview-login', {jwt})
  }
}

export const login = createAuthSaga({
  onSuccess: loginSuccess
})

export function* logout() {
  const client = yield getContext('apolloClient')
  yield call(JWT.reset)
  yield call([client, client.resetStore])

  /**
   * This is a message to the webview on RN Mobile App to logout
   * app-view-[event]
   */
  yield call(postWebviewMessage, 'app-webview-logout')
}

export function* setJwtToken({jwt}) {
  yield loginSuccess(jwt)
}

export default function* authSaga() {
  yield all([
    takeEvery(action.SET_JWT_TOKEN, setJwtToken),
    takeEvery(action.LOGOUT, logout),
    fork(login)
  ])
}
