import {createContext, useMemo, useContext, useState} from 'react'
import Cookie from 'js-cookie'

export const Context = createContext({
  set: () => null,
  get: () => null
})

export default function CookiesProvider({children, cookies: initialCookies}) {
  const [cookies, setCookies] = useState(initialCookies)
  const state = useMemo(
    () => ({
      get(key) {
        return cookies[key]
      },
      set(key, value, options) {
        Cookie.set(key, value, options)
        setCookies({...cookies, [key]: value})
      }
    }),
    [cookies]
  )

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export const useCookies = () => useContext(Context)
