import {getWhatsappUrl} from '@/lib/user'

export const ABOUT = 'O melhor caminho até o seu imóvel.'

export const FOOTER_SOCIAL = [
  {
    name: 'Facebook',
    slug: 'facebook',
    url: 'https://www.facebook.com/EmCasa'
  },
  {
    name: 'Instagram',
    slug: 'instagram',
    url: 'https://www.instagram.com/emcasa'
  },
  {
    name: 'Linkedin',
    slug: 'linkedin',
    url: 'https://www.linkedin.com/company/emcasa'
  }
]

export const FOOTER_NAV = [
  {
    title: 'Vender',
    list: [
      {
        label: 'Vender imóvel',
        to: '/vender'
      },
      {
        label: 'Avaliar imóvel',
        to: '/avaliar'
      },
      {
        label: 'Parcerias',
        to: '/parceria'
      }
    ]
  },
  {
    title: 'Explorar',
    list: [
      {
        label: 'Sobre a EmCasa',
        to: '/quem-somos'
      },
      {label: 'Mapa do site', to: '/sitemap'},
      {
        label: 'EmCasa Blog',
        to: '/blog?nocache=sw'
      },
      {
        label: 'Trabalhe Conosco',
        url: 'https://www.linkedin.com/company/emcasa/jobs/'
      },
      {label: 'Imprensa', url: 'mailto:imprensa@emcasa.com'}
    ]
  },
  {
    title: 'Contato',
    list: [
      {
        label: 'contato@emcasa.com',
        url: `mailto:contato@emcasa.com?subject=${encodeURIComponent(
          'EmCasa - Fale conosco'
        )}`
      },
      {
        label: 'Brasil: (11) 3197-6638',
        url: getWhatsappUrl({phone: '551131976638'})
      }
    ]
  }
]

export const COPYRIGHT = (
  <>
    <strong>
      © {new Date().getFullYear()} EmCasa | CRECI-SP J-34416 | CRECI-RJ J-7712
    </strong>
    <br /> Rua General Artigas, 232, 2º andar - Leblon, Rio de Janeiro - RJ,
    22441-140 - CNPJ 29.212.794/0001-39
  </>
)
