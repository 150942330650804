import {NEARBY_SETTINGS} from '@/config'
import logger from '@/lib/logger'
import useLoadMapScript from '@/lib/hooks/useLoadMapScript'
import SearchLocationInput from '@/pages/listings/SearchListings/components/SearchLocationInput'

const loggerAction = {
  onclear: 'listings-searchlocationinput-clear',
  onselect: 'listings-searchlocationinput-select',
  onchange: 'listings-searchlocationinput-change'
}

const Search = ({filters, submitFilter}) => {
  const {isMapScriptLoaded} = useLoadMapScript()

  function onClear() {
    if (filters && filters.searchLocation) {
      delete filters.searchLocation
    }
    submitFilter({...filters})
    logger.action(loggerAction.onclear)
  }

  function onSelect(place) {
    logger.action(loggerAction.onselect, {place})
  }

  function onChange(coord, place) {
    if (coord) {
      submitFilter({
        ...filters,
        citiesSlug: [coord.citySlug || filters.citiesSlugs],
        searchLocation: {
          center: {
            coordinates: [coord.lng, coord.lat],
            type: 'Point'
          },
          radius: NEARBY_SETTINGS.searchInputRadius,
          place
        },
        neighborhoods: []
      })
    }
    logger.action(loggerAction.onchange, {...coord, place})
  }

  if (!isMapScriptLoaded) return null

  return (
    <SearchLocationInput
      clearPlace={filters && !filters.searchLocation}
      onClear={onClear}
      onChange={onChange}
      onSelect={onSelect}
    />
  )
}

export default Search
