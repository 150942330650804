import deepEqual from 'fast-deep-equal'
import Button from '@/components/form/Button'
import SharedButton from '@/components/shared/Button'
import styles from '@/styles/organisms/Filters/styles.module.css'

const deepEqualStrategy = {
  isSelected: deepEqual,
  update: (_, value) => value
}

export function CityFilter({initialValue, onChange, value, cities}) {
  return (
    <div
      className={`${styles.ecFilters__filter__buttons} ${styles.ecFilters__filter__buttons_wrapButtons}`}
    >
      {cities && cities.length > 0 && (
        <Button.Group
          strategy={deepEqualStrategy}
          initialValue={initialValue}
          onChange={onChange}
        >
          {cities.map((item, index) => (
            <SharedButton
              key={index}
              selected={value && value[0] === item.slug}
              small
              value={[item.slug]}
            >
              {item.name}
            </SharedButton>
          ))}
        </Button.Group>
      )}
    </div>
  )
}
