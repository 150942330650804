import {UBA_URL} from '@/config'

let userData = {}

export function identify(user) {
  userData.user = user
}

export async function logUBA(event, properties, timestamp, deviceId) {
  if (!UBA_URL) return
  const {user} = userData
  if (!deviceId) {
    // We can't log if we don't have at least user or deviceId
    return null
  }
  const path =
    (properties && properties.path) ||
    (window.location ? window.location.pathname : null)
  const resource = {
    event,
    properties,
    path,
    deviceId,
    userId: user ? user.id : null,
    timestamp: timestamp || new Date().getTime()
  }
  try {
    const payload = JSON.stringify(resource)
    await fetch(`${UBA_URL}/resource`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {'Content-Type': 'application/json'},
      body: payload
    })
  } catch (e) {
    console.error(`Failed to log event (${event}) to UBA: ${e}`)
  }
}
