import IconSet from '@emcasa/ui/lib/styles/createIconSet'

export const ICON_INFO_WIDTH = 14
export const ICON_INFO_HEIGHT = 14

export default IconSet({
  arrowAltCircleBottom: {
    width: 8,
    height: 10,
    render: ({color}) => (
      <>
        <path
          d="M2.51775 0.381331V6.06753H0.388656C0.0391032 6.06753 -0.119785 6.48064 0.102658 6.73486L3.24864 9.88083C3.40752 10.0397 3.62997 10.0397 3.78886 9.88083L6.93483 6.73486C7.18905 6.48064 6.99839 6.06753 6.68061 6.06753H4.55152V0.381331C4.55152 0.190665 4.36085 0 4.17019 0H2.89908C2.67664 0 2.51775 0.190665 2.51775 0.381331Z"
          fill={color}
        />
      </>
    )
  },
  area: {
    width: 17,
    height: 17,
    render: ({color}) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 16H14.5858L1 2.41422L1 16ZM0 1.68996V16.3C0 16.6866 0.313401 17 0.7 17H15.3101C15.9337 17 16.246 16.246 15.805 15.805L1.19498 1.19498C0.754 0.754009 0 1.06633 0 1.68996Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 13H7.58579L4 9.41422V13ZM3 8.68996V13.3C3 13.6866 3.3134 14 3.7 14H8.31005C8.93368 14 9.246 13.246 8.80503 12.805L4.19497 8.19498C3.754 7.75401 3 8.06633 3 8.68996Z"
          fill={color}
        />
        <path
          d="M13.0815 13.1109L12.0208 14.1716C11.8256 14.3668 11.509 14.3668 11.3137 14.1716C11.1185 13.9763 11.1185 13.6597 11.3137 13.4645L12.3744 12.4038L13.0815 13.1109Z"
          fill={color}
        />
        <path
          d="M10.9601 10.9896L9.89948 12.0503C9.70422 12.2455 9.38764 12.2455 9.19238 12.0503C8.99712 11.855 8.99712 11.5384 9.19238 11.3431L10.253 10.2825L10.9601 10.9896Z"
          fill={color}
        />
        <path
          d="M8.83887 8.86828L7.77821 9.92894C7.58294 10.1242 7.26636 10.1242 7.0711 9.92894C6.87584 9.73368 6.87584 9.4171 7.0711 9.22183L8.13176 8.16117L8.83887 8.86828Z"
          fill={color}
        />
        <path
          d="M6.71753 6.74696L5.65687 7.80762C5.46161 8.00288 5.14502 8.00288 4.94976 7.80762C4.7545 7.61236 4.7545 7.29577 4.94976 7.10051L6.01042 6.03985L6.71753 6.74696Z"
          fill={color}
        />
        <path
          d="M4.59619 4.62563L3.53553 5.6863C3.34027 5.88156 3.02369 5.88156 2.82842 5.6863C2.63316 5.49103 2.63316 5.17445 2.82842 4.97919L3.88908 3.91853L4.59619 4.62563Z"
          fill={color}
        />
      </>
    )
  },
  barsUpToRight: {
    width: 10,
    height: 10,
    render: ({color}) => (
      <>
        <path
          d="M4.98806 4.40628H3.604C3.43442 4.40628 3.29694 4.54376 3.29694 4.71334V9.69294C3.29694 9.77445 3.32934 9.85263 3.38705 9.91023C3.44463 9.96771 3.52267 10 3.604 10H3.60447L4.98853 9.99709C5.15792 9.99682 5.2951 9.85942 5.2951 9.69003V4.71334C5.29512 4.54376 5.15766 4.40628 4.98806 4.40628Z"
          fill={color}
        />
        <path
          d="M1.69112 5.70508H0.307059C0.137481 5.70508 0 5.84256 0 6.01214V9.69293C0 9.77445 0.0324048 9.85262 0.0901115 9.91023C0.147695 9.96771 0.225729 9.99999 0.307059 9.99999H0.30753L1.69159 9.99708C1.86099 9.99682 1.99816 9.85942 1.99816 9.69002V6.01214C1.99818 5.84256 1.86072 5.70508 1.69112 5.70508Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.59211 3.13915L9.34066 3.12646C9.92806 3.14541 10.2123 2.44433 9.79542 2.02746L7.88165 0.113689C7.73006 -0.0378967 7.42689 -0.0378964 7.2753 0.11369L5.36153 2.02746C4.94467 2.44432 5.22889 3.14541 5.81629 3.12646L6.59395 3.13964V9.69294C6.59395 9.77446 6.62635 9.85263 6.68406 9.91024C6.74164 9.96772 6.81968 10 6.90101 10H6.90148L8.28554 9.99709C8.45493 9.99683 8.59211 9.85943 8.59211 9.69003V3.13915Z"
          fill={color}
        />
      </>
    )
  },
  bathroom: {
    width: 14,
    height: 16,
    render: ({color}) => (
      <>
        <path
          d="M3 2.5C3 2.22386 3.22386 2 3.5 2H4.5C4.77614 2 5 2.22386 5 2.5C5 2.77614 4.77614 3 4.5 3H3.5C3.22386 3 3 2.77614 3 2.5Z"
          fill={color}
        />
        <path
          d="M0 0.5C0 0.223858 0.223858 0 0.5 0H13.5C13.7761 0 14 0.223858 14 0.5C14 0.776142 13.7761 1 13.5 1H0.5C0.223857 1 0 0.776142 0 0.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 7C14 6.73478 13.8946 6.48043 13.7071 6.29289C13.5196 6.10536 13.2652 6 13 6H7H1C0.447715 6 0 6.44771 0 7C0 10.4767 3.31247 13 7 13C10.6875 13 14 10.4767 14 7ZM7 12C4.09725 12 1.67595 10.2822 1.12002 8C1.04132 7.67689 1 7.34247 1 7C1 6.44772 3.68629 6 7 6C10.3137 6 13 6.44772 13 7C13 7.34247 12.9587 7.67689 12.88 8C12.3241 10.2822 9.90275 12 7 12Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01493 15.3787L3.01493 11.3787L3.98507 11.6213L3.14039 15H10.8596L10.0149 11.6213L10.9851 11.3787L11.9851 15.3787C12.0224 15.5281 11.9889 15.6863 11.8941 15.8077C11.7993 15.9291 11.654 16 11.5 16H2.5C2.34603 16 2.20065 15.9291 2.1059 15.8077C2.01114 15.6863 1.97759 15.5281 2.01493 15.3787Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1784 7.5054C12.1574 7.51136 12.1361 7.51729 12.1144 7.52317C11.0586 7.80925 9.16297 8 7 8C4.83703 8 2.94138 7.80925 1.88563 7.52317C1.86392 7.51729 1.84257 7.51136 1.82158 7.5054C1.2994 7.3571 1 7.18438 1 7C1 6.81562 1.2994 6.6429 1.82158 6.4946C1.84257 6.48864 1.86392 6.48271 1.88563 6.47683C2.94138 6.19075 4.83703 6 7 6C9.16297 6 11.0586 6.19075 12.1144 6.47683C12.1361 6.48271 12.1574 6.48864 12.1784 6.4946C12.7006 6.6429 13 6.81562 13 7C13 7.18438 12.7006 7.3571 12.1784 7.5054ZM11.407 8.6935C10.252 8.88601 8.69442 9 7 9C5.30558 9 3.74799 8.88601 2.59296 8.6935C2.02347 8.59859 1.50441 8.47704 1.1049 8.31962C0.910119 8.24287 0.68461 8.13509 0.490382 7.9769C0.308594 7.82884 0 7.50577 0 7C0 6.49423 0.308594 6.17116 0.490382 6.0231C0.68461 5.86491 0.910119 5.75713 1.1049 5.68038C1.50441 5.52296 2.02347 5.40141 2.59296 5.3065C3.74799 5.114 5.30558 5 7 5C8.69442 5 10.252 5.114 11.407 5.3065C11.9765 5.40141 12.4956 5.52296 12.8951 5.68038C13.0899 5.75713 13.3154 5.86491 13.5096 6.0231C13.6914 6.17116 14 6.49423 14 7C14 7.50577 13.6914 7.82884 13.5096 7.9769C13.3154 8.13509 13.0899 8.24287 12.8951 8.31962C12.4956 8.47704 11.9765 8.59859 11.407 8.6935Z"
          fill={color}
        />
        <path
          d="M1 0.5V6.5H1.80269C1.86523 6.48198 1.93105 6.46432 2 6.44703V0.5H1Z"
          fill={color}
        />
        <path
          d="M12.1973 6.5C12.1348 6.48198 12.069 6.46432 12 6.44703V0.5H13V6.5H12.1973Z"
          fill={color}
        />
      </>
    )
  },
  dollarSign: {
    width: 7,
    height: 10,
    render: ({color}) => (
      <>
        <path
          d="M3.048 10C2.968 10 2.9 9.97407 2.844 9.92222C2.788 9.87778 2.76 9.81481 2.76 9.73333V8.95555C2.184 8.9037 1.688 8.77407 1.272 8.56667C0.864 8.35926 0.552 8.10741 0.336 7.81111C0.128 7.50741 0.016 7.18519 0 6.84444C0 6.78519 0.024 6.73333 0.072 6.68889C0.128 6.63704 0.192 6.61111 0.264 6.61111H1.344C1.44 6.61111 1.512 6.62963 1.56 6.66667C1.616 6.6963 1.668 6.74815 1.716 6.82222C1.804 7.07407 1.988 7.28518 2.268 7.45556C2.556 7.62593 2.948 7.71111 3.444 7.71111C3.996 7.71111 4.412 7.62593 4.692 7.45556C4.98 7.27778 5.124 7.03704 5.124 6.73333C5.124 6.53333 5.052 6.36667 4.908 6.23333C4.764 6.1 4.544 5.98518 4.248 5.88889C3.96 5.78518 3.532 5.66296 2.964 5.52222C2.02 5.31481 1.328 5.04074 0.888 4.7C0.448 4.35926 0.228 3.87037 0.228 3.23333C0.228 2.65556 0.452 2.16667 0.9 1.76667C1.356 1.36667 1.976 1.12222 2.76 1.03333V0.266667C2.76 0.185185 2.788 0.122222 2.844 0.0777774C2.9 0.0259258 2.968 0 3.048 0H3.792C3.88 0 3.948 0.0259258 3.996 0.0777774C4.052 0.122222 4.08 0.185185 4.08 0.266667V1.05556C4.6 1.12222 5.044 1.26667 5.412 1.48889C5.788 1.7037 6.072 1.95556 6.264 2.24444C6.464 2.53333 6.572 2.81482 6.588 3.08889C6.588 3.15556 6.564 3.21111 6.516 3.25556C6.468 3.3 6.408 3.32222 6.336 3.32222H5.196C5.028 3.32222 4.912 3.25556 4.848 3.12222C4.8 2.88519 4.64 2.68889 4.368 2.53333C4.104 2.37037 3.772 2.28889 3.372 2.28889C2.932 2.28889 2.584 2.36667 2.328 2.52222C2.08 2.67778 1.956 2.9037 1.956 3.2C1.956 3.4 2.016 3.56667 2.136 3.7C2.264 3.83333 2.464 3.95185 2.736 4.05556C3.016 4.15926 3.412 4.27037 3.924 4.38889C4.636 4.53704 5.2 4.7037 5.616 4.88889C6.04 5.07407 6.352 5.30741 6.552 5.58889C6.752 5.87037 6.852 6.22963 6.852 6.66667C6.852 7.31111 6.6 7.83333 6.096 8.23333C5.6 8.63333 4.928 8.87407 4.08 8.95555V9.73333C4.08 9.81481 4.052 9.87778 3.996 9.92222C3.948 9.97407 3.88 10 3.792 10H3.048Z"
          fill={color}
        />
      </>
    )
  },
  elevator: {
    width: 16,
    height: 16,
    render: ({color}) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1ZM3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H3Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.00042 4C5.15231 4 5.29597 4.06905 5.39085 4.18765L7.07851 6.29722C7.25101 6.51285 7.21605 6.8275 7.00042 7C6.78479 7.1725 6.47014 7.13754 6.29764 6.92191L5.50042 5.92539V10.5C5.50042 10.7761 5.27656 11 5.00042 11C4.72428 11 4.50042 10.7761 4.50042 10.5V5.92539L3.7032 6.92191C3.5307 7.13754 3.21605 7.1725 3.00042 7C2.78479 6.8275 2.74983 6.51285 2.92233 6.29722L4.60999 4.18765C4.70487 4.06905 4.84853 4 5.00042 4Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0004 12C11.1523 12 11.296 11.931 11.3909 11.8123L13.0785 9.70278C13.251 9.48715 13.2161 9.1725 13.0004 9C12.7848 8.8275 12.4701 8.86246 12.2976 9.07809L11.5004 10.0746V5.5C11.5004 5.22386 11.2766 5 11.0004 5C10.7243 5 10.5004 5.22386 10.5004 5.5V10.0746L9.7032 9.07809C9.5307 8.86246 9.21605 8.8275 9.00042 9C8.78479 9.1725 8.74983 9.48715 8.92233 9.70278L10.61 11.8123C10.7049 11.931 10.8485 12 11.0004 12Z"
          fill={color}
        />
      </>
    )
  },
  filter: {
    width: 21,
    height: 16.5,
    render: ({color, ...props}) => (
      <g fill="none" fillRule="evenodd">
        <path d="M-2-4h24v24H-2z" />
        <path
          strokeLinecap="round"
          strokeWidth="2"
          {...props}
          stroke={color}
          d="M19.5 3.5H6h13.5zm-4.5 9H1.10546875 15zM3.5 6C2.11928813 6 1 4.88071187 1 3.5S2.11928813 1 3.5 1 6 2.11928813 6 3.5 4.88071187 6 3.5 6zm14 9c-1.3807119 0-2.5-1.1192881-2.5-2.5s1.1192881-2.5 2.5-2.5 2.5 1.1192881 2.5 2.5-1.1192881 2.5-2.5 2.5z"
        />
      </g>
    )
  },
  floor: {
    width: 16,
    height: 16,
    render: ({color}) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 0.5C1 0.223858 1.22386 0 1.5 0H12.5C12.7761 0 13 0.223858 13 0.5V15H12V1H2V15H1V0.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12.5C6 12.2239 6.22386 12 6.5 12H7.5C7.77614 12 8 12.2239 8 12.5V15.5H6V12.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.5C4 3.22386 4.22386 3 4.5 3H5.5C5.77614 3 6 3.22386 6 3.5V4.5C6 4.77614 5.77614 5 5.5 5H4.5C4.22386 5 4 4.77614 4 4.5V3.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.5C8 3.22386 8.22386 3 8.5 3H9.5C9.77614 3 10 3.22386 10 3.5V4.5C10 4.77614 9.77614 5 9.5 5H8.5C8.22386 5 8 4.77614 8 4.5V3.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 6.5C4 6.22386 4.22386 6 4.5 6H5.5C5.77614 6 6 6.22386 6 6.5V7.5C6 7.77614 5.77614 8 5.5 8H4.5C4.22386 8 4 7.77614 4 7.5V6.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 6.5C8 6.22386 8.22386 6 8.5 6H9.5C9.77614 6 10 6.22386 10 6.5V7.5C10 7.77614 9.77614 8 9.5 8H8.5C8.22386 8 8 7.77614 8 7.5V6.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 9.5C4 9.22386 4.22386 9 4.5 9H5.5C5.77614 9 6 9.22386 6 9.5V10.5C6 10.7761 5.77614 11 5.5 11H4.5C4.22386 11 4 10.7761 4 10.5V9.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9.5C8 9.22386 8.22386 9 8.5 9H9.5C9.77614 9 10 9.22386 10 9.5V10.5C10 10.7761 9.77614 11 9.5 11H8.5C8.22386 11 8 10.7761 8 10.5V9.5Z"
          fill={color}
        />
        <path
          d="M0 15.5C0 15.2239 0.223858 15 0.5 15H13.5C13.7761 15 14 15.2239 14 15.5C14 15.7761 13.7761 16 13.5 16H0.5C0.223857 16 0 15.7761 0 15.5Z"
          fill={color}
        />
      </>
    )
  },
  garage: {
    width: 16,
    height: 16,
    render: ({color}) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 15C3.27615 15 3.5 14.7761 3.5 14.5V12.5H4.5L4.5 14.5C4.5 15.3284 3.82843 16 3 16C2.17158 16 1.5 15.3284 1.5 14.5L1.5 12.5H2.5V14.5C2.5 14.7761 2.72386 15 3 15Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 6C9.67158 6 9 6.67157 9 7.5H8C8 6.11929 9.11929 5 10.5 5C11.8807 5 13 6.11929 13 7.5H12C12 6.67157 11.3284 6 10.5 6Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 15C13.2761 15 13.5 14.7761 13.5 14.5V12.5H14.5V14.5C14.5 15.3284 13.8284 16 13 16C12.1716 16 11.5 15.3284 11.5 14.5V12.5H12.5V14.5C12.5 14.7761 12.7239 15 13 15Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.79645 0.996447C3.4879 0.305001 4.40334 0 5.5 0H8.5V1H5.5C4.59667 1 3.96211 1.245 3.50356 1.70355C3.03191 2.1752 2.68457 2.93396 2.4932 4.0822C2.13087 6.25621 1.46873 9.95613 1.09884 12H8.5V13H0.500004C0.351742 13 0.211126 12.9342 0.116128 12.8204C0.0211299 12.7065 -0.0184528 12.5564 0.00806911 12.4106C0.341431 10.5771 1.10749 6.31373 1.50681 3.9178C1.71543 2.66604 2.1181 1.6748 2.79645 0.996447Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2036 0.996447C12.5121 0.305001 11.5967 0 10.5 0H7.5V1H10.5C11.4033 1 12.0379 1.245 12.4965 1.70355C12.9681 2.1752 13.3154 2.93396 13.5068 4.0822C13.8691 6.25621 14.5313 9.95613 14.9012 12H7.5V13H15.5C15.6483 13 15.7889 12.9342 15.8839 12.8204C15.9789 12.7065 16.0185 12.5564 15.9919 12.4106C15.6586 10.5771 14.8925 6.31373 14.4932 3.9178C14.2846 2.66604 13.8819 1.6748 13.2036 0.996447Z"
          fill={color}
        />
        <path
          d="M4.06157e-06 7.5C4.06157e-06 7.22386 0.223862 7 0.500004 7H15.5C15.7761 7 16 7.22386 16 7.5C16 7.77614 15.7761 8 15.5 8H0.500004C0.223862 8 4.06157e-06 7.77614 4.06157e-06 7.5Z"
          fill={color}
        />
        <path
          d="M6 9.5C6 9.22386 6.22386 9 6.5 9H9.5C9.77615 9 10 9.22386 10 9.5C10 9.77614 9.77615 10 9.5 10H6.5C6.22386 10 6 9.77614 6 9.5Z"
          fill={color}
        />
        <path
          d="M12 10.5C12 10.2239 12.2239 10 12.5 10H15V11H12.5C12.2239 11 12 10.7761 12 10.5Z"
          fill={color}
        />
        <path
          d="M4 10.5C4 10.2239 3.77615 10 3.5 10H1V11H3.5C3.77615 11 4 10.7761 4 10.5Z"
          fill={color}
        />
      </>
    )
  },
  info: {
    width: ICON_INFO_WIDTH,
    height: ICON_INFO_HEIGHT,
    render: ({color}) => (
      <>
        <path
          d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM7 12.7273C10.1631 12.7273 12.7273 10.1631 12.7273 7C12.7273 3.83691 10.1631 1.27273 7 1.27273C3.83691 1.27273 1.27273 3.83691 1.27273 7C1.27273 10.1631 3.83691 12.7273 7 12.7273Z"
          fill={color}
        />
        <path
          d="M6.50336 10.8888C6.42539 10.8888 6.35911 10.8589 6.30453 10.799C6.24995 10.7391 6.22266 10.6663 6.22266 10.5807V6.07667C6.22266 5.98252 6.24995 5.90977 6.30453 5.85842C6.35911 5.79851 6.42539 5.76855 6.50336 5.76855H7.49751C7.57548 5.76855 7.64176 5.79851 7.69634 5.85842C7.75092 5.91833 7.77821 5.99108 7.77821 6.07667V10.5807C7.77821 10.6663 7.75092 10.7391 7.69634 10.799C7.64176 10.8589 7.57548 10.8888 7.49751 10.8888H6.50336Z"
          fill={color}
        />
        <path
          d="M6.29988 4.58772C6.35137 4.64763 6.41388 4.67758 6.48743 4.67758H7.5024C7.57595 4.67758 7.63847 4.64763 7.68995 4.58772C7.74879 4.5278 7.77821 4.45505 7.77821 4.36947V3.43228C7.77821 3.33814 7.75247 3.26111 7.70099 3.2012C7.6495 3.14128 7.58331 3.11133 7.5024 3.11133H6.48743C6.41388 3.11133 6.35137 3.14128 6.29988 3.2012C6.2484 3.26111 6.22266 3.33814 6.22266 3.43228V4.36947C6.22266 4.45505 6.2484 4.5278 6.29988 4.58772Z"
          fill={color}
        />
      </>
    )
  },
  room: {
    width: 16,
    height: 16,
    render: ({color}) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 7H3C1.89543 7 1 7.89543 1 9V13H15V9C15 7.89543 14.1046 7 13 7ZM3 6C1.34315 6 0 7.34315 0 9V14H16V9C16 7.34315 14.6569 6 13 6H3Z"
          fill={color}
        />
        <path
          d="M11 1H5C3.89543 1 3 1.89543 3 3V6.5L2 7V3C2 1.34315 3.34315 0 5 0H11C12.6569 0 14 1.34315 14 3V7L13 6.5V3C13 1.89543 12.1046 1 11 1Z"
          fill={color}
        />
        <path
          d="M3 13V15.5C3 15.7761 2.77614 16 2.5 16C2.22386 16 2 15.7761 2 15.5L2 13H3Z"
          fill={color}
        />
        <path
          d="M14 13V15.5C14 15.7761 13.7761 16 13.5 16C13.2239 16 13 15.7761 13 15.5V13H14Z"
          fill={color}
        />
        <path d="M0 10H16V11H0V10Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 6C8 5.28547 7.56559 4.761 7.11536 4.46084C6.65814 4.15603 6.0841 4 5.5 4C4.9159 4 4.34186 4.15603 3.88464 4.46084C3.43441 4.761 3 5.28547 3 6H4C4 5.44772 4.67157 5 5.5 5C6.32843 5 7 5.44772 7 6H8Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 6C13 5.28547 12.5656 4.761 12.1154 4.46084C11.6581 4.15603 11.0841 4 10.5 4C9.9159 4 9.34186 4.15603 8.88464 4.46084C8.43441 4.761 8 5.28547 8 6H9C9 5.44772 9.67157 5 10.5 5C11.3284 5 12 5.44772 12 6H13Z"
          fill={color}
        />
      </>
    )
  },
  sparkles: {
    width: 10,
    height: 10,
    render: ({color}) => (
      <>
        <path
          d="M6.31579 2.02729L7.48538 2.49513L7.95322 3.66472C7.9922 3.7232 8.05068 3.76218 8.10916 3.76218C8.14815 3.76218 8.20663 3.7232 8.24561 3.66472L8.73294 2.49513L9.88304 2.02729C9.94152 1.9883 9.98051 1.92982 9.98051 1.87135C9.98051 1.83236 9.94152 1.77388 9.88304 1.73489L8.73294 1.24756L8.24561 0.0974659C8.20663 0.0389864 8.14815 0 8.10916 0C8.05068 0 7.9922 0.0389864 7.95322 0.0974659L7.48538 1.24756L6.31579 1.73489C6.25731 1.77388 6.21832 1.83236 6.21832 1.87135C6.21832 1.92982 6.25731 1.9883 6.31579 2.02729ZM9.88304 7.97271L8.73294 7.48538L8.24561 6.33528C8.20663 6.2768 8.14815 6.23782 8.10916 6.23782C8.05068 6.23782 7.9922 6.2768 7.95322 6.33528L7.48538 7.48538L6.31579 7.97271C6.25731 8.0117 6.21832 8.07018 6.21832 8.10916C6.21832 8.16764 6.25731 8.22612 6.31579 8.26511L7.48538 8.73294L7.95322 9.90253C7.9922 9.96101 8.05068 10 8.10916 10C8.14815 10 8.20663 9.96101 8.24561 9.90253L8.73294 8.73294L9.88304 8.26511C9.94152 8.22612 9.98051 8.16764 9.98051 8.10916C9.98051 8.07018 9.94152 8.0117 9.88304 7.97271ZM7.48538 4.99025C7.46589 4.83431 7.36842 4.65887 7.21248 4.5809L5.16569 3.56725L4.15205 1.52047C3.9961 1.20858 3.46979 1.20858 3.31384 1.52047L2.30019 3.56725L0.253411 4.5809C0.0974659 4.65887 0 4.83431 0 4.99025C0 5.16569 0.0974659 5.34113 0.253411 5.4191L2.30019 6.43275L3.31384 8.47953C3.39181 8.63548 3.56725 8.75244 3.74269 8.75244C3.89864 8.75244 4.07407 8.63548 4.15205 8.47953L5.16569 6.43275L7.21248 5.4191C7.36842 5.34113 7.46589 5.16569 7.48538 4.99025Z"
          fill={color}
        />
      </>
    )
  },
  subway: {
    width: 16,
    height: 16,
    render: ({color}) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.5C0 1.567 1.567 0 3.5 0H12.5C14.433 0 16 1.567 16 3.5V10.5C16 12.433 14.433 14 12.5 14H3.5C1.567 14 0 12.433 0 10.5V3.5ZM3.5 1C2.11929 1 1 2.11929 1 3.5V10.5C1 11.8807 2.11929 13 3.5 13H12.5C13.8807 13 15 11.8807 15 10.5V3.5C15 2.11929 13.8807 1 12.5 1H3.5Z"
          fill={color}
        />
        <path d="M1 7H15V8H1V7Z" fill={color} />
        <path d="M1 3H15V4H1V3Z" fill={color} />
        <path d="M8.5 3V8H7.5V3H8.5Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10C4.27614 10 4.5 10.2239 4.5 10.5C4.5 10.7761 4.27614 11 4 11C3.72386 11 3.5 10.7761 3.5 10.5C3.5 10.2239 3.72386 10 4 10ZM2.5 10.5C2.5 11.3284 3.17157 12 4 12C4.82843 12 5.5 11.3284 5.5 10.5C5.5 9.67157 4.82843 9 4 9C3.17157 9 2.5 9.67157 2.5 10.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10C12.2761 10 12.5 10.2239 12.5 10.5C12.5 10.7761 12.2761 11 12 11C11.7239 11 11.5 10.7761 11.5 10.5C11.5 10.2239 11.7239 10 12 10ZM10.5 10.5C10.5 11.3284 11.1716 12 12 12C12.8284 12 13.5 11.3284 13.5 10.5C13.5 9.67157 12.8284 9 12 9C11.1716 9 10.5 9.67157 10.5 10.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05279 15.2764L4.05279 13.2764L4.94721 13.7236L4.30902 15H11.691L11.0528 13.7236L11.9472 13.2764L12.9472 15.2764C13.0247 15.4314 13.0164 15.6155 12.9253 15.7629C12.8342 15.9103 12.6733 16 12.5 16H3.5C3.32671 16 3.16578 15.9103 3.07467 15.7629C2.98357 15.6155 2.97529 15.4314 3.05279 15.2764Z"
          fill={color}
        />
      </>
    )
  },
  suite: {
    width: 14,
    height: 16,
    render: ({color}) => (
      <>
        <path
          d="M1 4L0.999999 15.5C0.999999 15.7761 0.776142 16 0.5 16C0.223857 16 -1.20706e-08 15.7761 0 15.5L5.02681e-07 4H1Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1C2.34315 1 1 2.34315 1 4H5.02681e-07C5.02681e-07 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4H7C7 2.34315 5.65685 1 4 1Z"
          fill={color}
        />
        <path
          d="M8.47398 9.29843C8.71924 9.17154 9.02093 9.26749 9.14783 9.51276L9.60734 10.4009C9.73424 10.6462 9.63828 10.9479 9.39302 11.0748C9.14776 11.2017 8.84607 11.1057 8.71918 10.8604L8.25966 9.97227C8.13276 9.72701 8.22872 9.42532 8.47398 9.29843Z"
          fill={color}
        />
        <path
          d="M12.0266 7.46036C12.2719 7.33346 12.5735 7.42942 12.7004 7.67468L13.16 8.56285C13.2868 8.80811 13.1909 9.1098 12.9456 9.23669C12.7004 9.36359 12.3987 9.26763 12.2718 9.02237L11.8123 8.1342C11.6854 7.88894 11.7813 7.58725 12.0266 7.46036Z"
          fill={color}
        />
        <path
          d="M10.7407 11.5034C10.9859 11.3765 11.2876 11.4725 11.4145 11.7177L11.874 12.6059C12.0009 12.8512 11.905 13.1529 11.6597 13.2798C11.4145 13.4066 11.1128 13.3107 10.9859 13.0654L10.5264 12.1773C10.3995 11.932 10.4954 11.6303 10.7407 11.5034Z"
          fill={color}
        />
        <path
          d="M12.517 10.5844C12.7623 10.4575 13.064 10.5534 13.1909 10.7987L13.6504 11.6869C13.7773 11.9321 13.6813 12.2338 13.4361 12.3607C13.1908 12.4876 12.8891 12.3917 12.7622 12.1464L12.3027 11.2582C12.1758 11.013 12.2718 10.7113 12.517 10.5844Z"
          fill={color}
        />
        <path
          d="M10.4801 8.82348C10.7253 8.69659 11.027 8.79254 11.1539 9.0378L11.6134 9.92597C11.7403 10.1712 11.6444 10.4729 11.3991 10.5998C11.1538 10.7267 10.8521 10.6308 10.7252 10.3855L10.2657 9.49732C10.1388 9.25206 10.2348 8.95037 10.4801 8.82348Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.48388 8.63917L12.6381 5.45512C11.6432 4.06014 9.74848 3.5724 8.18243 4.38264C6.61638 5.19288 5.91995 7.02122 6.48388 8.63917ZM5.79399 9.55906C4.65196 7.35171 5.51557 4.6365 7.72292 3.49447C9.93026 2.35244 12.6455 3.21605 13.7875 5.4234C13.9144 5.66866 13.8184 5.97035 13.5732 6.09724L6.46783 9.77338C6.35005 9.83432 6.21289 9.84597 6.08652 9.80578C5.96015 9.76558 5.85492 9.67684 5.79399 9.55906Z"
          fill={color}
        />
      </>
    )
  }
})
