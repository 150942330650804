import {useEffect} from 'react'
import styled from 'styled-components'
import {useForm, FormProvider} from 'react-hook-form'
import OfferCash from './Cash'
import OfferFinancing from './Financing'
import Text from '@emcasa/ui-dom/components/Text'
import {Form} from './styles'

const PAYMENT_METHOD_MAPPER = {
  DEFERRED: {Fields: OfferFinancing, title: 'Financiamento'},
  NEED_TO_SELL: {Fields: OfferCash, title: 'Precisa vender para comprar'},
  CASH: {Fields: OfferCash, title: 'À vista'}
}

const Container = styled.div`
  padding: 15px;
`

export default function Offer({paymentType, onSubmit, onChange, offer}) {
  const {Fields, title} = PAYMENT_METHOD_MAPPER[paymentType] || {
    Fields: null,
    title: null
  }
  if (Fields === null) {
    throw new Error(`[Offer] Unsupported payment method: ${paymentType}`)
  }
  const form = useForm({
    mode: 'onChange',
    defaultValues: Object.assign(
      {
        hasApprovedCredit: true,
        creditTotalValue: null,
        creditDownPayment: null,
        needFgtsWithdrawal: null,
        term: ''
      },
      offer
    )
  })
  const data = form.watch()
  const {isValid} = form.formState

  const submitCallback = form.handleSubmit(onSubmit)
  useEffect(() => {
    onChange({submit: submitCallback, data, isValid})
  })

  return (
    <Container>
      <Text fontWeight="bold" fontSize="small" textAlign="center">
        {title}
      </Text>
      <FormProvider {...form}>
        <Form onSubmit={submitCallback}>
          <Fields />
        </Form>
      </FormProvider>
    </Container>
  )
}
