export const YOU_SETUP_INTRO = 'you-setup-intro'
export const YOU_SETUP_INTRO_NEXT = 'you-setup-intro-next'
export const YOU_SETUP_LOCATION = 'you-setup-location'
export const YOU_SETUP_LOCATION_NEXT = 'you-setup-location-next'
export const YOU_SETUP_LOCATION_BACK = 'you-setup-location-back'
export const YOU_SETUP_TYPE = 'you-setup-type'
export const YOU_SETUP_TYPE_NEXT = 'you-setup-type-next'
export const YOU_SETUP_TYPE_BACK = 'you-setup-type-back'
export const YOU_SETUP_PRICE = 'you-setup-price'
export const YOU_SETUP_PRICE_NEXT = 'you-setup-price-next'
export const YOU_SETUP_PRICE_BACK = 'you-setup-price-back'
export const YOU_SETUP_CONDO = 'you-setup-condo'
export const YOU_SETUP_CONDO_NEXT = 'you-setup-condo-next'
export const YOU_SETUP_CONDO_BACK = 'you-setup-condo-back'
export const YOU_SETUP_DETAILS = 'you-setup-details'
export const YOU_SETUP_DETAILS_NEXT = 'you-setup-details-next'
export const YOU_SETUP_DETAILS_BACK = 'you-setup-details-back'
export const YOU_SETUP_PREFERENCES = 'you-setup-preferences'
export const YOU_SETUP_PREFERENCES_NEXT = 'you-setup-preferences-next'
export const YOU_SETUP_PREFERENCES_BACK = 'you-setup-preferences-back'
export const YOU_SETUP_PAYMENT_TYPE = 'you-setup-payment-type'
export const YOU_SETUP_PAYMENT_TYPE_NEXT = 'you-setup-payment-type-next'
export const YOU_SETUP_PAYMENT_TYPE_BACK = 'you-setup-payment-type-back'
export const YOU_SETUP_BUYER_STAGE = 'you-setup-buyer-stage'
export const YOU_SETUP_BUYER_STAGE_NEXT = 'you-setup-buyer-stage-next'
export const YOU_SETUP_BUYER_STAGE_BACK = 'you-setup-buyer-stage-back'
export const YOU_SETUP_PROFILE = 'you-setup-profile'
export const YOU_SETUP_PROFILE_NEXT = 'you-setup-profile-next'
export const YOU_SETUP_PROFILE_BACK = 'you-setup-profile-back'
export const YOU_SETUP_SELL_TO_BUY = 'you-setup-sell-to-buy'
export const YOU_SETUP_SELL_TO_BUY_ERROR = 'you-setup-sell-to-buy-error'
export const YOU_SETUP_SELL_TO_BUY_NEXT = 'you-setup-sell-to-buy-next'
export const YOU_SETUP_SELL_TO_BUY_BACK = 'you-setup-sell-to-buy-back'
export const YOU_SETUP_FINANCING = 'you-setup-financing'
export const YOU_SETUP_FINANCING_NEXT = 'you-setup-financing-next'
export const YOU_SETUP_FINANCING_BACK = 'you-setup-financing-back'
export const YOU_SETUP_FINANCING_ERROR = 'you-setup-financing-error'
export const YOU_SETUP_SEND = 'you-setup-send'
export const YOU_SETUP_SEND_SUCCESS = 'you-setup-send-success'
export const YOU_SETUP_SEND_ERROR = 'you-setup-send-error'
export const YOU_SETUP_SEND_GETSHORTLIST = 'you-setup-send-getshortlist'
export const YOU_SETUP_SEND_GETSHORTLIST_SUCCESS =
  'you-setup-send-getshortlist-success'
export const YOU_SETUP_SEND_GETSHORTLIST_ERROR =
  'you-setup-send-getshortlist-error'
export const YOU_SETUP_SEND_GETSHORTLIST_TIMEOUT =
  'you-setup-send-getshortlist-timeout'
export const YOU_SETUP_SUCCESS = 'you-setup-success'
export const YOU_SETUP_SUCCESS_BUTTON = 'you-setup-success-button'
export const YOU_SETUP_ERROR = 'you-setup-error'
export const YOU_SETUP_ERROR_BUTTON = 'you-setup-error-button'
export const YOU_SETUP_ERROR_MAILTO = 'you-setup-error-mailto'
export const YOU_SETUP_SIGNIN = 'you-setup-signin'
export const YOU_SETUP_SIGNIN_SUCCESS = 'you-setup-signin-success'
export const YOU_SETUP_SIGNIN_ERROR = 'you-setup-signin-error'
export const YOU_SETUP_COMPLETED = 'you-setup-completed'
export const YOU_SETUP_COMPLETED_BUTTON_WHATSAPP =
  'you-setup-completed-button-whatsapp'
export const YOU_SETUP_COMPLETED_BUTTON_LISTINGS =
  'you-setup-completed-button-listings'
export const YOU_CTA_LINK = 'you-cta-link'
export const YOU_SETUP_SPECIALIST_SEND = 'you-setup-specialist-send'
export const YOU_SETUP_SPECIALIST_SEND_SUCCESS =
  'you-setup-specialist-send-success'
export const YOU_SETUP_SPECIALIST_SEND_ERROR = 'you-setup-specialist-send-error'
