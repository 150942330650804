import {memo} from 'react'
import deepEqual from 'fast-deep-equal'
import useToggle from '@/lib/hooks/useToggle'
import logger from '@/lib/logger'
import {INITIAL_STATE} from '@/lib/hooks/useOrderBy'
import Dropdown from '@emcasa/ui-dom/components/Dropdown'
import styles from '@/styles/templates/Listings/styles.module.css'

const deepEqualStrategy = {
  isSelected: deepEqual,
  update: (_, value) => value
}

const ICON_PROPS = {type: 'default'}

const BUTTON_PROPS = {style: {boxSizing: 'border-box'}}

function SearchOrder({value, onChange}) {
  const focus = useToggle()
  return (
    <div className={styles.ecTemplateListings__order}>
      <p className={styles.ecTemplateListings__order__label}>Ordenar:</p>
      <Dropdown
        strategy={deepEqualStrategy}
        placeholder="Ordenação"
        flexDirection="row-reverse"
        icon={focus.active ? 'chevron-up' : 'chevron-down'}
        iconProps={ICON_PROPS}
        buttonProps={BUTTON_PROPS}
        selectedValue={value ? value : INITIAL_STATE}
        initialValue={INITIAL_STATE}
        height={40}
        onChange={(newValue) => {
          onChange(newValue)
          logger.action('listing-search-ordering', {order: newValue})
        }}
        onFocus={focus.on}
        onBlur={focus.off}
      >
        <Dropdown.Option value={INITIAL_STATE}>Relevância</Dropdown.Option>
        <Dropdown.Option value={{field: 'ID', type: 'DESC'}}>
          Mais recentes
        </Dropdown.Option>
        <Dropdown.Option value={{field: 'PRICE', type: 'ASC'}}>
          Menor preço
        </Dropdown.Option>
        <Dropdown.Option value={{field: 'PRICE', type: 'DESC'}}>
          Maior preço
        </Dropdown.Option>
      </Dropdown>
    </div>
  )
}

export default memo(SearchOrder)
