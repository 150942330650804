import {useEffect} from 'react'
import compose from 'recompose/compose'
import {graphql} from 'react-apollo'
import USER_BLACKLIST_LISTINGS from '@/graphql/queries/userBlacklistListings'
import {buildListingsUrl} from '@/lib/listingsUrl'
import HeadHelmet from '@/components/shared/HeadHelmet'
import {metatagImage} from '@/pages/listings'
import {LazyImageProvider} from '@/components/shared/LazyImage'
import ListingsGrid from '@/components/shared/ListingsGrid'
import Shell from '@/pages/hub/Shell'
import Result from '@/components/shared/Result'
import styles from '@/styles/templates/Profile/styles.module.css'
import resultStyles from '@/styles/molecules/Result/styles.module.css'
import logger from '@/lib/logger'

function HiddenListings({listings, loading}) {
  useEffect(() => {
    logger.action('listing-search-hidden', {
      itemsCount: listings.length
    })
  }, [loading === false])

  return (
    <Shell>
      <HeadHelmet
        title="Seus imóveis escondidos | EmCasa"
        description="Seus imóveis escondidos"
        url={`https://emcasa.com${buildListingsUrl('/escondidos')}`}
        image={metatagImage}
      />
      {!loading && listings && listings.length === 0 ? (
        <div className={resultStyles.ecResult__wrapper}>
          <Result
            icon="/img/icons/hide.svg"
            title="Ainda não há imóveis escondidos"
            text="Esconda os imóveis que você não gostou, e deixe a busca do seu jeito."
            buttons={[
              {
                active: true,
                label: 'Buscar imóveis',
                to: '/imoveis'
              }
            ]}
          />
        </div>
      ) : (
        <>
          <div className={styles.ecTemplateProfile__header}>
            <h2 className={styles.ecTemplateProfile__header__title}>
              Imóveis escondidos
            </h2>
          </div>
          <LazyImageProvider>
            <div className={styles.ecTemplateProfile__preferences__container}>
              <ListingsGrid
                loading={loading}
                listings={listings}
                hideLikeButton={true}
              />
            </div>
          </LazyImageProvider>
        </>
      )}
    </Shell>
  )
}

export default compose(
  graphql(USER_BLACKLIST_LISTINGS, {
    props: ({data = {}}) => ({
      listings:
        (data.userProfile &&
          data.userProfile.blacklists &&
          data.userProfile.blacklists.filter((item) => item.isActive)) ||
        [],
      loading: data && data.loading
    })
  })
)(HiddenListings)
