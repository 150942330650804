import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '@/styles/atoms/Button/styles.module.css'

export function getClassName({
  circle,
  rounded,
  small,
  active,
  dark,
  noBorder,
  noBg,
  anchor,
  selected,
  disabled,
  className
}) {
  return classNames({
    [styles.ecButton]: !circle,
    [styles.ecButtonCircle]: circle,
    [styles.ecButton_s]: small && !circle,
    [styles.ecButtonCircle_s]: small && circle,
    [styles.ecButton_rounded]: rounded,
    [styles.ecButton_active]: active,
    [styles.ecButton_dark]: dark,
    [styles.ecButton_noBorder]: noBorder,
    [styles.ecButton_noBg]: noBg,
    [styles.ecButton_anchor]: anchor,
    [styles.ecButton_selected]: selected,
    [styles.ecButton_disabled]: disabled,
    [className]: className
  })
}

export function getChildren({
  icon,
  iconEqualSize,
  children,
  circle,
  small,
  flipIcon,
  notification
}) {
  return (
    <>
      {icon ? (
        <img
          className={classNames({
            [styles.ecButton__Icon]: true,
            [styles.ecButton__IconNoMargin]: !children || circle,
            [styles.ecButton__Icon_s]: small,
            [styles.ecButton__Icon_flip]: flipIcon,
            [styles.ecButton__Icon_sameSize]: iconEqualSize && !small,
            [styles.ecButton__Icon_s_sameSize]: iconEqualSize && small
          })}
          src={icon}
          alt="Ícone"
          width="18"
          height="18"
        />
      ) : null}
      {circle ? null : children}
      {notification && <span className={styles.ecButton__notification} />}
    </>
  )
}

const Button = ({
  children,
  active,
  dark,
  selected,
  circle,
  rounded,
  icon,
  iconEqualSize,
  noBorder,
  noBg,
  anchor,
  small,
  flipIcon,
  disabled,
  notification,
  className,
  ...otherProps
}) => {
  return (
    <button
      className={getClassName({
        circle,
        rounded,
        small,
        active,
        dark,
        noBorder,
        noBg,
        anchor,
        selected,
        disabled,
        notification,
        className
      })}
      disabled={disabled}
      {...otherProps}
    >
      {getChildren({
        icon,
        iconEqualSize,
        children,
        circle,
        rounded,
        small,
        flipIcon,
        notification
      })}
    </button>
  )
}

Button.propTypes = {
  active: PropTypes.bool,
  dark: PropTypes.bool,
  selected: PropTypes.bool,
  circle: PropTypes.bool,
  rounded: PropTypes.bool,
  small: PropTypes.bool,
  icon: PropTypes.string,
  iconEqualSize: PropTypes.bool,
  flipIcon: PropTypes.bool,
  noBorder: PropTypes.bool,
  noBg: PropTypes.bool,
  anchor: PropTypes.bool,
  disabled: PropTypes.bool,
  notification: PropTypes.bool
}

export default Button
