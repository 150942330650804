import {useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import styled from 'styled-components'
import withGeolocation from '@/lib/hoc/withGeolocation'
import labelStyles from '@/styles/atoms/Label/styles.module.css'
import inputStyles from '@/styles/atoms/Input/styles.module.css'
import textareaStyles from '@/styles/atoms/Textarea/styles.module.css'
import CurrencyInput from '@/components/form/CurrencyInput'
import AddressInput from '@/components/form/AddressInput'
import Text from '@emcasa/ui-dom/components/Text'

export const mapServiceApiUrl = ({center, radius}) => (url) => {
  let {endpoint, queryString} = url
  if (endpoint === 'autocomplete' && center) {
    queryString += `&location[]=${center.lat}&location[]=${center.lng}`
    if (radius) {
      queryString += `&radius=${radius}`
    }
  }
  return `/maps/${endpoint}?${queryString}`
}

const Form = styled.form`
  padding: 15px;
  label {
    margin-bottom: 10px;
  }
`

function SellToBuy({onSubmit, onChange, userLocation, sellToBuy}) {
  const apiURL =
    userLocation && userLocation.ll
      ? mapServiceApiUrl({
          center: {lat: userLocation.ll[0], lng: userLocation.ll[1]},
          radius: 5000
        })
      : '/maps'

  const defaultValues = Object.assign(
    {
      complement: '',
      price: null,
      additionalInformation: '',
      address: null,
      type: 'Apartamento'
    },
    sellToBuy || {}
  )

  const form = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
  })

  const {control, handleSubmit, formState} = form
  const {isValid} = formState

  const submitCallback = handleSubmit(onSubmit)
  useEffect(() => {
    onChange({submit: submitCallback, isValid})
  })

  return (
    <Form onSubmit={submitCallback}>
      <Text fontWeight="bold" fontSize="small" textAlign="center">
        Qual imóvel você precisa vender?
      </Text>
      <Controller name="type" type="hidden" control={control} />
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Endereço</span>
        <Controller
          name="address"
          apiURL={apiURL}
          rules={{
            validate: {
              required: (value) => {
                return Boolean(
                  value !== null &&
                    value.address &&
                    value.addressData &&
                    value.streetNumber
                )
              }
            }
          }}
          control={control}
          as={AddressInput}
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Complemento</span>
        <Controller
          name="complement"
          rules={{required: false}}
          as={<input className={inputStyles.ecInput} type="text" />}
          control={control}
          arial-label="Insira o complemento do endereço"
          placeholder="Ex: Apto 102"
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Valor do imóvel</span>
        <Controller
          name="price"
          control={control}
          rules={{
            required: true,
            validate: (v) => {
              return v > 0
            }
          }}
          as={CurrencyInput}
          arial-label="Insira o valor do seu imóvel"
          placeholder="Ex: 650.000"
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Observações</span>
        <Controller
          control={control}
          rules={{required: false}}
          as={<textarea className={textareaStyles.ecTextarea} />}
          name="additionalInformation"
          arial-label="Insira sua observação"
          placeholder="Digite aqui"
        />
      </label>
    </Form>
  )
}

export default withGeolocation()(SellToBuy)
