import Icon from '@emcasa/ui-dom/components/Icon'
import {mergeProps} from '@/lib/props'
import {Container, Button} from './styles'

export default function CarouselController({
  children,
  controller,
  leftProps = {},
  rightProps = {},
  iconProps = {},
  ...props
}) {
  return (
    <Container {...props}>
      <Button
        {...mergeProps(
          {
            position: 'left',
            'aria-label': 'Voltar',
            className: 'carousel-button-left',
            onClick: () => controller.slideBy(-1)
          },
          leftProps
        )}
      >
        <Icon
          name="chevron-left"
          type="default"
          color="white"
          size={25}
          {...iconProps}
        />
      </Button>
      {children}
      <Button
        {...mergeProps(
          {
            position: 'right',
            'aria-label': 'Próximo',
            className: 'carousel-button-right',
            onClick: () => controller.slideBy(1)
          },
          rightProps
        )}
      >
        <Icon
          name="chevron-right"
          type="default"
          color="white"
          size={25}
          {...iconProps}
        />
      </Button>
    </Container>
  )
}
