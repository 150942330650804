import React, {createContext, useState, useEffect} from 'react'
import LazyLoad from 'vanilla-lazyload'
import styles from './styles.module.css'

const Context = createContext()

export const LazyImageProvider = ({children}) => {
  const [lazyLoad, setLazyLoad] = useState(null)

  useEffect(() => {
    const options = {
      elements_selector: `.${styles.lazyImage__img}`,
      use_native: true
    }
    if (!lazyLoad) {
      setLazyLoad(new LazyLoad(options))
    }
    return () => {
      return lazyLoad && lazyLoad.destroy()
    }
  }, [lazyLoad, lazyLoad && lazyLoad._settings])

  return (
    <Context.Provider value={lazyLoad && lazyLoad._settings ? lazyLoad : null}>
      {children}
    </Context.Provider>
  )
}

export const withLazyImageContext = (Component) => (props) => (
  <Context.Consumer>
    {(context) => <Component {...props} lazyLoad={context} />}
  </Context.Consumer>
)

function LazyImage({as, src, lazyLoad, ...rest}) {
  const props = {
    className: styles.lazyImage__img,
    'data-src': src,
    ...rest
  }

  useEffect(() => {
    if (lazyLoad && lazyLoad._settings) lazyLoad.update()
  }, [src, lazyLoad])

  return React.isValidElement(as)
    ? React.cloneElement(as, props)
    : React.createElement('img', props)
}

export default withLazyImageContext(LazyImage)
