import {nameFromSlug} from '@/lib/format'
import {getCityPreposition} from '@/lib/cities'
import Button from '@/components/shared/Button'
import styles from '@/styles/templates/Listings/styles.module.css'

export const LISTINGS_HEADER_ID = 'listingsheader'

export function getTitle(city, filters, itemsCount) {
  const {neighborhoods, types, streetSlug} = filters
  const typeText =
    types && types.length === 1
      ? `${types[0]}${itemsCount === 1 ? '' : 's'}`
      : itemsCount === 1
      ? 'Imóvel'
      : 'Apartamentos e casas'

  const locationText = streetSlug
    ? nameFromSlug(streetSlug)
    : neighborhoods && neighborhoods.length === 1
    ? neighborhoods[0]
    : city && city.city

  return `${
    itemsCount > 0 ? typeText.toLowerCase() : typeText
  } à venda ${getCityPreposition(city.citySlug)} ${locationText}`
}

export default function SearchTitle({
  city,
  filters,
  isMap,
  itemsCount,
  setFilters
}) {
  return (
    <div id={LISTINGS_HEADER_ID} className={styles.ecTemplatelistings__header}>
      <div className={styles.ecTemplatelistings__header__container}>
        <div className={styles.ecTemplateListings__titleWrapper}>
          {itemsCount && itemsCount >= 10000 && '+'}
          {itemsCount && itemsCount > 0 ? itemsCount : ''}{' '}
          <h1 className={styles.ecTemplateListings__title}>
            {getTitle(city, filters, itemsCount)}
          </h1>
        </div>
        <Button
          icon={
            isMap ? '/img/icons/search-list.svg' : '/img/icons/search-map.svg'
          }
          small={true}
          onClick={() => {
            setFilters({...filters, isMap: !isMap})
          }}
        >
          {isMap ? 'Lista' : 'Mapa'}
        </Button>
      </div>
    </div>
  )
}
