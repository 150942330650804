import {PAVLOV_URL} from '@/config'
import {getUserGeoDataToPavlov} from '@/lib/user'
import {transformCamelCaseKeysToSnakeCase} from '@/lib/format'

const PAVLOV_EVENTS = {
  'page-open': {
    event: 'page-open',
    backends: ['CAPI']
  },
  'listing-detail-open': {
    event: 'listing-detail-open',
    backends: ['CAPI']
  },
  'listing-detail-open-visit-form': {
    event: 'listing-detail-open-visit-form',
    backends: ['CAPI']
  },
  'listing-detail-schedule-visit': {
    event: 'listing-detail-schedule-visit',
    backends: ['CAPI']
  },
  'listing-detail-favorite-listing': {
    event: 'listing-detail-favorite-listing',
    backends: ['CAPI']
  },
  'listing-detail-favorite-related-listing': {
    event: 'listing-detail-favorite-related-listing',
    backends: ['CAPI']
  },
  'listing-offer-submit-success': {
    event: 'listing-offer-submit-success',
    backends: ['CAPI']
  },
  'listing-search-favorite-listing': {
    event: 'listing-search-favorite-listing',
    backends: ['CAPI']
  },
  'listing-detail-open-interest': {
    event: 'listing-detail-open-interest',
    backends: ['CAPI']
  },
  'listing-search-results': {
    event: 'listing-search-results',
    backends: ['CAPI']
  },
  'you-setup-send': {
    event: 'you-setup-send',
    backends: ['CAPI']
  },
  'you-setup-send-success': {
    event: 'you-setup-send-success',
    backends: ['CAPI']
  },
  'you-setup-specialist-send-success': {
    event: 'you-setup-specialist-send-success',
    backends: ['CAPI']
  }
}

export function logEvent(event, properties, user) {
  if (window && window.fetch) {
    const pavlovEvent = PAVLOV_EVENTS[event]

    if (pavlovEvent) {
      // eslint-disable-next-line no-unused-vars
      const {eventID, currentUser, userProfile, ...eventProps} = properties

      fetch(`${PAVLOV_URL}/api/events/push`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          backends: pavlovEvent.backends,
          event: {
            id: eventID,
            name: event,
            props: eventProps,
            source: 'website',
            url: window.location.href,
            timestamp: Math.floor(Date.now() / 1000)
          },
          userData: {
            ...transformCamelCaseKeysToSnakeCase(user),
            ...transformCamelCaseKeysToSnakeCase(getUserGeoDataToPavlov())
          }
        })
      })
    }
  }
}
