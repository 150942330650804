import {Fragment, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import logger from '@/lib/logger'
import Card from '@/components/listings/Card'
import CardLoading from '@/components/listings/Card/Loading'
import {LazyImageProvider} from '@/components/shared/LazyImage'
import ButtonLink from '@/components/shared/Button/Link'
import styles from './styles.module.css'

const noLazyCardImage = (index, noLazy) => Boolean(index < 2 && noLazy)

function ListingsGrid({
  scroll,
  listings,
  small,
  noPadding = false,
  feedType,
  loading,
  loadingCardsCount = 4,
  hideCardPagination,
  cardActions = {},
  cardTarget,
  cta,
  cardUrl,
  checkCardActivity,
  button,
  noLazy
}) {
  const scrollRef = useRef()
  let refScrolled = false
  let scrollTimeout

  function onScroll() {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout)
    }

    if (!refScrolled && scrollRef.current.scrollLeft > 0) {
      scrollTimeout = setTimeout(() => {
        refScrolled = true
        logger.action('listings-scroll', {feedType})
      }, 500)
    }
  }

  const listClasses = classNames({
    [styles.ecListingsGrid__list]: !scroll,
    [styles.ecListingsGrid__list_s]: small && !scroll,
    [styles.ecListingsGrid__list_scroll]: scroll,
    [styles.ecListingsGrid__list_scroll_s]: small && scroll,
    [styles.ecListingsGrid__list_noPadding]: noPadding
  })

  useEffect(() => {
    if (scroll && scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener('scroll', onScroll)
      return () => {
        scrollRef.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return (
    <div className={styles.ecListingsGrid}>
      <div ref={scrollRef} className={listClasses}>
        <LazyImageProvider>
          {loading
            ? Array.from({length: loadingCardsCount}).map((_, index) => (
                <CardLoading key={`loading-${index}`} />
              ))
            : listings.map((listing, index) => {
                return (
                  <Fragment key={`listing-${index}`}>
                    {cta && cta.position === index ? (
                      <div className={styles.ecListingsGrid__cta}>
                        {cta.component}
                      </div>
                    ) : null}
                    <Card
                      noLazy={noLazyCardImage(index, noLazy)}
                      listing={listing}
                      feedType={feedType}
                      hidePagination={hideCardPagination}
                      cardUrl={cardUrl}
                      cardTarget={cardTarget}
                      fromShortlist={feedType === 'selected'}
                      checkCardActivity={checkCardActivity}
                      {...cardActions}
                    />
                  </Fragment>
                )
              })}
        </LazyImageProvider>
      </div>
      {button ? (
        <div className={styles.ecListingsGrid__buttonWrapper}>
          <ButtonLink
            to={button.to}
            target={cardTarget}
            onClick={button.onClick}
          >
            {button.label}
          </ButtonLink>
        </div>
      ) : null}
    </div>
  )
}

ListingsGrid.propTypes = {
  scroll: PropTypes.bool,
  listings: PropTypes.array.isRequired,
  small: PropTypes.bool,
  noPadding: PropTypes.bool,
  loading: PropTypes.bool,
  loadingCardsCount: PropTypes.number,
  feedType: PropTypes.string,
  hideLikeButton: PropTypes.bool,
  hideDislikeButton: PropTypes.bool,
  cardActions: PropTypes.object,
  cardTarget: PropTypes.string,
  checkCardActivity: PropTypes.bool,
  noLazy: PropTypes.bool,
  cta: PropTypes.shape({
    component: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired
  }),
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick: PropTypes.func
  })
}

export default ListingsGrid
