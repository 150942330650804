import PropTypes from 'prop-types'
import styles from '@/styles/templates/Default/styles.module.css'
import stylesHeader from '@/styles/organisms/Header/styles.module.css'
import Login from '@emcasa/login/lib/LoginSaga'

function Template({children, noLogo}) {
  return (
    <>
      <header className={stylesHeader.ecHeader}>
        <div className={stylesHeader.ecHeader__wrapper}>
          <div className={stylesHeader.ecHeader__item}>
            {!noLogo && (
              <a
                className={stylesHeader.ecHeader__logo}
                href="/"
                title="Ir para página inicial"
              >
                <img
                  className={stylesHeader.ecHeader__logo__img}
                  src="/img/emcasa.svg"
                  alt="Logo da EmCasa"
                />
              </a>
            )}
          </div>
        </div>
      </header>
      <main className={styles.main}>{children}</main>
      <Login />
    </>
  )
}

Template.propTypes = {
  isLoading: PropTypes.bool,
  noLogo: PropTypes.bool
}

export default Template
