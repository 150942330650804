export const loggerAction = {
  buttonLogin: 'header-menu-login',
  buttonLogout: 'header-menu-logout',
  link: 'menu-link'
}

export const NAV_ITEMS = [
  {
    to: '/',
    title: 'Home',
    text: 'Conheça como comprar imóvel com a EmCasa'
  },
  {
    to: '/imoveis',
    title: 'Buscar imóvel',
    text: 'Busque imóveis disponíveis para venda'
  },
  {
    to: '/vender',
    title: 'Vender imóvel',
    text: 'Anuncie seu imóvel com fotos profissionais gratuitamente'
  },
  {
    to: '/parceria',
    title: 'Parceria',
    text:
      'Tenha acesso a serviços e ferramentas para potencializar seu negócio imobiliário'
  },
  {
    to: '/favoritos',
    title: 'Imóveis favoritos',
    text: 'Veja os seus imóveis favoritos'
  },
  {
    to: '/recomendacoes',
    title: 'Recomendação de imóveis',
    text: 'Veja a nossa seleção diária de imóveis com base no que você procura'
  },
  {
    to: '/avaliar',
    title: 'Avaliar imóvel',
    text:
      'Receba uma recomendação de valor de anúncio competitivo para o imóvel'
  },
  {
    to: '/quem-somos',
    title: 'Sobre a EmCasa',
    text: 'Conheça nossa história e veja as vagas que temos abertas.'
  }
]
