import gql from 'graphql-tag'
import UserProfile from '../fragments/UserProfile'

export default gql`
  query userProfile {
    userProfile {
      ...UserProfile
      buyerStage
      paymentType
      searchPreferences {
        areaOfInterest
        city
        citySlug
        elevator
        listingTypes
        maxMaintenanceFee
        maxValue
        minBathrooms
        minGarageSpots
        minRooms
        nearbySubway
        neighborhoods
        state
        stateSlug
      }
      activeBuyerOpportunity {
        owner {
          name
          phone
          pictureUrl
        }
      }
      recommendations(filters: {origins: [SALES]}) {
        totalEntries
      }
    }
  }
  ${UserProfile}
`
