import {useMutation} from 'react-apollo'
import logger from '@/lib/logger'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setJwtToken} from '@/redux/modules/auth/actions'
import Col from '@emcasa/ui-dom/components/Col'
import Text from '@emcasa/ui-dom/components/Text'
import Button from '@/components/shared/Button'
import ButtonLink from '@/components/shared/Button/Link'
import Loader from '@/components/listing/components/Gallery/components/Loader'
import OFFER_CREATE from '@/graphql/mutations/offerCreate'
import CREATE_SELL_TO_BUY_LEAD from '@/graphql/mutations/createSellToBuyLead'
import {getAddressInput} from '@/lib/address'
import theme from '@/config/theme'
import styles from '@/styles/atoms/Button/styles.module.css'
import composesStyles from '@/styles/_settings/composes.module.css'
import {getListingUrl} from '@/lib/listingsUrl'

function Error({retry}) {
  return (
    <>
      <Text color="pink500" fontWeight="bold" fontSize="large" as="h3">
        Ocorreu um erro ao enviar a sua proposta
      </Text>
      <Text fontSize="small">
        Tente enviá-la novamente ou entre em contato com a nossa equipe.
      </Text>

      <Button active onClick={retry}>
        Tentar novamente
      </Button>
      <a
        className={styles.ecButton}
        href={String(
          `mailto:contato@emcasa.com?subject=${encodeURIComponent(
            'EmCasa - Erro ao enviar Proposta'
          )}`
        )}
      >
        Entrar em contato
      </a>
    </>
  )
}

function Success({listingId}) {
  const ICON_SIZE = 60

  return (
    <>
      <svg
        viewBox={`0 0 ${ICON_SIZE} ${ICON_SIZE}`}
        xmlns="http://www.w3.org/2000/svg"
        width={ICON_SIZE}
        height={ICON_SIZE}
      >
        <circle cx="28" cy="28" r="28" fill="#B3F4D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 4.75C16.5024 4.75 4.75 16.5024 4.75 31C4.75 45.4976 16.5024 57.25 31 57.25C45.4976 57.25 57.25 45.4976 57.25 31C57.25 16.5024 45.4976 4.75 31 4.75ZM3 31C3 15.5359 15.5359 3 31 3C46.4641 3 59 15.5359 59 31C59 46.4641 46.4641 59 31 59C15.5359 59 3 46.4641 3 31ZM45.6187 23.3813C45.9604 23.723 45.9604 24.277 45.6187 24.6187L30.3062 39.9312C29.9645 40.2729 29.4105 40.2729 29.0688 39.9312L19.8813 30.7437C19.5396 30.402 19.5396 29.848 19.8813 29.5063C20.223 29.1646 20.777 29.1646 21.1187 29.5063L29.6875 38.0751L44.3813 23.3813C44.723 23.0396 45.277 23.0396 45.6187 23.3813Z"
          fill="#33174D"
        />
      </svg>
      <Text color="pink500" fontWeight="bold" fontSize="large" as="h3">
        Sucesso!
        <br />
        Recebemos sua proposta, em breve entraremos em contato.
      </Text>
      <Text fontSize="small">
        Enquanto isso, veja mais uma vez o imóvel que você acabou de fazer uma
        proposta.
      </Text>
      <ButtonLink
        active
        to={{pathname: getListingUrl({id: listingId})}}
        onClick={() => logger.action('listing-offer-gotolisting', {listingId})}
      >
        Ver imóvel
      </ButtonLink>
      <ButtonLink
        to={{pathname: '/interesses/propostas'}}
        onClick={() => logger.action('listing-offer-gotooffers', {listingId})}
      >
        Ver propostas
      </ButtonLink>
    </>
  )
}

export default function SubmitOffer({
  offer,
  contact,
  paymentType,
  sellToBuy,
  listingId
}) {
  const dispatch = useDispatch()
  const [offerCreate, {loading, error}] = useMutation(OFFER_CREATE)
  const [createSellToBuyLead] = useMutation(CREATE_SELL_TO_BUY_LEAD, {
    options: {
      ignoreResults: true,
      errorPolicy: 'ignore'
    }
  })

  const createSellToBuy = () => {
    if (sellToBuy) {
      const {address, ...input} = sellToBuy
      let addressInput = getAddressInput({addressData: address.addressData})
      if (address.streetNumber) {
        addressInput.streetNumber = address.streetNumber
      }
      createSellToBuyLead({
        variables: {
          input: {
            ...input,
            address: addressInput
          }
        }
      })
    }
  }

  const executeMutation = () => {
    const input = {
      ...offer,
      ...contact,
      paymentType,
      listingId
    }

    logger.action('listing-offer-submit', {listingId, offer: input})

    offerCreate({
      variables: {
        input
      }
    })
      .then(({data}) => {
        const {
          offerCreate: {credentials}
        } = data
        logger.action('listing-offer-submit-success', {listingId})
        if (credentials && credentials.jwt) {
          dispatch(setJwtToken(credentials.jwt))
          createSellToBuy()
        }
      })
      .catch(() => {
        logger.action('listing-offer-submit-error', {listingId})
      })
  }

  useEffect(() => executeMutation(), [])

  if (!loading) {
    return (
      <Col
        className={composesStyles.animation_enterfrombottom}
        px={4}
        py={5}
        width="100%"
        minHeight="100%"
        style={{
          display: 'grid',
          gridGap: theme.space[4],
          alignContent: 'center',
          boxSizing: 'border-box'
        }}
      >
        {error ? (
          <Error retry={executeMutation} />
        ) : (
          <Success listingId={listingId} />
        )}
      </Col>
    )
  }

  return (
    <div>
      <Loader />
    </div>
  )
}
