import Cookie from 'js-cookie'

export const persist = (jwt) => {
  if (jwt) {
    Cookie.set('jwt', jwt)
  }
}

export const reset = () => Cookie.remove('jwt')

export const getToken = (req) => {
  const token =
    (req ? require('cookie').parse(req.headers.cookie || '') : Cookie.get())
      .jwt || null
  if (token === 'undefined' || !token) {
    return null
  }
  return token
}
