import flow from 'lodash/fp/flow'
import deburr from 'lodash/deburr'
import groupBy from 'lodash/fp/groupBy'
import sortBy from 'lodash/fp/sortBy'
import esc from 'escape-string-regexp'
import diacritics from 'diacritic'
import Button from '@/components/shared/Button'
import styles from '@/styles/organisms/Filters/styles.module.css'

export const getGroupsMatched = (pattern, options) =>
  groupOptions(
    !pattern
      ? options
      : options
          .map((o) => ({...o, match: matchOption(pattern)(o)}))
          .filter((o) => Boolean(o.match))
  )

/**
 * Sorts and groups TagInput options by category.
 * @param {Array} options
 * @returns {Map} Option arrays grouped by category
 */
const groupOptions = flow(
  sortBy((facet) => deburr(facet.name)),
  groupBy('group'),
  (g) => Object.entries(g),
  sortBy(([g]) => g),
  (g) => new Map(g)
)

const match = (regexp) => RegExp.prototype.exec.bind(regexp)

/**
 * Matches a pattern against an option's `name` property.
 * @param {String} pattern
 * @param {Object} option
 * @returns {Array|null} Array with matched string's [index, length]
 */
const matchOption = (pattern) =>
  flow(
    (o) => diacritics.clean(o.name),
    match(new RegExp(esc(diacritics.clean(pattern)), 'ig')),
    (m) => (m ? [m.index, m[0].length] : null)
  )

// –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

const TagButton = ({value, name, match, selected, onSelect}) => {
  return (
    <div key={value} className={styles.ecFilters__search__options__item}>
      <Button small value={value} selected={selected} onClick={onSelect}>
        {match ? highlightText(name, match) : name}
      </Button>
    </div>
  )
}

export const renderGroup = ([group, options]) => [
  <GroupTitle key={group}>{group}</GroupTitle>,
  ...options.map(({value, name, match}) => {
    return <TagButton key={value} name={name} value={value} match={match} />
  })
]

const GroupTitle = ({children}) => (
  <p className={styles.ecFilters__search__options_title}>{children}</p>
)

const highlightText = (text, [start, len]) => [
  text.slice(0, start),
  <span key="highlight" className="highlight">
    {text.slice(start, start + len)}
  </span>,
  text.slice(start + len, text.length)
]
