import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import {useRef, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import withDistricts from '@/lib/hoc/withDistricts'
import {getAddressInput} from '@/lib/address'
import logger from '@/lib/logger'
import {NEARBY_SETTINGS} from '@/config'
import {BRAZIL_CENTER} from '@/components/listings/ListingsMap'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import Suggestion from './Suggestion'
import classNames from 'classnames'
import styles from './styles.module.css'

const loggerAction = {
  error: 'location-search-input-geocode-error',
  focus: 'location-search-input-focus'
}

const placeholderDefault = 'Buscar por bairro ou rua'

function geocodePlace(place, districts, onSuccess) {
  geocodeByAddress(place)
    .then((results) => {
      getLatLng(results[0]).then(({lat, lng}) => {
        const inputAddress = getAddressInput({addressData: results[0]})
        const stateAndCity =
          inputAddress?.city &&
          districts.find((item) => item.city === inputAddress.city)
        if (onSuccess) {
          onSuccess(
            {
              ...inputAddress,
              lat: lat,
              lng: lng,
              citySlug: stateAndCity && stateAndCity.citySlug,
              stateSlug: stateAndCity && stateAndCity.stateSlug
            },
            place
          )
        }
      })
    })
    .catch((error) => logger.error({type: loggerAction.error, error}))
}

function LocationSearchInput({
  onChange = null,
  onClear = null,
  onSelect = null,
  placeholder = placeholderDefault,
  clearPlace,
  enableIDSearch = false,
  districts,
  small = true
}) {
  const history = useHistory()
  const [place, setPlace] = useState('')
  const inputRef = useRef()

  function clear() {
    if (place !== '') {
      setPlace('')
      onChange()
    }
  }

  function focus() {
    logger.action(loggerAction.focus)
  }

  function onPlacesAutocompleteSelect(place) {
    if (isListingID) {
      history.push(`/imoveis/${place}`, {
        goBack: true
      })
      return
    }
    setPlace(place)
    geocodePlace(place, districts, onChange)
    if (inputRef && inputRef.current) {
      inputRef.current.blur()
    }
  }
  const center = BRAZIL_CENTER
  const bounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1
  }

  const searchOptions = {
    bounds,
    radius: NEARBY_SETTINGS.searchInputRadius,
    componentRestrictions: {
      country: 'br'
    }
  }

  const isListingID = place && /^\d+$/.test(place) && enableIDSearch

  useEffect(() => {
    if (clearPlace) clear()
  }, [clearPlace])

  return (
    <PlacesAutocomplete
      value={place}
      onChange={setPlace}
      searchOptions={searchOptions}
      onSelect={onPlacesAutocompleteSelect}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div className={styles.ecSearchLocationInput}>
          <div
            className={classNames(
              styles.ecSearchLocationInput__inputContainer,
              {
                [styles.ecSearchLocationInput__inputContainer_small]: small
              }
            )}
          >
            <img
              className={styles.ecSearchLocationInput__leadingIcon}
              src="/img/icons/search-grey300.svg"
              alt="Ícone: lupa"
            />
            <input
              className={classNames(styles.ecSearchLocationInput__input, {
                [styles.ecSearchLocationInput__input_small]: small
              })}
              {...getInputProps({
                ref: inputRef,
                placeholder
              })}
              onFocus={focus}
            />
            {place !== '' ? (
              <button
                className={classNames(
                  styles.ecSearchLocationInput__buttonIcon,
                  {
                    [styles.ecSearchLocationInput__buttonIcon_small]: small
                  }
                )}
                onClick={() => {
                  if (onClear) onClear()
                  clear()
                }}
              >
                <img
                  src="/img/icons/close-gray.svg"
                  className={styles.ecSearchLocationInput__buttonIcon__icon}
                />
              </button>
            ) : null}
          </div>
          <div
            className={classNames(styles.ecSearchLocationInput__list, {
              [styles.ecSearchLocationInput__list_show]:
                loading || suggestions.length > 0 || isListingID
            })}
          >
            {loading && !isListingID && (
              <Suggestion small={small} mainText="Carregando..." />
            )}
            {isListingID && (
              <Suggestion
                onSelect={onSelect}
                small={small}
                {...getSuggestionItemProps({
                  active: false,
                  index: 0,
                  placeId: place,
                  description: place
                })}
                mainText={`Ver imóvel #${place}`}
              />
            )}
            {!isListingID &&
              suggestions.map((suggestion, index) => {
                return (
                  <Suggestion
                    onSelect={onSelect}
                    small={small}
                    {...getSuggestionItemProps(suggestion, {
                      active: suggestion.active
                    })}
                    key={`suggestion-${index}`}
                    mainText={suggestion.formattedSuggestion.mainText}
                    secondaryText={suggestion.formattedSuggestion.secondaryText}
                  />
                )
              })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

LocationSearchInput.propTypes = {
  small: PropTypes.bool
}

export default compose(withDistricts)(LocationSearchInput)
