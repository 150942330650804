import {useEffect} from 'react'
import compose from 'recompose/compose'
import {useHistory} from 'react-router-dom'
import withDistricts from '@/lib/hoc/withDistricts'
import {getCitiesFromDistricts} from '@/lib/districts'
import {buildListingsUrl, getSearchPath} from '@/lib/listingsUrl'
import logger from '@/lib/logger'
import {getUserGeoDataCitySlug} from '@/lib/user'
import Shell from '../Shell'
import {metatagImage} from '@/pages/listings'
import HeadHelmet from '@/components/shared/HeadHelmet'
import Button from '@/components/shared/Button/Link'
import styles from '@/styles/templates/Listings/styles.module.css'

function CitySwitcher({districts, match}) {
  if (typeof window === 'undefined') return null

  const history = useHistory()

  const cities = districts ? getCitiesFromDistricts(districts) : []
  const userCity = getUserGeoDataCitySlug()
  const userCityObj = userCity && cities?.find((item) => item.slug === userCity)
  const searchPath = getSearchPath(match)

  useEffect(() => logger.action('listing-search-nocity'), [])
  useEffect(() => {
    if (userCityObj) {
      history.replace({
        pathname: `/imoveis/${userCityObj?.state}/${
          userCityObj?.slug
        }${searchPath.filteredPathname || ''}`,
        search: searchPath.qs || '',
        state: {
          scroll: 0
        }
      })
    }
  }, [userCity, userCityObj?.state, searchPath.filteredPathname, searchPath.qs])

  return (
    <Shell>
      <HeadHelmet
        title="EmCasa - Imóveis à venda com os melhores especialistas imobiliários"
        description="A EmCasa te ajuda a encontrar Apartamentos à Venda com tecnologia e um time de especialistas, tornando a compra de um imóvel mais transparente, ágil e segura."
        url={`https://emcasa.com${buildListingsUrl('/')}`}
        image={metatagImage}
      />
      <div className={styles.ecTemplateListings__noCity}>
        <h3>Onde você quer comprar um imóvel?</h3>
        <p>Selecione a cidade:</p>
        <div className={styles.ecTemplateListings__noCity__buttons}>
          {cities &&
            cities.map(({name, slug, state}) => (
              <Button
                className={styles.ecTemplateListings__noCity__buttons__item}
                icon="/img/icons/location.svg"
                to={buildListingsUrl(
                  `${state}/${slug}${searchPath.fullPath || ''}`
                )}
                key={slug}
                onClick={() =>
                  logger.action('listing-search-nocity-button', {
                    name,
                    slug,
                    state
                  })
                }
              >
                {name}
              </Button>
            ))}
        </div>
        <img
          alt="Ilustração de um homem indo até uma porta aberta"
          src="/img/illustrations/listings-no-city.svg"
        />
      </div>
    </Shell>
  )
}

export default compose(withDistricts)(CitySwitcher)
