// Listing Detail
export const LISTING_DETAIL_OPEN = 'listing-detail-open'
export const LISTING_DETAIL_PHOTOS = 'listing-detail-photos'
export const LISTING_DETAIL_PHOTOS_FULLSCREEN_OPEN =
  'listing-detail-photos-fullscreen-open'
export const LISTING_DETAIL_PHOTOS_FULLSCREEN_CLOSE =
  'listing-detail-photos-fullscreen-close'
export const LISTING_DETAIL_MATTERPORT_OPEN = 'listing-detail-matterport-open'
export const LISTING_DETAIL_MATTERPORT_CLOSE = 'listing-detail-matterport-close'
export const LISTING_DETAIL_VIEW_FEATURED_LISTING =
  'listing-detail-view-featured-listing'
export const LISTING_DETAIL_MAP_OPEN = 'listing-detail-map-open'
export const LISTING_DETAIL_MAP_CLOSE = 'listing-detail-map-close'
export const LISTING_DETAIL_STREETVIEW_OPEN = 'listing-detail-streetview-open'
export const LISTING_DETAIL_STREETVIEW_CLOSE = 'listing-detail-streetview-close'
export const LISTING_DETAIL_MORE_LISTINGS_BUTTON =
  'listing-detail-more-listings-button'
export const LISTING_DETAIL_FAVORITE_LISTING = 'listing-detail-favorite-listing'
export const LISTING_DETAIL_FAVORITE_RELATED_LISTING =
  'listing-detail-favorite-related-listing'
export const LISTING_FINANCING_DOWNPAYMENTVALUE_CHANGE =
  'listing-financing-downpayment-change'
export const LISTING_FINANCING_TERM_CHANGE = 'listing-financing-term-change'
export const LISTING_FINANCING_CONTACT_BUTTON =
  'listing-financing-contact-button'
export const LISTING_DETAIL_TAXES = 'listing-detail-taxes'
export const LISTING_DETAIL_GALLERY_BUTTON = 'listing-detail-gallery-button'
export const LISTING_DETAIL_GALLERY_SCROLL_COMPLETED =
  'listing-detail-gallery-scroll-completed'
export const LISTING_DETAIL_GALLERY_FULLSCREEN_OPEN =
  'listing-detail-gallery-fullscreen-open'
export const LISTING_DETAIL_GALLERY_FULLSCREEN_CLOSE =
  'listing-detail-gallery-fullscreen-close'
export const LISTING_DETAIL_OPEN_INTEREST = 'listing-detail-open-interest'

// Buyer Lead
export const LISTING_DETAIL_CONTACT_BUTTON = 'listing-detail-contact-button'
export const LISTING_DETAIL_OPEN_VISIT_FORM = 'listing-detail-open-visit-form'
export const LISTING_DETAIL_SINGLECTA_SHOWMODAL =
  'listing-detail-singlecta-showModal'
export const LISTING_DETAIL_SCHEDULE_VISIT = 'listing-detail-schedule-visit'
export const LISTING_DETAIL_SCHEDULEMODAL_GETAVAILABLEAPPOINTMENTTIME =
  'listing-detail-schedulemodal-getavailableappointmenttime'
export const LISTING_DETAIL_SCHEDULEMODAL_GETAVAILABLEAPPOINTMENTTIME_SUCCESS =
  'listing-detail-schedulemodal-getavailableappointmenttime-success'
export const LISTING_DETAIL_SCHEDULEMODAL_GETAVAILABLEAPPOINTMENTTIME_ERROR =
  'listing-detail-schedulemodal-getavailableappointmenttime-error'
export const LISTING_DETAIL_SCHEDULEMODAL_GETUSERINFO =
  'listing-detail-schedulemodal-getuserinfo'
export const LISTING_DETAIL_SCHEDULEMODAL_GETUSERINFO_SUCCESS =
  'listing-detail-schedulemodal-getuserinfo-success'
export const LISTING_DETAIL_SCHEDULEMODAL_GETUSERINFO_ERROR =
  'listing-detail-schedulemodal-getuserinfo-error'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_DATE =
  'listing-detail-schedulemodal-step-date'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_DATE_ONDAYSELECTED =
  'listing-detail-schedulemodal-step-date-ondayselected'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_DATE_ONHOURSELECTED =
  'listing-detail-schedulemodal-step-date-onhourselected'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_PROFILE =
  'listing-detail-schedulemodal-step-profile'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SEND =
  'listing-detail-schedulemodal-step-send'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SEND_SUCCESS =
  'listing-detail-schedulemodal-step-send-success'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SEND_ERROR =
  'listing-detail-schedulemodal-step-send-error'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS =
  'listing-detail-schedulemodal-step-success'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_SEARCH =
  'listing-detail-schedulemodal-step-success-button-search'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_LIKE =
  'listing-detail-schedulemodal-step-success-button-like'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_LIKE_SUCCESS =
  'listing-detail-schedulemodal-step-success-button-like-success'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_SUCCESS_BUTTON_LIKE_ERROR =
  'listing-detail-schedulemodal-step-success-button-like-error'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_ERROR =
  'listing-detail-schedulemodal-step-error'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_ERROR_BUTTON_TRYGAIN =
  'listing-detail-schedulemodal-step-error-button-tryagain'
export const LISTING_DETAIL_SCHEDULEMODAL_STEP_ERROR_BUTTON_MAILTO =
  'listing-detail-schedulemodal-step-error-button-mailto'
