import logger from '@/lib/logger'
import styles from '@/styles/templates/Listings/styles.module.css'

const ScrollTopButton = () => (
  <div className={styles.ecTemplateListings__buttonAnchorWrapper}>
    <a
      href="#"
      className={styles.ecTemplateListings__buttonAnchor}
      onClick={() => {
        logger.action('listing-search-scroll-top')
      }}
    >
      <img
        alt="Ícone"
        className={styles.ecTemplateListings__buttonAnchor__icon}
        src="/img/icons/arrow-y-pink.svg"
        width="88"
        height="12"
      />
      Ir para o topo
    </a>
  </div>
)

export default ScrollTopButton
