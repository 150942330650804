import logger from '@/lib/logger'
import {FIREBASE_CONFIG, FIREBASE_FCM_VAPID} from '@/config'
const NOTIFICATION_TOKEN_EVENT = 'push-notification-token'

function getFCMToken(registration, onFinish) {
  if (!self.firebase.messaging.isSupported() || !('Notification' in self)) {
    console.error('Firebase is not supported')
    return false
  }
  if (!window.firebase) {
    console.error('No firebase avaialble')
    return false
  }
  if (!FIREBASE_CONFIG || !FIREBASE_FCM_VAPID) {
    console.error('Missing firebase config')
    return false
  }
  if (!window.firebase.apps.length)
    window.firebase.initializeApp(FIREBASE_CONFIG)

  window.firebase
    .messaging()
    .getToken({
      vapidKey: FIREBASE_FCM_VAPID,
      serviceWorkerRegistration: registration
    })
    .then((currentToken) => {
      if (currentToken) {
        logger.identify(null, currentToken)
        logger.action(NOTIFICATION_TOKEN_EVENT, {
          token: currentToken,
          success: true
        })
      } else {
        logger.action(NOTIFICATION_TOKEN_EVENT, {
          token: null,
          error: 'deny',
          success: false
        })
      }
    })
    .catch((err) => {
      logger.action(NOTIFICATION_TOKEN_EVENT, {
        token: null,
        error: String(err),
        success: false
      })
    })
    .finally(() => {
      if (onFinish) onFinish()
    })
}

export async function requestPushNotificationToken(
  swRegistration = null,
  onFinish
) {
  if (swRegistration === null) {
    swRegistration = await navigator.serviceWorker.ready
  }
  getFCMToken(swRegistration, onFinish)
}

export function verifyPermission() {
  if (typeof window === 'undefined') return false

  // Safari doesn't support navigator.permissions, therefore, we need to use the notification system
  // that is available in the user's browser (navigator.permissions or Notification)
  // if none is available, we simply return false
  if (
    typeof window.navigator?.permissions === 'undefined' &&
    typeof window.Notification === 'undefined'
  )
    return false

  const permission =
    window.Notification?.permission ||
    window.navigator?.permissions
      ?.query({name: 'notifications'})
      .then((result) => result.state)
      .catch((err) => console.error(`Error requesting permission: ${err}`))

  // Notification returns 'default' if it hasn't been asked yet,
  // navigator.permissions returns 'prompt' if it hasn't been asked yet
  // To maintain a simpler interface, 'prompt' will always be returned instead of default
  return permission === 'default' ? 'prompt' : permission
}
