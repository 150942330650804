import {graphql} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import logger from '@/lib/logger'
import {compose, setStatic, lifecycle} from 'recompose'
import GET_HTTP_STATUS from '@/graphql/queries/httpStatus'
import RedirectToLoginPage from './RedirectToLoginPage'
import ErrorPage from './ErrorPage'

const withHttpStatus = graphql(GET_HTTP_STATUS, {
  props: ({data}) => ({
    status: data.httpStatus
  })
})

const catchErrors = compose(
  lifecycle({
    componentDidCatch(error) {
      logger.capture(error)
    }
  }),
  setStatic('getDerivedStateFromError', () => ({}))
)

export default compose(
  withRouter,
  withHttpStatus,
  catchErrors
)(function ErrorBoundary({status, children, location, redirectToLoginPage}) {
  switch ((status && status.code) || 200) {
    case 200:
      return children
    case 401:
      return redirectToLoginPage ? (
        <RedirectToLoginPage location={location} />
      ) : (
        children
      )
    default:
      return <ErrorPage {...status} />
  }
})
