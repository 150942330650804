export const INITIAL = 'initial_'
export const KEY_CAMPAIGN = 'utm_campaign'
export const KEY_CONTENT = 'utm_content'
export const KEY_GCLID = 'gclid'
export const KEY_ID = 'utm_id'
export const KEY_MEDIUM = 'utm_medium'
export const KEY_SOURCE = 'utm_source'
export const KEY_TERM = 'utm_term'

const UTM_KEYS = [
  KEY_CAMPAIGN,
  KEY_CONTENT,
  KEY_GCLID,
  KEY_ID,
  KEY_MEDIUM,
  KEY_SOURCE,
  KEY_TERM
]

/**
 * Saves user utm and initial utm data. If no initial utm keys
 * are set yet, save them. Otherwise, only save the current values.
 *
 * @param utm array containing utm objects with key/value.
 */
export function saveUtm(utm) {
  if (!utm || !Array.isArray(utm) || utm.length === 0) {
    return
  }
  utm.forEach((item) => {
    const key = Object.keys(item)[0]
    const value = item[key]
    const initial = localStorage.getItem(`${INITIAL}${key}`)
    if (!initial) {
      localStorage.setItem(`${INITIAL}${key}`, value)
    }
    localStorage.setItem(key, value)
  })
}

/**
 * Get utm data from query. Returns an array of utm
 * objects with their key/values.
 */
export function getUtmArrayFromUrl(search) {
  if (!search || search.trim() === '') {
    return []
  }
  const cleanSearch = search.split('?')[1]
  const items = cleanSearch
    .split('&')
    .map((item) => {
      const keyValue = item.split('=')
      return {
        [keyValue[0]]: keyValue[1]
      }
    })
    .filter((item) => {
      const key = Object.keys(item)[0]
      const value = item[key]
      return UTM_KEYS.indexOf(key) !== -1 && value && value.trim() !== ''
    })
  return items
}

/**
 * Get utm data from local storage. Returns an array of utm
 * objects with their key/values.
 */
export function getUtmArrayFromLocalStorage() {
  return [
    {initialCampaign: localStorage.getItem(`${INITIAL}${KEY_CAMPAIGN}`)},
    {initialContent: localStorage.getItem(`${INITIAL}${KEY_CONTENT}`)},
    {initialGclid: localStorage.getItem(`${INITIAL}${KEY_GCLID}`)},
    {initialId: localStorage.getItem(`${INITIAL}${KEY_ID}`)},
    {initialMedium: localStorage.getItem(`${INITIAL}${KEY_MEDIUM}`)},
    {initialSource: localStorage.getItem(`${INITIAL}${KEY_SOURCE}`)},
    {initialTerm: localStorage.getItem(`${INITIAL}${KEY_TERM}`)},
    {campaign: localStorage.getItem(KEY_CAMPAIGN)},
    {content: localStorage.getItem(KEY_CONTENT)},
    {gclid: localStorage.getItem(KEY_GCLID)},
    {id: localStorage.getItem(KEY_ID)},
    {medium: localStorage.getItem(KEY_MEDIUM)},
    {source: localStorage.getItem(KEY_SOURCE)},
    {term: localStorage.getItem(KEY_TERM)}
  ]
}

/**
 * Get data from getUtmArrayFromUrl return. Returns an array of utm
 * objects with their key/values to send to BROKER_SELLER_LEAD_CREATE.
 */
export function removeUtmPrefix(arr) {
  const inputUtm = arr.reduce((prev, item) => {
    prev[Object.keys(item)[0].replace('utm_', '')] = Object.values(item)[0]
    return prev
  }, {})
  return inputUtm
}

export function getUTMObject() {
  let utmArray = getUtmArrayFromUrl(location.search)

  if (!utmArray.length) {
    utmArray = getUtmArrayFromLocalStorage()
  }

  if (utmArray.length) {
    utmArray = removeUtmPrefix(utmArray)
    saveUtm(utmArray)

    return {
      initialCampaign: utmArray.initialCampaign,
      initialContent: utmArray.initialContent,
      initialGclid: utmArray.initialGclid,
      initialId: utmArray.initialId,
      initialMedium: utmArray.initialMedium,
      initialSource: utmArray.initialSource,
      initialTerm: utmArray.initialTerm,
      campaign: utmArray.campaign,
      content: utmArray.content,
      gclid: utmArray.gclid,
      id: utmArray.id,
      medium: utmArray.medium,
      source: utmArray.source,
      term: utmArray.term
    }
  }
}

export const hasUtmSource = (utm_source) => getUTMObject().source === utm_source
