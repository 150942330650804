import {useEffect, useMemo} from 'react'
import {useHistory} from 'react-router-dom'

const getStack = ({state = {}}) => (state ? state.stack || [] : [])
const findInStack = (hash, location) =>
  getStack(location).findIndex((h) => h === hash)
const isInStack = (hash, location) => findInStack(hash, location) >= 0

/**
 * Mounts the component to a new hash location on the browser's history.
 * @param {Function} onPop         Callback fired when the user leaves the active
 *                                 location. Should handle unnmounting of this
 *                                 component.
 * @param {Object} options
 * @param {String} options.hash    Hash location of this component.
 * @param {Boolean} options.active Controls when to actually enter/leave the hash
 *                                 location. Default behaviour is to navigate on
 *                                 mount and pop to the previous location on
 *                                 unmount.
 * @param {Array} deps             Dependencies of the onPop callback.
 */
export default function useHashLocation(onPop, {hash, active}) {
  if (!hash) throw new Error('useHashLocation: hash is required')

  const history = useHistory()
  const location = useMemo(() => ({...history.location, hash: `#${hash}`}))

  // Push location to the history whenever this hooks turns active
  useEffect(() => {
    if (!active) return
    history.push({
      ...location,
      state: {stack: getStack(history.location).concat(hash)}
    })
    return () => {
      const stack = getStack(history.location)
      const idx = findInStack(hash, history.location)
      if (idx == -1) return
      const pos = stack.length - idx
      history.go(-pos)
    }
  }, [active])

  // Listen for changes on the history and fire the onPop callback.
  useEffect(() => {
    if (active) {
      return history.listen((location) => {
        if (!isInStack(hash, location)) onPop(history, location)
      })
    }
  }, [active])
}
