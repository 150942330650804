import gql from 'graphql-tag'
import PartialListing from '../fragments/PartialListing'

const GET_SEARCH_CONTEXT = gql`
  query getSearchContext {
    districts {
      name
      nameSlug
      citySlug
      stateSlug
      city
    }
    tags {
      category
      nameSlug
    }
  }
`

const GET_LISTING_URL = gql`
  query getListingURL($id: ID!) {
    listing(id: $id) {
      id
      address {
        streetSlug
        neighborhoodSlug
        citySlug
        stateSlug
      }
    }
  }
`

const LISTING_MAP_FRAGMENT = gql`
  fragment ListingMap on Listing {
    id
    price
    address {
      lat
      lng
    }
  }
`

export {GET_SEARCH_CONTEXT, GET_LISTING_URL}

export default gql`
  query searchListings(
    $filters: ListingFilterInput
    $pagination: ListingSearchPagination
    $orderBy: [ListingSearchOrderBy]
    $isMap: Boolean!
  ) {
    searchListings(
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      from
      totalCount
      listings @skip(if: $isMap) {
        ...PartialListing
      }
      listings @include(if: $isMap) {
        ...ListingMap
      }
    }
  }
  ${LISTING_MAP_FRAGMENT}
  ${PartialListing}
`
