import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import styles from './styles.module.css'

const FollowButton = ({
  title,
  text,
  icon,
  image,
  to,
  href,
  withBorder,
  ...props
}) => {
  const getContent = () => (
    <>
      <div className={styles.ecFollowButton__content}>
        {icon || image ? (
          <div className={styles.ecFollowButton__assetWrapper}>
            {icon?.src && (
              <span className={styles.ecFollowButton__assetIconWrapper}>
                <img
                  className={styles.ecFollowButton__assetIcon}
                  src={icon?.src}
                  alt={icon?.alt}
                  width={icon?.width}
                  height={icon?.height}
                />
              </span>
            )}
            {image?.src && (
              <img
                className={styles.ecFollowButton__assetImage}
                src={image?.src}
                alt={image?.alt}
                width={image?.width}
                height={image?.height}
              />
            )}
            <span className={styles.ecFollowButton__title}>{title}</span>
          </div>
        ) : (
          <span className={styles.ecFollowButton__title}>{title}</span>
        )}
        <span className={styles.ecFollowButton__text}>{text}</span>
      </div>
      <img
        className={styles.ecFollowButton__icon}
        src="/img/icons/arrow-x-black.svg"
        alt="Ícone: seta"
        width="16"
        height="16"
      />
    </>
  )

  const className = classNames(styles.ecFollowButton, {
    [styles.ecFollowButton_withBorder]: withBorder
  })

  return href ? (
    <a className={className} href={href} {...props}>
      {getContent()}
    </a>
  ) : to ? (
    <Link className={className} to={to} {...props}>
      {getContent()}
    </Link>
  ) : (
    <button className={className} {...props}>
      {getContent()}
    </button>
  )
}

FollowButton.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  withBorder: PropTypes.bool
}

export default FollowButton
