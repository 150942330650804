import {useEffect, useState} from 'react'
import {useMutation} from '@apollo/react-hooks'
import {connect, useDispatch} from 'react-redux'
import * as layout from '@/redux/modules/layout/selectors'
import {setJwtToken} from '@/redux/modules/auth/actions'
import {showLoginModal} from '@/redux/modules/layout/actions'
import SIGN_IN_CREATE_AUTHENTICATION_CODE from '@/graphql/mutations/signInCreateAuthenticationCode'
import SIGN_IN_VERIFY_AUTHENTICATION_CODE from '@/graphql/mutations/signInVerifyAuthenticationCode'
import {getInputMaskPhoneNumber, removeInternationalCode} from '@/lib/format'
import {validatePhone} from '@/lib/validation'
import logger from '@/lib/logger'
import Modal from '@/components/shared/Modal'
import Button from '@/components/shared/Button'
import PhoneInput from '@/components/form/PhoneInput'
import Loader from '@/components/shared/Loader'
import Result from '@/components/shared/Result'
import classNames from 'classnames'
import inputStyles from '@/styles/atoms/Input/styles.module.css'
import modalStyles from '@/styles/organisms/Modal/styles.module.css'
import styles from '@/styles/molecules/LoginModal/styles.module.css'

const LoginModal = ({getLoginModal}) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState('phone')
  const [isLoading, setLoading] = useState(true)
  const [inputPhone, setInputPhone] = useState()
  const [inputCode, setInputCode] = useState()
  const [error, setError] = useState(false)

  const [
    signInCreateAuthenticationCode,
    {data: dataCreate, error: errorCreate}
  ] = useMutation(SIGN_IN_CREATE_AUTHENTICATION_CODE)

  const [
    signInVerifyAuthenticationCode,
    {data: dataCode, error: errorCode}
  ] = useMutation(SIGN_IN_VERIFY_AUTHENTICATION_CODE)

  const isCreateAuthenticationCodeSuccess =
    dataCreate &&
    dataCreate.signInCreateAuthenticationCode &&
    dataCreate.signInCreateAuthenticationCode.enqueued === 'SUCCESS'

  const isPhoneStepValid = inputPhone && validatePhone(inputPhone)
  const isCodeStepValid =
    String(inputCode).length === 4 && isCreateAuthenticationCodeSuccess

  const isBackButtonDisabled = isLoading || step === 'phone'
  const isSubmitButtonDisabled = () =>
    isLoading ||
    (step === 'phone' && !isPhoneStepValid) ||
    (step === 'code' && !isCodeStepValid)

  const getSubmitButtonLabel = step === 'phone' ? 'Enviar' : 'Entrar'
  const getErrorText = errorCode ? (
    <>
      O <strong>código de login</strong> não pode ser verificado ou está
      incorreto.
    </>
  ) : (
    <>
      Não foi possível enviar o <strong>código de login</strong>.
    </>
  )
  const getErrorButton = {
    label: 'Tentar novamente',
    active: true,
    onClick: () => {
      setInputCode('')
      setLoading(false)
      setError(false)
      setStep('phone')
    }
  }

  const getVerifyAuthenticationCodeJwt =
    dataCode &&
    dataCode.signInVerifyAuthenticationCode &&
    dataCode.signInVerifyAuthenticationCode.jwt

  function reset() {
    setInputPhone('')
    setInputCode('')
    setLoading(false)
    setStep('phone')
  }

  function onCloseModal() {
    dispatch(showLoginModal({show: false, phone: null}))
    logger.action('login-modal-close', {phone: inputPhone})
  }

  function onSubmit(e) {
    e.preventDefault()
    if (step === 'phone' && isPhoneStepValid) {
      setLoading(true)
      signInCreateAuthenticationCode({variables: {phone: inputPhone}})
      logger.action('login-modal-phone-send', {phone: inputPhone})
    } else if (step === 'code' && isCodeStepValid) {
      setLoading(true)
      signInVerifyAuthenticationCode({
        variables: {phone: inputPhone, code: inputCode}
      })
      logger.action('login-modal-code-send', {phone: inputPhone})
    }
  }

  function handleBackButton() {
    setStep('phone')
  }

  function handleInputCode({target: {value}}) {
    const inputValue =
      value.length > 4 ? Number(String(value).substr(0, 4)) : value
    setInputCode(inputValue)
  }

  useEffect(() => {
    if (isCreateAuthenticationCodeSuccess) {
      setStep('code')
      setLoading(false)
      logger.action('login-modal-phone-send-success', {phone: inputPhone})
    }
  }, [dataCreate])

  useEffect(() => {
    if (getVerifyAuthenticationCodeJwt) {
      dispatch(setJwtToken(dataCode.signInVerifyAuthenticationCode.jwt))
      dispatch(showLoginModal({show: false, phone: null}))
      logger.action('login-modal-success', {phone: inputPhone})
      logger.action('login-modal-code-send-success', {phone: inputPhone})
    }
  }, [dataCode])

  useEffect(() => {
    if (errorCreate || errorCode) {
      setError(true)
      logger.action('login-modal-error', {phone: inputPhone})
      logger.action(
        errorCode
          ? 'login-modal-code-send-error'
          : 'login-modal-phone-send-error',
        {phone: inputPhone, error: errorCode ? errorCode : errorCreate}
      )
    }
  }, [errorCreate, errorCode])

  useEffect(() => {
    if (getLoginModal && getLoginModal.show) {
      logger.action('login-modal', {phone: inputPhone})
      reset()
      if (validatePhone(getLoginModal.phone)) {
        logger.action('login-modal-hasphone', {phone: getLoginModal.phone})
        setLoading(true)
        setInputPhone(getLoginModal.phone)
        signInCreateAuthenticationCode({
          variables: {phone: getLoginModal.phone}
        })
      }
    }
  }, [getLoginModal])

  return (
    <Modal
      topZindex
      show={getLoginModal.show}
      dialog
      dialogSmall
      onClose={onCloseModal}
    >
      <form onSubmit={onSubmit} className={styles.ecLoginModal}>
        <div className={modalStyles.ecModal__header}>
          <p className={modalStyles.ecModal__header__title}>
            Entre na sua conta
          </p>
        </div>
        <div className={styles.ecLoginModal__container}>
          {error ? (
            <Result
              error
              title="Ocorreu um erro"
              text={getErrorText}
              buttons={[getErrorButton]}
            />
          ) : isLoading ? (
            <div className={styles.ecLoginModal__content}>
              <Loader />
            </div>
          ) : step === 'code' && isCreateAuthenticationCodeSuccess ? (
            <div className={styles.ecLoginModal__content}>
              <p>
                Digite o <strong>código de login</strong> enviado para o seu
                celular
                {inputPhone ? (
                  <>
                    <br />
                    <strong>
                      {getInputMaskPhoneNumber(
                        removeInternationalCode(inputPhone)
                      )}
                    </strong>
                  </>
                ) : null}
              </p>
              <input
                className={inputStyles.ecInput}
                name="code"
                type="number"
                tabIndex="0"
                placeholder="____"
                value={inputCode || ''}
                onChange={handleInputCode}
              />
            </div>
          ) : (
            <div className={styles.ecLoginModal__content}>
              <p>
                Entre com o seu celular.
                <br />
                Enviaremos o <strong>código de login</strong> no seu celular.
              </p>
              <PhoneInput
                name="phone"
                type="tel"
                tabIndex="0"
                placeholder="(11) 99999-9999"
                value={inputPhone || undefined}
                onChange={setInputPhone}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(modalStyles.ecModal__footer, {
            [modalStyles.ecModal__footer_hide]: error
          })}
        >
          <div className={modalStyles.ecModal__footer__buttons}>
            <Button
              type="button"
              onClick={handleBackButton}
              disabled={isBackButtonDisabled}
            >
              Voltar
            </Button>
            <Button type="submit" active disabled={isSubmitButtonDisabled()}>
              {getSubmitButtonLabel}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default connect((state) => ({
  getLoginModal: layout.getLoginModal(state)
}))(LoginModal)
