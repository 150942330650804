import {graphql} from 'react-apollo'
import GET_USER_PROFILE from '@/graphql/queries/userProfile'
import {emitGraphQLErrors} from '@/lib/httpStatus'

export default graphql(GET_USER_PROFILE, {
  props: ({data}) => ({
    userProfile: data.userProfile
  }),
  options: {
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    onError: emitGraphQLErrors([401, 403, 404])
  }
})
