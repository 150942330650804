import {Provider} from 'react-redux'
import {ApolloProvider} from 'react-apollo'
import {ThemeProvider} from 'styled-components'
import {getToken} from '@/lib/jwt'
import CookiesProvider from './Cookies'
import createApolloClient from '@/graphql/client'
import createStore from '@/redux/store'
import theme from '@/config/theme'

export default function ContextProvider({
  children,
  apolloClient,
  store,
  cookies
}) {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <CookiesProvider cookies={cookies}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CookiesProvider>
      </ApolloProvider>
    </Provider>
  )
}

export function createContext(ctx, initialState = {}) {
  if (!ctx.getToken) ctx.getToken = getToken
  if (!ctx.apolloClient)
    ctx.apolloClient = createApolloClient(ctx, initialState.apollo)
  if (!ctx.store) ctx.store = createStore(ctx, initialState.redux)
  return ctx
}
