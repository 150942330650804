import {useState, useEffect} from 'react'
import * as cdn from '@/lib/cdn'
import Carousel, {
  useControlledCarousel,
  Controller,
  Slide
} from '@/components/shared/Carousel'
import Image from '@/components/shared/Image'
import Placeholder from '@/components/listings/ImagePlaceholder'
import styles from '../styles.module.css'
import styled from 'styled-components'
import logger from '@/lib/logger'

const BUTTON_PROPS = {
  onClick(e) {
    e.stopPropagation()
    e.preventDefault()
  }
}

const Container = styled.div`
  width: 100%;
`

const noLazyImage = (index, noLazy) => Boolean(index < 1 && noLazy)

export default function ImagesCarousel({
  images,
  alt,
  listingId,
  fromShortlist,
  controlAlwaysVisible,
  noLazy
}) {
  if (!images || !images.length) {
    return (
      <div className={styles.ecListingCard__gallery}>
        <Placeholder bg="lightGrey" color="disabled">
          Sem fotos publicadas
        </Placeholder>
      </div>
    )
  } else {
    const [bind, controller, {position}] = useControlledCarousel()
    const galleryImages = images.slice(0, Math.min(5, images.length))
    const [carouselIndex, setCarouselIndex] = useState(position)

    useEffect(() => {
      if (position !== carouselIndex) {
        setCarouselIndex(position)
        logger.action('listing-card-photos', {
          listingId,
          fromShortlist
        })
      }
    }, [position])

    return (
      <Container
        as={Controller}
        className={styles.ecListingCard__gallery}
        controller={controller}
        leftProps={BUTTON_PROPS}
        rightProps={BUTTON_PROPS}
        controlAlwaysVisible={controlAlwaysVisible}
      >
        <Carousel height="100%" width="100%" {...bind}>
          {galleryImages.map((img, index) => (
            <Slide key={img.id} snap flex="0 0 100%">
              <Image
                noLazy={noLazyImage(index, noLazy)}
                src={cdn.thumbnailUrl()(img)}
                alt={alt}
                fromShortlist={fromShortlist}
              />
              {index >= galleryImages?.length - 1 && (
                <div className={styles.ecListingCard__galleryMoreDetails}>
                  Ver mais detalhes
                </div>
              )}
            </Slide>
          ))}
        </Carousel>
      </Container>
    )
  }
}
