import {
  LISTING_ORIENTATIONS,
  LISTING_SUNPERIOD,
  LISTING_GARAGETYPE
} from './constants'
import * as format from '@/lib/format'
import logger from '@/lib/logger'

export const SUBWAY_DISTANCE_LIMIT = 500

export const getSubwayDistance = (subwayDistance) => {
  const subwayDistanceFormated =
    subwayDistance >= 1000
      ? `${Number(subwayDistance / 1000).toFixed(1)} km`
      : `${subwayDistance} m`
  return subwayDistanceFormated.replace('.', ',')
}

export function getImages(listing) {
  let images = listing.images
  if (listing.development && listing.development.images) {
    images.concat(listing.development.images)
  }
  return images.map((item) => {
    if (item && item.id) {
      return {
        id: item.id,
        filename: item.filename,
        alt: `Imagem do imóvel ID-${listing.id} na ${listing.address.street}, ${listing.address.neighborhood}, ${listing.address.city} - ${listing.address.state}`
      }
    }
  })
}

export function getPricesList(listing) {
  let list = [
    {
      label: 'Preço/m²',
      value: Math.floor(listing.price / listing.area)
    }
  ]
  if (listing.propertyTax) {
    list.push({
      label: 'IPTU/ano',
      value: listing.propertyTax
    })
  }

  if (listing.maintenanceFee) {
    list.push({
      label: 'Condomínio',
      value: listing.maintenanceFee
    })
  }

  return list
}

export const getBreadcrumbList = ({
  id,
  address: {
    state,
    stateSlug,
    city,
    citySlug,
    neighborhood,
    neighborhoodSlug,
    street,
    streetSlug
  }
}) => [
  {
    to: '/',
    label: 'Página inicial',
    onClick: () =>
      logger.action('listing-detail-breadcrumb', {
        url: '/',
        label: 'Página inicial'
      })
  },
  {
    to: `/imoveis/${stateSlug}/${citySlug}`,
    label: `${city}, ${state}`,
    onClick: () =>
      logger.action('listing-detail-breadcrumb', {
        url: `/imoveis/${stateSlug}/${citySlug}`,
        label: `${city}, ${state}`
      })
  },
  {
    to: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}`,
    label: neighborhood,
    onClick: () =>
      logger.action('listing-detail-breadcrumb', {
        url: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}`,
        label: neighborhood
      })
  },
  {
    to: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}/${streetSlug}`,
    label: street,
    onClick: () =>
      logger.action('listing-detail-breadcrumb', {
        url: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}/${streetSlug}`,
        label: street
      })
  },
  {
    to: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}/${streetSlug}/${id}`,
    active: true,
    label: `Imóvel ${id}`
  }
]

export function getListingInfos({
  area,
  suites,
  rooms,
  bathrooms,
  floor,
  elevators,
  garageSpots,
  garageType,
  orientation,
  sunPeriod
}) {
  const list = []

  if (area) {
    list.push({
      label: 'Área',
      value: `${area}m²`
    })
  }

  if (suites) {
    list.push({
      label: 'Suítes',
      value: format.minTwoDigits(suites)
    })
  }

  if (rooms) {
    list.push({
      label: 'Quartos',
      value: format.minTwoDigits(rooms)
    })
  }

  if (bathrooms) {
    list.push({
      label: 'Banheiros',
      value: format.minTwoDigits(bathrooms)
    })
  }

  if (floor) {
    list.push({
      label: 'Andar',
      value: format.floor(floor, true)
    })
  }

  if (elevators != null) {
    list.push({
      label: 'Elevador',
      value: elevators ? 'Com elevador' : 'Sem elevador'
    })
  }

  if (!!garageSpots !== null && garageSpots !== undefined) {
    list.push({
      label: `Vaga${garageSpots === 1 ? '' : 's'}`,
      value: format.minTwoDigits(garageSpots)
    })
  }

  if (garageType) {
    list.push({
      label: 'Tipo de vaga',
      value: LISTING_GARAGETYPE[garageType]
    })
  }

  if (orientation) {
    list.push({
      label: 'Orientação',
      value: LISTING_ORIENTATIONS[orientation]
    })
  }

  if (sunPeriod) {
    list.push({
      label: 'Sol',
      value: LISTING_SUNPERIOD[sunPeriod]
    })
  }

  return list
}

export function getBuildingInfos({floorCount, unitPerFloor}, concierge) {
  const list = []

  if (concierge) {
    list.push({
      label: 'Portaria',
      value: concierge.replace('Portaria ', '')
    })
  }

  if (floorCount) {
    list.push({
      label: 'Total de andares',
      value: format.minTwoDigits(floorCount)
    })
  }

  if (unitPerFloor) {
    list.push({
      label: 'Aptos por andar',
      value: format.minTwoDigits(unitPerFloor)
    })
  }

  return list
}
