import {memo, useReducer, useEffect, useCallback, useState} from 'react'
import compose from 'recompose/compose'
import useHashLocation from '@/lib/hooks/useHashLocation'
import withDistricts from '@/lib/hoc/withDistricts'
import {getCitiesFromDistricts} from '@/lib/districts'
import logger from '@/lib/logger'
import Button from '@/components/shared/Button'
import NeighborhoodsFilter from './Neighborhoods'
import TagsFilter from './Tags'
import TypeFilter from './Type'
import PriceFilter from './Price'
import AreaFilter from './Area'
import MaintenanceFeeFilter from './MaintenanceFee'
import {
  BedroomsFilter,
  BathroomsFilter,
  SuitesFilter,
  GarageSpotsFilter
} from './Rooms'
import {OrientationFilter, SunPeriodFilter, GarageTypeFilter} from './Enums'
import {SubwayDistanceFilter} from './Subway'
import {ElevatorFilter} from './Elevator'
import {CityFilter} from './City'
import styles from '@/styles/organisms/Filters/styles.module.css'
import navStyles from '@/styles/organisms/Nav/styles.module.css'
import composesStyles from '@/styles/_settings/composes.module.css'

const formReducer = ({...state}, {name, value}) => {
  if (typeof value === 'undefined') delete state[name]
  else Object.assign(state, {[name]: value})
  return state
}

function ListingFilters({
  active,
  onClose,
  onSubmit,
  onClear,
  districts,
  initialValue = {}
}) {
  const [loggerInteraction, setInteraction] = useState({})
  const [value, setValue] = useReducer(formReducer, initialValue)
  const inputProps = (name) => ({
    onChange: useCallback((value) => {
      setValue({name, value})
      if (name === 'citiesSlug') {
        setValue({name: 'neighborhoods', value: []})
      }
      if (!loggerInteraction[name]) {
        setInteraction({
          ...loggerInteraction,
          [name]: true
        })
        logger.action('listing-search-filter-item', {
          name,
          value
        })
      }
    }),
    initialValue: initialValue[name],
    value: value[name]
  })
  const filters = [
    {
      legend: 'Cidade',
      component: CityFilter,
      inputProps: 'citiesSlug',
      props: {cities: districts && getCitiesFromDistricts(districts)}
    },
    {
      legend: 'Bairros',
      component: NeighborhoodsFilter,
      inputProps: 'neighborhoods',
      props: {citiesSlug: value.citiesSlug, districts}
    },
    {
      legend: 'Tipo de imóvel',
      component: TypeFilter,
      inputProps: 'types'
    },
    {
      legend: 'Preço',
      component: PriceFilter,
      inputProps: 'price'
    },
    {
      legend: 'Área do imóvel',
      component: AreaFilter,
      inputProps: 'area'
    },
    {
      legend: 'Condomínio',
      component: MaintenanceFeeFilter,
      inputProps: 'maintenanceFee'
    },
    {
      legend: 'Banheiros',
      component: BathroomsFilter,
      inputProps: 'minTotalBathrooms'
    },
    {
      legend: 'Vagas',
      component: GarageSpotsFilter,
      inputProps: 'minGarageSpots'
    },
    {
      legend: 'Próximo ao metrô',
      component: SubwayDistanceFilter,
      inputProps: 'maxSubwayDistance'
    },
    {
      legend: 'Elevador',
      component: ElevatorFilter,
      inputProps: 'hasElevator'
    },
    {
      legend: 'Quartos',
      component: BedroomsFilter,
      inputProps: 'minRooms'
    },
    {
      legend: 'Suítes',
      component: SuitesFilter,
      inputProps: 'minSuites'
    },
    {
      legend: 'Garagem',
      component: GarageTypeFilter,
      inputProps: 'garageTypes'
    },
    {
      legend: 'Orientação',
      component: OrientationFilter,
      inputProps: 'orientations'
    },
    {
      legend: 'Sol',
      component: SunPeriodFilter,
      inputProps: 'sunPeriods'
    },
    {
      component: TagsFilter,
      inputProps: 'tagsSlug'
    }
  ]
  const getFilter = (item, index) => {
    const FilterComponent = item.component
    const propsComponent = {
      ...inputProps(item.inputProps),
      ...item.props
    }
    return (
      <div className={styles.ecFilters__fieldset} key={index}>
        {item.legend ? (
          <p className={styles.ecFilters__fieldset__legend}>{item.legend}</p>
        ) : null}
        <FilterComponent {...propsComponent} />
      </div>
    )
  }
  function handleOnSubmitFilters() {
    const {neighborhoods, searchLocation} = value
    const filters = {
      ...value,
      searchLocation:
        neighborhoods && neighborhoods.length ? null : searchLocation
    }
    onSubmit(filters)
    logger.action('listing-search-filter-apply', {filters: filters})
  }
  useHashLocation(
    () => {
      onClose()
      logger.action('listing-search-filter-close')
    },
    {hash: 'filter', active}
  )
  useEffect(() => {
    if (active) {
      logger.action('listing-search-filter-open')
    }
  }, [])

  return (
    <>
      <div className={navStyles.ecNav__wrapper}>
        <div className={composesStyles.paddingx}>
          <p className={styles.ecFilters__title}>Filtrar imóveis</p>
          {filters.map((item, index) => getFilter(item, index))}
        </div>
      </div>
      <div className={navStyles.ecNav__footer}>
        <Button
          className={navStyles.ecNav__footer__item}
          onClick={() => {
            onClear()
            logger.action('listing-search-filter-clear')
          }}
        >
          Limpar
        </Button>
        <Button
          className={navStyles.ecNav__footer__item}
          active
          onClick={handleOnSubmitFilters}
        >
          Filtrar
        </Button>
      </div>
    </>
  )
}

export default compose(memo, withDistricts)(ListingFilters)
