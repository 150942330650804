import {useEffect} from 'react'
import {useHistory} from 'react-router'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as layout from '@/redux/modules/layout/selectors'
import {setModal} from '@/redux/modules/layout/actions'
import Modal from '@/components/shared/Modal'
import Header from '@/components/layout/Header'
import Seo from './Seo.js'
import Footer from '@/components/layout/Footer'
import LoginModal from '@/components/shared/LoginModal'
import logger from '@/lib/logger'
import Login from '@emcasa/login/lib/LoginSaga'
import LegalNotification from '@/pages/legal/notification'
import styles from '@/styles/templates/Default/styles.module.css'

function getModal({ModalContent, hasModal, setModal, modalProps}) {
  if (!hasModal) {
    return null
  }

  const closeModal = () => setModal(null)
  const modal = ModalContent && (
    <ModalContent closeModal={closeModal} {...modalProps} />
  )

  return modalProps && modalProps.standalone ? (
    modal
  ) : (
    <Modal show={true} onClose={closeModal}>
      {modal}
    </Modal>
  )
}

function Template({
  children,
  withBottomBar = false,
  noHeader = false,
  noFooter = false,
  noLogin = false,
  noModal = false,
  noSeo = false,
  noLegalNotification = false,
  isLoading = false,
  filters,
  hideMenuButton = false,
  ...props
}) {
  const history = useHistory()

  useEffect(() => {
    logger.action('page-open')
  }, [history])

  return (
    <>
      {!noHeader && (
        <Header
          isLoading={isLoading}
          filters={filters}
          hideMenuButton={hideMenuButton}
        />
      )}
      {!noLogin && <LoginModal />}
      {!noModal && getModal(props)}
      {!noSeo && <Seo />}
      <main className={styles.main}>{children}</main>
      {!noFooter && <Footer withBottomBar={withBottomBar} />}
      {!noLogin && <Login />}
      {!noLegalNotification && <LegalNotification />}
    </>
  )
}

Template.propTypes = {
  withBottomBar: PropTypes.bool, // add extra padding(.ecFooter_withBar)
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  noLogin: PropTypes.bool,
  noModal: PropTypes.bool,
  noSeo: PropTypes.bool,
  noLegalNotification: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideMenuButton: PropTypes.bool
}

export default connect(
  (state) => ({
    modalProps: layout.getModalProps(state),
    ModalContent: layout.getModalComponent(state),
    hasModal: layout.hasModal(state)
  }),
  {setModal}
)(Template)
