import {initialState} from '@/redux/modules/you/reducer'
import {YOU_HOME_TYPES} from './steps/Send'
import {buildListingsUrl} from '@/lib/listingsUrl'
import {haveSubway} from '@/lib/cities'

export const validateName = (value) => value && value.trim().length > 1
export const validatePhone = (value) => value && value.length > 10
export const validateEmail = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

export function getSearchURL() {
  return {pathname: buildListingsUrl(), state: {fromYou: true}}
}

export const getSearchPreferences = ({
  stepType = initialState?.stepType,
  stepLocation = initialState?.stepLocation,
  stepPrice = initialState?.stepPrice,
  stepCondo = initialState?.stepCondo,
  stepDetails = initialState?.stepDetails,
  stepPreferences = initialState?.stepPreferences
}) => {
  const listingTypes = stepType?.types.map((item) => YOU_HOME_TYPES[item])
  return {
    citySlug: stepLocation?.citySlug || 'sao-paulo',
    stateSlug: String(stepLocation?.state || 'sp').toLocaleLowerCase(),
    maxValue: stepPrice?.price,
    maxMaintenanceFee: stepCondo?.maintenanceFee,
    minRooms: stepDetails?.rooms,
    minBathrooms: stepDetails?.bathrooms,
    minGarageSpots: stepDetails?.garageSpots,
    nearbySubway:
      haveSubway(stepLocation?.citySlug) &&
      stepPreferences?.nearSubway === 'sim'
        ? true
        : null,
    elevator: stepPreferences?.hasElevator === 'sim' ? true : null,
    listingTypes,
    neighborhoods: stepLocation?.selectedDistricts?.length
      ? stepLocation.selectedDistricts.reduce((a, c) => {
          a.push(c.name)
          return a
        }, [])
      : null
  }
}

export const getYouPropsFromFilter = ({filters, districts}) => {
  const stepLocation =
    districts?.reduce(
      (acc, item) => {
        if (filters?.neighborhoods?.includes(item.name)) {
          acc.selectedDistricts.push(item)
        }
        if (
          !(acc.city || acc.citySlug || acc.state) &&
          acc.selectedDistricts?.length
        ) {
          acc.city = acc.selectedDistricts[0]?.city
          acc.citySlug = acc.selectedDistricts[0]?.citySlug
          acc.state = acc.selectedDistricts[0]?.stateSlug
        }
        return acc
      },
      {...initialState.stepLocation, districts: [], selectedDistricts: []}
    ) || {}
  return {stepLocation}
}
