import {useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '@/components/shared/Button'
import styles from './styles.module.css'

const HeaderButton = ({
  className,
  title,
  icon,
  dark,
  opaqueButton,
  onClick
}) => (
  <Button
    circle={opaqueButton}
    noBorder={!opaqueButton}
    noBg={!opaqueButton}
    dark={dark}
    className={className}
    icon={
      icon || `${process.env.CLOUDINARY_IMAGE_URL}/site/icons/action/cross.svg`
    }
    aria-label={title}
    title={title}
    onClick={onClick}
  />
)

const Modal = ({
  children,
  show,
  dark,
  dialog,
  dialogSmall,
  bottom,
  withHeight,
  large,
  wide,
  topZindex,
  header,
  footer,
  hideCloseButton,
  opaqueButton,
  disableBackgroundClick,
  onClose
}) => {
  const refContainer = useRef()
  const hasNoHeaderItem = !(header?.leadingButton || header?.title)

  function handleCloseButton() {
    if (onClose) {
      onClose()
    }
  }

  const modalClassName = classNames(styles.ecModal, {
    [styles.ecModal_show]: show,
    [styles.ecModal_bottom]: bottom,
    [styles.ecModal_topzindex]: topZindex
  })

  const modalWrapperClassName = classNames({
    [styles.ecModal__wrapper]: !dialog && !dialogSmall && !bottom,
    [styles.ecModal__wrapper_dark]: dark,
    [styles.ecModal__wrapperDialog]: dialog || dialogSmall,
    [styles.ecModal__wrapperDialog_small]: dialogSmall,
    [styles.ecModal__wrapperBottom]: bottom,
    [styles.ecModal__wrapper_withHeight]: withHeight,
    [styles.ecModal__wrapper_large]: large,
    [styles.ecModal__wrapper_wide]: wide
  })

  const closeButtonClassName = classNames({
    [styles.ecModalCloseButton_dark]: dark,
    [styles.ecModalCloseButton_opaque]: opaqueButton,
    [styles.ecModalCloseButton_floating]: hasNoHeaderItem
  })

  const CloseButton = () => (
    <HeaderButton
      dark={dark}
      opaqueButton={opaqueButton}
      className={closeButtonClassName}
      title="Fechar janela"
      onClick={handleCloseButton}
    />
  )

  useEffect(() => {
    if (refContainer.current?.scrollTo) {
      refContainer.current?.scrollTo(0, 0)
    }
  }, [children])

  return (
    <div className={modalClassName}>
      <div className={modalWrapperClassName}>
        {!hasNoHeaderItem ? (
          <div className={styles.ecModalHeader}>
            <div className={styles.ecModalHeader__leading}>
              {header?.leadingButton && (
                <HeaderButton
                  dark={dark}
                  opaqueButton={opaqueButton}
                  className={closeButtonClassName}
                  icon={header.leadingButton.icon}
                  title={header.leadingButton.ariaLabel}
                  onClick={header.leadingButton.onClick}
                />
              )}
            </div>
            <p className={styles.ecModalHeader__title}>{header?.title}</p>
            <div className={styles.ecModalHeader__trailing}>
              {!hideCloseButton && <CloseButton />}
            </div>
          </div>
        ) : !hideCloseButton ? (
          <CloseButton />
        ) : null}
        <div ref={refContainer} className={styles.ecModal__container}>
          {children}
        </div>
        {footer && <div className={styles.ecModalFooter}>{footer}</div>}
      </div>
      <span
        className={styles.ecModal__background}
        onClick={disableBackgroundClick ? null : handleCloseButton}
      />
    </div>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  dark: PropTypes.bool,
  dialog: PropTypes.bool,
  dialogSmall: PropTypes.bool,
  bottom: PropTypes.bool,
  withHeight: PropTypes.bool,
  large: PropTypes.bool,
  wide: PropTypes.bool,
  topZindex: PropTypes.bool,
  header: PropTypes.shape({
    leadingButton: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      ariaLabel: PropTypes.string.isRequired,
      onClick: PropTypes.func
    }),
    title: PropTypes.string
  }),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideCloseButton: PropTypes.bool,
  opaqueButton: PropTypes.bool,
  disableBackgroundClick: PropTypes.bool,
  onClose: PropTypes.func
}

export default Modal
