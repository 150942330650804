import useSearch from '@/lib/hooks/useSearch'

export default (valueProp, setterProp, getOptions, onChange) => (Component) =>
  function withSearch({...props}) {
    const [value, setter] = useSearch(
      getOptions(props),
      onChange ? onChange.bind(null, props) : null
    )
    props[valueProp] = value
    props[setterProp] = setter
    return <Component {...props} />
  }
