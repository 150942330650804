import {Redirect} from 'react-router-dom'
import withSideEffect from 'react-side-effect'

const noop = () => null

function reducePropsToState(propsList) {
  const {to, permanent} = propsList.pop() || {}
  if (typeof to == 'string')
    return {pathname: to, status: permanent ? 301 : 302}
  return to
}

export default withSideEffect(reducePropsToState, noop)(Redirect)
