import {useState, useEffect} from 'react'
import slugify from 'slugify'
import {GOOGLE_MAPS_API_KEY} from '@/config'

const LOCAL_STORAGE_KEY = 'currentLocation'

async function fetchGeocode({latitude: lat, longitude: lng}) {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${GOOGLE_MAPS_API_KEY}`
  )
  if (!response.ok) return undefined
  const {results} = await response.json()
  return results ? results[0] : undefined
}

const findAddressComponent = (type, {address_components = []}) =>
  address_components.find(({types}) => types.includes(type))

const parseGeocode = (geocode, location) => {
  const state = (
    findAddressComponent('administrative_area_level_1', geocode) || {}
  ).short_name
  const city = (
    findAddressComponent('administrative_area_level_2', geocode) || {}
  ).short_name
  const citySlug = city ? slugify(city.toLowerCase()) : undefined
  const stateSlug = state ? state.toLowerCase() : undefined
  return {city, citySlug, stateSlug, geocode, location}
}

export function getUserCenter({geocode, location}) {
  if (geocode && geocode.geometry && geocode.geometry.location) {
    return geocode.geometry.location
  }

  return location || null
}

const settings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0
}

export default function useGeolocation({onLoad, askLocation}) {
  if (!process.browser) return undefined
  let currentLocation
  try {
    currentLocation = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  } catch {
    currentLocation = null
  }
  const [position, setPosition] = useState(currentLocation)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(askLocation)

  const onChange = async ({coords}) => {
    if (!coords || !coords.latitude || !coords.longitude) {
      setError('Geolocation not found')
      setLoading(false)
      return
    }
    const geocode = await fetchGeocode(coords)
    const parsedGeocode = parseGeocode(geocode, coords)

    if (parsedGeocode) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedGeocode))
      setPosition(parsedGeocode)
      onLoad(true)
      setLoading(false)
    } else {
      setError('Failed on geocoding')
      setLoading(false)
    }
  }

  const onError = (error) => {
    setError(error.message)
    onLoad(false)
    setLoading(false)
  }

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported')
      return
    }

    if (currentLocation === null && askLocation) {
      navigator.geolocation.getCurrentPosition(onChange, onError, settings)
    }
    return () => null
  }, [askLocation])

  return {...position, loading, error}
}
