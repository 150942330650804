import {Children, isValidElement, cloneElement} from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import {connect, useDispatch} from 'react-redux'
import {logout} from '@/redux/modules/auth/actions'
import {showLoginModal} from '@/redux/modules/layout/actions'
import withUserProfile from '@/lib/hoc/withUserProfile'
import withCurrentCity from '@/lib/hoc/withCurrentCity'
import {getFirstName} from '@/lib/format'
import logger from '@/lib/logger'
import Button from '@/components/shared/Button'
import FollowButton from '@/components/shared/FollowButton'
import Paragraph from '@/components/typography/Paragraph'
import {loggerAction, NAV_ITEMS} from './constants'
import classNames from 'classnames'
import styles from '@/styles/organisms/Nav/styles.module.css'
import motionStyles from '@/styles/_settings/motion.module.css'

const Menu = ({userProfile, onClose, onLogout}) => {
  const dispatch = useDispatch()

  const getGreeting = () => {
    const name =
      userProfile && userProfile.name && getFirstName(userProfile.name)

    return name ? `Olá, ${name}!` : 'Olá!'
  }

  function handleLoginButtonClick() {
    logger.action(loggerAction.buttonLogin, {userProfile})
    dispatch(showLoginModal({show: true}))
  }

  function handleLogoutButtonClick() {
    if (onLogout) {
      onLogout()
      logger.action(loggerAction.buttonLogout, {userProfile})
    }
    onClose()
  }

  function handleClick(url) {
    if (onClose) {
      onClose()
    }
    logger.action(loggerAction.link, {url})
  }

  const getNavItem = (item) => {
    return (
      <li key={item.label}>
        <FollowButton
          title={item.title}
          text={item.text}
          href={item.to}
          onClick={() => handleClick(item.to)}
        />
      </li>
    )
  }

  return (
    <>
      <nav className={styles.ecNav__wrapper}>
        {userProfile?.id ? (
          <>
            <FollowButton
              title={getGreeting()}
              text="Veja seus favoritos, visitas, imóveis ou recomendações"
              to="/perfil"
              onClick={() => handleClick('/perfil')}
            />
            <div className={styles.ecNav__profile}>
              <Button
                anchor
                small
                icon="/img/icons/people-pink.svg"
                onClick={handleLogoutButtonClick}
              >
                Sair da conta
              </Button>
            </div>
          </>
        ) : (
          <div
            className={classNames(
              styles.ecNav__profile,
              styles.ecNav__profile_unLogged
            )}
          >
            <Paragraph small>
              <Paragraph as="strong" big>
                Minha área
              </Paragraph>
              <br />
              Crie ou acesse sua conta para ver seus favoritos e salvar sua
              busca
            </Paragraph>
            <Button active onClick={handleLoginButtonClick}>
              Entrar
            </Button>
          </div>
        )}
        {NAV_ITEMS && (
          <ul className={styles.ecNav__list}>
            {NAV_ITEMS.map((item) => getNavItem(item))}
          </ul>
        )}
      </nav>
    </>
  )
}

const Nav = ({
  isVisible,
  hideNav,
  fromRight,
  children,
  userProfile,
  onLogout
}) => {
  const handleCloseButtonClick = () => {
    hideNav()
    logger.action('header-menu-close', {target: 'nav-button'})
  }
  const handleBackgroundClick = () => {
    hideNav()
    logger.action('header-menu-close', {target: 'menu-overlay'})
  }

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {userProfile, onLogout})
    }
    return child
  })

  return (
    <div
      className={classNames(styles.ecNav, {
        [styles.ecNav_show]: isVisible,
        [styles.ecNav_fromRight]: fromRight
      })}
    >
      <div
        className={classNames(styles.ecNav__nav, {
          [styles.ecNav__nav_fromRight]: fromRight,
          [motionStyles.animation_enterhiddenfromleft]: isVisible && !fromRight,
          [motionStyles.animation_enterhiddenfromright]: isVisible && fromRight
        })}
      >
        <div className={styles.ecNav__closeButtonWrapper}>
          <Button
            noBorder
            className={styles.ecNav__closeButton}
            icon="/img/icons/close.svg"
            onClick={handleCloseButtonClick}
          />
        </div>
        {childrenWithProps}
      </div>
      <span
        className={classNames({
          [styles.ecNav__background]: true,
          [styles.ecNav__background_show]: isVisible
        })}
        onClick={handleBackgroundClick}
      />
    </div>
  )
}

Nav.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hideNav: PropTypes.func.isRequired,
  fromRight: PropTypes.bool
}

Nav.Menu = Menu

export default compose(
  withUserProfile,
  withCurrentCity,
  connect(null, {onLogout: logout})
)(Nav)
