import omit from 'lodash/fp/omit'
import update from 'lodash/fp/update'
import pullAt from 'lodash/fp/pullAt'
import React from 'react'
import {graphql} from 'react-apollo'
import compose from 'recompose/compose'
import GET_TAGS from '@/graphql/queries/tags'
import * as format from '@/lib/format'
import logger from '@/lib/logger'
import {Orientations, SunPeriods, GarageTypes} from '@/config/enum/filters'
import TagButton from '@/components/shared/TagButton'
import classNames from 'classnames'
import styles from '@/styles/templates/Listings/styles.module.css'

const MAX_PLACE_LENGTH = 16

const groupTags = (tags = []) =>
  tags.reduce((obj, tag) => ({...obj, [tag.nameSlug]: tag.name}), {})

const getTagName = (slug) => ({tagNames}) => tagNames[slug]

const withTagNames = graphql(GET_TAGS, {
  props: ({data}) => ({
    tagNames: groupTags(data.tags)
  }),
  options: {
    fetchPolicy: 'cache-first'
  }
})

const mk = (getLabel, key, change = omit(key)) => (props) => (
  <div className={styles.ecTemplateListings__filters__item} key={key}>
    <TagButton
      onClick={() => {
        logger.action('listing-search-click-filter', {
          key,
          filters: props.value
        })
        props.openFilters()
      }}
      onClose={() => {
        props.onChange(change(props.value))
        logger.action('listing-search-remove-filter', {
          filters: props.value,
          removed: key
        })
      }}
    >
      {typeof getLabel === 'function' ? getLabel(props) : getLabel}
    </TagButton>
  </div>
)

const mkList = (value, getLabel, key, change = pullFromList(key)) =>
  value.map((v, index) =>
    mk(getLabel(v, index), `${key}.${v}`, change(v, index))
  )

const pullFromList = (name) => (_, index) => update(name, pullAt(index))

const getRangeLabel = (label, name, value, valueFormat) => {
  if (value.min && !value.max) {
    return mk(`${label}: A partir de ${valueFormat(value.min)}`, name)
  }
  if (value.max && !value.min) {
    return mk(`${label}: Até ${valueFormat(value.max)}`, name)
  }

  return mk(
    `${label}: ${valueFormat(value.min)} - ${valueFormat(value.max)}`,
    name
  )
}

function getLabel(name, value) {
  switch (name) {
    case 'price':
      return getRangeLabel('Preço', name, value, (v) => `R$${format.number(v)}`)
    case 'maintenanceFee':
      return getRangeLabel(
        'Condomínio',
        name,
        value,
        (v) => `R$${format.number(v)}`
      )
    case 'area':
      return getRangeLabel('Área', name, value, (v) => `${format.number(v)}m²`)
    case 'maxSubwayDistance':
      if (value) return mk('Próximo ao metrô', name)
      else return []
    case 'minRooms':
      return mk(`${value}+ quartos`, name)
    case 'minTotalBathrooms':
      return mk(`${value}+ banheiros`, name)
    case 'minSuites':
      return mk(`${value}+ suites`, name)
    case 'minGarageSpots':
      return mk(`${value}+ vagas`, name)
    case 'streetSlug':
      return mk(format.nameFromSlug(value), name)
    case 'tagsSlug':
      return mkList(value, getTagName, name)
    case 'types':
      return mkList(value, (v) => v, name)
    case 'neighborhoods':
      return mkList(value, (v) => v, name)
    case 'garageTypes':
      return mkList(value, (v) => GarageTypes.get(v), name)
    case 'sunPeriods':
      return mkList(value, (v) => SunPeriods.get(v), name)
    case 'orientations':
      return mkList(value, (v) => Orientations.get(v), name)
    case 'hasElevator':
      return mk(`${value ? 'Com' : 'Sem'} elevador`, name)
    case 'hasLiquidity':
      if (value) return mk('Ótimo preço', name)
      else return []
    case 'hasMatterport':
      if (value) return mk('Com Tour Virtual', name)
      else return []
    case 'priceRecentlyReduced':
      if (value) return mk('Preço baixou', name)
      else return []
    case 'recentlyAdded':
      if (value) return mk('Novo no site', name)
      else return []
    case 'location':
      if (value) return mk('Perto de você', name)
      else return []
    case 'searchLocation':
      if (value)
        return mk(
          value.place
            ? value.place.length > MAX_PLACE_LENGTH
              ? `${value.place.substr(0, MAX_PLACE_LENGTH)}...`
              : value.place
            : 'Endereço',
          name
        )
      else return []
    default:
      return []
  }
}

function ActiveListingFilters(props) {
  const {isMap, value} = props
  const labels = Object.keys(value || {})
    .sort()
    .reduce((labels, name) => labels.concat(getLabel(name, value[name])), [])

  return labels.length ? (
    <div className={styles.ecTemplateListings__filters}>
      <div
        className={classNames({
          [styles.ecTemplateListings__filters__container]: true,
          [styles.ecTemplateListings__filters__container_isMap]: isMap
        })}
      >
        {labels.map((renderLabel) => renderLabel(props))}
      </div>
    </div>
  ) : null
}

export default compose(React.memo, withTagNames)(ActiveListingFilters)
