import loadable from '@loadable/component'
import Loading from '../loading'

export default loadable(
  () =>
    import(
      /* webpackChunkName: "pages/listing" */
      './index'
    ),
  {fallback: <Loading />}
)
