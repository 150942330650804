import {CITY_CHANGED} from './actions'

const initialState = {
  city: null
}
export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case CITY_CHANGED:
      return {
        ...state,
        city: action.city
      }
    default:
      return state
  }
}
