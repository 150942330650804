import {Helmet} from 'react-helmet'
import Shell from '@/components/layout/templates/Clean'
import Spinner from '@/components/layout/Spinner'
import styles from '@/styles/_settings/composes.module.css'

export default function Loading() {
  return (
    <Shell noLogo>
      <Helmet>
        <title>EmCasa</title>
      </Helmet>
      <div className={styles.flexcenter}>
        <Spinner />
      </div>
    </Shell>
  )
}
