import React from 'react'
import moment from 'moment'
import * as format from '@/lib/format'
import {getListingUrl} from '@/lib/listingsUrl'
import {Link} from 'react-router-dom'
import Images from './Images'
import Label from '@/components/shared/Label'
import {
  labelOptions,
  NEW_LISTING_DAYS_OFFSET,
  LIQUIDITY_MIN_VALUE,
  BLACKNOVEMBER_NAMESLUG,
  DEVELOPMENT_NAMESLUG,
  CARD_CLICK_LOG
} from './constants'
import logger from '@/lib/logger'
import styles from './styles.module.css'

const getListingSummary = (
  {area, rooms, suites, garageSpots},
  separator = ` ${String.fromCharCode(0x2043)} `
) => {
  return [
    area && `${area}m²`,
    rooms && format.property(rooms, 'quarto'),
    suites && format.property(suites, 'suíte'),
    garageSpots && format.property(garageSpots, 'vaga')
  ]
    .filter(Boolean)
    .join(separator)
}

export const getDateDifference = (date) => {
  const a = moment(date)
  const b = moment()
  return a.diff(b, 'days')
}

export const isRecent = (date, limit) => {
  const diff = getDateDifference(date)
  return diff * -1 < limit
}

export const getLabel = ({
  insertedAt,
  priceRecentlyReduced,
  normalizedLiquidityRatio,
  lead,
  active,
  inactive,
  not_covered,
  dark,
  development
}) => {
  return development
    ? labelOptions.get('development')
    : dark
    ? labelOptions.get('black_november')
    : lead
    ? labelOptions.get('lead')
    : not_covered
    ? labelOptions.get('not_covered')
    : active
    ? labelOptions.get('active')
    : inactive
    ? labelOptions.get('inactive')
    : priceRecentlyReduced
    ? labelOptions.get('priceReduced')
    : isRecent(insertedAt, NEW_LISTING_DAYS_OFFSET)
    ? labelOptions.get('newListing')
    : normalizedLiquidityRatio &&
      normalizedLiquidityRatio >= LIQUIDITY_MIN_VALUE
    ? labelOptions.get('liquidity')
    : null
}

const sortByInsertedAt = (arr) =>
  arr.sort((a, b) => (a.insertedAt < b.insertedAt ? 1 : -1))

function ListingCard({
  listing,
  fromShortlist,
  related,
  feedType,
  hidePagination,
  cardUrl,
  cardClickAction = CARD_CLICK_LOG,
  cardTarget,
  imageControlsVisible,
  checkCardActivity,
  noLazy
}) {
  const isOnBlackNovember =
    listing &&
    listing.tags &&
    listing.tags.find((item) => item.nameSlug === BLACKNOVEMBER_NAMESLUG)
  const isDevelopment = listing?.tags?.find(
    (item) => item.nameSlug === DEVELOPMENT_NAMESLUG
  )

  const labelProps = getLabel({
    development: isDevelopment,
    dark: isOnBlackNovember,
    insertedAt: listing.insertedAt,
    priceRecentlyReduced: listing.priceRecentlyReduced,
    normalizedLiquidityRatio: listing.normalizedLiquidityRatio,
    lead: listing.origin === 'lead' && listing.status !== 'not_covered',
    active: checkCardActivity && listing.status === 'active',
    inactive: checkCardActivity && listing.status === 'inactive',
    not_covered: checkCardActivity && listing.status === 'not_covered'
  })

  const handleClick = () => {
    const logExtradata = {
      listingId: listing.id,
      fromShortlist,
      feedType,
      related,
      label: labelProps ? labelProps.label : null
    }

    logger.action(cardClickAction, logExtradata)
  }

  const alt = `Imóvel na ${listing.address.street}, ${listing.address.neighborhood}, ${listing.address.city} - ${listing.address.state} - ID-${listing.id}`

  const previousPrices =
    listing &&
    listing.previousPrices &&
    sortByInsertedAt(listing.previousPrices)

  return (
    <Link
      key={`listing-${listing.id}`}
      to={cardUrl ? `${cardUrl}/${listing.id}` : getListingUrl(listing)}
      target={cardTarget}
      className={styles.ecListingCard}
      title={alt}
      onClick={handleClick}
    >
      <Images
        noLazy={noLazy}
        alt={alt}
        images={listing.images}
        listingId={listing.id}
        fromShortlist={fromShortlist}
        controlAlwaysVisible={imageControlsVisible}
        hidePagination={hidePagination}
      />
      <div className={styles.ecListingCard__body}>
        <div className={styles.ecListingCard__body__header}>
          <p className={styles.ecListingCard__price}>
            {isOnBlackNovember &&
              Boolean(previousPrices && previousPrices.length) && (
                <>
                  <strong>
                    De: <span>{format.price(previousPrices[0].price)}</span>
                  </strong>
                  <br />
                </>
              )}
            {format.price(listing.price)}
          </p>
          {labelProps ? (
            <Label
              {...labelProps}
              lead={
                listing.origin === 'lead' && listing.status !== 'not_covered'
              }
              active={checkCardActivity && listing.status === 'active'}
              inactive={
                checkCardActivity &&
                (listing.status === 'inactive' ||
                  listing.status === 'not_covered')
              }
            />
          ) : null}
        </div>
        <p className={styles.ecListingCard__text}>
          {listing.address.street} - {listing.address.neighborhood}
        </p>
        <p className={styles.ecListingCard__text}>
          {getListingSummary(listing)}
        </p>
      </div>
    </Link>
  )
}

export default React.memo(ListingCard)
