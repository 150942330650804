import styled from 'styled-components'
import Loader from '@/components/listing/components/Gallery/components/Loader'

export default styled(() => (
  <div>
    <Loader />
  </div>
)).attrs(({theme}) => ({
  color: theme.colors.pink500
}))``
