import {Component} from 'react'
import styled from 'styled-components'
import Button from '@emcasa/ui-dom/components/Button'
import Text from '@emcasa/ui-dom/components/Text'

const PAYMENT_METHOD_LIST = [
  {label: 'À vista', value: 'CASH'},
  {label: 'Financiamento', value: 'DEFERRED'},
  {label: 'Precisa vender para comprar', value: 'NEED_TO_SELL'}
]

const Container = styled.div`
  padding: 15px;
`

export default class PaymentMethod extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentType: props.paymentType
    }
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.paymentType)
  }

  onChange = () => {
    const {paymentType} = this.state
    const {onChange} = this.props
    if (PAYMENT_METHOD_LIST.map(({value}) => value).indexOf(paymentType) > -1) {
      onChange({isValid: true, submit: this.onSubmit})
    }
  }

  componentDidMount() {
    this.onChange()
  }

  render() {
    const {paymentType} = this.state
    return (
      <Container>
        <Text fontWeight="bold" fontSize="small" textAlign="center">
          Qual é a forma de pagamento?
        </Text>
        <Button.Group
          mt={10}
          flexDirection="column"
          initialValue={paymentType}
          onChange={(value) => {
            this.setState({paymentType: value}, this.onChange)
          }}
        >
          {PAYMENT_METHOD_LIST.map(({label, value}, key) => (
            <Button
              key={`method-${key}`}
              value={value}
              height="medium"
              fontSize="small"
              mb={2}
            >
              {label}
            </Button>
          ))}
        </Button.Group>
      </Container>
    )
  }
}
