import styles from './styles.module.css'
import {memo} from 'react'

function MapClusterMarker({pointCount, totalPoints, onClick}) {
  const radius = `${10 + (pointCount / totalPoints) * 20}px`
  return (
    <div
      className={styles.ecMap__clusterMarker}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      style={{
        width: radius,
        height: radius
      }}
    >
      {pointCount}
    </div>
  )
}

export default memo(MapClusterMarker)
