import React from 'react'
import Slider from '@/components/form/SliderInput'

function PriceFilter({onChange, initialValue}) {
  return (
    <Slider
      onChange={onChange}
      initialValue={initialValue}
      step={5000}
      range={[0, 5000000]}
      limit={[0, 1500000000]}
      format={(num) => `R$ ${num}`}
      loggerAction={{
        onInputChange: 'listing-search-filter-input',
        propName: 'price'
      }}
    />
  )
}

export default React.memo(PriceFilter)
