import Icon from '@emcasa/ui-dom/components/Icon'
import Text from '@emcasa/ui-dom/components/Text'
import Image from '@/components/shared/Image'
import {Container, Content} from './styles'

export default function ImagePlaceholder({
  src,
  blur,
  color,
  height,
  children,
  ...props
}) {
  return (
    <Container height={height}>
      <Content {...props}>
        <Icon name="images" type="default" color={color} size={35} mb={2} />
        <Text fontSize="small" inline color={color} fontWeight="bold">
          {children}
        </Text>
      </Content>
      {src && (
        <Image zIndex={0} width="100%" height="100%" src={src} blur={blur} />
      )}
    </Container>
  )
}
