import styles from './styles.module.css'
import {memo} from 'react'

function MapUserMarker() {
  return (
    <svg
      className={styles.ecMapUserMarker}
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
    >
      <circle className={styles.ecMapUserMarker__circle} cx="50" cy="50" />
      <circle className={styles.ecMapUserMarker__circle2} cx="50" cy="50" />
      <circle className={styles.ecMapUserMarker__disk} cx="50" cy="50" />
    </svg>
  )
}

export default memo(MapUserMarker)
