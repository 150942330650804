const description =
  'A EmCasa te ajuda a encontrar casa e apartamentos à venda com tecnologia e um time de especialistas, tornando a compra de um imóvel mais transparente, ágil e segura.'
const telephone = '+55-11-3197-6638'
const email = 'mailto:contato@emcasa.com'

const SchemaContactPoint = [
  {
    '@type': 'ContactPoint',
    telephone: telephone,
    email: email,
    contactType: 'customer service',
    areaServed: 'BR',
    availableLanguage: 'Portuguese'
  }
]

const SchemaAddress = {
  '@type': 'PostalAddress',
  streetAddress: 'Rua General Artigas, 232',
  addressLocality: 'Rio de Janeiro',
  addressRegion: 'RJ',
  addressCountry: 'BR',
  postalCode: '22441-140'
}

const SchemaSameAs = [
  'https://www.facebook.com/EmCasa',
  'https://www.instagram.com/emcasa',
  'https://www.linkedin.com/company/emcasa'
]

const SchemaWebSite = {
  '@context': 'https://schema.org',
  '@id': 'https://emcasa.com/#website',
  '@type': 'WebSite',
  name: 'EmCasa',
  description: description,
  url: 'https://emcasa.com',
  sameAs: SchemaSameAs
}

const SchemaRealEstateAgent = {
  '@context': 'http://schema.org',
  '@id': 'https://emcasa.com/#realestateagent',
  '@type': 'RealEstateAgent',
  name: 'EmCasa',
  description: description,
  email: email,
  telephone: telephone,
  contactPoint: SchemaContactPoint,
  url: 'https://emcasa.com',
  logo:
    'https://res.cloudinary.com/emcasa/image/upload/v1609160145/logo/EmCasa-logo.jpg',
  address: SchemaAddress,
  sameAs: SchemaSameAs,
  priceRange: 'R$250.000 - R$2.000.000'
}

const SchemaOrganization = {
  '@context': 'http://schema.org',
  '@id': 'https://emcasa.com/#organization',
  '@type': 'Organization',
  name: 'EmCasa',
  description: description,
  email: email,
  telephone: telephone,
  contactPoint: SchemaContactPoint,
  url: 'https://emcasa.com',
  logo:
    'https://res.cloudinary.com/emcasa/image/upload/v1609160145/logo/EmCasa-logo.jpg',
  address: SchemaAddress,
  sameAs: SchemaSameAs
}

export {SchemaWebSite, SchemaRealEstateAgent, SchemaOrganization}
