import PropTypes from 'prop-types'
import logger from '@/lib/logger'
import Banner from '@/components/shared/Banner'

const YouCTA = ({
  icon = '/img/icons/people-ask.svg',
  title = 'Muitas opções?',
  text = 'Faça o seu perfil e veja imóveis que têm mais a ver com você.',
  href = '/voce',
  button = 'Completar perfil',
  buttonLog,
  state
}) => {
  const handleButtonClick = () => {
    logger.action(buttonLog ? buttonLog : 'you-cta-link', {
      href,
      pathname: window && window.location ? window.location.pathname : null
    })
  }

  return (
    <Banner
      pinkBg
      iconFull
      icon={icon}
      title={title}
      text={text}
      button={{
        label: button,
        onClick: handleButtonClick,
        to: {pathname: href, state}
      }}
    />
  )
}

YouCTA.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  button: PropTypes.string,
  buttonLog: PropTypes.string
}

export default YouCTA
