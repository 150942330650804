const filterComponent = (array, property) => {
  return (
    array.filter((component) => component.types.includes(property))[0] || {}
  )
}

/**
 * Given Google's addressData, return EmCasa's addressInput object.
 */
export const getAddressInput = (location) => {
  const {address_components: components} = location.addressData
  const neighborhood = filterComponent(components, 'sublocality_level_1')
    .long_name
  const neighborhoodFallback = filterComponent(
    components,
    'administrative_area_level_4'
  )?.long_name
  const street = filterComponent(components, 'route').long_name
  const streetNumber =
    filterComponent(components, 'street_number').long_name ||
    location.streetNumber
  const state = filterComponent(components, 'administrative_area_level_1')
    .short_name
  const city = filterComponent(components, 'administrative_area_level_2')
    .long_name
  const postalCode =
    filterComponent(components, 'postal_code').long_name || location.postalCode

  return {
    city,
    lat: location.addressData.geometry.location.lat,
    lng: location.addressData.geometry.location.lng,
    neighborhood: neighborhood || neighborhoodFallback,
    postalCode,
    street,
    streetNumber,
    state,
    approximated: Boolean(location.addressData.approximated)
  }
}
