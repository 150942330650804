export const LIQUIDITY_MIN_VALUE = 6
export const NEW_LISTING_DAYS_OFFSET = 30
export const BLACKNOVEMBER_NAMESLUG = 'blacknovember'
export const DEVELOPMENT_NAMESLUG = 'lancamento'
export const labelOptions = new Map([
  [
    'moreVisited',
    {
      icon: '/img/icons/cardLabel-moreVisited.svg',
      label: 'Mais visitado'
    }
  ],
  [
    'newListing',
    {
      icon: '/img/icons/cardLabel-newListing.svg',
      label: 'Novo no site'
    }
  ],
  [
    'priceReduced',
    {
      icon: '/img/icons/cardLabel-priceReduced.svg',
      label: 'Preço baixou'
    }
  ],
  [
    'liquidity',
    {
      icon: '/img/icons/cardLabel-liquidity.svg',
      label: 'Ótimo preço'
    }
  ],
  [
    'lead',
    {
      label: 'Em publicação'
    }
  ],
  [
    'active',
    {
      label: 'Ativo'
    }
  ],
  [
    'inactive',
    {
      label: 'Inativo'
    }
  ],
  [
    'not_covered',
    {
      label: 'Fora de cobertura'
    }
  ],
  [
    'black_november',
    {
      label: 'BlackNovember',
      dark: true
    }
  ],
  [
    'development',
    {
      icon: '/img/icons/cardLabel-development.svg',
      label: 'Lançamento'
    }
  ]
])
export const CARD_CLICK_LOG = 'listing-card-click'
