import * as UBA from './uba'
import sha256 from 'sha256'
import {isPWA, isTWA} from '@/lib/device'

const TYPE_IDENTIFY = 'identify'
const TYPE_EVENT = 'event'

function _getAmplitude() {
  window.onLoadAmplitude = function(_, callback) {
    setTimeout(_flushDataLayer.bind(null, callback), 100)
  }
  return window.amplitude ? window.amplitude.getInstance() : null
}

function _getDeviceInfo() {
  return {pwa: isPWA(), twa: isTWA()}
}

function _getDataLayer() {
  window.amplitudeDataLayer = window.amplitudeDataLayer || []
  window.amplitudeDataLayer.push = _pushEvent
  return window.amplitudeDataLayer
}

function _flushDataLayer(callback) {
  const amplitude = _getAmplitude()
  const dataLayer = _getDataLayer()
  if (amplitude === null) {
    throw new Error('Cannot flush data layer while Amplitude is not loaded')
  }

  // Set device ID as SHA-256 for Facebook Advanced Matching
  const deviceId = amplitude.options ? amplitude.options.deviceId : null
  const fbExternalId = sha256(deviceId)
  if (fbExternalId) {
    amplitude.identify(
      new window.amplitude.Identify().set('fbExternalId', fbExternalId)
    )
  }

  // Flush data layer
  for (let i = 0; i < dataLayer.length; i++) {
    _handleEvent(amplitude, dataLayer[i])
    dataLayer.splice(i, 1)
    --i
  }

  if (callback) {
    callback()
  }
}

function _handleEvent(amplitude, event) {
  const {type, ...data} = event
  const deviceId = amplitude.options ? amplitude.options.deviceId : null

  switch (type) {
    case TYPE_IDENTIFY:
      amplitude.setUserId(data.user.id)
      amplitude.setUserProperties(data.user)
      UBA.identify(data.user)
      return true
    case TYPE_EVENT:
      amplitude.logEventWithTimestamp(data.event, data.props, data.timestamp)
      UBA.logUBA(data.event, data.props, data.timestamp, deviceId)
      return true
    default:
      return false
  }
}

function _pushEvent() {
  const amplitude = _getAmplitude()
  if (amplitude) {
    _handleEvent.bind(null, amplitude).apply(null, arguments)
    return null
  }
  return Array.prototype.push.apply(this, arguments)
}

export function identify(user) {
  if (!user) {
    return
  }
  _getDataLayer().push({
    type: TYPE_IDENTIFY,
    user: Object.assign(user, _getDeviceInfo())
  })
}

export function logEvent(event, props) {
  _getDataLayer().push({
    type: TYPE_EVENT,
    event,
    props,
    timestamp: new Date().getTime()
  })
}
