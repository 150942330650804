import {Switch, Redirect, Route} from 'react-router-dom'
import ErrorBoundary from '@/pages/error/ErrorBoundary'
import ErrorPage from '@/pages/error'
import MyListings from './Seller/MyListings/lazy'
import MyListing from './Seller/MyListing/lazy'
import MyListingInfos from './Seller/MyListingInfos/lazy'
import MyListingValues from './Seller/MyListingValues/lazy'
import ScheduleTour from './Seller/ScheduleTour/lazy'
import Offers from './Interests/Offers/lazy'
import Visits from './Interests/Visits/lazy'
import Status from './Seller/Status/lazy'
import Profile from './User/Profile/lazy'
import Suggestions from './User/Suggestions/lazy'
import SellerAvailability from './Seller/VisitAvailability/lazy'
import HiddenListings from '@/pages/listings/HiddenListings'
import FavoriteLisitngs from '@/pages/listings/FavoriteListings'
import Hub from './lazy'

const INTERESTS_PATH = '/interesses'
const USER_PATH = '/perfil'
const MY_LISTINGS_PATH = '/meus-imoveis'
const SELLER_PROFILE = '/atualizar-perfil-proprietario'

const NotFound = () => <ErrorPage emit code={404} message="Not found" />

export default function getHubRoutes() {
  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/hub" component={Hub} />
        {/* Interests routes */}
        <Redirect
          from="/propostas/:profile?"
          to={`${INTERESTS_PATH}/propostas/:profile?`}
        />
        <Redirect from="/visitas" to={`${INTERESTS_PATH}/visitas`} />
        <Redirect
          exact
          from={INTERESTS_PATH}
          to={`${INTERESTS_PATH}/visitas`}
        />
        <Route exact path={`${INTERESTS_PATH}/visitas`} component={Visits} />
        <Route
          exact
          path={`${INTERESTS_PATH}/propostas/:profile?`}
          component={Offers}
        />

        {/* User routes */}
        <Redirect exact from={USER_PATH} to={`${USER_PATH}/dados`} />
        <Route exact path={`${USER_PATH}/dados`} component={Profile} />
        <Route
          exact
          path={`${USER_PATH}/favoritos`}
          component={FavoriteLisitngs}
        />
        <Route
          exact
          path={`${USER_PATH}/escondidos`}
          component={HiddenListings}
        />
        <Route exact path={`${USER_PATH}/sugestoes`} component={Suggestions} />
        <Redirect
          exact
          from="/imoveis/selecionados"
          to={`${USER_PATH}/sugestoes`}
        />
        <Redirect
          exact
          from="/imoveis/favoritos"
          to={`${USER_PATH}/favoritos`}
        />
        <Redirect
          exact
          from="/imoveis/escondidos"
          to={`${USER_PATH}/escondidos`}
        />

        {/* My Listings routes */}
        <Route exact path={MY_LISTINGS_PATH} component={MyListings} />
        <Route exact path={`${MY_LISTINGS_PATH}/:id`} component={MyListing} />
        <Route
          exact
          path={`${MY_LISTINGS_PATH}/:id/informacoes`}
          component={MyListingInfos}
        />
        <Route
          exact
          path={`${MY_LISTINGS_PATH}/:id/fotos`}
          component={ScheduleTour}
        />
        <Route
          path={`${MY_LISTINGS_PATH}/:id/visitas`}
          component={SellerAvailability}
        />
        <Route
          path={`${MY_LISTINGS_PATH}/:id/valores`}
          component={MyListingValues}
        />
        <Route path={`${MY_LISTINGS_PATH}/:id/status`} component={Status} />

        <Redirect
          from={`${SELLER_PROFILE}/:id/inativar`}
          to={`${MY_LISTINGS_PATH}/:id/status`}
        />

        <Redirect
          from={`${SELLER_PROFILE}/:id`}
          to={`${MY_LISTINGS_PATH}/:id/valores`}
        />

        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  )
}
