import AddressAutoComplete from '@emcasa/places-autocomplete/AddressAutoComplete'
import {getAddressInput} from '@/lib/address'
import Input from '@emcasa/ui-dom/components/Input'
import Col from '@emcasa/ui-dom/components/Col'
import styled from 'styled-components'

const Container = styled.div`
  button {
    font-size: 14px;
  }
`

function StreetNumberInput({value, onChange}) {
  return (
    <Col mt={2}>
      <Input
        type="number"
        height="medium"
        label="Número da rua"
        aria-label="Número da rua do imóvel"
        name="streetNumber"
        placeholder="Ex: 1234"
        error={
          !value
            ? 'Não conseguimos localizar o endereço completo. Por favor, confirme o número da rua do imóvel.'
            : null
        }
        tabIndex="0"
        hideErrorView={Boolean(value)}
        value={value}
        defaultValue={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Col>
  )
}

export default function AddressInput({apiURL, onChange, onBlur, value}) {
  const {collectStreetNumber, streetNumber} = value || {}
  return (
    <Container>
      <AddressAutoComplete
        p={0}
        height="medium"
        placeholder="Ex: Rua Aníbal de Mendonça, 132"
        icon={null}
        inputProps={{
          p: 0,
          tabIndex: 0,
          'aria-label': 'Endereço e número do imóvel',
          fontSize: '1rem'
        }}
        initialValue={value ? value.address : null}
        onBlur={onBlur}
        onSelect={(_, addressData, address) => {
          const input = getAddressInput({addressData})
          onChange({
            address,
            addressData,
            streetNumber: input.streetNumber,
            collectStreetNumber: !input.streetNumber
          })
        }}
        apiUrl={apiURL}
      />
      {collectStreetNumber ? (
        <StreetNumberInput
          value={streetNumber}
          onChange={(streetNumber) => {
            onChange({...value, streetNumber})
          }}
        />
      ) : null}
    </Container>
  )
}
