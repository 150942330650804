import gql from 'graphql-tag'

export default gql`
  fragment PartialListing on Listing {
    id
    isActive
    uuid
    type
    price
    area
    rooms
    bathrooms
    floor
    suites
    garageSpots
    insertedAt
    priceRecentlyReduced
    normalizedLiquidityRatio
    previousPrices {
      price
      insertedAt
    }
    tags {
      uuid
      name
      nameSlug
    }
    address {
      id
      neighborhood
      neighborhoodSlug
      street
      streetSlug
      streetNumber
      city
      state
      citySlug
      stateSlug
      neighborhoodDescription
    }
    images {
      id
      filename
      position
    }
  }
`
