import {memo} from 'react'
import compose from 'recompose/compose'
import withDistricts from '@/lib/hoc/withDistricts'
import {getCitiesFromDistricts} from '@/lib/districts'
import PropTypes from 'prop-types'
import {ABOUT, FOOTER_SOCIAL, FOOTER_NAV, COPYRIGHT} from './constants'
import styles from '@/styles/organisms/Footer/styles.module.css'
import logger from '@/lib/logger'

const Footer = ({withBottomBar, districts}) => {
  const cities = getCitiesFromDistricts(districts, true)
  const log = (url) => logger.action('footer-link', {url})
  return (
    <footer
      className={`${styles.ecFooter} ${
        withBottomBar ? styles.ecFooter_withBar : ''
      }`}
    >
      <div className={styles.ecFooter__container}>
        <div className={styles.ecFooter__about}>
          <div className={styles.ecFooter__brand}>
            <img
              className={styles.ecFooter__brand__logo}
              src="/img/emcasa-w.svg"
              alt="Logo da EmCasa"
            />
            <p className={styles.ecFooter__brand__p}>{ABOUT}</p>
          </div>
          <div className={styles.ecFooter__social}>
            {FOOTER_SOCIAL.map((item, index) => (
              <a
                key={index}
                className={styles.ecFooter__social__item}
                href={item.url}
                aria-label={`Acesse o ${item.name} da EmCasa`}
                title={`Acesse o ${item.name} da EmCasa`}
                target="_blank"
                rel="external noopener noreferrer"
                onClick={() => log(item.url)}
              >
                <img
                  className={styles.ecFooter__social__icon}
                  src={`/img/icons/${item.slug}.svg`}
                  alt={`Logo do ${item.name}`}
                />
              </a>
            ))}
          </div>
        </div>
        <div className={styles.ecFooter__nav}>
          <h2 className={styles.ecFooter__titleNav}>EmCasa</h2>

          {cities && cities.length ? (
            <div className={styles.ecFooter__nav__item}>
              <h3 className={styles.ecFooter__nav__title}>Comprar</h3>
              <ul className={styles.ecFooter__nav__list}>
                {cities.map((item, index) => {
                  const url = `/imoveis/${item.state}/${item.slug}`
                  return (
                    <li
                      key={index}
                      className={styles.ecFooter__nav__list__item}
                    >
                      <a
                        className={styles.ecFooter__nav__link}
                        href={url}
                        onClick={() => log(url)}
                      >
                        Imóveis em {item.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          ) : null}
          {FOOTER_NAV.map((item, index) => (
            <div key={index} className={styles.ecFooter__nav__item}>
              <h3 className={styles.ecFooter__nav__title}>{item.title}</h3>
              <ul className={styles.ecFooter__nav__list}>
                {item.list.map((item, index) => (
                  <li key={index} className={styles.ecFooter__nav__list__item}>
                    {item.to ? (
                      <a
                        className={styles.ecFooter__nav__link}
                        href={item.to}
                        onClick={() => log(item.to)}
                      >
                        {item.label}
                      </a>
                    ) : (
                      <a
                        className={styles.ecFooter__nav__link}
                        href={item.url}
                        target="_blank"
                        rel="external noopener noreferrer"
                        onClick={() => log(item.url)}
                      >
                        {item.label}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <p className={styles.ecFooter__copyright}>{COPYRIGHT}</p>
    </footer>
  )
}

Footer.propTypes = {
  withBottomBar: PropTypes.bool
}

export default compose(memo, withDistricts)(Footer)
