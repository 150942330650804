import {useState, useCallback, useEffect, useRef} from 'react'

export default function useToggle(initialState, callback) {
  const [active, setActive] = useState(initialState)
  const isInitial = useRef(true)
  useEffect(() => {
    if (callback) callback(active, isInitial.current)
    isInitial.current = false
  }, [active])
  const on = useCallback(() => setActive(true))
  const off = useCallback(() => setActive(false))
  const toggle = useCallback(() => setActive(!active), [active])
  return {active, on, off, toggle}
}
