import * as qs from 'query-string'
import {useState, useCallback} from 'react'
import {useHistory} from 'react-router-dom'

const QS_OPTIONS = {arrayFormat: 'comma'}
export const INITIAL_STATE = null

export default function useOrderBy() {
  const history = useHistory()
  const {query} = qs.parseUrl(
    (history.location.pathname + history.location.search || '?').slice(1),
    QS_OPTIONS
  )
  let initialState = INITIAL_STATE
  if (query.ob) {
    const type = query.ob.startsWith('-') ? 'ASC' : 'DESC'
    const field = type === 'ASC' ? query.ob.slice(1) : query.ob
    initialState = {type, field}
  }
  const [value, setValue] = useState(initialState)
  const setOrderBy = useCallback((props) => {
    const {field, type} = props || {}
    const {location} = history
    const query = qs.parse(
      (history.location.search || '?').slice(1),
      QS_OPTIONS
    )
    if (field) {
      query.ob = `${type === 'DESC' ? '' : '-'}${field}`
      setValue({field, type})
    } else {
      if (query.ob) {
        delete query.ob
      }
      setValue(null)
    }
    const search = qs.stringify(query, QS_OPTIONS)
    history.replace({...location, search})
  })
  return [value, setOrderBy]
}
