import {Switch, Route} from 'react-router-dom'
import compose from 'recompose/compose'
import {graphql} from 'react-apollo'
import {GET_SEARCH_CONTEXT} from '@/graphql/queries/searchListings'
import Redirect from '@/pages/context/side-effects/RedirectWithStatus'
import {getCitiesFromDistricts, getStatesFromDistricts} from '@/lib/districts'
import FindListingAndRedirect from './FindListingAndRedirect'
import OfferPage from '../../listing/OfferPage'
import ListingPage from '@/pages/listing/lazy'
import SearchListings from '../lazy'
import CitySwitcher from '../CitySwitcher'

const LISTING_ID_REGEX = '[0-9]+'

function ListingsRoutes({path, data: {districts, tags}}) {
  if (!districts || !tags) {
    return null
  }

  const states = getStatesFromDistricts(districts)
  const cities = getCitiesFromDistricts(districts)

  const stateRedirectPaths = states.map((state) => ({
    from: `${path}/${state}`,
    to: `${path}/${state}/${cities.find((c) => c.state === state).slug}`
  }))

  const citySearchPaths = cities.map(
    ({slug, state}) =>
      `${path}/:map(mapa)?/:state(${state})/:city(${slug})/:filters(.*)?`
  )

  const listingPath = `${path}/:state?/:city?/:neighborhood?/:street?/id-:id(${LISTING_ID_REGEX})`
  return (
    <Switch>
      <Redirect from="/imoveis/selecionados" to="/perfil/sugestoes" />
      <Redirect from="/imoveis/favoritos" to="/perfil/favoritos" />
      <Redirect from="/imoveis/escondidos" to="/perfil/escondidos" />
      <Route
        exact
        path={`${path}/:id(${LISTING_ID_REGEX})/:rest(.*)?`}
        component={FindListingAndRedirect}
      />
      <Route exact path={listingPath} component={ListingPage} />
      <Route exact path={`${listingPath}/proposta`} component={OfferPage} />
      <Route exact path={`${listingPath}/mapa`} component={ListingPage} />
      <Route exact path={`${listingPath}/rua`} component={ListingPage} />
      <Route
        exact
        path={`${listingPath}/tour-virtual`}
        component={ListingPage}
      />
      {stateRedirectPaths.map(({from, to}, index) => (
        <Redirect
          key={`redirect-${index}`}
          permanent
          exact
          from={from}
          to={to}
        />
      ))}
      <Route
        path={citySearchPaths}
        render={({match}) => (
          <SearchListings
            districts={districts}
            tags={tags}
            params={match.params}
          />
        )}
      />

      <Route path={path} component={CitySwitcher} />
    </Switch>
  )
}

export default compose(graphql(GET_SEARCH_CONTEXT, {options: {ssr: true}}))(
  ListingsRoutes
)
