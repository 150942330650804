import {memo} from 'react'
import PropTypes from 'prop-types'
import Button from '@/components/shared/Button'
import ButtonLink from '@/components/shared/Button/Link'
import classNames from 'classnames'
import styles from '@/styles/molecules/Result/styles.module.css'

const Result = ({
  large,
  illustration,
  illustrationSmall,
  illustrationTiny,
  icon,
  iconFull,
  title,
  titleBig,
  text,
  textBig,
  textIcon,
  success,
  error,
  buttons
}) => {
  return (
    <div
      className={classNames({
        [styles.ecResult]: true,
        [styles.ecResult_large]: large
      })}
    >
      {illustration && (
        <span className={styles.ecResult__illustrationWrapper}>
          <img
            className={classNames(styles.ecResult__illustration, {
              [styles.ecResult__illustration_small]: illustrationSmall,
              [styles.ecResult__illustration_tiny]: illustrationTiny
            })}
            src={illustration}
            alt="Ilustração"
          />
        </span>
      )}
      {icon && (
        <span
          className={classNames({
            [styles.ecResult__iconWrapper]: true,
            [styles.ecResult__iconWrapper_noBg]: iconFull,
            [styles.ecResult__iconWrapper_success]: success && !error,
            [styles.ecResult__iconWrapper_error]: error && !success
          })}
        >
          <img
            className={classNames({
              [styles.ecResult__icon]: true,
              [styles.ecResult__icon_full]: iconFull
            })}
            src={icon}
            alt="Ícone"
          />
        </span>
      )}
      {title && (
        <h2
          className={classNames({
            [styles.ecResult__title]: true,
            [styles.ecResult__title_big]: titleBig
          })}
        >
          <strong>{title}</strong>
        </h2>
      )}
      {text && (
        <p
          className={classNames({
            [styles.ecResult__text]: true,
            [styles.ecResult__text_big]: textBig
          })}
        >
          {text}
          {textIcon && (
            <>
              {' '}
              Clique em
              <img
                className={styles.ecResult__text__icon}
                src={textIcon}
                alt="Ícone"
              />
            </>
          )}
        </p>
      )}
      {buttons &&
        buttons.map((item) => {
          const ButtonComponent = item.to ? ButtonLink : Button
          return (
            <ButtonComponent
              {...item}
              key={item.label}
              className={styles.ecResult__button}
            >
              {item.label}
            </ButtonComponent>
          )
        })}
    </div>
  )
}

Result.propTypes = {
  large: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  illustration: PropTypes.string,
  illustrationSmall: PropTypes.bool,
  illustrationTiny: PropTypes.bool,
  icon: PropTypes.string,
  iconFull: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleBig: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textBig: PropTypes.bool,
  textIcon: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onClick: PropTypes.func
    })
  )
}

export default memo(Result)
