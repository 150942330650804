import {useState} from 'react'
import inputStyles from '@/styles/atoms/Input/styles.module.css'

export default function NumberInput({onChange, onBlur, value, ...rest}) {
  const [number, setNumber] = useState(value)
  return (
    <input
      {...rest}
      className={inputStyles.ecInput}
      type="number"
      value={number}
      onBlur={onBlur}
      onChange={(e) => {
        const value = e.currentTarget.value
        const valueAsInt = parseInt(value, 10)
        setNumber(value)
        onChange(isNaN(valueAsInt) ? null : valueAsInt)
      }}
    />
  )
}
