import {useEffect} from 'react'
import {graphql} from 'react-apollo'
import compose from 'recompose/compose'
import {useForm, Controller} from 'react-hook-form'
import styled from 'styled-components'
import labelStyles from '@/styles/atoms/Label/styles.module.css'
import inputStyles from '@/styles/atoms/Input/styles.module.css'
import GET_PROFILE from '@/graphql/queries/userProfile'
import PhoneInput from '@/components/form/PhoneInput'
import Text from '@emcasa/ui-dom/components/Text'

const Form = styled.form`
  padding: 15px;
  label {
    margin-bottom: 10px;
  }
`

function Contact({onSubmit, onChange, currentUser, contact}) {
  const {control, handleSubmit, trigger, formState} = useForm({
    mode: 'onChange',
    defaultValues: Object.assign(
      {
        name: '',
        phone: '',
        email: ''
      },
      contact || currentUser || {}
    )
  })
  const {isValid} = formState
  const submitCallback = handleSubmit(onSubmit)
  useEffect(() => {
    onChange({submit: submitCallback, isValid})
    if (!isValid && contact && contact.name && contact.phone) {
      trigger()
    }
  })

  return (
    <Form onSubmit={submitCallback}>
      <Text fontWeight="bold" fontSize="small" textAlign="center">
        Confirme os seus dados de contato
      </Text>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Nome:</span>
        <Controller
          name="name"
          rules={{required: true}}
          className={inputStyles.ecInput}
          as="input"
          type="text"
          control={control}
          arial-label="Insira o seu nome"
          placeholder="Ex: Maria da Silva"
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Celular:</span>
        <Controller
          name="phone"
          rules={{
            validate: PhoneInput.validate
          }}
          as={PhoneInput}
          control={control}
          placeholder="Ex: (11) 99999-9999"
          arial-label="Insira o seu celular"
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>E-mail (opcional):</span>
        <Controller
          name="email"
          rules={{required: false}}
          as="input"
          className={inputStyles.ecInput}
          type="email"
          control={control}
          arial-label="Insira o seu e-mail"
          placeholder="nome@email.com"
        />
      </label>
    </Form>
  )
}

export default compose(
  graphql(GET_PROFILE, {
    options: () => ({
      fetchPolicy: 'cache-first',
      errorPolicy: 'ignore'
    }),
    props: ({data}) => ({
      currentUser: data && data.userProfile ? data.userProfile : null
    })
  })
)(Contact)
