import {useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useLocation, NavLink} from 'react-router-dom'
import styles from '@/styles/molecules/ListingsTabs/styles.module.css'

export function Tabs({items}) {
  const scrollRef = useRef()
  const {pathname} = useLocation()
  useEffect(() => {
    const index = items.findIndex((item) => item.pathname === pathname)
    const children =
      scrollRef && scrollRef.current ? scrollRef.current.children[index] : null
    const width = children ? children.offsetLeft + children.offsetWidth : null
    if (index && children && width >= window.innerWidth) {
      scrollRef.current.scrollLeft = children.offsetLeft
    }
  }, [])

  return (
    <div className={styles.ecListingsTabs}>
      <div className={styles.ecListingsTabs__container} ref={scrollRef}>
        {items.map(({label, pathname, exact}, index) => (
          <div key={`tab-${index}`} className={styles.ecListingsTabs__item}>
            <NavLink
              exact={exact}
              to={pathname}
              className={styles.ecListingsTabs__button}
              activeClassName={styles.ecListingsTabs__button_active}
            >
              {label}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired
    })
  )
}

export default Tabs
