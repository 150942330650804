export const getLayout = (state) => state.layout

/**
 * Length of history since first access to the website. This is used to avoid
 * navigating to a different site when using the back button.
 */
export const getRecordedHistoryLength = (state) =>
  getLayout(state).recordedHistoryLength

export const getLayoutOptions = (state) => getLayout(state).options

export const getNav = (state) => getLayout(state).nav

export const hasNav = (state) => getNav(state).active

export const getNavComponent = (state) => getNav(state).Component

export const getNavProps = (state) => getNav(state).props

export const getModal = (state) => getLayout(state).modal

export const hasModal = (state) => getModal(state).active

export const getModalComponent = (state) => getModal(state).Component

export const getModalProps = (state) => getModal(state).props

export const getBackButton = (state) => getLayout(state).backButton

export const getLoginModal = (state) => getLayout(state).loginModal
