import {useRef, useEffect} from 'react'
import compose from 'recompose/compose'
import {graphql} from 'react-apollo'
import CardLoading from '@/components/listings/Card/Loading'
import Card from '@/components/listings/Card'
import styles from './styles.module.css'
import PARTIAL_LISTING from '@/graphql/queries/partialListing'
import {getDeviceType} from '@/lib/device'

function MapCard({loading, listing}) {
  const ref = useRef()

  const eventListener = (e) => {
    e.stopPropagation()
  }

  const {isDesktop} = getDeviceType()

  const cardTarget = isDesktop ? '_blank' : null

  useEffect(() => {
    ref.current.addEventListener('touchstart', eventListener)

    return () => {
      ref.current.removeEventListener('touchstart', eventListener)
    }
  }, [])

  return (
    <div className={styles.ecMap__card} ref={ref}>
      <div className={styles.ecMap__card__content}>
        {loading || !listing ? (
          <CardLoading />
        ) : (
          <Card
            imageControlsVisible={true}
            listing={listing}
            cardTarget={cardTarget}
          />
        )}
      </div>
    </div>
  )
}

export default compose(
  graphql(PARTIAL_LISTING, {
    options: ({listingId}) => ({
      ssr: false,
      fetchPolicy: 'cache-first',
      variables: {id: listingId}
    }),
    props: ({data}) => ({
      listing: data && data.listing,
      loading: data && data.loading
    })
  })
)(MapCard)
