// Partner Broker
export const PARTNERBROKER_LANDING_PAGE = 'partnerbroker-landing-page'
export const PARTNERBROKER_LANDING_SIGNUP_NOT_LOGGED =
  'partnerbroker-landing-signup-not-logged'
export const PARTNERBROKER_LANDING_REGISTER_NOT_LOGGED =
  'partnerbroker-landing-register-not-logged'
export const PARTNERBROKER_LANDING_SIGNUP = 'partnerbroker-landing-signup'
export const PARTNERBROKER_LANDING_REGISTER = 'partnerbroker-landing-register'
export const PARTNERBROKER_LANDING_FAQ_LINK = 'partnerbroker-landing-faq-link'
export const PARTNERBROKER_LANDING_FAQ_MAILTO =
  'partnerbroker-landing-faq-mailto'
export const PARTNERBROKER_LANDING_WHY_CARD_CHANGE =
  'partnerbroker-landing-why-card-change'
export const PARTNERBROKER_LANDING_COMMENTS_CARD_CHANGE =
  'partnerbroker-landing-comments-card-change'
export const PARTNERBROKER_LANDING_FOOTER_BUTTON_NOT_LOGGED =
  'partnerbroker-landing-footer-button-not-logged'
export const PARTNERBROKER_LANDING_FOOTER_BUTTON =
  'partnerbroker-landing-footer-button'
export const PARTNERBROKER_SIGNUP_PROFILE = 'partnerbroker-signup-profile'
export const PARTNERBROKER_SIGNUP_PROFILE_NEXT =
  'partnerbroker-signup-profile-next'
export const PARTNERBROKER_SIGNUP_NEIGHBORHOODS =
  'partnerbroker-signup-neighborhood'
export const PARTNERBROKER_SIGNUP_NEIGHBORHOODS_NEXT =
  'partnerbroker-signup-neighborhood-next'
export const PARTNERBROKER_SIGNUP_NEIGHBORHOODS_BACK =
  'partnerbroker-signup-neighborhood-back'
export const PARTNERBROKER_SIGNUP_SEND = 'partnerbroker-signup-send'
export const PARTNERBROKER_SIGNUP_SEND_DONE =
  'partnerbroker-signup-send-success'
export const PARTNERBROKER_SIGNUP_SEND_ERROR = 'partnerbroker-signup-send-error'
export const PARTNERBROKER_SIGNUP_SUCCESS = 'partnerbroker-signup-success'
export const PARTNERBROKER_SIGNUP_SUCCESS_BUTTON =
  'partnerbroker-signup-success-button'
export const PARTNERBROKER_SIGNUP_ERROR = 'partnerbroker-signup-error'
export const PARTNERBROKER_SIGNUP_ERROR_BUTTON =
  'partnerbroker-signup-error-button'
export const PARTNERBROKER_REGISTER_ADDRESS = 'partnerbroker-register-address'
export const PARTNERBROKER_REGISTER_ADDRESS_DUPLICATED_ERROR =
  'partnerbroker-register-address-duplicated-error'
export const PARTNERBROKER_REGISTER_ADDRESS_NOCOVERED_ERROR =
  'partnerbroker-register-address-nocovered-error'
export const PARTNERBROKER_REGISTER_ADDRESS_NEXT =
  'partnerbroker-register-address-next'
export const PARTNERBROKER_REGISTER_DUPLICATED =
  'partnerbroker-register-duplicated'
export const PARTNERBROKER_REGISTER_DUPLICATED_BUTTON =
  'partnerbroker-register-duplicated-button'
export const PARTNERBROKER_REGISTER_NOCOVERED =
  'partnerbroker-register-nocovered'
export const PARTNERBROKER_REGISTER_NOCOVERED_BUTTON =
  'partnerbroker-register-nocovered-button'
export const PARTNERBROKER_REGISTER_PRICING = 'partnerbroker-register-pricing'
export const PARTNERBROKER_REGISTER_PRICING_NEXT =
  'partnerbroker-register-pricing-next'
export const PARTNERBROKER_REGISTER_PRICING_BACK =
  'partnerbroker-register-pricing-back'
export const PARTNERBROKER_REGISTER_OWNER = 'partnerbroker-register-owner'
export const PARTNERBROKER_REGISTER_OWNER_NEXT =
  'partnerbroker-register-owner-next'
export const PARTNERBROKER_REGISTER_OWNER_BACK =
  'partnerbroker-register-owner-back'
export const PARTNERBROKER_REGISTER_OWNER_ERROR =
  'partnerbroker-register-owner-error'
export const PARTNERBROKER_REGISTER_DUPLICATEDOWNER =
  'partnerbroker-register-duplicatedowner'
export const PARTNERBROKER_REGISTER_DUPLICATEDOWNER_BUTTON =
  'partnerbroker-register-duplicatedowner-button'
export const PARTNERBROKER_REGISTER_SEND = 'partnerbroker-register-send'
export const PARTNERBROKER_REGISTER_SEND_DONE =
  'partnerbroker-register-send-success'
export const PARTNERBROKER_REGISTER_SEND_ERROR =
  'partnerbroker-register-send-error'
export const PARTNERBROKER_REGISTER_SUCCESS = 'partnerbroker-register-success'
export const PARTNERBROKER_REGISTER_SUCCESS_BUTTON =
  'partnerbroker-register-success-button'
export const PARTNERBROKER_REGISTER_ERROR = 'partnerbroker-register-error'
export const PARTNERBROKER_REGISTER_ERROR_BUTTON =
  'partnerbroker-register-error-button'
export const PARTNERBROKER_FAQ_PAGE = 'partnerbroker-faq-page'
export const PARTNERBROKER_FAQ_SEARCH = 'partnerbroker-faq-search'
export const PARTNERBROKER_FAQ_OPEN_QUESTION = 'partnerbroker-faq-open-question'
export const PARTNERBROKER_FAQ_CLOSE_QUESTION =
  'partnerbroker-faq-close-question'
export const PARTNERBROKER_LISTINGS_PAGE = 'partnerbroker-listings-page'
export const PARTNERBROKER_LISTINGS_PAGINATION =
  'partnerbroker-listings-pagination'

// Referrer
export const REFERRALS_LANDING_PAGE = 'referrals-landing-page'
export const REFERRALS_LANDING_DISTRICTS_MODAL =
  'referrals-landing-districts-modal'
export const REFERRALS_LANDING_HEADER_BUTTON_NOT_LOGGED =
  'referrals-landing-header-button-not-logged'
export const REFERRALS_LANDING_HEADER_BUTTON = 'referrals-landing-header-button'
export const REFERRALS_LANDING_FOOTER_BUTTON_NOT_LOGGED =
  'referrals-landing-footer-button-not-logged'
export const REFERRALS_LANDING_FOOTER_BUTTON = 'referrals-landing-footer-button'
export const REFERRALS_SIGNUP_PROFILE = 'referrals-signup-profile'
export const REFERRALS_SIGNUP_PROFILE_NEXT = 'referrals-signup-profile-next'
export const REFERRALS_SIGNUP_SEND = 'referrals-signup-send'
export const REFERRALS_SIGNUP_SEND_DONE = 'referrals-signup-send-success'
export const REFERRALS_SIGNUP_SEND_ERROR = 'referrals-signup-send-error'
export const REFERRALS_SIGNUP_SUCCESS = 'referrals-signup-success'
export const REFERRALS_SIGNUP_SUCCESS_BUTTON = 'referrals-signup-success-button'
export const REFERRALS_SIGNUP_ERROR = 'referrals-signup-error'
export const REFERRALS_SIGNUP_ERROR_BUTTON = 'referrals-signup-error-button'
export const REFERRALS_REGISTER_ADDRESS = 'referrals-register-address'
export const REFERRALS_REGISTER_ADDRESS_DUPLICATED_ERROR =
  'referrals-register-address-duplicated-error'
export const REFERRALS_REGISTER_ADDRESS_NOCOVERED_ERROR =
  'referrals-register-address-nocovered-error'
export const REFERRALS_REGISTER_ADDRESS_NEXT = 'referrals-register-address-next'
export const REFERRALS_REGISTER_OWNER = 'referrals-register-owner'
export const REFERRALS_REGISTER_OWNER_NEXT = 'referrals-register-owner-next'
export const REFERRALS_REGISTER_OWNER_BACK = 'referrals-register-owner-back'
export const REFERRALS_REGISTER_OWNER_ERROR = 'referrals-register-owner-error'
export const REFERRALS_REGISTER_DUPLICATEDOWNER =
  'referrals-register-duplicatedowner'
export const REFERRALS_REGISTER_DUPLICATEDOWNER_BUTTON =
  'referrals-register-duplicatedowner-button'
export const REFERRALS_REGISTER_SEND = 'referrals-register-send'
export const REFERRALS_REGISTER_SEND_DONE = 'referrals-register-send-success'
export const REFERRALS_REGISTER_SEND_ERROR = 'referrals-register-send-error'
export const REFERRALS_REGISTER_NOCOVERED = 'referrals-register-nocovered'
export const REFERRALS_REGISTER_NOCOVERED_BUTTON =
  'referrals-register-nocovered-next'
export const REFERRALS_REGISTER_DUPLICATED = 'referrals-register-duplicated'
export const REFERRALS_REGISTER_DUPLICATED_BUTTON =
  'referrals-register-duplicated-next'
export const REFERRALS_REGISTER_ERROR = 'referrals-register-error'
export const REFERRALS_REGISTER_ERROR_BUTTON = 'referrals-register-error-next'
export const REFERRALS_REGISTER_SUCCESS = 'referrals-register-success'
export const REFERRALS_REGISTER_SUCCESS_BUTTON =
  'referrals-register-success-button'
export const REFERRALS_CTA_CLOSE = 'referrals-cta-close'
export const REFERRALS_CTA_LINK = 'referrals-cta-link'
