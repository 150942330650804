import {useState, useEffect} from 'react'
import logger from '@/lib/logger'
import styles from '@/styles/organisms/Modal/styles.module.css'
import Button from '@/components/shared/Button'
import OfferListingSummary from './ListingSummary'
import Contact from './steps/Contact'
import PaymentMethod from './steps/PaymentMethod'
import SellToBuy from './steps/SellToBuy'
import Offer from './steps/Offer'
import SubmitOffer from './steps/SubmitOffer'

const INITIAL_STATE = {
  contact: null,
  paymentType: null,
  offer: null,
  submitCallback: null,
  index: 0
}

export default function OfferCreate({listingId, initialState}) {
  const [state, setState] = useState(
    Object.assign(INITIAL_STATE, initialState || {})
  )
  const {index, submitCallback, ...stepsState} = state

  const steps = [
    {key: 'contact', Component: Contact},
    {key: 'paymentType', Component: PaymentMethod},
    stepsState.paymentType === 'NEED_TO_SELL'
      ? {key: 'sellToBuy', Component: SellToBuy}
      : null,
    {key: 'offer', Component: Offer},
    {key: 'send', Component: SubmitOffer}
  ].filter((s) => s !== null)

  const {key, Component} = steps[index]
  const isLastStep = index == steps.length - 1

  useEffect(() => logger.action('listing-offer-init', {listingId}), [])
  useEffect(() => logger.action(`listing-offer-${key}-init`, {listingId}), [
    key
  ])

  return (
    <>
      <div className={styles.ecModal__header}>
        <p className={styles.ecModal__header__title}>Proposta</p>
      </div>
      <div className={styles.ecModal__content}>
        {!isLastStep && <OfferListingSummary listingId={listingId} />}
        <Component
          {...stepsState}
          listingId={listingId}
          onChange={({isValid, submit}) => {
            if (Boolean(submitCallback) !== isValid) {
              setState({...state, submitCallback: isValid ? submit : null})
            }
          }}
          onSubmit={(data) => {
            logger.action(`listing-offer-${key}-submit`, {listingId, data})
            setState({
              ...state,
              index: Math.min(index + 1, steps.length - 1),
              submitCallback: null,
              [key]: data
            })
          }}
        />
      </div>
      {!isLastStep && (
        <div className={styles.ecModal__footer}>
          <div className={styles.ecModal__footer__buttons}>
            <Button
              disabled={index === 0}
              onClick={() => {
                setState({
                  ...state,
                  submitCallback: null,
                  index: Math.max(0, index - 1)
                })
              }}
              style={{flex: '0 0 auto'}}
            >
              Voltar
            </Button>
            <Button
              active
              disabled={submitCallback === null}
              onClick={submitCallback}
            >
              Avançar
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
