import Button from '@/components/shared/Button'
import LoadBar from '@/components/shared/LoadBar'
import styles from '@/styles/templates/Listings/styles.module.css'
import logger from '@/lib/logger'

export default function SearchPagination({
  canFetchMore,
  itemsDisplayed,
  itemsCount,
  loading,
  onLoad
}) {
  const progress = (itemsDisplayed / itemsCount) * 100
  return (
    <div className={styles.ecTemplateListings__pagination}>
      <p>
        Você viu{' '}
        <strong>
          {itemsDisplayed > itemsCount ? itemsCount : itemsDisplayed}{' '}
        </strong>{' '}
        de
        <strong>{` ${itemsCount} `}</strong>
        resultados
      </p>
      <div className={styles.ecTemplateListings__pagination__bar}>
        <LoadBar percentage={progress} />
      </div>
      <Button
        small={true}
        onClick={() => {
          onLoad()
          logger.action('listing-search-load-more', {
            itemsDisplayed,
            itemsCount
          })
        }}
        disabled={loading || !canFetchMore || itemsDisplayed >= itemsCount}
      >
        {loading ? 'Carregando...' : 'Ver mais'}
      </Button>
    </div>
  )
}
