import {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Cookie from 'js-cookie'
import {getUtmArrayFromUrl} from '@/lib/utm'
import logger from '@/lib/logger'
import {getAppVersion} from '@/lib/experiments'
import Anchor from '@/components/shared/Anchor'
import Notification from '@/components/shared/Notification'

const NOTIFICATION_COOKIE = 'EmCasaCookieLegal'

const LegalNotification = () => {
  const [isNotificationVisible, showNotification] = useState(false)
  const history = useHistory()
  const isWebview = !!getAppVersion()

  const handleNotificationButtonClick = () => {
    Cookie.set(NOTIFICATION_COOKIE, 'done')
    showNotification(false)
  }

  useEffect(() => {
    const {location} = history
    const utmArray = getUtmArrayFromUrl(location.search)
    if (
      !utmArray.length &&
      !Cookie.get(NOTIFICATION_COOKIE) &&
      !isWebview &&
      (location.pathname.indexOf('/imoveis') === -1 ||
        (location.pathname.indexOf('/imoveis') !== -1 &&
          location.pathname.indexOf('/id-') !== -1))
    ) {
      showNotification(true)
      logger.action('legal-notification')
    }
  }, [])

  return (
    <Notification
      show={isNotificationVisible}
      buttons={[
        {
          active: true,
          label: 'Entendi',
          onClick: handleNotificationButtonClick
        }
      ]}
    >
      <p>
        Usamos cookies para personalizar conteúdos e melhorar a sua experiência.
        Ao navegar neste site, você concorda com a nossa{' '}
        <Anchor to="/legal">Política de Cookies</Anchor>.
      </p>
    </Notification>
  )
}

export default LegalNotification
