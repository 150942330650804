export const validateCity = (value) => value && value.trim().length > 1

export function validateCPF(value) {
  const CPF = value ? value.replace(/\D/g, '') : ''
  var numbers, digits, cpf_add, i, cpf_result, equal_digits
  equal_digits = 1

  if (CPF.length < 11) {
    return false
  }

  for (i = 0; i < CPF.length - 1; i++) {
    if (CPF.charAt(i) != CPF.charAt(i + 1)) {
      equal_digits = 0
      break
    }
  }

  if (!equal_digits) {
    numbers = CPF.substring(0, 9)
    digits = CPF.substring(9)
    cpf_add = 0

    for (i = 10; i > 1; i--) {
      cpf_add += numbers.charAt(10 - i) * i
    }
    cpf_result = cpf_add % 11 < 2 ? 0 : 11 - (cpf_add % 11)

    if (cpf_result != digits.charAt(0)) {
      return false
    }

    numbers = CPF.substring(0, 10)
    cpf_add = 0

    for (i = 11; i > 1; i--) {
      cpf_add += numbers.charAt(11 - i) * i
    }

    cpf_result = cpf_add % 11 < 2 ? 0 : 11 - (cpf_add % 11)
    if (cpf_result != digits.charAt(1)) {
      return false
    }
  } else {
    return false
  }
  return true
}

export const validateCRECI = (value) => value && value.trim().length > 1

export const validateEmail = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

export const validateName = (value) => value && value.trim().length > 1

export const validatePhone = (value) => !!(value && value.length > 13)
