import {useState, useEffect} from 'react'
import inputStyles from '@/styles/atoms/Input/styles.module.css'
import {
  getInputMaskPhoneNumber,
  clearPhoneString,
  addInternationalCode,
  removeInternationalCode
} from '@/lib/format'

function PhoneInput({onChange, onBlur, value, ...rest}) {
  const [phone, setPhone] = useState(
    value ? removeInternationalCode(value) : null
  )

  useEffect(() => {
    if (value) {
      setPhone(removeInternationalCode(value))
    }
  }, [value])

  return (
    <input
      {...rest}
      className={inputStyles.ecInput}
      value={getInputMaskPhoneNumber(phone)}
      onBlur={onBlur}
      onChange={(e) => {
        const cleanPhone = clearPhoneString(e.currentTarget.value).slice(0, 11)
        setPhone(cleanPhone)
        onChange(addInternationalCode(cleanPhone))
      }}
    />
  )
}

PhoneInput.validate = {
  required: (value) => value && value.startsWith('+55') && value.length === 14
}

export default PhoneInput
