import {Link} from 'react-router-dom'
import {getClassName, getChildren} from './'

const ButtonLink = ({
  children,
  active,
  dark,
  selected,
  circle,
  rounded,
  icon,
  noBorder,
  anchor,
  small,
  flipIcon,
  disabled,
  className,
  href,
  to,
  ...props
}) => {
  const _className = getClassName({
    circle,
    rounded,
    small,
    active,
    dark,
    noBorder,
    anchor,
    selected,
    disabled,
    className
  })
  const childrenElement = getChildren({icon, children, circle, small, flipIcon})
  if (href) {
    return (
      <a href={href} className={_className} {...props}>
        {childrenElement}
      </a>
    )
  }

  return (
    <Link {...props} to={to} className={_className}>
      {childrenElement}
    </Link>
  )
}

export default ButtonLink
