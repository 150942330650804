export default {
  default: require('@fortawesome/pro-solid-svg-icons/faQuestionCircle')
    .definition,
  times: require('@fortawesome/pro-solid-svg-icons/faTimes').definition,
  'sign-out-alt': require('@fortawesome/pro-solid-svg-icons/faSignOutAlt')
    .definition,
  'chevron-up': require('@fortawesome/pro-regular-svg-icons/faChevronUp')
    .definition,
  'chevron-down': require('@fortawesome/pro-regular-svg-icons/faChevronDown')
    .definition,
  'chevron-left': require('@fortawesome/pro-regular-svg-icons/faChevronLeft')
    .definition,
  'chevron-right': require('@fortawesome/pro-regular-svg-icons/faChevronRight')
    .definition,
  'exclamation-circle': require('@fortawesome/pro-regular-svg-icons/faExclamationCircle')
    .definition,
  images: require('@fortawesome/pro-regular-svg-icons/faImages').definition,
  tags: require('@fortawesome/pro-regular-svg-icons/faTags').definition,
  search: require('@fortawesome/pro-regular-svg-icons/faSearch').definition,
  heart: require('@fortawesome/pro-solid-svg-icons/faHeart').definition,
  'eye-slash': require('@fortawesome/pro-solid-svg-icons/faEyeSlash')
    .definition,
  check: require('@fortawesome/pro-regular-svg-icons/faCheck').definition,
  plus: require('@fortawesome/pro-regular-svg-icons/faPlus').definition,
  'map-maker-alt': require('@fortawesome/pro-solid-svg-icons/faMapMarkerAlt')
    .definition
}
