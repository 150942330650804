// Home
export const BUYER_LANDING_PAGE = 'buyer-landing-page'
export const BUYER_LANDING_SELECT_CHANGE = 'buyer-landing-select-change'
export const BUYER_LANDING_SELECT_OPEN = 'buyer-landing-select-open'
export const BUYER_LANDING_SEARCH_CTA = 'buyer-landing-search-cta'
export const SELLER_CTA_LINK = 'seller-cta-link'
export const BUYER_LANDING_SEARCH_CTA_LINK = 'buyer-landing-search-cta-link'
export const BUYER_LANDING_YOU_CLICK = 'buyer-landing-you-click'
export const BUYER_LANDING_YOU_CHANGE = 'buyer-landing-you-change'
export const BUYER_LANDING_YOU_CTA = 'buyer-landing-you-cta'
export const BUYER_LANDING_FEED_LIST_BUTTON = 'buyer-landing-feed-list-button'
export const BUYER_LANDING_PARTNERBROKER_CTA_LINK =
  'buyer-landing-partnerbroker-cta-link'
export const BUYER_LANDING_PARTNER_CTA_LINK = 'buyer-landing-partner-cta-link'
