import gql from 'graphql-tag'

export default gql`
  mutation offerCreate($input: OfferCreateInput!) {
    offerCreate(input: $input) {
      uuid
      credentials {
        jwt
        user {
          id
          name
        }
      }
    }
  }
`
