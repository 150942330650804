import compose from 'recompose/compose'
import withUserProfile from '@/lib/hoc/withUserProfile'
import Shell from '@/components/layout/templates/Default'
import Spinner from '@/components/layout/Spinner'
import Tabs from '@/components/shared/Tabs'
import ResultLogin from '@/components/shared/Result/Login'
import styles from '@/styles/templates/Profile/styles.module.css'
import resultStyles from '@/styles/molecules/Result/styles.module.css'

const HUB_ITEMS = [
  {label: 'Meus dados', pathname: '/perfil/dados'},
  {label: 'Visitas', pathname: '/interesses/visitas'},
  {label: 'Propostas', pathname: '/interesses/propostas'},
  {label: 'Minhas indicações', pathname: '/perfil/minhas-indicacoes'},
  {label: 'Meus imóveis', pathname: '/meus-imoveis'}
]

function HubShell({children, noFooter, userProfile}) {
  return (
    <Shell withBottomBar noFooter={noFooter || true}>
      <Tabs items={HUB_ITEMS} />
      {userProfile !== undefined ? (
        userProfile ? (
          <div className={styles.ecTemplateProfile}>{children}</div>
        ) : (
          <ResultLogin />
        )
      ) : (
        <div className={resultStyles.ecTemplateListings__result}>
          <Spinner />
        </div>
      )}
    </Shell>
  )
}

export default compose(withUserProfile)(HubShell)
