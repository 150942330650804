import {useCallback, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import {setNav, setBackButton} from '@/redux/modules/layout/actions'
import {updateStepLocation} from '@/redux/modules/you/actions'
import {getYouPropsFromFilter} from '@/components/you/lib'
import {buildListingsCanonicalUrl} from '@/lib/listingsUrl'
import {getListingsDescription} from '@/lib/listings'
import logger from '@/lib/logger'
import * as format from '@/lib/format'
import {getSha256Data} from '@/lib/user'
import {CARD_CLICK_LOG} from '@/components/listings/Card/constants'
import HeadHelmet from '@/components/shared/HeadHelmet'
import YouCTA from '@/components/shared/YouCTA'
import LandingSection from '@/components/shared/LandingSection'
import {LazyImageProvider} from '@/components/shared/LazyImage'
import ListingsGrid from '@/components/shared/ListingsGrid'
import EnableNotification from '@/components/shared/EnableNotification'
import ListingsShell from '../Shell'
import SearchHeader, {getTitle} from '../Shell/SearchHeader'
import SearchFilters from '../Shell/SearchFilters'
import SearchOrder from '../Shell/SearchOrder'
import SearchPagination from '../Shell/SearchPagination'
import ListingsMap from '@/components/listings/ListingsMap'
import Filters from '@/components/listings/Filters'
import {getDeviceType} from '@/lib/device'
import {metatagImage} from '@/pages/listings'
import EmptySearch from './components/EmptySearch'
import ScrollTopButton from './components/ScrollTopButton'
import classNames from 'classnames'
import styles from '@/styles/templates/Listings/styles.module.css'

export const SPECIALIST_QEV_NOTIFICATION_COOKIE =
  'EmCasaCookieSpecialistQEVNotification'
import {PAGE_SIZE} from '../'
const EMBED_PARAM = 'isembed'
const loggerAction = {
  mapopen: 'listing-search-map-open',
  open: 'listing-search-open',
  notfound: 'listing-search-not-found',
  results: 'listing-search-results',
  embedCard: 'listing-search-embed-card',
  embedButton: 'listing-search-embed-button',
  cardActions: (isEmbed) => ({
    likeAction: 'listing-search-favorite-listing',
    dislikeAction: 'listing-search-hide-listing',
    cardClickAction: isEmbed ? 'listing-search-embed-card' : CARD_CLICK_LOG
  })
}

/**
 * Provides loading state from an async "fetchMore" callback.
 */
function useFetchMore({fetchMore, loading: initialLoading}) {
  const [loading, setLoading] = useState(initialLoading)
  useEffect(() => setLoading(initialLoading), [initialLoading])
  const onLoad = useCallback(async () => {
    if (loading) return
    setLoading(true)
    await fetchMore()
    setLoading(false)
  }, [loading])
  return [loading, onLoad]
}

function openFilters(dispatch, setFilters, filters) {
  dispatch(
    setNav(
      (props) => (
        <Filters
          onSubmit={setFilters}
          onClear={() => {
            const {isMap = false, citiesSlug} = filters
            setFilters({isMap, citiesSlug})
          }}
          initialValue={filters}
          {...props}
        />
      ),
      {navFromRight: true}
    )
  )
}

function SearchListings({
  loading: loading_,
  setFilters,
  setOrderBy,
  orderBy,
  listings,
  filters,
  canFetchMore,
  fetchMore,
  itemsDisplayed,
  itemsCount,
  districts,
  userProfile,
  geolocation,
  updateStepLocation
}) {
  const dispatch = useDispatch()
  const location = useLocation()
  const isEmbed = Boolean(location.search.indexOf(EMBED_PARAM) !== -1)

  const {isDesktop} = getDeviceType()

  const cardTarget = isDesktop || isEmbed ? '_blank' : null

  let searchedParams = filters?.citiesSlug
    ? districts.find((item) => item.citySlug === filters.citiesSlug[0])
    : null

  if (filters?.neighborhoods?.length === 1) {
    searchedParams = districts.find(
      (item) => item.name === filters.neighborhoods[0]
    )
  }

  const [loading, onLoad] = useFetchMore({
    loading: loading_,
    fetchMore: () => {
      return fetchMore()
    }
  })

  const {isMap} = filters

  const isNeighborhoodSingle = () =>
    filters?.neighborhoods?.length === 1 && listings[0]?.address

  const getType = () => (filters?.types?.length === 1 ? filters.types[0] : null)

  const getCity = () => searchedParams?.city

  const getNeighborhood = () =>
    filters?.neighborhoods?.length === 1 ? filters.neighborhoods[0] : null

  const getStreet = () =>
    filters?.streetSlug ? format.nameFromSlug(filters.streetSlug) : null

  const getCanonical = () => {
    return searchedParams
      ? buildListingsCanonicalUrl(searchedParams, filters, districts)
      : '/'
  }

  const getNeighborhoodTitle = () =>
    isNeighborhoodSingle() && listings[0].address.neighborhood
      ? `Conheça ${listings[0].address.neighborhood}`
      : null

  const getNeighborhoodDescription = () =>
    isNeighborhoodSingle() && listings[0].address.neighborhoodDescription
      ? listings[0].address.neighborhoodDescription
      : null

  const searchClasses = classNames(styles.ecTemplateListings__search, {
    [styles.ecTemplateListings__search_isembed]: isEmbed
  })

  const embedListingsGridButton = {
    label: 'Ver mais imóveis',
    to: {
      pathname: `https://emcasa.com${
        location.pathname
      }${location.search.replace(EMBED_PARAM, '') || ''}`
    },
    onClick: () => logger.action(loggerAction.embedButton)
  }

  const onChangeFilters = useCallback((filters) => {
    setFilters(filters)
  })

  const onChangeOrder = useCallback((order = []) => {
    setOrderBy(order)
  })

  useEffect(() => {
    if (location.state && location.state.scroll === 0) {
      window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    logger.action(isMap ? loggerAction.mapopen : loggerAction.open)
  }, [isMap])

  useEffect(() => {
    if (loading) {
      return
    }
    if (listings.length === 0) {
      logger.action(loggerAction.notfound, {isMap, filters})
    } else {
      const loggerProps = {
        listings,
        isMap,
        filters,
        itemsDisplayed,
        itemsCount
      }
      if (userProfile && (userProfile.phone || userProfile.email)) {
        const user = getSha256Data(userProfile)
        logger.action(loggerAction.results, {
          ...loggerProps,
          ...user
        })
      } else {
        logger.action(loggerAction.results, loggerProps)
      }

      dispatch(
        setBackButton({
          label: 'Voltar para a busca',
          to: null,
          icon: '/img/icons/arrow-x-black.svg',
          flipIcon: true
        })
      )
    }
  }, [listings, loading])

  useEffect(() => {
    if (!userProfile?.requested_service) {
      const youProps = getYouPropsFromFilter({filters, districts})
      updateStepLocation({...youProps?.stepLocation})
    }
  }, [filters, districts])

  return (
    <ListingsShell
      noHeader={isEmbed}
      noFooter={isMap || isEmbed}
      noLogin={isEmbed}
      noModal={isEmbed}
      noSeo={isEmbed}
      noLegalNotification={isEmbed}
      filters={filters}
    >
      <HeadHelmet
        title={`${getTitle(searchedParams, filters)} ${
          searchedParams && searchedParams.stateSlug
            ? ` - ${searchedParams.stateSlug.toUpperCase()} | EmCasa`
            : ''
        }`}
        description={getListingsDescription({
          type: getType(),
          city: getCity(),
          neighborhood: getNeighborhood(),
          street: getStreet()
        })}
        image={metatagImage}
        url={`https://emcasa.com${location.pathname}${location.search || ''}`}
      >
        <link rel="canonical" href={`https://emcasa.com${getCanonical()}`} />
      </HeadHelmet>
      {!isMap && (
        <EnableNotification
          wrapperClass={styles.ecTemplateListings__enableNotification}
        />
      )}
      {!isEmbed && searchedParams && (
        <SearchHeader
          city={searchedParams}
          filters={filters}
          isMap={isMap}
          itemsCount={itemsCount}
          setFilters={onChangeFilters}
          onChangeOrder={onChangeOrder}
        />
      )}
      {!isEmbed && filters && (
        <SearchFilters
          value={filters}
          isMap={isMap}
          openFilters={() => {
            openFilters(dispatch, setFilters, filters)
          }}
          onChange={setFilters}
        />
      )}
      {!isEmbed && !isMap && listings?.length > 0 && (
        <SearchOrder value={orderBy} onChange={onChangeOrder} />
      )}
      {!isEmbed && !loading && listings?.length === 0 ? (
        <EmptySearch
          userProfile={userProfile}
          searchedParams={searchedParams}
          filters={filters}
          openFilters={() => {
            openFilters(dispatch, setFilters, filters)
          }}
        />
      ) : (
        <LazyImageProvider>
          {!isEmbed && isMap ? (
            <ListingsMap
              listings={listings}
              loading={loading_}
              filters={filters}
              geolocation={geolocation}
            />
          ) : (
            <div className={searchClasses}>
              <ListingsGrid
                noLazy
                noPadding={isEmbed}
                scroll={isEmbed}
                listings={listings}
                loading={loading_}
                cardActions={loggerAction.cardActions(isEmbed)}
                cardTarget={cardTarget}
                cta={
                  isEmbed || (userProfile && userProfile.completedAboutYou)
                    ? null
                    : {
                        component: <YouCTA />,
                        position: PAGE_SIZE - 4
                      }
                }
                button={isEmbed ? embedListingsGridButton : null}
              />
            </div>
          )}
        </LazyImageProvider>
      )}
      {!isEmbed && !isMap && itemsCount && itemsDisplayed ? (
        <>
          <SearchPagination
            canFetchMore={canFetchMore}
            itemsCount={itemsCount}
            itemsDisplayed={itemsDisplayed}
            loading={loading}
            onLoad={onLoad}
          />
          <ScrollTopButton />
        </>
      ) : null}
      {!isEmbed && getNeighborhoodDescription() && (
        <LandingSection
          reverse={true}
          label="O bairro"
          title={getNeighborhoodTitle()}
          contents={[
            <p style={{columns: '2 448px'}}>{getNeighborhoodDescription()}</p>
          ]}
        />
      )}
    </ListingsShell>
  )
}

export default connect(null, {setBackButton, updateStepLocation})(
  SearchListings
)
