import PropTypes from 'prop-types'
import Button from '@/components/shared/Button'
import ButtonLink from '@/components/shared/Button/Link'
import styles from '@/styles/molecules/Notification/styles.module.css'

const Notification = ({children, buttons, show}) => {
  return show ? (
    <div className={styles.ecNotification}>
      <div className={styles.ecNotification__container}>
        <div className={styles.ecNotification__content}>{children}</div>
        {buttons && (
          <div className={styles.ecNotification__buttons}>
            {buttons.map((item, index) =>
              item.to || item.href ? (
                <ButtonLink
                  key={index}
                  small
                  active={item.active}
                  to={item.to}
                  href={item.href}
                  target={item.target}
                  className={styles.ecNotification__button}
                  onClick={item.onClick}
                >
                  {item.label}
                </ButtonLink>
              ) : (
                <Button
                  key={index}
                  small
                  active={item.active}
                  className={styles.ecNotification__button}
                  onClick={item.onClick}
                >
                  {item.label}
                </Button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  ) : null
}

Notification.propTypes = {
  show: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      active: PropTypes.bool,
      target: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      href: PropTypes.string,
      onClick: PropTypes.func
    })
  )
}

export default Notification
