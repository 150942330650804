import {Component} from 'react'

export default class OriginRoute extends Component {
  componentDidMount() {
    const {protocol, hostname, search} = window.location
    const qs = search ? `&${search.substring(1)}` : ''
    const host = window.location.port
      ? `${hostname}:${window.location.port}`
      : hostname
    if (!search.includes('nocache=sw')) {
      window.location.href = `${protocol}//${host}${this.props.location.pathname}?nocache=sw${qs}`
    }
  }

  render() {
    return null
  }
}
