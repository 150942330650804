import React from 'react'
import Button from '@/components/form/Button'
import SharedButton from '@/components/shared/Button'
import styles from '@/styles/organisms/Filters/styles.module.css'

const formatLabel = (emptyStr) => (value) => (value ? `${value}+` : emptyStr)

/**
 * Generic filter of min/max rooms of a given type
 */
function RoomsFilter({min, max, formatLabel, initialValue, onChange, value}) {
  return (
    <div className={styles.ecFilters__filter__buttons}>
      <Button.Group
        strategy="switchable"
        initialValue={initialValue}
        onChange={onChange}
      >
        {Array(max - 1)
          .fill(null)
          .map((_, val) => (
            <SharedButton
              selected={value === val + min}
              small
              key={val}
              value={val + min}
            >
              {formatLabel(val + min)}
            </SharedButton>
          ))}
      </Button.Group>
    </div>
  )
}

RoomsFilter.defaultProps = {
  min: 1,
  max: 5,
  formatLabel: formatLabel('')
}

const PureRoomsFilter = React.memo(RoomsFilter)

export const BedroomsFilter = (props) => <PureRoomsFilter {...props} />

export const BathroomsFilter = (props) => <PureRoomsFilter {...props} />

export const SuitesFilter = (props) => <PureRoomsFilter {...props} />

export const GarageSpotsFilter = (props) => (
  <PureRoomsFilter min={0} formatLabel={formatLabel('Sem vagas')} {...props} />
)
