import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '@/styles/organisms/LandingSection/styles.module.css'
import composesStyles from '@/styles/_settings/composes.module.css'

const LandingSection = ({
  greyBg,
  nudeBg,
  reverse,
  noMaxWidth,
  removeContentsPadding,
  textAlignCenter,
  label,
  title,
  contents,
  contentLeft,
  contentRight
}) => {
  return (
    <div
      className={classNames(styles.ecLandingSection, {
        [styles.ecLandingSection_greyBg]: greyBg,
        [styles.ecLandingSection_nudeBg]: nudeBg
      })}
    >
      <div
        className={classNames(styles.ecLandingSection__container, {
          [styles.ecLandingSection__container_reverse]: reverse,
          [styles.ecLandingSection__container_contentLeft]: contentLeft,
          [styles.ecLandingSection__container_contentRight]: contentRight,
          [styles.ecLandingSection__container_noMaxWidth]: noMaxWidth
        })}
      >
        <div
          className={classNames(styles.ecLandingSection__content, {
            [composesStyles.textAlignCenter]: textAlignCenter
          })}
        >
          {(label || title) && (
            <div
              className={classNames(styles.ecLandingSection__header, {
                [styles.ecLandingSection__header_left]:
                  contentLeft || contentRight
              })}
            >
              {label && (
                <h1 className={styles.ecLandingSection__header__label}>
                  {label}
                </h1>
              )}
              {title && (
                <h2
                  className={classNames(
                    styles.ecLandingSection__header__title,
                    {
                      [styles.ecLandingSection__header__title_left]:
                        contentLeft || contentRight
                    }
                  )}
                >
                  {title}
                </h2>
              )}
            </div>
          )}
          {contents &&
            contents.map((item, index) => (
              <div
                key={index}
                className={classNames(styles.ecLandingSection__content__item, {
                  [styles.ecLandingSection__content__item_noPadding]: removeContentsPadding
                })}
              >
                {item}
              </div>
            ))}
        </div>
        {contentLeft || contentRight ? (
          <div className={styles.ecLandingSection__subcontent}>
            {contentLeft || contentRight}
          </div>
        ) : null}
      </div>
    </div>
  )
}

LandingSection.propTypes = {
  greyBg: PropTypes.bool,
  nudeBg: PropTypes.bool,
  reverse: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
  removeContentsPadding: PropTypes.bool,
  textAlignCenter: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contents: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ),
  contentLeft: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  contentRight: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default LandingSection
