import {useState, useEffect} from 'react'
import {useMutation} from '@apollo/react-hooks'
import ABOUT_YOU_BUYER_LEAD_CREATE from '@/graphql/mutations/aboutYouBuyerLeadCreate'
import TALK_WITH_SPECIALIST_BUYER_LEAD from '@/graphql/mutations/talkWithSpecialistBuyerLead'
import Cookie from 'js-cookie'
import logger from '@/lib/logger'
import {addInternationalCode} from '@/lib/format'
import {getUTMObject} from '@/lib/utm'
import {haveSubway} from '@/lib/cities'
import {getSha256Data} from '@/lib/user'
import LoadingText from '@/components/shared/LoadingText'
import {SPECIALIST_QEV_NOTIFICATION_COOKIE} from '@/pages/listings/SearchListings'

export const YOU_HOME_TYPES = {
  apartamento: 'APARTMENT',
  casa: 'HOUSE'
}

const loggerActions = {
  error: 'you-setup-send-error',
  success: 'you-setup-send-success',
  send: 'you-setup-send',
  specialistError: 'you-setup-specialist-send-error',
  specialistSuccess: 'you-setup-specialist-send-success',
  specialistSend: 'you-setup-specialist-send'
}

function Send({
  navigateTo,
  stepLocation,
  stepPreferences,
  stepType,
  stepCondo,
  stepPrice,
  stepDetails,
  stepProfile,
  stepBuyerStage,
  stepPaymentType,
  userProfile,
  stepSuccess,
  stepError,
  isLSS,
  loggerAction
}) {
  const [logProps, setLogProps] = useState({
    currentUser: userProfile,
    lss: isLSS,
    isLSS,
    input: {}
  })

  const [createAboutYouLead, {called, error, data}] = useMutation(
    ABOUT_YOU_BUYER_LEAD_CREATE
  )

  const [
    talkWithSpecialistBuyerLead,
    {called: specialistCalled, error: specialistError, data: specialistData}
  ] = useMutation(TALK_WITH_SPECIALIST_BUYER_LEAD)

  useEffect(() => {
    if (error) {
      logger.capture(error)
      loggerAction(loggerActions.error, logProps)
      navigateTo(stepError)
    }
  }, [error])

  useEffect(() => {
    if (data && data.aboutYouBuyerLeadCreate) {
      loggerAction(loggerActions.success, logProps)
      navigateTo(stepSuccess)
    }
  }, [data])

  useEffect(() => {
    if (!userProfile?.completedAboutYou && !called) {
      const listingTypes = stepType.types.map((item) => YOU_HOME_TYPES[item])
      const utmObject = getUTMObject()
      let input = {
        city: stepLocation.city,
        elevator: stepPreferences.hasElevator === 'sim' ? true : null,
        listingTypes,
        maxMaintenanceFee: stepCondo.maintenanceFee,
        maxValue: stepPrice.price,
        minBathrooms: stepDetails.bathrooms,
        minGarageSpots: stepDetails.garageSpots,
        minRooms: stepDetails.rooms,
        name: stepProfile.name,
        nearbySubway:
          haveSubway(stepLocation.citySlug) &&
          stepPreferences.nearSubway === 'sim'
            ? true
            : null,
        neighborhoods:
          stepLocation.selectedDistricts &&
          stepLocation.selectedDistricts.length
            ? stepLocation.selectedDistricts.reduce((a, c) => {
                a.push(c.name)
                return a
              }, [])
            : null,
        origin: isLSS ? 'TALK_WITH_SPECIALIST' : 'ABOUT_YOU',
        phoneNumber: addInternationalCode(stepProfile.phone),
        email: stepProfile.email || null,
        state: stepLocation.state,
        buyerStage: stepBuyerStage.buyerStage,
        paymentType: stepPaymentType.paymentType,
        tags: [],
        utm: utmObject
      }
      logger.identify(userProfile)

      setLogProps({
        ...logProps,
        input: input
      })

      const loggerProps = {
        ...logProps,
        input
      }
      if (userProfile && (userProfile.phone || userProfile.email)) {
        const user = getSha256Data(userProfile)
        loggerAction(loggerActions.send, {
          ...loggerProps,
          ...user
        })
      } else {
        loggerAction(loggerActions.send, loggerProps)
      }
      createAboutYouLead({variables: {input}})
    }
  }, [called])

  useEffect(() => {
    if (specialistError) {
      logger.capture(specialistError)
      loggerAction(loggerActions.specialistError, logProps)
      navigateTo(stepError)
    }
  }, [specialistError])

  useEffect(() => {
    if (specialistData?.talkWithSpecialistBuyerLead?.message === 'ok') {
      Cookie.set(SPECIALIST_QEV_NOTIFICATION_COOKIE, 'done')
      loggerAction(loggerActions.specialistSuccess, logProps)
      navigateTo(stepSuccess)
    }
  }, [specialistData])

  useEffect(() => {
    if (userProfile?.completedAboutYou && !specialistCalled) {
      talkWithSpecialistBuyerLead()
      loggerAction(loggerActions.specialistSend, logProps)
    }
  }, [specialistCalled])

  return (
    <LoadingText
      sentences={['Enviando os seus dados...', 'Aguarde um momento...']}
    />
  )
}

export default Send
