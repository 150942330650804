import {useState} from 'react'
import PropTypes from 'prop-types'
import flow from 'lodash/fp/flow'
import Col from '@emcasa/ui-dom/components/Col'
import * as format from '@/lib/format'
import Input from './Input'
import Slider from './Slider'
import Label from '@/components/shared/Label'

const id = (x) => x

const clamp = (limit) => (value) => {
  const min = Math.max(limit[0], Math.min(limit[1], value.min))
  const max = Math.max(limit[0], Math.min(limit[1], value.max))
  if (max > min) return {min, max}
  if (min > 0) return {min: min - 1, max: min}
  return {min, max: min + 1}
}
const parseNum = (x) => parseInt(String(x).replace(/[^\d]/g, '') || '0')
const formatNum = (num) => {
  if (!num || isNaN(num)) return '0'
  return format.number(num)
}
const mapRange = (fun) => ({min, max} = {}) => ({min: fun(min), max: fun(max)})

export function SliderInput({
  range,
  value: _value,
  initialValue,
  step = 1,
  limit = range,
  onChange: _onChange,
  format: _format,
  loggerAction,
  removeInputFormat
}) {
  if (initialValue) {
    if (isNaN(initialValue.min)) {
      initialValue.min = limit[0]
    }
    if (isNaN(initialValue.max)) {
      initialValue.max = limit[1]
    }
  }
  const [inputInteraction, setInputInteraction] = useState(false)
  const value = typeof _value === 'undefined' ? initialValue : _value
  const format = flow(formatNum, _format || id)
  const onChange = flow(mapRange(parseNum), _onChange)
  const onChangeValidate = flow(mapRange(parseNum), clamp(limit), _onChange)
  const inputVal = removeInputFormat ? value : mapRange(format)(value)
  const isValid =
    !isNaN(value.min) &&
    !isNaN(value.max) &&
    value.min >= limit[0] &&
    value.max <= limit[1]

  function onInputChange(e) {
    setInputInteraction(true)
    onChange(e)
  }

  function onInputBlur(e) {
    setInputInteraction(false)
    onChangeValidate(e)
  }

  function onSliderChange(e) {
    if (!inputInteraction) {
      onChange(e)
    }
  }

  return (
    <>
      <Input
        isValid={isValid}
        value={inputVal}
        onBlur={onInputBlur}
        onChange={onInputChange}
        loggerAction={loggerAction}
      />
      <Col px={2}>
        <Slider
          step={step}
          range={range}
          value={clamp(range)(value)}
          onChange={onSliderChange}
        />
      </Col>
      <Label min={format(range[0])} max={format(range[1])} />
    </>
  )
}

const Range = PropTypes.shape({
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
})

SliderInput.propTypes = {
  value: Range,
  initialValue: Range,
  step: PropTypes.number,
  /**
   * [min, max] range of the slider component.
   */
  range: PropTypes.arrayOf(PropTypes.number),
  /**
   * [min, max] allowed values of the input. Values inserted manually on the
   * input are restricted to this range. Defaults to the same value as `range`.
   */
  limit: PropTypes.arrayOf(PropTypes.number),
  /**
   * Takes the numeric input value and formats it for display.
   */
  format: PropTypes.func,
  onChange: PropTypes.func,
  removeInputFormat: PropTypes.bool
}

export default function ControlledSliderInput(props) {
  if (typeof props.value !== 'undefined') return <SliderInput {...props} />
  const [value, setValue] = useState(
    props.initialValue || {min: props.range[0], max: props.range[1]}
  )
  const onChange = (value) => {
    setValue(value)
    if (!props.onChange) return
    if (value.min === props.range[0] && value.max === props.range[1])
      props.onChange(undefined)
    else props.onChange(value)
  }
  return <SliderInput {...props} value={value} onChange={onChange} />
}

ControlledSliderInput.propTypes = SliderInput.propTypes
