import * as Sentry from 'isomorphic-sentry'

export function init() {
  const environment = process.env.NODE_ENV
  Sentry.init({
    environment,
    enabled: Boolean(process.env.SENTRY_DSN),
    debug: environment === 'development',
    dsn: process.env.SENTRY_DSN,
    release: process.env.SENTRY_RELEASE,
    ignoreErrors: [
      'maps-api-v3',
      'WebGLRenderingContext',
      // https://github.com/getsentry/sentry/issues/5267
      'Blocked a frame with origin'
    ]
  })
  if (process.browser) Sentry.forceLoad()
}

export function identify(user) {
  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser(user)
    })
  }
}

export function log(message, {category, level = 'info', ...data} = {}) {
  Sentry.addBreadcrumb({
    message,
    category,
    level,
    data
  })
}

export function capture(error, ...args) {
  let [errorInfo = {}, callback] = args
  if (typeof args[0] === 'function') {
    errorInfo = {}
    callback = args[0]
  }
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key])
    })
    if (callback) callback(scope)
    Sentry.captureException(error)
  })
}
