import React from 'react'
import Cookie from 'js-cookie'
import {loadableReady} from '@loadable/component'
import {requestPushNotificationToken} from '@/lib/pushNotification'
import {createBrowserHistory} from 'history'
import ReactDOM from 'react-dom'
import {createContext} from '@/pages/context'
import logger from '@/lib/logger'
import {saveUtm, getUtmArrayFromUrl} from '@/lib/utm'
import App from './App'
import {isApp} from '@/lib/device'

window.__initialState = window.__initialState || {}
window.onRegisterSW = (registration) => {
  if (isApp()) {
    requestPushNotificationToken(registration)
  }
}

const getRootElement = () => document.getElementById('root')
const history = createBrowserHistory({})
const ctx = createContext(
  {history, cookies: Cookie.get()},
  window.__initialState
)
const useRoutesFromOrigin = window.__initialState.useRoutesFromOrigin

const AppWithContext = () => (
  <App useRoutesFromOrigin={useRoutesFromOrigin} {...ctx} />
)
if (
  process.env.NODE_ENV === 'development' &&
  process.env.DEBUG_RENDER === 'true'
) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackHooks: true,
    trackAllPureComponents: true,
    collapseGroups: true
  })
}

const utmArray = getUtmArrayFromUrl(location.search)
saveUtm(utmArray)
logger.init(ctx)

const initApplication = () => {
  const root = getRootElement()
  if (!root) {
    if (document.readyState === 'complete') {
      throw new Error('DOM is ready but root element not found')
    }
    window.addEventListener('DOMContentLoaded', initApplication)
    return
  }
  loadableReady(() => {
    ReactDOM.hydrate(<AppWithContext />, root)
  })
}

initApplication()

if (module.hot) {
  const root = getRootElement()
  module.hot.accept('../redux/store.js', () => {
    ctx.store = require('../redux/store').default(
      ctx,
      window.__initialState.redux
    )
    ReactDOM.render(<AppWithContext />, root)
  })
  module.hot.accept(() => ReactDOM.render(<App />, root))
}
