import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import Row from '@emcasa/ui-dom/components/Row'
import Col from '@emcasa/ui-dom/components/Col'
import Text from '@emcasa/ui-dom/components/Text'
import {breakpoint} from '@emcasa/ui/lib/styles'

const BAR_WRAPPER_HEIGHT = 50
const BARCONTAINER_MINHEIGHT_DESKTOP = 120

export const Container = styled.div`
  overflow: hidden;
  width: 100%;

  h3 {
    margin: 0 0 ${themeGet('space.2')}px;
  }

  .legalText {
    font-size: 12px;
  }
`

export const Subtitle = styled(Text)`
  font-size: ${themeGet('fontSizes.1')}px;
  margin: 0 0 ${themeGet('space.1')}px;

  @media screen and ${breakpoint.up('desktop')} {
    display: ${({hideondesktop}) => (hideondesktop ? 'none' : null)};
  }
`

export const SliderContainer = styled.div`
  padding: ${themeGet('space.4')}px ${themeGet('space.3')}px
    ${themeGet('space.3')}px;
  box-sizing: border-box;
  border: 1px solid
    ${({error}) =>
      error ? themeGet('colors.red500') : themeGet('colors.grey100')};
  border-radius: 4px;
  transition: border 0.3s;

  > div {
    margin: ${themeGet('space.4')}px 0 ${themeGet('space.2')}px;
  }

  p {
    margin: 0;
    transition: color 0.3s;
  }
`

export const TermContainer = styled(Col)`
  button {
    flex: 1;
    margin: 0 ${themeGet('space.2')}px 0 0;
    padding: 0 ${themeGet('space.2')}px;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const BarContainer = styled(Row)`
  margin: 0 0 ${themeGet('space.2')}px;

  @media screen and ${breakpoint.up('desktop')} {
    flex-direction: column-reverse;
    flex: 1 1 50%;
    width: 50%;
    min-height: ${BARCONTAINER_MINHEIGHT_DESKTOP}px;
    margin-bottom: 0;
  }

  p {
    margin: 0;
    line-height: 1.3;
  }
`

export const Bar = styled.div`
  width: ${({error, percentageWidth}) => (error ? '2' : percentageWidth)}%;
  height: ${BAR_WRAPPER_HEIGHT}px;
  margin: 0 ${themeGet('space.2')}px 0 0;
  background-color: ${({error}) =>
    error ? themeGet('colors.grey100') : themeGet('colors.grey300')};
  transition: width 0.75s cubic-bezier(0.4, 0.2, 0, 1)
    ${({delay}) => (delay ? delay : '0')}s;

  @media screen and ${breakpoint.up('desktop')} {
    width: 100%;
    height: ${({error, percentageWidth}) =>
      error ? '10' : percentageWidth * 1.5}%;
    transition-property: height;
  }
`

export const PaymentValue = styled.strong`
  display: block;
  color: ${({error}) =>
    error ? themeGet('colors.grey300') : themeGet('colors.grey900')};
  font-weight: ${themeGet('fontWeights.2')};
  font-size: ${themeGet('fontSizes.2')}px;
`

export const SuggestText = styled.div`
  margin: ${themeGet('space.2')}px 0;
  text-align: center;

  p {
    width: 100%;
    margin: 0;
    padding: ${themeGet('space.1')}px ${themeGet('space.3')}px;
    box-sizing: border-box;
    border-radius: 14px;
    background-color: ${themeGet('colors.grey100')};
    color: ${themeGet('colors.grey900')};
    text-align: center;
  }

  strong {
    margin-left: ${themeGet('space.1')}px;
  }
`
