import styles from '@/styles/atoms/Loader/styles.module.css'

const Loader = () => (
  <span className={styles.ecLoader}>
    <img
      className={styles.ecLoader__icon}
      src="/img/icons/loader.svg"
      alt="Ícone de carregamento"
    />
  </span>
)

export default Loader
