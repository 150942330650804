import {memo} from 'react'
import styles from './styles.module.css'
import classNames from 'classnames'

function formatPrice(price) {
  const f = (n) => {
    if (n >= 1000000) {
      return (n / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (n >= 1000) {
      return (n / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return n
  }
  return `R$ ${f(price)}`
}

function MapMarker({price, selected, onClick, children}) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      className={classNames({
        [styles.ecMap__marker__container__selected]: selected
      })}
    >
      <button
        className={classNames(styles.ecMap__marker, {
          [styles.ecMap__marker__selected]: selected
        })}
      >
        <span className={styles.ecMap__markerLabel}>{formatPrice(price)}</span>
      </button>
      {children}
    </div>
  )
}

export default memo(MapMarker)
