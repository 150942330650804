import {memo} from 'react'
import compose from 'recompose/compose'
import {graphql} from 'react-apollo'
import withSearch from '@/lib/hoc/withSearch'
import withPagination from '@/lib/hoc/withPagination'
import withOrderBy from '@/lib/hoc/withOrderBy'
import withGeolocation from '@/lib/hoc/withGeolocation'
import withUserProfile from '@/lib/hoc/withUserProfile'
import SEARCH_LISTINGS from '@/graphql/queries/searchListings'
import {searchMapping, formatGraphQLFilters} from '@/lib/searchFilters'
import SearchListings from './SearchListings'

export const metatagImage = `${process.env.CLOUDINARY_IMAGE_URL}/share/share-3`
export const PAGE_SIZE = 12
const LISTINGS_KEY = 'searchListings'

export default compose(
  withPagination('pagination', LISTINGS_KEY, PAGE_SIZE, (key, a, b) => ({
    [key]: {
      ...b[key],
      listings: [...a[key].listings, ...b[key].listings]
    }
  })),
  withSearch('filters', 'setFilters', searchMapping, ({pagination}) => {
    if (process.browser) {
      window.scrollTo(0, 0)
    }
    pagination.clear()
  }),
  withUserProfile,
  withOrderBy('orderBy', 'setOrderBy'),
  withGeolocation(
    ({filters}) => filters.location || filters.isMap,
    ({setFilters, filters}, foundLocation) => {
      if (!foundLocation && filters.location) {
        setFilters({...filters, location: null})
      }
    }
  ),
  graphql(SEARCH_LISTINGS, {
    options: ({filters, pagination, orderBy, geolocation}) => {
      return {
        ssr: !filters.isMap,
        variables: {
          filters: formatGraphQLFilters(filters, geolocation),
          pagination: filters.isMap
            ? {from: 0, size: 10 * 1000}
            : pagination.variables,
          orderBy:
            filters.isMap ||
            (!orderBy && filters?.searchLocation?.center?.coordinates)
              ? {field: 'DISTANCE', type: 'ASC'}
              : orderBy,
          isMap: filters.isMap || false
        }
      }
    },
    props: ({
      data = {},
      ownProps: {pagination, orderBy, setOrderBy, filters}
    }) => ({
      loading: data.loading,
      listings: (data[LISTINGS_KEY] && data[LISTINGS_KEY].listings) || [],
      fetchMore: pagination.fetchMore.bind(null, data),
      canFetchMore: pagination.canFetchMore(data) && !filters.isMap,
      itemsDisplayed:
        data[LISTINGS_KEY] &&
        data[LISTINGS_KEY].from + pagination.variables.size,
      itemsCount: data[LISTINGS_KEY] && data[LISTINGS_KEY].totalCount,
      orderBy,
      setOrderBy
    })
  }),
  memo
)(SearchListings)
