import gql from 'graphql-tag'

export default gql`
  fragment UserProfile on User {
    id
    name
    email
    phone
    role
    type
    cpf
    creci
    completedAboutYou
    requested_service
  }
`
