import * as qs from 'query-string'
import {LISTINGS_ROOT_PATH} from '@/lib/listingsUrl'
import {getUserCenter} from '@/lib/hooks/useGeolocation'
import {NEARBY_SETTINGS} from '@/config'

const QS_OPTIONS = {arrayFormat: 'comma'}

const NEARBY_SUBWAY_DISTANCE = 500

const RANGE_KEYS = ['min', 'max']

const TYPE_MAPPING = {
  apartamento: 'Apartamento',
  casa: 'Casa',
  cobertura: 'Cobertura'
}

export const MAP_TO_SEGMENT_KEYS = [
  'citiesSlug',
  'neighborhoods',
  'streetSlug',
  'types',
  'tagsSlug',
  'location',
  'isMap'
]

const STREET_PREFIX = [
  'rua',
  'avenida',
  'alameda',
  'praia',
  'praca',
  'estrada',
  'travessa',
  'largo',
  'viaduto',
  'av'
]

const NOT_GRAPHQL_FILTER = ['isMap']

const parseArray = (x) => [].concat(x).filter(Boolean)

const parseRange = (value) => {
  const range = value.split('-', 2).map((i) => parseInt(i, 10))
  if (!isNaN(range[0]) && !isNaN(range[1]) && range[0] <= range[1])
    return {min: range[0], max: range[1]}
}

const formatRange = ({min, max}) => `${Math.floor(min)}-${Math.floor(max)}`

const parseRangeUrl = (segmentName) => (segments) => {
  const range = RANGE_KEYS.reduce((mapper, key) => {
    const regexp = new RegExp(`${segmentName}-${key}-(\\d+)`)
    const match = segments.map((s) => s.match(regexp)).find((s) => s !== null)
    if (match) {
      mapper[key] = parseInt(match[1], 10)
    }
    return mapper
  }, {})
  return Object.keys(range).length === 0 ? null : range
}

const parseBoolean = (value) => Boolean(Number(value))

const formatBoolean = (value) => Number(value)

const parseBooleanUrl = (segmentName) => (segments) => {
  return segments.some((s) => s === segmentName)
}

const parseDistrictsFromUrl = (districts, city, state, segments) =>
  segments
    .map((segment) =>
      districts.find(
        (d) =>
          d.nameSlug === segment && d.citySlug === city && d.stateSlug === state
      )
    )
    .filter((d) => d)
    .map((d) => d.name) || null

const parseTagsFromUrl = (tags, segments) =>
  segments.filter((segment) => tags.find((t) => t.nameSlug === segment))

const parseTypesFromUrl = (segments) => {
  return segments
    .filter((segment) => Object.keys(TYPE_MAPPING).indexOf(segment) > -1)
    .map((t) => TYPE_MAPPING[t])
}

export const parseLocation = (value) => {
  if (!value) return
  const coordinates = value.split(',')
  if (!isNaN(coordinates[0]) || !isNaN(coordinates[1]))
    return {
      center: {
        coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])],
        type: 'Point'
      },
      radius: NEARBY_SETTINGS.searchInputRadius,
      place: coordinates[2]
    }
}

export const formatLocation = (value) => {
  if (!value) {
    return null
  }
  if (value.area) {
    return JSON.stringify(value.area)
  }
  const {
    center: {coordinates},
    place
  } = value
  return `${coordinates[0]},${coordinates[1]}${place ? `,${place}` : ''}`
}

const getURLBooleanFilter = (urlName) => {
  return {
    format: () => undefined,
    parseUrl: parseBooleanUrl(urlName),
    formatUrl: (value) => (value ? urlName : undefined)
  }
}

export const filtersMapping = ({districts, tags, params: {city, state}}) => {
  return {
    types: {
      query: 't',
      parseQs: parseArray,
      formatUrl: ([type]) => {
        return Object.keys(TYPE_MAPPING).find((k) => TYPE_MAPPING[k] === type)
      },
      parseUrl: parseTypesFromUrl
    },
    neighborhoods: {
      query: 'n',
      parseQs: (v) =>
        parseArray(v).filter((district) =>
          districts.find((d) => d.name === district && d.citySlug == city)
        ),
      formatUrl: ([name]) => {
        if (name) {
          const district = districts.find(
            (d) => d.name === name && (d.city === city || d.citySlug === city)
          )
          return district ? district.nameSlug : null
        }
        return null
      },
      parseUrl: parseDistrictsFromUrl.bind(null, districts, city, state)
    },
    tagsSlug: {
      query: 'ts',
      parseQs: parseArray,
      formatUrl: ([slug]) => slug,
      parseUrl: parseTagsFromUrl.bind(null, tags)
    },
    minRooms: {query: 'q', parseQs: parseInt},
    minTotalBathrooms: {query: 'b', parseQs: parseInt},
    minSuites: {query: 's', parseQs: parseInt},
    minGarageSpots: {query: 'gs', parseQs: parseInt},
    garageTypes: {query: 'gt', parseQs: parseArray},
    orientations: {query: 'ori', parseQs: parseArray},
    sunPeriods: {query: 'sun', parseQs: parseArray},
    maxSubwayDistance: {
      query: 'sd',
      parseUrl: (segments) => {
        const hasSegment = parseBooleanUrl('proximo-ao-metro')(segments)
        return hasSegment ? NEARBY_SUBWAY_DISTANCE : null
      },
      parseQs: parseInt
    },
    price: {
      query: 'p',
      parseUrl: parseRangeUrl('preco'),
      parseQs: parseRange,
      format: formatRange
    },
    maintenanceFee: {
      query: 'mf',
      parseUrl: parseRangeUrl('condominio'),
      parseQs: parseRange,
      format: formatRange
    },
    area: {
      query: 'a',
      parseUrl: parseRangeUrl('area'),
      parseQs: parseRange,
      format: formatRange
    },
    hasElevator: {query: 'e', parseQs: parseBoolean, format: formatBoolean},

    citiesSlug: {
      query: 'c',
      parseQs: parseArray,
      formatUrl: ([slug]) => {
        const district = districts.find(({citySlug}) => citySlug === slug)
        if (district) {
          return `${district.stateSlug}/${district.citySlug}`
        } else {
          return null
        }
      },
      defaultValue: [city || 'sao-paulo']
    },
    streetSlug: {
      parseUrl: (segments) => {
        const segDistricts = parseDistrictsFromUrl(
          districts,
          city,
          state,
          segments
        )
        if (
          segDistricts &&
          segDistricts.length > 0 &&
          segDistricts.length < 2
        ) {
          const regexp = new RegExp(`^(${STREET_PREFIX.join('|')}-*)`)
          return segments.find((s) => s.match(regexp))
        }
      },
      formatUrl: (slug, values) => {
        if (
          !values.neighborhoods ||
          values.neighborhoods.length === 0 ||
          values.neighborhoods.length > 1
        ) {
          return null
        }
        return slug
      }
    },
    isMap: {
      query: 'map',
      ...getURLBooleanFilter('mapa')
    },
    hasMatterport: {
      query: 'hasVirtualTour',
      ...getURLBooleanFilter('tour-virtual')
    },
    priceRecentlyReduced: {
      query: 'priceRecentlyReduced',
      ...getURLBooleanFilter('preco-baixou')
    },
    hasLiquidity: {
      query: 'hasLiquidity',
      ...getURLBooleanFilter('otimo-preco')
    },
    recentlyAdded: {
      query: 'recentlyAdded',
      ...getURLBooleanFilter('recentes')
    },
    location: {
      query: 'nearby',
      ...getURLBooleanFilter(NEARBY_SETTINGS.url)
    },
    searchLocation: {
      query: 'lc',
      parseQs: parseLocation,
      format: formatLocation
    }
  }
}

const capitalize = (str) => str[0].toUpperCase() + str.slice(1)

const rangeToFields = (value, name) => {
  if (!value) return undefined
  return RANGE_KEYS.reduce((fields, key) => {
    if (key in value) {
      fields[`${key}${capitalize(name)}`] = value[key]
    }
    return fields
  }, {})
}

export const formatGraphQLFilters = (
  {price, maintenanceFee, area, location, searchLocation, ...filters} = {},
  geolocation
) => {
  const graphqlFilters = Object.assign(
    Object.keys(filters)
      .filter((key) => !NOT_GRAPHQL_FILTER.includes(key))
      .reduce((obj, key) => ({...obj, [key]: filters[key]}), {}),
    rangeToFields(price, 'price'),
    rangeToFields(maintenanceFee, 'maintenanceFee'),
    rangeToFields(area, 'area')
  )
  if (searchLocation?.center?.coordinates) {
    graphqlFilters.location = {
      center: JSON.stringify({
        type: 'Point',
        coordinates: searchLocation.center.coordinates
      }),
      radius: NEARBY_SETTINGS.searchInputRadius
    }
  } else if (location && geolocation) {
    const center = getUserCenter(geolocation)
    if (center) {
      const {lat, lng} = center
      graphqlFilters.location = {
        center: JSON.stringify({
          type: 'Point',
          coordinates: [lng, lat]
        }),
        radius: NEARBY_SETTINGS.radius
      }
    }
  }
  return graphqlFilters
}

const id = (x) => x

const mapFrom = (mapping) => ({search = {}, pathname}) => {
  const segments = pathname.split('/')
  const {query} = qs.parseUrl((pathname + search || '?').slice(1), QS_OPTIONS)
  return Object.keys(mapping).reduce((value, key) => {
    const {query: q, parseQs = id, parseUrl, defaultValue} = mapping[key]
    const qsValue = q in query ? parseQs(query[q]) : null
    const v = qsValue || (parseUrl && parseUrl(segments)) || defaultValue
    if (typeof v === 'undefined') return value
    return Object.assign(value, {[key]: v})
  }, {})
}

const mapTo = (mapping) => (value) => {
  const segments = MAP_TO_SEGMENT_KEYS.reduce((params, key) => {
    const {formatUrl, defaultValue} = mapping[key] || {}
    const v = value[key] || defaultValue
    if (typeof v === 'undefined' || !formatUrl) return params
    if (Array.isArray(v) && v.length > 1) return params
    params[key] = formatUrl(v, value)
    return params
  }, {})
  const search = Object.keys(value).reduce((query, key) => {
    if (Object.keys(segments).indexOf(key) > -1) {
      return query
    }
    const {query: q, format = id} = mapping[key]
    const v = format(value[key])
    if (typeof v === 'undefined') return query
    return Object.assign(query, {[q]: format(value[key])})
  }, {})

  const pathname = MAP_TO_SEGMENT_KEYS.map((s) => segments[s])
    .filter((s) => s)
    .join('/')

  return {
    search: qs.stringify(search, QS_OPTIONS),
    pathname: `${LISTINGS_ROOT_PATH}/${pathname}`
  }
}

export const searchMapping = (props) => ({
  parse: mapFrom(filtersMapping(props)),
  format: mapTo(filtersMapping(props))
})
