import classNames from 'classnames'
import styles from './styles.module.css'

const defaultTag = 'p'

const Paragraph = ({
  children,
  small = false,
  big = false,
  as = defaultTag,
  ...otherProps
}) => {
  const defaultProps = {
    ...otherProps,
    className: classNames(styles.ecParagraph, {
      [styles.ecParagraph_small]: small,
      [styles.ecParagraph_big]: big
    })
  }
  const CustomTag = as
  return <CustomTag {...defaultProps}>{children}</CustomTag>
}

export default Paragraph
