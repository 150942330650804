import {useState, useEffect, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import isEqual from 'lodash/isEqual'

/**
 *
 * @param {Object} options
 * @param {Function} options.format
 * @param {Function} options.parse
 * @param {Function} onChange
 */
export default function useSearch({parse, format}, onChange = null) {
  const history = useHistory()
  const [value, setValue] = useState(parse(history.location))

  useEffect(() => {
    const newValue = parse(history.location)
    if (!isEqual(newValue, value)) {
      setValue(newValue)
    }
  }, [history.location])

  const setQuery = useCallback((value) => {
    const {location} = history
    const state = {scroll: 0}
    history.replace({...location, ...format(value), state})
    setValue(value)
    if (onChange !== null) {
      onChange()
    }
  })
  return [value, setQuery]
}
