import gql from 'graphql-tag'

export default gql`
  query districts {
    districts {
      state
      stateSlug
      city
      citySlug
      name
      nameSlug
      uuid
    }
  }
`
