import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'
import compose from 'recompose/compose'
import {connect, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import useBodyClass from '@/lib/hooks/useBodyClass'
import logger from '@/lib/logger'
import * as layout from '@/redux/modules/layout/selectors'
import {setNav, setBackButton} from '@/redux/modules/layout/actions'
import {redirectToSearch} from '@/redux/modules/search/actions'
import Nav from '@/components/layout/Nav'
import Button from '@/components/shared/Button'
import Filters from '@/components/listings/Filters'
import BackButton from './BackButton'
import Search from './Search'
import classNames from 'classnames'
import styles from '@/styles/organisms/Header/styles.module.css'

const isOnAListingPage = (pathname) => pathname.indexOf('/id-') !== -1
const isOnASearchOrListingPage = (pathname) =>
  pathname.indexOf('/imoveis') !== -1
const showLogo = (pathname) => pathname.indexOf('/imoveis') < 0

function Header(props) {
  const {
    hasNav,
    setNav,
    setBackButton,
    navProps,
    NavContent,
    getBackButton,
    filters,
    hideMenuButton
  } = props
  const [navFromRight, setNavFromRight] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  useBodyClass(hasNav ? 'js-no-scroll' : null)

  const toggleNav = () => {
    setNavFromRight(false)
    setNav(hasNav ? null : Nav.Menu)
    if (Nav.Menu)
      logger.action(hasNav ? 'header-menu-close' : 'header-menu-open', {
        target: 'nav-button'
      })
  }

  function handleSubmitFilter(filters) {
    dispatch(redirectToSearch(filters))
  }

  function handleFilterButton() {
    dispatch(
      setNav(
        (props) => (
          <Filters
            onSubmit={handleSubmitFilter}
            onClear={() => {
              const {isMap = false, citiesSlug} = filters
              handleSubmitFilter({isMap, citiesSlug})
            }}
            initialValue={filters}
            {...props}
          />
        ),
        {navFromRight: true}
      )
    )
  }

  useEffect(() => {
    if (
      hasNav &&
      navProps &&
      navProps.navFromRight &&
      navProps.navFromRight != navFromRight
    ) {
      setNavFromRight(navProps.navFromRight)
    }
  }, [hasNav])

  const changeBackButton =
    history.location.pathname.indexOf('/imoveis') === -1 &&
    (history.location.pathname.indexOf('/perfil/sugestoes') !== -1 ||
      history.location.pathname.indexOf('/perfil/favoritos') !== -1 ||
      history.location.pathname.indexOf('/perfil/escondidos') !== -1 ||
      history.location.pathname.indexOf('/interesses') !== -1 ||
      history.location.pathname.indexOf('/meus-imoveis') !== -1)

  function handleBackButtonLocation() {
    if (changeBackButton) {
      dispatch(
        setBackButton({
          label: 'Voltar',
          to: null,
          icon: '/img/icons/arrow-x-black.svg',
          flipIcon: true
        })
      )
    }
  }

  useEffect(handleBackButtonLocation, [])

  return (
    <header className={styles.ecHeader}>
      <div
        className={classNames(styles.ecHeader__wrapper, {
          [styles.ecHeader__wrapper_noMenuButton]: hideMenuButton
        })}
      >
        {(!hideMenuButton || (getBackButton && getBackButton.label)) && (
          <div className={styles.ecHeader__item}>
            {!hideMenuButton && (
              <Button
                noBorder
                aria-label="Abrir ou Fechar menu"
                title="Abrir ou Fechar menu"
                icon="/img/icons/hamburguer-menu.svg"
                onClick={toggleNav}
              />
            )}
            {history && isOnAListingPage(history.location.pathname) ? (
              <BackButton getBackButton={getBackButton} history={history} />
            ) : (
              <>
                {showLogo(history.location.pathname) ||
                !isOnASearchOrListingPage(history.location.pathname) ? (
                  <a
                    className={styles.ecHeader__logo}
                    href="/"
                    title="Ir para página inicial"
                    aria-label="Logo da EmCasa. Link para página inicial"
                    onClick={() => logger.action('header-logo')}
                  >
                    <img
                      className={styles.ecHeader__logo__img}
                      src="/img/emcasa.svg"
                      alt="Logo da EmCasa"
                    />
                  </a>
                ) : null}
              </>
            )}
          </div>
        )}
        {!!filters && (
          <>
            <Search filters={filters} submitFilter={handleSubmitFilter} />
            <div className={styles.ecHeader__item}>
              <Button
                anchor
                onClick={handleFilterButton}
                icon="/img/icons/filter.svg"
              >
                Filtrar
              </Button>
            </div>
          </>
        )}
      </div>
      <Nav
        isVisible={hasNav}
        hideNav={() => setNav(false)}
        fromRight={navFromRight}
      >
        {NavContent && (
          <NavContent active={hasNav} onClose={() => setNav(false)} />
        )}
      </Nav>
    </header>
  )
}

Header.propTypes = {
  hideMenuButton: PropTypes.bool
}

export default compose(
  connect(
    (state) => ({
      ...layout.getLayoutOptions(state),
      recordedHistoryLength: layout.getRecordedHistoryLength(state),
      navProps: layout.getNavProps(state),
      NavContent: layout.getNavComponent(state),
      hasNav: layout.hasNav(state),
      getBackButton: layout.getBackButton(state)
    }),
    {setNav, setBackButton}
  )
)(Header)
