import {takeEvery, select, call, put, getContext} from 'redux-saga/effects'
import {LOCATION_CHANGE, push} from 'connected-react-router'
import {GET_SEARCH_CONTEXT} from '@/graphql/queries/searchListings'
import {getCitiesFromDistricts} from '@/lib/districts'
import {changeCity, REDIRECT_TO_SEARCH} from './actions'
import {getCity} from './selectors'
import {LISTINGS_ROOT_PATH} from '@/lib/listingsUrl'
import {searchMapping} from '@/lib/searchFilters'

let allCities = null
let searchContext = null

function* getSearchContext() {
  if (searchContext === null) {
    const apolloClient = yield getContext('apolloClient')
    searchContext = yield call([apolloClient, apolloClient.query], {
      query: GET_SEARCH_CONTEXT,
      fetchPolicy: 'cache-first'
    })
  }
  return searchContext
}

function* getCities() {
  if (allCities === null) {
    const searchContext = yield getSearchContext()
    allCities = getCitiesFromDistricts(searchContext.data.districts)
  }
  return allCities
}

function* locationChange({payload}) {
  if (!process.browser) return
  const {location} = payload
  const currentCity = yield select(getCity)
  if (location.pathname.match(`${LISTINGS_ROOT_PATH}/.*`)) {
    const cities = yield getCities()
    const urlSegments = location.pathname.split('/')
    const cityFromUrl = cities.find((c) =>
      urlSegments.some((s) => s === c.slug)
    )
    if (cityFromUrl && cityFromUrl !== currentCity) {
      yield put(changeCity(cityFromUrl))
    }
  }
}

function* redirectToSearch({filters}) {
  const currentCity = yield select(getCity)
  const searchContext = yield getSearchContext()
  const searchFilter = searchMapping({
    params: {city: currentCity.name, state: currentCity.state},
    ...searchContext.data
  }).format(filters)
  yield put(
    push({
      pathname: searchFilter.pathname,
      search: searchFilter.search,
      state: {scroll: 0}
    })
  )
}

export default function* searchSaga() {
  yield takeEvery(LOCATION_CHANGE, locationChange)
  yield takeEvery(REDIRECT_TO_SEARCH, redirectToSearch)
}
