import {memo} from 'react'
import {Container, Image as Img} from './styles'
import LazyImage from '@/components/shared/LazyImage'

function Image({src, alt, noLazy, ...props}) {
  return (
    <Container {...props}>
      {noLazy ? (
        <Img alt={alt} src={src} />
      ) : (
        <LazyImage as={Img} alt={alt} src={src} />
      )}
    </Container>
  )
}
export default memo(Image)
