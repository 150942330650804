import gql from 'graphql-tag'

export default gql`
  mutation signInCreateAuthenticationCode($phone: String!) {
    signInCreateAuthenticationCode(phone: $phone) {
      enqueued
      message
    }
  }
`
