import {Link} from 'react-router-dom'
import classNames from 'classnames'
import styles from './styles.module.css'

const Anchor = (props) => {
  return props.to ? (
    <Link
      {...props}
      to={props.to}
      className={classNames(styles.ecAnchor, {
        [props.className]: props.className
      })}
    >
      {props.children}
    </Link>
  ) : (
    <a {...props} href={props.href} className={styles.ecAnchor}>
      {props.children}
    </a>
  )
}

export default Anchor
