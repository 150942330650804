export const citiesWithSubway = ['sao-paulo', 'rio-de-janeiro']

export const haveSubway = (citySlug) => citiesWithSubway.includes(citySlug)

export const getCityPreposition = (citySlug) =>
  citySlug === 'rio-de-janeiro' ? 'no' : 'em'

export const getCitiesWithDistricts = (districts) => {
  return districts.reduce((allCities, district) => {
    if (district.citySlug in allCities) {
      const districtsList = allCities[district.citySlug].districts

      districtsList.push(district)
      districtsList.sort((a, b) => (a.nameSlug > b.nameSlug) * 2 - 1)
      allCities[district.citySlug].districts = districtsList
    } else {
      allCities[district.citySlug] = {
        state: district.state,
        stateSlug: district.stateSlug,
        city: district.city,
        citySlug: district.citySlug,
        districts: [district]
      }
    }
    return allCities
  }, {})
}
