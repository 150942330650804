export function isTWA() {
  if (process.browser) {
    return (
      document &&
      document.referrer &&
      document.referrer.includes('android-app://com.emcasa.twa')
    )
  }
  return false
}

export function isPWA() {
  if (process.browser) {
    return (
      ('navigator' in window && window.navigator.standalone) ||
      ('matchMedia' in window &&
        window.matchMedia('(display-mode: standalone)').matches)
    )
  }
  return false
}

export function isApp() {
  return isTWA()
}

export function getDeviceType() {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isAndroid = Boolean(userAgent.match(/Android/i))
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = Boolean(userAgent.match(/Opera Mini/i))
  const isWindowsMobile = Boolean(userAgent.match(/IEMobile/i))
  const isSSR = Boolean(userAgent.match(/SSR/i))

  const isMobile = Boolean(isAndroid || isIos || isOpera || isWindowsMobile)
  const isDesktop = Boolean(!isMobile && !isSSR)

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR
  }
}
