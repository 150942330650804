import {Component} from 'react'
import PropTypes from 'prop-types'
import Row from '@emcasa/ui-dom/components/Row'
import Col from '@emcasa/ui-dom/components/Col'
import Text from '@emcasa/ui-dom/components/Text'
import Button from '@emcasa/ui-dom/components/Button'
import Slider from '@emcasa/ui-dom/components/Slider'
import NumberFormat from 'react-number-format'
import theme from '@/config/theme'
import {
  Container,
  Subtitle,
  SliderContainer,
  TermContainer,
  BarContainer,
  Bar,
  PaymentValue,
  SuggestText
} from './styles'
import logger from '@/lib/logger'

const intToCurrency = (value) => (
  <NumberFormat
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    value={value}
    displayType="text"
    prefix="R$ "
  />
)

class Financing extends Component {
  constructor(props) {
    super(props)
    const {percentageStart, terms, term, termsStartIndex} = props
    this.timeout = null
    this.state = {
      downPaymentValue: (percentageStart * props.price) / 100,
      downPaymentPercentage: percentageStart,
      term: term || terms[termsStartIndex]
    }
  }

  componentDidMount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  componentDidUpdate(prevProps) {
    const {price, percentageStart, term} = this.props
    if (prevProps.price !== price) {
      const downPaymentValue = (this.state.downPaymentPercentage * price) / 100
      this.setState({downPaymentValue})
    }

    if (prevProps.percentageStart !== percentageStart) {
      this.setState({
        downPaymentPercentage: percentageStart,
        downPaymentValue: (percentageStart * this.props.price) / 100
      })
    }

    if (prevProps.term !== term) {
      this.setState({term})
    }
  }

  getInterestPow = () => Math.pow(1 + this.props.interestRate / 100, 1 / 12) - 1

  getBalance = () => this.props.price - this.state.downPaymentValue

  getAmortization = (term) => this.getBalance() / (term * 12)

  getFirstInterest = () => this.getBalance() * this.getInterestPow()

  getLastInterest = () =>
    this.getAmortization(this.state.term) * this.getInterestPow()

  getFirstPayment = () =>
    this.getAmortization(this.state.term) + this.getFirstInterest()

  getFirstHighestPayment = () =>
    this.getAmortization(this.props.terms[this.props.terms.length - 1]) +
    this.getFirstInterest()

  getLastPayment = () =>
    this.getAmortization(this.state.term) + this.getLastInterest()

  getHouseHoldIncome = () =>
    (this.getFirstPayment() * 100) / this.props.householdPercentage

  getMinDownPayment = () =>
    this.state.downPaymentPercentage < this.props.percentageMin

  getMaxDownPayment = () =>
    this.state.downPaymentPercentage > this.props.percentageMax

  getError = () => this.getMinDownPayment() || this.getMaxDownPayment()

  getFirstPaymentBarWidth = () =>
    Math.round((this.getFirstPayment() / this.getFirstHighestPayment()) * 100) /
    2

  getLastPaymentBarWidth = () =>
    Math.round((this.getLastPayment() / this.getFirstHighestPayment()) * 100) /
    2

  onSliderChange = (value) => {
    const {sliderIncrementNumber, price} = this.props
    const incrementValue =
      Math.round(value / sliderIncrementNumber) * sliderIncrementNumber
    const updateState = {
      downPaymentValue: Number((incrementValue * price) / 100),
      downPaymentPercentage: incrementValue
    }

    this.setState(updateState)

    if (Math.round(value) !== Math.round(this.state.downPaymentPercentage)) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        logger.action('listing-financing-downpayment-change', {
          ...this.state,
          ...updateState
        })
      }, 500)
    }
  }

  onChangeTerm = (term) => {
    const {id} = this.props
    this.setState({term})
    logger.action('listing-financing-term-change', {
      ...this.state,
      id: id ? id : null
    })
  }

  getEventProps = (type) => {
    const {id} = this.props
    return {
      ...this.state,
      houseHoldIncome: this.getHouseHoldIncome(),
      id: id ? id : null,
      leadType: type
    }
  }

  handleButtonsClick = (event, log, type) => {
    logger.action(log, this.getEventProps(type))
    if (event) {
      event(this.getEventProps(type))
    }
  }

  handleButtonClick = () => {
    this.handleButtonsClick(
      this.props.handleContactButtonClick,
      'listing-financing-contact-button',
      'financing-contact'
    )
  }

  handleCTAClick = () => {
    this.handleButtonsClick(
      this.props.handleCTAButtonClick,
      'listing-financing-cta',
      'financing-cta'
    )
  }

  render() {
    const {
      hideSlider,
      hideTerm,
      percentageMax,
      percentageMin,
      interestRate,
      terms,
      termsStartIndex
    } = this.props
    const {downPaymentValue, downPaymentPercentage} = this.state

    return (
      <Container>
        {!hideSlider && (
          <Text fontWeight={2} color="grey900" as="h2">
            Simulador de Financiamento
          </Text>
        )}
        <Row flexDirection={['column', null, null, hideSlider ? null : 'row']}>
          <Row flexDirection="column" flex="1" mr={[null, null, null, 4]}>
            {!hideSlider ? (
              <Col mb={5}>
                <Subtitle>Entrada + FGTS</Subtitle>
                <SliderContainer error={this.getError()}>
                  <Text
                    fontSize={2}
                    fontWeight={2}
                    textAlign="center"
                    color={this.getError() ? 'red500' : null}
                  >
                    {intToCurrency(downPaymentValue)}
                  </Text>
                  <Text
                    fontSize={1}
                    fontWeight={2}
                    textAlign="center"
                    color={this.getError() ? 'red500' : 'pink500'}
                  >
                    {downPaymentPercentage}%
                  </Text>
                  <Slider
                    height={6}
                    range={[0, 100]}
                    initialValue={20}
                    value={downPaymentPercentage}
                    onChange={(value) => this.onSliderChange(value[0])}
                    trackProps={{
                      height: 6,
                      style: {
                        background: theme.colors.pink100,
                        marginTop: -2
                      }
                    }}
                    style={{zIndex: 10}}
                  >
                    <Slider.Marker
                      trackProps={{
                        height: 6,
                        style: {backgroundColor: theme.colors.pink500}
                      }}
                    />
                  </Slider>
                  {this.getMinDownPayment() && (
                    <Text fontSize={1} color="red500">
                      O valor de entrada deve ser de, no mínimo, {percentageMin}
                      % do valor total do imóvel.
                    </Text>
                  )}
                  {this.getMaxDownPayment() && (
                    <Text fontSize={1} color="red500">
                      O valor de entrada deve ser de, no máximo, {percentageMax}
                      % do valor total do imóvel.
                    </Text>
                  )}
                </SliderContainer>
              </Col>
            ) : null}
            {!hideTerm && (
              <TermContainer mb={4}>
                <Subtitle>Prazo de financiamento</Subtitle>
                <Button.Group
                  initialValue={terms[termsStartIndex]}
                  onChange={(value) => this.onChangeTerm(value)}
                >
                  {terms.map((item) => (
                    <Button
                      key={item}
                      value={item}
                      fontSize={1}
                      disabled={this.getError()}
                    >
                      {`${item} anos`}
                    </Button>
                  ))}
                </Button.Group>
              </TermContainer>
            )}
          </Row>
          <Row flexDirection="column" flex="1">
            <Col>
              <Subtitle hideondesktop="true">Resumo</Subtitle>
              <Row flexDirection={['column', null, null, 'row']}>
                <BarContainer mr={[null, null, null, 2]}>
                  <Bar
                    error={this.getError()}
                    percentageWidth={this.getFirstPaymentBarWidth()}
                  />
                  <Row alignItems="center">
                    <Text fontSize={1}>
                      Primeira parcela{' '}
                      <PaymentValue error={this.getError()}>
                        {this.getError()
                          ? '-'
                          : intToCurrency(this.getFirstPayment())}
                      </PaymentValue>
                    </Text>
                  </Row>
                </BarContainer>
                <BarContainer ml={[null, null, null, 2]}>
                  <Bar
                    error={this.getError()}
                    delay="0.1"
                    percentageWidth={this.getLastPaymentBarWidth()}
                  />
                  <Row alignItems="center">
                    <Text fontSize={1}>
                      Última parcela{' '}
                      <PaymentValue error={this.getError()}>
                        {this.getError()
                          ? '-'
                          : intToCurrency(this.getLastPayment())}
                      </PaymentValue>
                    </Text>
                  </Row>
                </BarContainer>
              </Row>
            </Col>
            {!this.getError() ? (
              <SuggestText>
                <Text fontSize={1}>
                  Renda familiar mínima:{' '}
                  <strong>{intToCurrency(this.getHouseHoldIncome())}</strong>
                </Text>
              </SuggestText>
            ) : null}
            <Col mb={true && 6}>
              <Text className="legalText" fontSize={1} textAlign="center">
                *A taxa de juros base é de{' '}
                {String(interestRate).replace('.', ',')}%. Os valores estão
                sujeitos à análise de crédito e podem variar para cada banco.{' '}
                {hideSlider
                  ? 'Você poderá tirar todas as suas dúvidas com nossos especialistas.'
                  : null}
              </Text>
            </Col>
          </Row>
        </Row>
      </Container>
    )
  }
}

Financing.propTypes = {
  id: PropTypes.string,
  price: PropTypes.number.isRequired,
  hideSlider: PropTypes.bool,
  hideTerm: PropTypes.bool,
  householdPercentage: PropTypes.number,
  percentageStart: PropTypes.number,
  percentageMax: PropTypes.number,
  percentageMin: PropTypes.number,
  sliderIncrementNumber: PropTypes.number,
  interestRate: PropTypes.number,
  terms: PropTypes.array,
  termsStartIndex: PropTypes.number,
  term: PropTypes.number
}

Financing.defaultProps = {
  householdPercentage: 45,
  hideSlider: false,
  hideTerm: false,
  percentageStart: 20,
  percentageMax: 90,
  percentageMin: 20,
  sliderIncrementNumber: 1,
  interestRate: 12,
  terms: [30, 20, 10],
  termsStartIndex: 0
}

export default Financing
