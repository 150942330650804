import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Label.module.css'

export const ICON_SIZE = 11

const Label = ({icon, label, active, inactive, dark, lead}) => (
  <div
    className={classNames(styles.ecLabel, {
      [styles.ecLabel_pink]: lead,
      [styles.ecLabel_active]: active,
      [styles.ecLabel_inactive]: inactive,
      [styles.ecLabel_dark]: dark
    })}
  >
    {icon && (
      <img
        className={styles.ecLabel__icon}
        src={icon}
        alt={`Ícone ${label}`}
        width="16"
        height="16"
      />
    )}
    {label}
  </div>
)

Label.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  lead: PropTypes.bool,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
  dark: PropTypes.bool
}

export default Label
