import React from 'react'
import Button from '@/components/form/Button'
import SharedButton from '@/components/shared/Button'
import styles from '@/styles/organisms/Filters/styles.module.css'

function ListingTypeFilter({onChange, initialValue, value}) {
  return (
    <div className={styles.ecFilters__filter__buttons}>
      <Button.Group
        strategy="multi"
        initialValue={initialValue}
        onChange={onChange}
      >
        <SharedButton
          selected={value && value.includes('Casa')}
          small
          value={'Casa'}
        >
          Casa
        </SharedButton>
        <SharedButton
          selected={value && value.includes('Apartamento')}
          small
          value={'Apartamento'}
        >
          Apartamento
        </SharedButton>
        <SharedButton
          selected={value && value.includes('Cobertura')}
          small
          value={'Cobertura'}
        >
          Cobertura
        </SharedButton>
      </Button.Group>
    </div>
  )
}

export default React.memo(ListingTypeFilter)
