// Seller landing
export const SELLER_LANDING_PAGE = 'seller-landing-page'
export const SELLER_LANDING_HEADER_ADDRESS_ONFOCUS =
  'seller-landing-header-address-onfocus'
export const SELLER_LANDING_HEADER_ADDRESS_DATA =
  'seller-landing-header-address-data'
export const SELLER_ONBOARDING_CTA_LINK = 'seller-onboarding-cta-link'
export const SELLER_LANDING_EVALUATION_ADDRESS_ONDATA =
  'seller-landing-evaluation-address-ondata'
export const SELLER_LANDING_EVALUATION_ADDRESS_ONFOCUS =
  'seller-landing-evaluation-address-onfocus'
export const SELLER_LANDING_EVALUATION_CTA_LINK =
  'seller-landing-evaluation-cta-link'
export const LISTING_SEARCH_CTA_LINK = 'listing-search-cta-link'
export const SELLER_LANDING_YOU_CTA = 'seller-landing-you-cta'
export const SELLER_LANDING_PARTNERBROKER_CTA_LINK =
  'seller-landing-partnerbroker-cta-link'
export const SELLER_LANDING_PARTNER_CTA_LINK = 'seller-landing-partner-cta-link'
