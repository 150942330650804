import {memo, useState, useMemo, useEffect} from 'react'
import compose from 'recompose/compose'
import logger from '@/lib/logger'
import useHashLocation from '@/lib/hooks/useHashLocation'
import {getGroupsMatched} from '@/components/form/TagInput/groups'
import Button from '@/components/shared/Button'
import TagButton from '@/components/shared/TagButton'
import classNames from 'classnames'
import filtersStyles from '@/styles/organisms/Filters/styles.module.css'
import inputStyles from '@/styles/atoms/Input/styles.module.css'

const filterByCities = (cities = []) => ({citySlug}) =>
  cities.length == 0 || cities.includes(citySlug)

const withDistricts = (Component) => (props) => {
  const componenProps = {
    ...props,
    options: (props.districts || []).filter(filterByCities(props.citiesSlug))
  }

  return <Component {...componenProps} />
}

const Neighborhoods = (props) => {
  const {citiesSlug, districts, options, onChange} = props

  const [searchOpen, showSearch] = useState(false)
  const [value, setValue] = useState(props.initialValue || [])
  const [search, setSearch] = useState('')

  useHashLocation(
    () => showSearch(false),
    {hash: 'bairros', active: searchOpen},
    [searchOpen]
  )

  const selectedOptions = useMemo(
    () => options && options.filter((o) => value.includes(o.name)),
    [options, value]
  )

  const filteredDistricts = useMemo(() => getGroupsMatched(search, districts), [
    search,
    districts
  ])

  const title = districts.find((item) => item.citySlug === citiesSlug[0])

  function onClear() {
    props.onChange([])
    logger.action('listing-search-neighborhood-clear')
    showSearch(false)
  }

  function onApply() {
    showSearch(false)
    logger.action('listing-search-neighborhood-apply', {
      neighborhoods: props.value
    })
  }

  function clearSearch() {
    setSearch('')
  }

  function onInputSearchChange(e) {
    setSearch(e.target.value)
  }

  function onDistrictButtonClick(name) {
    let newValue = value
    if (newValue.includes(name)) {
      newValue = value.filter((v) => v !== name)
    } else {
      newValue.push(name)
    }
    onChange(newValue)
  }

  useEffect(() => setValue(props.value), [props.value])
  useEffect(clearSearch, [searchOpen])

  const wrapperClasses = classNames({
    [filtersStyles.ecFilters__search]: true,
    [filtersStyles.ecFilters__search_open]: searchOpen
  })

  return (
    <div className={wrapperClasses}>
      {!searchOpen ? (
        <>
          <Button
            type="button"
            icon="/img/icons/search-grey300.svg"
            onClick={() => showSearch(true)}
          >
            Filtrar por bairros
          </Button>
          {selectedOptions && (
            <div className={filtersStyles.ecFilters__search__tagButtons}>
              {selectedOptions.map((o) => (
                <div className={filtersStyles.ecFilters__search__item}>
                  <TagButton
                    key={o.value}
                    onClick={() => showSearch(true)}
                    onClose={() => onChange(value.filter((v) => v !== o.name))}
                  >
                    {o.name}
                  </TagButton>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={filtersStyles.ecFilters__search__options__header}>
            <div
              className={filtersStyles.ecFilters__search__options__inputWrapper}
            >
              <input
                type="text"
                tabIndex="0"
                placeholder="Procurar bairros"
                className={inputStyles.ecInput}
                value={search}
                onChange={onInputSearchChange}
              />
              <button
                className={
                  filtersStyles.ecFilters__search__options__clearButton
                }
                aria-label="Limpar busca de bairros"
                onClick={clearSearch}
              >
                <img src="/img/icons/close-gray.svg" alt="Ícone" />
              </button>
            </div>
            {title && (
              <p className={filtersStyles.ecFilters__search__options__title}>
                {title.city}
              </p>
            )}
          </div>
          <div className={filtersStyles.ecFilters__search__options__list}>
            {filteredDistricts.size ? (
              Array.from(filteredDistricts).map(
                (i) =>
                  i[1] &&
                  i[1].map(
                    (item, index) =>
                      item.citySlug === citiesSlug[0] && (
                        <Button
                          key={item.name}
                          className={filtersStyles.ecFilters__search__item}
                          small
                          type="button"
                          key={index}
                          selected={value.includes(item.name)}
                          onClick={() => onDistrictButtonClick(item.name)}
                        >
                          {item.name}
                        </Button>
                      )
                  )
              )
            ) : (
              <p>Nenhum resultado para "{search}"</p>
            )}
          </div>
          <div className={filtersStyles.ecFilters__search__options__footer}>
            <Button
              type="button"
              ariaLabel="Remover bairros selecionados"
              onClick={onClear}
            >
              Limpar
            </Button>
            <Button
              active
              type="button"
              ariaLabel="Aplicar bairros selecionados"
              onClick={onApply}
            >
              Aplicar bairros
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default compose(memo, withDistricts)(Neighborhoods)
