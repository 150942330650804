import {graphql} from 'react-apollo'
import GET_DISTRICTS from '@/graphql/queries/districts'

const mapDistrictToOption = (item) => ({
  ...item
})

const filterByCities = (cities = []) => ({citySlug}) =>
  cities.length == 0 || cities.includes(citySlug)

export default graphql(GET_DISTRICTS, {
  props: ({data, ownProps: {citiesSlug}}) => ({
    districts: (data.districts || [])
      .filter(filterByCities(citiesSlug))
      .map(mapDistrictToOption)
      .sort((a, b) => (a.nameSlug > b.nameSlug) * 2 - 1)
  }),
  options: {
    fetchPolicy: 'cache-and-network'
  }
})
