import {
  SET_NAV,
  SET_MODAL,
  SET_OPTIONS,
  SET_BACKBUTTON,
  SHOW_LOGINMODAL
} from './actions'
import {LOCATION_CHANGE} from 'connected-react-router'

const initialState = {
  recordedHistoryLength: 1,
  title: undefined,
  options: {},
  nav: {
    active: false,
    Component: undefined,
    props: {}
  },
  modal: {
    active: false,
    Component: undefined,
    props: {}
  },
  backButton: {
    to: null,
    label: null,
    icon: null,
    flipIcon: false
  },
  loginModal: {
    show: false,
    phone: null
  }
}

const getNavProps = ({Component, props}) =>
  Object.assign({}, Component.navProps, props)

const getModalProps = ({Component, props}) =>
  Object.assign({}, Component.modalProps, props)

const HISTORY_ACTION_WEIGHTS = {
  PUSH: 1,
  POP: -1,
  REPLACE: 0
}

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (action.payload.isFirstRendering) return state
      return {
        ...state,
        recordedHistoryLength:
          state.recordedHistoryLength +
          HISTORY_ACTION_WEIGHTS[action.payload.action]
      }
    case SET_OPTIONS:
      return {
        ...state,
        options: action.options
      }
    case SET_NAV:
      return {
        ...state,
        nav: {
          active: Boolean(action.Component),
          Component: action.Component || state.nav.Component,
          props: action.Component ? getNavProps(action) : state.nav.props
        }
      }
    case SET_MODAL:
      return {
        ...state,
        modal: {
          active: Boolean(action.Component),
          Component: action.Component || state.modal.Component,
          props: action.Component ? getModalProps(action) : state.modal.props
        }
      }
    case SET_BACKBUTTON:
      return {
        ...state,
        backButton: {
          to: action.to,
          label: action.label,
          icon: action.icon,
          flipIcon: action.flipIcon
        }
      }
    case SHOW_LOGINMODAL:
      return {
        ...state,
        loginModal: {
          show: action.show,
          phone: action.phone
        }
      }
    default:
      return state
  }
}
