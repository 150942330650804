import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

const Suggestion = ({
  active,
  small,
  mainText,
  secondaryText,
  onClick,
  onSelect,
  ...props
}) => {
  function handleOnClick() {
    onSelect(`${mainText}${secondaryText ? ` - ${secondaryText}` : ''}`)
    onClick()
  }
  return (
    <button
      className={classNames(styles.ecSearchLocationInput__item, {
        [styles.ecSearchLocationInput__item_small]: small,
        [styles.ecSearchLocationInput__item_active]: active
      })}
      onClick={handleOnClick}
      {...props}
    >
      <img
        src="/img/icons/map-marker-alt.svg"
        className={styles.ecSearchLocationInput__item__icon}
      />
      {mainText}
      {secondaryText ? (
        <span className={styles.ecSearchLocationInput__item__secondaryText}>
          {`- ${secondaryText}`}
        </span>
      ) : null}
    </button>
  )
}

Suggestion.propTypes = {
  small: PropTypes.bool,
  mainText: PropTypes.string,
  secondaryText: PropTypes.string
}

export default Suggestion
