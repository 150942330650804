import {
  NAVIGATETO,
  STEP_LOCATION,
  STEP_TYPE,
  STEP_PRICE,
  STEP_CONDO,
  STEP_DETAILS,
  STEP_PREFERENCES,
  STEP_BUYER_STAGE,
  STEP_PAYMENT_TYPE,
  STEP_PROFILE,
  STEP_FINANCING,
  STEP_SELL_TO_BUY
} from './actions'

export const initialState = {
  step: 'intro',
  stepLocation: {
    state: null,
    city: null,
    citySlug: null,
    districts: null,
    selectedDistricts: null
  },
  stepType: {
    types: ['apartamento']
  },
  stepPrice: {
    price: 1000000
  },
  stepCondo: {
    maintenanceFee: 1000
  },
  stepDetails: {
    rooms: 1,
    bathrooms: 1,
    garageSpots: 1
  },
  stepPreferences: {
    nearSubway: 'sim',
    hasElevator: 'tanto faz'
  },
  stepBuyerStage: {
    buyerStage: null
  },
  stepPaymentType: {
    paymentType: null
  },
  stepProfile: {
    name: null,
    phone: null,
    email: null
  },
  stepFinancing: {
    hasApprovedCredit: null,
    creditDownPayment: null,
    creditTotalValue: null,
    needFgtsWithdrawal: null
  },
  stepSellToBuy: {
    address: null,
    complement: null,
    price: null
  }
}

export default function youReducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATETO:
      return {
        ...state,
        step: action.value
      }
    case STEP_LOCATION:
      return {
        ...state,
        stepLocation: {...state.stepLocation, ...action.value}
      }
    case STEP_TYPE:
      return {
        ...state,
        stepType: action.value
      }
    case STEP_PRICE:
      return {
        ...state,
        stepPrice: action.value
      }
    case STEP_CONDO:
      return {
        ...state,
        stepCondo: action.value
      }
    case STEP_DETAILS:
      return {
        ...state,
        stepDetails: action.value
      }
    case STEP_PREFERENCES:
      return {
        ...state,
        stepPreferences: action.value
      }
    case STEP_BUYER_STAGE:
      return {
        ...state,
        stepBuyerStage: action.value
      }
    case STEP_PAYMENT_TYPE:
      return {
        ...state,
        stepPaymentType: action.value
      }
    case STEP_PROFILE:
      return {
        ...state,
        stepProfile: action.value
      }
    case STEP_FINANCING:
      return {
        ...state,
        stepFinancing: action.value
      }
    case STEP_SELL_TO_BUY:
      return {
        ...state,
        stepSellToBuy: action.value
      }
    default:
      return state
  }
}
