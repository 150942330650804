import React, {useCallback, useRef, useReducer, useEffect} from 'react'
import Controller from './Controller'
import {Carousel, Slide} from './styles'
import {useScroll} from 'react-use-gesture'

const CarouselController = React.forwardRef(function CarouselController(
  {children, ...props},
  ref
) {
  return (
    <Carousel ref={ref} {...props}>
      {React.Children.map(
        children,
        (node, index) =>
          node && React.cloneElement(node, {'data-slide-index': index})
      )}
    </Carousel>
  )
})

const AXIS = 0 // x
const LEFT = -1
const noop = () => null

export const useSlideTo = (ref, callback = noop, deps = []) =>
  useCallback((index, behavior = 'smooth') => {
    const slide = ref.current.querySelector(`[data-slide-index="${index}"]`)
    if (slide) {
      const width = ref.current.getBoundingClientRect().width
      const left = Math.floor(slide.offsetLeft / width) * width
      callback(left, index, behavior)
      if (typeof ref.current.scrollTo === 'function') {
        ref.current.scrollTo({left, behavior})
      }
    }
  }, deps)

const canScrollBy = (element, offset) => {
  const scrollLeft = element.scrollLeft
  const scrollWidth = element.scrollWidth
  const left = scrollLeft + offset
  return offset !== 0 && left < scrollWidth
}

export const useSlideBy = (ref, callback = noop, deps = []) =>
  useCallback((amount, behavior = 'smooth') => {
    const width = ref.current.getBoundingClientRect().width
    const offset = Math.ceil(amount * width)
    if (canScrollBy(ref.current, offset)) {
      const left = ref.current.scrollLeft + offset
      callback(left, amount, behavior)
      ref.current.scrollTo({left, behavior})
    }
  }, deps)

export function useCarousel() {
  const ref = useRef()
  const slideTo = useSlideTo(ref)
  const slideBy = useSlideBy(ref)
  return [ref, {slideTo, slideBy}]
}

const carouselReducer = (state, next) => ({...state, ...next})

const getMarginOffset = (node) => {
  const style = window.getComputedStyle(node)
  return parseInt(style.marginLeft)
}

const getThresholds = (ref) =>
  Array.from(ref.current.childNodes.entries()).map(([index, element]) => ({
    index,
    offset: element.offsetLeft - getMarginOffset(element)
  }))

const createScrollMemo = (pos, ref) => {
  const thresholds = getThresholds(ref)
  const current = thresholds[pos]
  return {current, thresholds}
}

const THRESHOLD_OFFSET = 20

const thresholdReached = (scrollLeft, direction, {offset}) =>
  direction == LEFT
    ? scrollLeft - THRESHOLD_OFFSET <= offset
    : scrollLeft + THRESHOLD_OFFSET >= offset

const handleSmoothScroll = (e, callback) => {
  const direction = e.direction[AXIS]
  const offset = e.offset[AXIS]
  const memo = e.memo
  const next = memo.thresholds[memo.current.index + direction]
  if (
    next &&
    next !== memo.current &&
    thresholdReached(offset, direction, next)
  ) {
    memo.current = next
    requestAnimationFrame(() => callback(next))
  }
  return memo
}

const handleLastScroll = (e, callback) => {
  const {thresholds} = e.memo
  const offset = e.offset[AXIS]
  const threshold =
    thresholds.find(
      (t) =>
        t.offset + THRESHOLD_OFFSET >= offset &&
        t.offset - THRESHOLD_OFFSET <= offset
    ) || e.memo.current
  callback(threshold)
}

export function useControlledCarousel(initialPosition = 0, onChangePosition) {
  const [state, dispatch] = useReducer(carouselReducer, {
    position: initialPosition
  })
  const ref = useRef()
  const slideTo = useSlideTo(ref)
  const slideBy = useSlideBy(ref)
  const props = useScroll((e) => {
    if (e.first) {
      return createScrollMemo(state.position, ref)
    } else if (e.last) {
      handleLastScroll(e, (threshold) => {
        dispatch({position: threshold.index})
      })
    } else {
      return handleSmoothScroll(e, (threshold) => {
        dispatch({position: threshold.index})
        if (onChangePosition) onChangePosition(threshold.index)
      })
    }
  })
  useEffect(() => {
    if (ref.current) slideTo(initialPosition, 'auto')
  }, [])
  return [{ref, ...props()}, {slideBy, slideTo}, state]
}

export {Slide, Controller}

export default Object.assign(React.memo(CarouselController), {
  Slide,
  Controller
})
