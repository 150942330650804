import {useEffect} from 'react'
import {ConnectedRouter} from 'connected-react-router'
import OpenReplay from '@openreplay/tracker/cjs'
import Context from '@/pages/context'
import Routes from '@/pages/routes'
import {OPENREPLAY_KEY, NODE_ENV} from '../config'

export default function App({
  store,
  apolloClient,
  history,
  cookies,
  useRoutesFromOrigin
}) {
  const isProduction = NODE_ENV === 'production'

  const tracker = isProduction
    ? new OpenReplay({
        projectKey: OPENREPLAY_KEY
      })
    : null

  useEffect(() => {
    if (isProduction)
      tracker.start().catch((error) => console.log({openreplay: error}))
  }, [])

  return (
    <Context store={store} apolloClient={apolloClient} cookies={cookies}>
      <ConnectedRouter history={history}>
        <Routes useRoutesFromOrigin={useRoutesFromOrigin} />
      </ConnectedRouter>
    </Context>
  )
}
