import labelStyles from '@/styles/atoms/Label/styles.module.css'
import textareaStyles from '@/styles/atoms/Textarea/styles.module.css'
import {useFormContext, Controller} from 'react-hook-form'
import YesNoButtonGroup from '@/components/form/YesNoButtonGroup'
import CurrencyInput from '@/components/form/CurrencyInput'

export default function OfferCash() {
  const {control} = useFormContext()
  return (
    <>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Valor da proposta</span>
        <Controller
          name="creditTotalValue"
          control={control}
          rules={{required: true, validate: (v) => v > 0}}
          as={CurrencyInput}
          arial-label="Insira o valor da proposta"
          placeholder="Ex: 575.000"
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Pretende usar FGTS?</span>
        <Controller
          control={control}
          name="needFgtsWithdrawal"
          rules={{
            validate: {
              required: (value) => value !== null
            }
          }}
          as={YesNoButtonGroup}
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Observações</span>
        <Controller
          control={control}
          rules={{required: false}}
          as={<textarea className={textareaStyles.ecTextarea} />}
          name="note"
          arial-label="Insira suas observações"
          placeholder="Digite aqui"
        />
      </label>
    </>
  )
}
