export function getCitiesFromDistricts(districts, alphabeticalOrder) {
  return districts.reduce((items, {citySlug, stateSlug, city}) => {
    if (!items.find((i) => i.slug === citySlug && i.state === stateSlug)) {
      items.push({slug: citySlug, state: stateSlug, name: city})
    }
    return alphabeticalOrder
      ? items.sort((a, b) => (a.slug > b.slug) * 2 - 1)
      : items
  }, [])
}

export function getStatesFromDistricts(districts) {
  return districts.reduce((items, {stateSlug}) => {
    if (items.indexOf(stateSlug) === -1) {
      items.push(stateSlug)
    }
    return items
  }, [])
}
