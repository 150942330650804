import {useCookies} from '@/pages/context/Cookies'
import Cookie from 'js-cookie'

export const EXPERIMENT_PREFIX = 'EmCasa-Experiment-'
const VARIANT_VALUE = 'variant'

function useExperiment(experimentName, variants = [false, true]) {
  const cookies = useCookies()
  const experiment = cookies.get(`${EXPERIMENT_PREFIX}${experimentName}`)
  const [original, variant] = variants
  return experiment === VARIANT_VALUE ? variant : original
}

function getActiveExperiments() {
  const cookies = Cookie.get() || {}
  return Object.keys(cookies)
    .filter((key) => key.startsWith(EXPERIMENT_PREFIX))
    .reduce((dict, key) => {
      dict[key] = cookies[key]
      return dict
    }, {})
}

function getActiveExperimentsList() {
  const experiments = getActiveExperiments()
  return Object.keys(experiments).map((key) => `${key}|${experiments[key]}`)
}

export {getActiveExperiments, getActiveExperimentsList}
export default useExperiment
