import OfferCreate from '@/components/listing/components/OfferCreate'
import {getListingUrl} from '@/lib/listingsUrl'
import Modal from '@/components/shared/Modal'
import Loader from '@/components/listing/components/Gallery/components/Loader'
import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {useHistory} from 'react-router-dom'

export default function OfferPage({match}) {
  const [show, showModal] = useState(false)
  const listingId = match.params.id
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => showModal(true), 500)
  }, [])

  return (
    <>
      <Helmet>
        <title>EmCasa - Proposta - Imóvel #{listingId}</title>
      </Helmet>
      {!show && <Loader />}
      <div style={{opacity: show ? 1 : 0}}>
        <Modal
          show={show}
          disableBackgroundClick={true}
          onClose={() => history.push(getListingUrl({id: listingId}))}
        >
          {show ? <OfferCreate listingId={listingId} /> : null}
        </Modal>
      </div>
    </>
  )
}
