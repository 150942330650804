import urlJoin from 'url-join'
export const LISTINGS_ROOT_PATH = '/imoveis'

export function buildListingsCanonicalUrl(city, filters, districts) {
  const neighborhood =
    filters.neighborhoods && filters.neighborhoods.length === 1
      ? districts.find((item) => item.name === filters.neighborhoods[0])
      : null
  const type =
    filters.types && filters.types.length === 1
      ? `/${filters.types[0].toLowerCase()}`
      : ''
  const path = `${city.stateSlug}/${city.citySlug}${
    neighborhood ? `/${neighborhood.nameSlug}` : ''
  }${type}`
  return buildListingsUrl(path)
}

export function buildListingsUrl(urlPath) {
  if (!urlPath || !urlPath.trim()) {
    return LISTINGS_ROOT_PATH
  }
  return urlJoin(LISTINGS_ROOT_PATH, urlPath)
}

export function getCityUrlFromListing(listing) {
  const {address} = listing
  const {stateSlug, citySlug} = address || {}
  if (!address || !stateSlug || !citySlug) {
    return `${LISTINGS_ROOT_PATH}`
  }
  return buildListingsUrl(`${stateSlug}/${citySlug}`)
}

export function getListingUrl(listing) {
  const {id, address} = listing
  const {stateSlug, citySlug, neighborhoodSlug, streetSlug} = address || {}
  if (!address || !stateSlug || !citySlug || !neighborhoodSlug || !streetSlug) {
    return `${LISTINGS_ROOT_PATH}/${id}`
  }
  return buildListingsUrl(
    `${stateSlug}/${citySlug}/${neighborhoodSlug}/${streetSlug}/id-${id}`
  )
}

export function getSearchPath(match) {
  if (typeof window === 'undefined') return {}

  const pathname = window?.location?.pathname || ''
  const qs = window?.location?.search || ''
  const filteredPathname = pathname.replace(match?.path, '')

  return {qs, filteredPathname, fullPath: `${filteredPathname}${qs}`}
}
