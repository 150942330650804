import compose from 'recompose/compose'
import {graphql} from 'react-apollo'
import {GET_LISTING_URL} from '@/graphql/queries/searchListings'
import {getListingUrl} from '@/lib/listingsUrl'
import Redirect from '@/pages/context/side-effects/RedirectWithStatus'

export default compose(
  graphql(GET_LISTING_URL, {
    options: ({
      match: {
        params: {id}
      }
    }) => ({
      ssr: true,
      variables: {
        id
      }
    })
  })
)(
  ({
    location,
    data: {listing},
    match: {
      params: {rest}
    }
  }) => {
    if (!listing) {
      return null
    }
    const fwdPath = rest ? `/${rest}` : ''
    const pathname = `${getListingUrl(listing)}${fwdPath}`
    const search = location.search || null
    const redirect = process.browser
      ? {pathname, search}
      : {pathname: `${pathname}${search || ''}`}
    return <Redirect permanent to={redirect} />
  }
)
