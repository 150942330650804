import slugify from 'slugify'
import logger from '@/lib/logger'
import {USER_GEO_DATA_LOCALSTORAGE_KEY} from '@/config'
import sha256 from 'sha256'
import {MIN_PRICE} from '@/lib/values'
import {SUBWAY_DISTANCE_LIMIT} from '@/components/listing/lib'

export const USER_TYPE_PARTNER = 'partner_broker'
export const USER_TYPE_OWNER = 'property_owner'
export const USER_TYPE_REFERRER = 'partner_referrer'

const LISTING_TYPES = new Map([
  ['HOUSE', 'Casa'],
  ['APARTMENT', 'Apartamento']
])

const getTypes = (types, item) => {
  types = types === '' ? LISTING_TYPES.get(item) : `,${LISTING_TYPES.get(item)}`
  return types
}

export const getUserProfileSearchPreferencesLinkTo = ({
  stateSlug,
  citySlug,
  neighborhoods,
  maxValue,
  maxMaintenanceFee,
  minRooms,
  minBathrooms,
  minGarageSpots,
  nearbySubway,
  elevator,
  listingTypes
}) => {
  const filteredSearch = `?n=${neighborhoods?.toString() ||
    ''}&p=${MIN_PRICE}-${maxValue || ''}&mf=${maxMaintenanceFee ||
    ''}&q=${minRooms || ''}&b=${minBathrooms || ''}&gs=${minGarageSpots || ''}${
    nearbySubway ? `&sd=${SUBWAY_DISTANCE_LIMIT}` : ''
  }${elevator ? '&e=1' : ''}&t=${(listingTypes || []).reduce(getTypes, '')}`
    .replace(/\w+=&/g, '')
    .replace(/&\w+=$/, '')

  return {
    pathname: `/imoveis/${stateSlug}/${citySlug}`,
    search: filteredSearch,
    state: {scroll: 0}
  }
}

export const getUserProfileBuyerOwnerAvatar = (userProfile) =>
  userProfile?.activeBuyerOpportunity?.owner
    ? userProfile.activeBuyerOpportunity.owner?.pictureUrl ||
      '/img/icons/avatar-pink.png'
    : null

export function getSha256Data(user) {
  if (!user) return {}
  return {
    sha256_email: user?.email ? sha256(user.email) : '',
    sha256_phone_number: user?.phone ? sha256(user.phone) : ''
  }
}

export const getWhatsappUrl = ({phone, text}) => {
  let url = 'https://wa.me'
  if (phone) url += `/${phone.replace(/[^\d]/g, '')}`
  if (text) url += `?text=${encodeURIComponent(text)}`
  return url
}

export const getUserGeoData = () => {
  if (typeof window === 'undefined') return

  try {
    const data = localStorage.getItem(USER_GEO_DATA_LOCALSTORAGE_KEY)

    if (data) return JSON.parse(data)

    return {}
  } catch (e) {
    logger.capture(e)
  }

  return null
}

export const getUserGeoDataCity = () => {
  const userGeoData = getUserGeoData()
  if (userGeoData && userGeoData.city) return userGeoData.city

  return null
}

export const getUserGeoDataCitySlug = () => {
  const userGeoDataCity = getUserGeoDataCity()
  if (userGeoDataCity)
    return slugify(userGeoDataCity, {
      lower: true,
      strict: true
    })

  return null
}

export const getUserGeoDataToPavlov = () => {
  const userGeoData = getUserGeoData()
  if (userGeoData) {
    const {xForwardedFor, ...restUserGeoData} = userGeoData

    return {
      ...restUserGeoData,
      ipAddress: xForwardedFor?.split(',')[0] || null,
      userAgent: userGeoData.userAgent?.replace(/\([^)]*\)\s/, '')
    }
  }

  return {}
}
