import {useState} from 'react'
import logger from '@/lib/logger'
import Row from '@emcasa/ui-dom/components/Row'
import Text from '@emcasa/ui-dom/components/Text'
import {Input, InputWrapper} from './styles'
import styles from '@/styles/atoms/Input/styles.module.css'

export default function SliderInputInput({
  value,
  isValid,
  onChange,
  onBlur,
  loggerAction
}) {
  const [loggerInteraction, setInteraction] = useState(false)
  function onInputChange(inputValue) {
    onChange(inputValue)
    if (!loggerInteraction) {
      setInteraction(true)
      logger.action(loggerAction.onInputChange, {
        name: loggerAction.propName,
        value
      })
    }
  }
  const {min, max} = value
  return (
    <Row alignItems="center" justifyContent="space-between" mb={3}>
      <Row as="label" flex="1 1 50%" alignItems="center">
        <Text inline color="grey900" fontSize={14}>
          De
        </Text>
        <InputWrapper>
          <Input
            isValid={isValid}
            value={min}
            size={min.length}
            inputMode="tel"
            onChange={(e) => onInputChange({min: e.target.value, max})}
            onBlur={(e) => onBlur({min: e.target.value, max})}
            className={`${styles.ecInput} ${styles.ecInput_small}`}
          />
        </InputWrapper>
      </Row>
      <Row
        as="label"
        flex="1 1 50%"
        alignItems="center"
        justifyContent="flex-end"
        paddingLeft="2"
      >
        <Text inline color="grey900" fontSize={14}>
          Até
        </Text>
        <InputWrapper>
          <Input
            isValid={isValid}
            value={max}
            size={max.length}
            inputMode="tel"
            onChange={(e) => onInputChange({min, max: e.target.value})}
            onBlur={(e) => onBlur({min, max: e.target.value})}
            className={`${styles.ecInput} ${styles.ecInput_small}`}
          />
        </InputWrapper>
      </Row>
    </Row>
  )
}
