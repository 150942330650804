import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Button from '@/components/shared/Button'
import {
  requestPushNotificationToken,
  verifyPermission
} from '@/lib/pushNotification'
import logger from '@/lib/logger'
import styles from './styles.module.css'

const ENABLE_NOTIFICATION_EVENT = 'enable-notification-banner'

const EnableNotification = ({wrapperClass}) => {
  const [shouldHide, setShouldHide] = useState(false)
  const [notificationPermission, setNotificationPermission] = useState(null)

  const handleEnableClick = async () => {
    await requestPushNotificationToken(null, () => setShouldHide(true))

    logger.action(ENABLE_NOTIFICATION_EVENT, {
      type: 'enable'
    })
  }

  const handleHideClick = () => {
    setShouldHide(true)

    logger.action(ENABLE_NOTIFICATION_EVENT, {
      type: 'not-now'
    })
  }

  useEffect(() => {
    async function getPermission() {
      if (typeof window !== 'undefined') {
        const permission = await verifyPermission()
        setNotificationPermission(permission)
      }
    }

    getPermission()
  }, [])

  if (
    typeof window === 'undefined' ||
    typeof window.firebase === 'undefined' ||
    !window.firebase?.messaging?.isSupported() ||
    shouldHide ||
    notificationPermission !== 'prompt'
  )
    return null

  return (
    <div className={wrapperClass}>
      <div className={styles.ecEnableNotification__container}>
        <div className={styles.ecEnableNotification__left}>
          <h2 className={styles.ecEnableNotification__title}>
            Se mantenha atualizado
          </h2>
          <h3 className={styles.ecEnableNotification__description}>
            Ative suas notificações para não perder imóveis
          </h3>
        </div>
        <div className={styles.ecEnableNotification__right}>
          <Button
            className={[
              styles.ecEnableNotification__button,
              styles.ecEnableNotification__hideButton
            ].join(' ')}
            onClick={handleHideClick}
          >
            Não quero
          </Button>
          <Button
            active
            className={styles.ecEnableNotification__button}
            onClick={handleEnableClick}
          >
            Quero saber
          </Button>
        </div>
      </div>
    </div>
  )
}

EnableNotification.propTypes = {
  wrapperClass: PropTypes.string.isRequired
}

export default EnableNotification
