import {SUBWAY_DISTANCE_LIMIT} from '@/components/listing/lib'
import SharedButton from '@/components/shared/Button'
import styles from '@/styles/organisms/Filters/styles.module.css'

export function SubwayDistanceFilter({onChange, value}) {
  return (
    <div className={styles.ecFilters__filter__buttons}>
      <div>
        <SharedButton
          selected={value && value === SUBWAY_DISTANCE_LIMIT}
          small
          onClick={() => onChange(SUBWAY_DISTANCE_LIMIT)}
          selected={Boolean(value)}
        >
          Sim
        </SharedButton>
        <SharedButton
          selected={value && value === undefined}
          small
          onClick={() => onChange(undefined)}
          selected={Boolean(!value)}
        >
          Tanto faz
        </SharedButton>
      </div>
    </div>
  )
}
