import theme from '@emcasa/ui'
import fas from './icons/fas'
import light from './icons/light'
import emcasa from './icons/emcasa'

export default {
  ...theme,
  GOOGLE_FONTS_URL:
    'https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap',
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48],
  icons: {
    default: fas,
    emcasa,
    light
  }
}
