export const TagCategories = new Map([
  ['view', 'Vista'],
  ['concierge', 'Portaria'],
  ['realty', 'Imóvel'],
  ['infrastructure', 'Infraestrutura'],
  ['undefined', 'Outros']
])

export const Orientations = new Map([
  ['FRONTSIDE', 'Frente'],
  ['BACKSIDE', 'Fundos'],
  ['LATERAL', 'Lateral'],
  ['INSIDE', 'Meio'] // ??
])

export const SunPeriods = new Map([
  ['EVENING', 'Tarde'],
  ['MORNING', 'Manhã']
])

export const GarageTypes = new Map([
  ['CONDOMINIUM', 'Condomínio'],
  ['CONTRACT', 'Escritura']
])
