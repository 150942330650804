export const SET_OPTIONS = '@components/layout/SET_OPTIONS'
export const SET_NAV = '@components/layout/SET_NAV'
export const SET_MODAL = '@components/layout/SET_MODAL'
export const SET_BACKBUTTON = '@components/layout/SET_BACKBUTTON'
export const SHOW_LOGINMODAL = '@components/layout/SHOW_LOGINMODAL'

export const setLayoutOptions = (options) => ({
  type: SET_OPTIONS,
  options
})

export const setNav = (Component, props = {}) => ({
  type: SET_NAV,
  Component,
  props
})

export const setModal = (Component, props = {}) => ({
  type: SET_MODAL,
  Component,
  props
})

export const setBackButton = (actions) => ({
  type: SET_BACKBUTTON,
  to: actions.to,
  label: actions.label,
  icon: actions.icon,
  flipIcon: actions.flipIcon
})

export const showLoginModal = (actions) => ({
  type: SHOW_LOGINMODAL,
  show: actions.show,
  phone: actions.phone
})
