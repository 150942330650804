import {Route, Redirect, Switch} from 'react-router-dom'
import ErrorBoundary from './error/ErrorBoundary'
import ErrorPage from './error'
import HealthCheckPage from './health_check/lazy'
import HomePage from './home/lazy'
import AboutPage from './about/lazy'
import SpecialistsPage from './specialists/lazy'
import LoginPage from './login/lazy'
import ListingsRoutes from './listings/routes'
import SellerLanding from './seller/Landing/lazy'
import PartnerBrokerCreate from './partnerBroker/Create/lazy'
import PartnerBrokerLeadCreate from './partnerBroker/LeadCreate/lazy'
import PartnerBrokerFaq from './partnerBroker/FAQ/lazy'
import PartnerReferrerLeadCreate from './partnerReferrer/LeadCreate/lazy'
import SellerCreatePage from './seller/SellerCreatePage/lazy'
import Evaluator from '../pages/evaluator/lazy'
import getHubRoutes from './hub/routes'
import YouPage from './you/lazy'
import SiteMapPage from './sitemap/lazy'
import LegalPage from './legal/lazy'
import {LISTINGS_ROOT_PATH} from '@/lib/listingsUrl'
import OriginRoute from '@/components/shared/OriginRoute'
import {ROUTES_FROM_ORIGIN, NEARBY_SETTINGS} from '@/config'
import {ABOUT_YOU_LSS_ROUTER_TO} from '@/components/you/constants'

export const availableRoutes = [
  '/quem-somos',
  '/especialistas-de-vendas',
  '/especialistas-de-venda',
  '/especialistas',
  '/especialista',
  '/login',
  '/vender',
  '/vender-imovel',
  '/avaliar',
  '/legal',
  '/mapa',
  '/corretor-parceiro',
  '/indique-imoveis',
  '/indique-imoveis/cadastrar',
  '/indique-imoveis/indicar',
  '/hub',
  '/visitas',
  '/interesses',
  '/perfil',
  '/meus-imoveis',
  '/atualizar-perfil-proprietario',
  '/propostas',
  '/imoveis',
  '/voce'
]

const NotFound = () => <ErrorPage emit code={404} message="Not found" />

export default function Router({useRoutesFromOrigin = false}) {
  return (
    <ErrorBoundary>
      <Switch>
        <Redirect exact from="/index.html" to="/" />
        <Redirect from="/falar-com-especialista" to="/" />
        {useRoutesFromOrigin && (
          <Route path={ROUTES_FROM_ORIGIN} component={OriginRoute} />
        )}
        <Route
          exact
          path="/:city(sao-paulo|rio-de-janeiro)?"
          component={HomePage}
        />
        <Route exact path="/quem-somos" component={AboutPage} />
        <Route
          exact
          path="/especialistas-de-vendas"
          component={SpecialistsPage}
        />
        <Redirect
          from="/especialistas-de-venda"
          to="/especialistas-de-vendas"
        />
        <Redirect from="/especialista" to="/especialistas-de-vendas" />
        <Redirect from="/especialistas" to="/especialistas-de-vendas" />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/health_check" component={HealthCheckPage} />
        <Route exact path="/vender" component={SellerLanding} />
        <Route exact path="/vender-imovel" component={SellerCreatePage} />
        <Route exact path="/avaliar" component={Evaluator} />
        <Route exact path="/legal" component={LegalPage} />
        <Route exact path="/sitemap" component={SiteMapPage} />
        <Redirect
          from="/mapa"
          to={
            NEARBY_SETTINGS
              ? `${LISTINGS_ROOT_PATH}/${NEARBY_SETTINGS.url}/mapa`
              : `${LISTINGS_ROOT_PATH}/mapa`
          }
        />
        <ListingsRoutes path={LISTINGS_ROOT_PATH} />
        <Route exact path="/voce" component={YouPage} />
        <Route
          exact
          path={ABOUT_YOU_LSS_ROUTER_TO.pathname}
          component={YouPage}
        />
        <Route
          exact
          path="/corretor-parceiro/cadastrar"
          component={PartnerBrokerCreate}
        />
        <Route
          exact
          path="/corretor-parceiro/indicar"
          component={PartnerBrokerLeadCreate}
        />
        <Route
          exact
          path="/corretor-parceiro/faq"
          component={PartnerBrokerFaq}
        />
        <Route
          exact
          path="/indique-imoveis/indicar"
          component={PartnerReferrerLeadCreate}
        />
        {getHubRoutes()}
        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  )
}
