import styled from 'styled-components'
import {layout, color, opacity} from 'styled-system'

export const Container = styled.div`
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  ${layout};
`

export const Content = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  backdrop-filter: blur(0);
  ${color};
  ${opacity};
  ${(props) =>
    props.shadow &&
    `
    span {
      text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
    }
    svg {
      filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.6));
    }
    `}
`
