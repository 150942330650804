import gql from 'graphql-tag'

export default gql`
  query offerListing($id: ID!) {
    listing(id: $id) {
      id
      area
      price
      propertyTax
      maintenanceFee
      address {
        neighborhood
        street
        streetNumber
        city
        state
      }
    }
  }
`
