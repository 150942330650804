import {useFormContext, Controller} from 'react-hook-form'
import Text from '@emcasa/ui-dom/components/Text'
import labelStyles from '@/styles/atoms/Label/styles.module.css'
import textareaStyles from '@/styles/atoms/Textarea/styles.module.css'
import YesNoButtonGroup from '@/components/form/YesNoButtonGroup'
import CurrencyInput from '@/components/form/CurrencyInput'
import NumberInput from '@/components/form/NumberInput'
import Financing from '@/components/listing/components/Financing'

const TERM_MIN = 12
const TERM_MAX = 420

export default function OfferFinancing() {
  const {errors, control, watch, getValues} = useFormContext()
  const {creditTotalValue, hasApprovedCredit, creditDownPayment, term} = watch()
  const termAsYears = term ? Math.max(TERM_MIN / 12, Math.floor(term / 12)) : 0
  const downPaymentPct =
    creditTotalValue && creditDownPayment
      ? (creditDownPayment / creditTotalValue) * 100
      : 0

  return (
    <>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>
          Você tem carta de crédito aprovada?
        </span>
        <Controller
          control={control}
          name="hasApprovedCredit"
          rules={{
            validate: {
              required: (value) => value !== null
            }
          }}
          as={YesNoButtonGroup}
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>
          Valor total da proposta
        </span>
        <Controller
          name="creditTotalValue"
          control={control}
          rules={{required: true, validate: (v) => v > 0}}
          as={CurrencyInput}
          arial-label="Insira o valor da proposta"
          placeholder="Ex: 2.500.000"
        />
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>
          Entrada ({Math.floor(downPaymentPct)}%)
        </span>
        <Controller
          name="creditDownPayment"
          rules={{
            required: true,
            validate: (value) => {
              if (isNaN(value)) {
                return false
              }
              const pct = (value / getValues('creditTotalValue')) * 100
              return pct < 20
                ? 'A entrada deve ser no mínimo 20%'
                : pct > 80
                ? 'A entrada deve ser no máximo 80%'
                : true
            }
          }}
          as={CurrencyInput}
          arial-label="Insira o valor da entrada"
          placeholder="Ex: 500.000 (20%)"
          control={control}
        />
        {errors.creditDownPayment ? (
          <Text fontSize="small" color="red500">
            {errors.creditDownPayment.message}
          </Text>
        ) : null}
      </label>
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Pretende usar FGTS?</span>
        <Controller
          control={control}
          name="needFgtsWithdrawal"
          rules={{
            validate: {
              required: (value) => value !== null
            }
          }}
          as={YesNoButtonGroup}
        />
      </label>
      {!hasApprovedCredit && (
        <>
          <label className={labelStyles.ecLabel}>
            <span className={labelStyles.ecLabel__value}>
              Prazo de financiamento em meses
              {term ? ` (${termAsYears} anos)` : null}
            </span>
            <Controller
              name="term"
              rules={{
                required: true,
                min: TERM_MIN,
                max: TERM_MAX,
                validate: (v) =>
                  v < TERM_MIN
                    ? `O prazo deve ser no mínimo de ${TERM_MIN} meses`
                    : v > TERM_MAX
                    ? `O prazo deve ser no máximo ${TERM_MAX} meses`
                    : true
              }}
              step={12}
              as={NumberInput}
              control={control}
              arial-label="Insira o valor da entrada"
              placeholder="Ex: 120 meses"
            />
            {errors.term ? (
              <Text fontSize="small" color="red500">
                {errors.term.message}
              </Text>
            ) : null}
          </label>
          <div style={{marginBottom: 25}}>
            <Financing
              price={creditTotalValue || 0}
              percentageStart={
                termAsYears > 0 && !errors.term ? downPaymentPct : 0
              }
              term={termAsYears}
              hideSlider={true}
              hideTerm={true}
            />
          </div>
        </>
      )}
      <label className={labelStyles.ecLabel}>
        <span className={labelStyles.ecLabel__value}>Observações</span>
        <Controller
          control={control}
          rules={{required: false}}
          as={<textarea className={textareaStyles.ecTextarea} />}
          name="note"
          arial-label="Insira sua observação"
          placeholder="Digite aqui"
        />
      </label>
    </>
  )
}
