const LEVELS = {
  error: 0,
  warn: 1,
  info: 2,
  verbose: 3,
  debug: 4,
  silly: 5
}

export default LEVELS

/**
 * Get level number
 * @param {String|Number} level
 */
export const getLevel = (level, def) => {
  const num = level == 'none' ? -1 : isNaN(level) ? LEVELS[level] : level
  return isNaN(num) ? def : num
}

/**
 * Creates a logger object with methods for each level
 * @param {Function} factory
 */
export function createLogger(factory, options = {}) {
  const logger = {log: factory(), ...options}
  return Object.keys(LEVELS).reduce(
    (logger, level) => ({
      ...logger,
      [level]: factory(level).bind(logger)
    }),
    logger
  )
}
