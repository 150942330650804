import {useState, useEffect} from 'react'
import {GOOGLE_MAPS_API_KEY} from '@/config'
import fetchScript from '@/lib/helpers/loadScript'

const useLoadMapScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [isMapAvailable, setIsMapAvailable] = useState(false)

  if (typeof window === 'undefined') return {isMapScriptLoaded: false}

  const isGooglePresent = !!window.google

  useEffect(() => {
    if (!isGooglePresent) {
      window.onMapScriptLoaded = () => {
        setIsMapAvailable(true)
      }
    }

    fetchScript({
      id: 'google-maps-script',
      idsToIgnore: ['__googleMapsScriptId'],
      url: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,drawing&region=BR&language=pt-br&callback=onMapScriptLoaded`,
      defer: true,
      callback: () => setIsScriptLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (isMapAvailable) {
      delete window.onMapScriptLoaded
    }
  }, [isMapAvailable])

  return {
    isMapScriptLoaded: isScriptLoaded && !!window.google
  }
}

export default useLoadMapScript
