import {isArray, isPlainObject} from 'lodash'

export const PLACEHOLDER = ` ${String.fromCharCode(8212)} `
export const POSTALCODE_LENGTH = 8

export const number = (num) =>
  Number(num)
    .toLocaleString('pt-BR')
    .replace(/,/g, '.')

export const minTwoDigits = (num) => (num < 10 ? '0' : '') + num

export const date = (dateString) => {
  const date = new Date(dateString)
  return [
    String(date.getDate()).padStart(2, '0'),
    String(date.getMonth() + 1).padStart(2, '0'),
    date.getFullYear()
  ].join('/')
}

export const property = (
  value,
  singular,
  plural = singular + 's',
  empty = PLACEHOLDER
) => {
  if (value === 1) return `${value} ${singular}`
  if (value > 1) return `${value} ${plural}`
  else return empty
}

export const floor = (floor, withoutText) => {
  if (!isNaN(floor) && floor !== null)
    return `${floor}º${withoutText ? '' : ' andar'}`
  else if (floor) return floor
  else return PLACEHOLDER
}

export const price = (num) => `R$${number(num)}`

export const getInputMaskPhoneNumber = (value) => {
  const cleanValue = value ? value.replace(/\D/g, '') : ''
  const match =
    cleanValue.length > 10
      ? /(\d{0,2})(\d{0,5})(\d{0,4})/
      : /(\d{0,2})(\d{0,4})(\d{0,4})/
  const x = cleanValue.match(match)
  return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
}

export const getInputMaskCep = (value) => {
  const cleanValue = value ? value.replace(/\D/g, '') : ''
  const re = /^([\d]{5})-*([\d]{3})/
  const limitValue =
    cleanValue && cleanValue.length > POSTALCODE_LENGTH
      ? String(cleanValue).substr(0, POSTALCODE_LENGTH)
      : cleanValue
  return limitValue.replace(re, '$1-$2')
}

export const clearPhoneString = (phoneString) => {
  if (!phoneString) {
    return phoneString
  }
  return phoneString
    .replace('(', '')
    .replace(')', '')
    .replace(/ /g, '')
    .replace('-', '')
    .replace(/_/g, '')
}

export const addInternationalCode = (phone) => {
  const prefix = '+55'
  if (phone && !phone.startsWith(prefix)) {
    return `${prefix}${phone}`
  }
  return phone
}

export const removeInternationalCode = (phone) => {
  const prefix = '+55'
  if (phone && phone.startsWith(prefix)) {
    return phone.replace(prefix, '')
  }
  return phone
}

export const nameFromSlug = (slug) => {
  return slug
    .replace(/-/g, ' ')
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
}

export const getFirstName = (name) =>
  name.substring(0, name.indexOf(' ') !== -1 ? name.indexOf(' ') : name.length)

export const camelCaseToSnakeCase = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1')

  return result
    .split(' ')
    .join('_')
    .toLowerCase()
}

export const transformCamelCaseKeysToSnakeCase = (obj) => {
  if (isPlainObject(obj)) {
    const newObj = {}

    Object.keys(obj).forEach(
      (key) =>
        (newObj[camelCaseToSnakeCase(key)] = transformCamelCaseKeysToSnakeCase(
          obj[key]
        ))
    )

    return newObj
  } else if (isArray(obj)) {
    obj.map((item) => transformCamelCaseKeysToSnakeCase(item))
  }

  return obj
}
