import useGeolocation from '@/lib/hooks/useGeolocation'

export default (requestLocation = () => true, onLoad = () => null) => (
  Component
) =>
  function withGeolocation({...props}) {
    props.geolocation = useGeolocation({
      onLoad: onLoad.bind(null, props),
      askLocation: requestLocation(props)
    })
    return <Component {...props} />
  }
