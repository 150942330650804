export function getListingsDescription({type, city, neighborhood, street}) {
  const listingsType = type ? `${type.toLowerCase()}s` : 'apartamentos e casas'

  const listingsDescription = `Encontramos o imóvel ideal para você! Confira ${listingsType} à venda em $location. Na EmCasa, a compra e venda de imóveis é muito mais prática e segura!`

  if (street)
    return listingsDescription.replace('$location', `${street}, ${city}`)

  if (neighborhood)
    return listingsDescription.replace('$location', `${neighborhood}, ${city}`)

  return listingsDescription.replace('$location', city)
}
