import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'

const HeadHelmet = ({
  title,
  description,
  url,
  canonical,
  image,
  children,
  ...props
}) => {
  return (
    <Helmet {...props}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="image_src" type="image/jpeg" href={image} />
      <meta name="author" content="EmCasa" />
      <meta name="publisher" content="EmCasa" />
      {(canonical || url) && <link rel="canonical" href={canonical || url} />}

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={image} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {children}
    </Helmet>
  )
}

HeadHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
}

export default HeadHelmet
