import Slider from '@emcasa/ui-dom/components/Slider'
import {Marker, trackProps} from './styles'

export default function SliderInputSlider({value, range, step, onChange}) {
  const round = (value) => Math.round(value / step) * step
  return (
    <Slider
      value={value}
      initialValue={value}
      onChange={({min, max}) => {
        if (value.min !== min || value.max !== max)
          onChange({min: round(min), max: round(max)})
      }}
      range={range}
      height={8}
      trackProps={trackProps.default}
    >
      <Marker name="min" trackProps={trackProps.default} />
      <Marker name="max" trackProps={trackProps.active} />
    </Slider>
  )
}
