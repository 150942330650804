import gql from 'graphql-tag'
import PartialListing from '../fragments/PartialListing'
import {MAX_FETCH_ITEMS} from '@/config'

export default gql`
  query userFavoritesListings {
    userProfile {
      id
      favorites(pagination: {pageSize: ${MAX_FETCH_ITEMS}}, filters: {}) {
        ...PartialListing
      }
    }
  }
  ${PartialListing}
`
