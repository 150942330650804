export const NAVIGATETO = '@components/you/NAVIGATETO'
export const STEP_LOCATION = '@components/you/STEP_LOCATION'
export const STEP_TYPE = '@components/you/STEP_TYPE'
export const STEP_PRICE = '@components/you/STEP_PRICE'
export const STEP_CONDO = '@components/you/STEP_CONDO'
export const STEP_DETAILS = '@components/you/STEP_DETAILS'
export const STEP_PREFERENCES = '@components/you/STEP_PREFERENCES'
export const STEP_BUYER_STAGE = '@components/you/STEP_BUYER_STAGE'
export const STEP_PAYMENT_TYPE = '@components/you/STEP_PAYMENT_TYPE'
export const STEP_PROFILE = '@components/you/STEP_PROFILE'
export const STEP_FINANCING = '@components/you/STEP_FINANCING'
export const STEP_SELL_TO_BUY = '@components/you/STEP_SELL_TO_BUY'

export const navigateTo = (value) => {
  return {
    type: NAVIGATETO,
    value: value
  }
}

export const updateStepLocation = (value) => {
  return {
    type: STEP_LOCATION,
    value: value
  }
}

export const updateStepType = (value) => {
  return {
    type: STEP_TYPE,
    value: value
  }
}

export const updateStepPrice = (value) => {
  return {
    type: STEP_PRICE,
    value: value
  }
}

export const updateStepCondo = (value) => {
  return {
    type: STEP_CONDO,
    value: value
  }
}

export const updateStepDetails = (value) => {
  return {
    type: STEP_DETAILS,
    value: value
  }
}

export const updateStepPreferences = (value) => {
  return {
    type: STEP_PREFERENCES,
    value: value
  }
}

export const updateStepBuyerStage = (value) => {
  return {
    type: STEP_BUYER_STAGE,
    value: value
  }
}

export const updateStepPaymentType = (value) => {
  return {
    type: STEP_PAYMENT_TYPE,
    value: value
  }
}

export const updateStepProfile = (value) => {
  return {
    type: STEP_PROFILE,
    value: value
  }
}

export const updateStepFinancing = (value) => {
  return {
    type: STEP_FINANCING,
    value: value
  }
}

export const updateStepSellToBuy = (value) => {
  return {
    type: STEP_SELL_TO_BUY,
    value: value
  }
}
