import {useState} from 'react'
import NumberFormat from 'react-number-format'
import inputStyles from '@/styles/atoms/Input/styles.module.css'

export default function CurrencyInput({
  onChange,
  onBlur,
  value,
  mutableValue,
  ...rest
}) {
  const [currency, setCurrency] = useState(value)
  return (
    <NumberFormat
      {...rest}
      value={!mutableValue ? (currency === 0 ? null : currency) : value}
      thousandSeparator="."
      decimalSeparator=","
      className={inputStyles.ecInput}
      decimalScale={2}
      onBlur={onBlur}
      onValueChange={(target) => {
        setCurrency(target.floatValue)
        onChange(target.floatValue)
      }}
      isNumericString
      prefix="R$ "
    />
  )
}
