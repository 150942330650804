import SharedButton from '@/components/shared/Button'
import styles from '@/styles/organisms/Filters/styles.module.css'

export function ElevatorFilter({onChange, value}) {
  return (
    <div className={styles.ecFilters__filter__buttons}>
      <div>
        <SharedButton
          selected={value && value === 'Sim'}
          small
          onClick={() => onChange(true)}
          selected={Boolean(value)}
        >
          Sim
        </SharedButton>
        <SharedButton
          selected={value && value === 'Tanto faz'}
          small
          onClick={() => onChange(undefined)}
          selected={Boolean(!value)}
        >
          Tanto faz
        </SharedButton>
      </div>
    </div>
  )
}
