export const CITY_CHANGED = '@/listings/search/CITY_CHANGED'
export const REDIRECT_TO_SEARCH = '@/listings/search/REDIRECT_TO_SEARCH'

export const changeCity = (city) => ({
  type: CITY_CHANGED,
  city
})

export const redirectToSearch = (filters) => ({
  type: REDIRECT_TO_SEARCH,
  filters
})
