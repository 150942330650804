export const LISTING_ORIENTATIONS = {
  BACKSIDE: 'Fundos',
  FRONTSIDE: 'Frente',
  LATERAL: 'Lateral',
  INSIDE: 'Meio'
}

export const LISTING_SUNPERIOD = {
  EVENING: 'Tarde',
  MORNING: 'Manhã'
}

export const LISTING_GARAGETYPE = {
  CONDOMINIUM: 'Condomínio',
  CONTRACT: 'Escritura'
}
