import styled from 'styled-components'
import {flexbox} from 'styled-system'
import {themeGet} from '@styled-system/theme-get'
import Row from '@emcasa/ui-dom/components/Row'
import BaseButton from '@emcasa/ui-dom/components/Button'

const Button = styled(BaseButton).attrs({height: 'tall'})`
  font-size: 16px;
  background-color: ${({selected, theme}) =>
    selected ? theme.colors.purple500 : theme.colors.white};
  color: ${({selected, theme}) =>
    selected ? theme.colors.white : theme.colors.grey900};
  margin-bottom: ${themeGet('space.2')}px;
  margin-right: ${themeGet('space.2')}px;
  border-color: ${themeGet('colors.grey300')};
  ${flexbox};
`

Button.Flex = ({flex, ...props}) => (
  <Row flex={flex}>
    <Button flex={1} {...props} />
  </Row>
)

Button.Group = (props) => <BaseButton.Group {...props} flexWrap="wrap" />

export default Button
