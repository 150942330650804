import {getLevel, createLogger} from '@/lib/logger/levels'

const tag = (color) =>
  `color:white;font-weight:bold;background:${color};border-radius:8px;padding:0 4px;`

const STYLES = {
  error: tag('#db2828'),
  warn: tag('#fbbd08'),
  info: tag('#c4c64f'),
  verbose: tag('#6435c9'),
  debug: tag('#2185d0'),
  silly: tag('#21ba45'),
  action: tag('#ff2b7f'),
  actionName: 'color:#ff2b7f;font-weight:bold;'
}

let LOG_LEVEL

const logFactory = (level) =>
  function log(...message) {
    if (!level) console.log(...message)
    else if (this.getLevel() >= getLevel(level)) {
      const fn = console[level] || console.log
      fn('%c%s', STYLES[level], level, ...message)
    }
  }

export default createLogger(logFactory, {
  getLevel() {
    return LOG_LEVEL
  },
  setLevel(level) {
    LOG_LEVEL = getLevel(level)
  },
  capture: logFactory('error'),
  action(action, props) {
    if (this.getLevel() >= getLevel('debug')) {
      console.debug(
        '%c%s%c%s',
        STYLES.action,
        'action',
        STYLES.actionName,
        ' ' + action,
        props
      )
    }
  }
})
