import gql from 'graphql-tag'

export default gql`
  query tags {
    tags {
      uuid
      name
      nameSlug
      category
    }
  }
`
