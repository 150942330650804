import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import layout from './layout/reducer'
import you from './you/reducer'
import search from './search/reducer'

export default ({history}) =>
  combineReducers({
    layout,
    you,
    search,
    router: history ? connectRouter(history) : () => ({})
  })
