import React from 'react'
import Button from '@/components/form/Button'
import SharedButton from '@/components/shared/Button'
import {Orientations, SunPeriods, GarageTypes} from '@/config/enum/filters'
import styles from '@/styles/organisms/Filters/styles.module.css'

function EnumFilter({options, initialValue = [], onChange, value}) {
  return (
    <div className={styles.ecFilters__filter__buttons}>
      <Button.Group
        strategy="switchable"
        initialValue={initialValue[0]}
        onChange={(v) => {
          onChange([v].filter(Boolean))
        }}
      >
        {Array.from(options).map(([btnValue, label]) => {
          return (
            <SharedButton
              small
              key={btnValue}
              selected={value && value[0] === btnValue}
              value={btnValue}
            >
              {label}
            </SharedButton>
          )
        })}
      </Button.Group>
    </div>
  )
}

const PureEnumFilter = React.memo(EnumFilter)

export const OrientationFilter = (props) => (
  <PureEnumFilter options={Orientations} {...props} />
)

export const SunPeriodFilter = (props) => (
  <PureEnumFilter options={SunPeriods} {...props} />
)

export const GarageTypeFilter = (props) => (
  <PureEnumFilter options={GarageTypes} {...props} />
)
