import Loader from '@/components/shared/Loader'
import styles from '@/styles/molecules/ListingGallery/styles.module.css'

export const SIZE = 30

const GalleryLoader = () => (
  <span className={styles.ecListingGallery__item__loader}>
    <Loader />
  </span>
)

export default GalleryLoader
