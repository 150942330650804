import React from 'react'
import Slider from '@/components/form/SliderInput'

function MaintenanceFeeFilter({initialValue, onChange}) {
  return (
    <Slider
      onChange={onChange}
      initialValue={initialValue}
      step={5}
      range={[0, 5000]}
      limit={[0, 50000]}
      format={(num) => `R$ ${num}`}
      loggerAction={{
        onInputChange: 'listing-search-filter-input',
        propName: 'maintenanceFee'
      }}
    />
  )
}

export default React.memo(MaintenanceFeeFilter)
